import {Link} from "./link.model";

export class MetaDataModel {
    id: number;
    name: string;
    icon: string;
    image: string; // deprecated
    images: Array<string>; // deprecated
    instructionImages: Array<string>; // media, images, videos
    description: string;
    shortDescription: string;
    instructions: string;
    locationType: string;
    isTemplate: boolean;

    weekly: number;
    daily: number;
    eachTime: number;
    eachSet: number;
    usedTemplateId: number;
    expirationDate: number;
    timeOfDay: string;
    weekDays: string[];

    taskTypeId: number;
    urls: Array<string>;
    dosage: number;
    unitOfMeasure: string;
    medicationRoute: string;
    doseTaken: number;
    procedureCode: string;
    reason: string;
    therapyEntityId: string;
    attachments: Array<any>;

    serviceTypeIds: Array<number>;
    selections: any;

    deletedAttachmentIds: any[];

    links;
    recommended?: boolean;
}

export class ObservationModel {
    id?: string;
    name: string;
    description: string;
    procedure: string;
    unitOfMeasure: string;
    lowerLimitRange: number;
    upperLimitRange: number;
}

export class ServiceModel {
    id?: string;
    name: string;
    description: string;
    nameByLanguageContext: string;
    taskTypes: any[];
    specialties: any[];
    patientId?: string;
    surveyId?: string;
}

export class SpecialtyModel {
    id?: string;
    name: string;
    description: string;
    serviceTypes: any[];
}

export class MasterDataModel {
    id?: string;
    name: string;
    dataType: string;
    dataTypeAlias: string;
    attributes: string;
    deviceModels: any[];
    description: string;
    labOrderOverride: 'ELIS' | 'RUME' | '';
    shortName: string;
    price: any;
    specimenType: string;
    timeForResults: any;
    surveyId: number;
    rank: number;
    imageId: string;
    links: any;
    cptCode: any;
    panelCode: any;
    testingLocation: string;
    loincTestPerformedCode: any;
    disabled?: boolean;
    isTemplate?: boolean;
}

export class DeviceModel {
    id?: string;
    name: string;
    testProtocol: string;
    faqSheet: string;
}

export class ProgramTypeModel {
    id?: string;
    name: string;
    description: string;
}

export class ProgramCategoryModel {
    id?: string;
    name: string;
    description: string;
}

export class InsuranceProviderModel {
    id?: string;
    payerName: string;
    payerId: string;
}
