import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import config from '../../../../config';
import { AssessmentReportResource } from '../../models/activity.model';
import {
    Assessment,
    AssessmentAdminListResponse,
    AssessmentAnswer,
    AssessmentQuestion,
    RequestRedoAssessment,
    Survey,
} from '../../models/assessment.model';
import { MetaDataModel } from '../../models/metadata.model';
import { AnswersPatientStats } from '../../models/patient.model';
import { TherapyTypeModel } from '../../models/therapy-type';
import { DateUtility } from '../../utils/date-utility';
import { LinkUtil } from '../../utils/link-util/link-util.service';
import { DataBrokerService } from '../data-broker/data-broker.service';
import { NetworkService } from '../network/network.service';
import { UserSessionService } from '../user-session/user-session.service';

export class ResponseAnswerStats {
    averageSpentTime: number;
    completionRate: number;
}

@Injectable({
    providedIn: 'root',
})
export class AssessmentService {
    baseURL = '/api/surveys/';

    constructor(
        private data: DataBrokerService,
        private session: UserSessionService,
        private network: NetworkService,
        private linkUtil: LinkUtil,
        private fb: UntypedFormBuilder,
        private dateUtil: DateUtility,
    ) {}

    public getAssessment(assessmentId: string): Observable<Assessment> {
        const url = `${this.baseURL}${assessmentId}`;

        return this.network.fetchResource(url);
    }

    public getAssessments(
        organizationId: string,
        {
            page = 0, size = 50, orderType = '', asc = null, ownerId = null, name = ''
        },
    ): Observable<AssessmentAdminListResponse> {
        const isSuperAdmin = +this.session.sessionAccount.privilege.id === config.PRIVILEGEID.SUPER_ADMIN;
        let url = `/api/surveys/organization/${organizationId}`;
        let params = new HttpParams().set('page', page.toString()).set('size', size.toString());

        if (typeof asc === 'boolean') params = params.set('asc', asc);
        if (orderType) params = params.set('orderType', orderType);

        if (isSuperAdmin) {
            url = this.linkUtil.parseLink(this.session.sessionAccount.links, 'assessmentList');
        }

        if (name) {
            params = params.set('name', name);
        }

        if (ownerId) {
            params = params.append('ownerId', ownerId.toString());
        }

        return this.network.fetchResource<AssessmentAdminListResponse>(url, { params });
    }

    getAssessmentsFilters(organizationId: string): Observable<{ ownerFilter: any }> {
        const isSuperAdmin = +this.session.sessionAccount.privilege.id === config.PRIVILEGEID.SUPER_ADMIN;
        let url = `/api/surveys/organization/${organizationId}/filters`;

        if (isSuperAdmin) {
            url = '/api/surveys/public-filters';
        }

        return this.network.fetchResource(url);
    }

    public createAssessment(assessment: Assessment): Observable<Assessment> {
        const createAssessmentLink = '/api/surveys';

        return this.network.postResource(createAssessmentLink, assessment);
    }

    public updateAssessment(assessment: Assessment): Observable<any> {
        const url = `/api/surveys/${assessment.id}`;

        return this.network.putResource(url, assessment);
    }

    public createAssessmentImage(id: string, image: string): Observable<any> {
        const url = `/api/surveys/${id}/picture`;

        return this.network.postResource(url, { data: image });
    }

    public deleteAssessmentImage(id: string): Observable<any> {
        const url = `/api/surveys/${id}/picture`;

        return this.network.deleteResource(url);
    }

    public deleteAssessment(assessment, flag?:boolean) {
        let deleteAssessmentLink: string;
        if (flag) {
            deleteAssessmentLink = this.linkUtil.parseLink(assessment.links, 'assessment');
        } else {
            deleteAssessmentLink = this.linkUtil.parseLink(assessment.links, 'deleteAssessment');
        }
        return this.network.deleteResource(deleteAssessmentLink);
    }

    public getGenderVotingStats(question) {
        const genderVotingStatsLink = this.linkUtil.parseLink(question.links, 'answerGenderStats');

        return this.network.fetchResource<any>(genderVotingStatsLink);
    }

    public getPatientsStats(questionId: string): Observable<AnswersPatientStats[]> {
        const patientsStatsLink = `/api/surveys/questions/${questionId}/answers/patients`;

        return this.network.fetchResource<AnswersPatientStats[]>(patientsStatsLink);
    }

    public createAnswer(questionId: string, answer: AssessmentAnswer, lang: string): Observable<AssessmentAnswer> {
        const createAnswerLink = `/api/surveys/questions/${questionId}/choices`;
        const headers = lang ? { 'Content-Language': lang } : null;

        return this.network.postResource(createAnswerLink, answer, { headers });
    }

    public updateAnswer(answer: AssessmentAnswer, lang: string): Observable<AssessmentAnswer> {
        const url = `/api/surveys/choices/${answer.id}`;
        const headers = lang ? { 'Content-Language': lang } : null;

        return this.network.putResource(url, answer, { headers });
    }

    public deleteAnswer(answerId: string): Observable<any> {
        const url = `/api/surveys/choices/${answerId}`;

        return this.network.deleteResource(url);
    }

    public getTherapy(taskTypeId: number, entityType: string, entityId: string): Observable<MetaDataModel> {
        const link = `/api/meta/therapy?taskTypeId=${taskTypeId}&entityType=${entityType}&entityId=${entityId}`;

        return this.network.fetchResource(link).pipe(map((therapies: any[]) => therapies[0]));
    }

    public createTherapy(therapy): Observable<TherapyTypeModel> {
        return this.network.postResource<any>('/api/meta/therapy', therapy);
    }

    public updateTherapy(therapy) {
        return this.network.putResource<any>(`/api/meta/therapy/${therapy?.id}`, therapy);
    }

    public deleteTherapy(therapy) {
        return this.network.deleteResource(`/api/meta/therapy/${therapy?.id}`);
    }
    public deleteTherapyByEntity(entityId) {
        return this.network.deleteResource(`/api/meta/therapy/entity/${entityId}`);
    }

    public deleteTherapyIcon(therapy) {
        return this.network.deleteResource(`/api/meta/therapy/${therapy?.id}/image`);
    }

    public getAnswerStats(assessment: Assessment): Observable<ResponseAnswerStats> {
        const start = this.dateUtil.getToday().toDate();
        start.setDate(start.getDate() + 1);
        const dateTo = this.dateUtil.formatDate(start, 'YYYY-MM-DD');

        const url = `/api/surveys/${assessment.id}/answer-stats?dateFrom=1970-01-01&dateTo=${dateTo}`;

        return this.network.fetchResource(url);
    }

    public getQuestionsByAssessmentId(assessmentId: string): Observable<AssessmentQuestion[]> {
        const link = `/api/surveys/${assessmentId}/questions?page=0&size=100`;

        return this.network.fetchResource(link).pipe(map((res: any) => res.content));
    }

    public getAssessmentReports(patientId: string): Observable<AssessmentReportResource[]> {
        const link = `/api/surveys/patient/${patientId}/assessment-reports`;

        return this.network.fetchResource(link);
    }

    public getAssessmentReportsByPatientIdAndSurveyId(patientId: string, surveyId: string): Observable<AssessmentReportResource> {
        const link = `/api/surveys/patient/${patientId}/assessment-reports/${surveyId}`;

        return this.network.fetchResource(link)
    }

    public requestRedoAssessment(patientId: string, parentActivityId: string): Observable<RequestRedoAssessment> {
        const url = `/api/surveys/patient/${patientId}/requestRedoAssessment`;
        let params = new HttpParams();

        if (parentActivityId) params = params.set('parentActivityId', parentActivityId);

        return this.network.postResource(url, {}, { params });
    }

    getAnswerBreakdown(assessmentId: string) {
        const url = `/api/surveys/${assessmentId}/answer-breakdown`;

        return this.network.fetchResource(url);
    }

    getPublicBrief(): Observable<Survey[]> {
        const url = '/api/surveys/public-brief';

        return this.network.fetchResource(url);
    }

    assessmentReport(body, { headers }): Observable<{ priority: string; careplanName: string }> {
        const url = '/api/surveys/assessment-report';

        return this.network.postResource<{ priority: string; careplanName: string }>(url, body, { headers });
    }
}
