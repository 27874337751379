import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import * as Sentry from '@sentry/browser';
import { InjectorInstance } from 'src/app/app.module';

export default class ErrorLog {
    constructor(error) {
        window['dataLayer'] ||= [];
        const dataLayer = window['dataLayer'];
        const location = InjectorInstance.get(LocationStrategy);
        const message = error.message ? error.message : error.toString();
        const url = location instanceof PathLocationStrategy
            ? location.path()
            : '';

        const event = {
            event: 'gtm.pageError',
            'gtm.errorLineNumber': '',
            'gtm.errorMessage': message,
            'gtm.errorUrl': url,
            'gtm.uniqueEventId': dataLayer.length + 1,
        };

        dataLayer.push(event);

        const extra = {
            code: error.error.code || 'N/A',
            httpStatus: error.error.httpStatus || 'N/A',
            errorCorrelationId: error.error.errorCorrelationId || 'N/A',
            endpoint: url,
        };

        Sentry.captureException(new Error(JSON.stringify({ message })), { extra });
    }
}
