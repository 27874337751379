import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IPatientGroups } from 'src/app/core/models/patient-groups.model';
import { NetworkService } from 'src/app/core/services/network/network.service';
import { IProductItem } from 'src/app/core/services/product-item/product-items.interface';

@Injectable({
    providedIn: 'root',
})
export class SetupVaccinationSlotsServiceService {
    _selectedTab = new BehaviorSubject<any>(0);
    set selectedTab(value) {
        this._selectedTab.next(value);
    }
    get selectedTab() {
        return this._selectedTab.getValue();
    }
    constructor(private network: NetworkService) {
    }

    public getHourlyChartData(startDate, endDate, clinicId): Observable<any> {
        const url = `/api/organizations/${clinicId}/hourly-summary-chart-data`;
        const params = new HttpParams().set('startDate', startDate).set('endDate', endDate);

        return this.network.fetchResource(url, { params });
    }

    public updateAppointment(clinicId, appointment): Observable<any> {
        const url = `/api/organizations/${clinicId}/clinic-day-sign-off`;
        return this.network.putResource(url, appointment);
    }

    public saveClinicSchedule(clinicId, appointment): Observable<any> {
        const url = `/api/organizations/${clinicId}/clinic-appointment`;
        return this.network.postResource(url, appointment);
    }

    public checkAppointments(clinicId, clinicAppointmentId, startTime, endTime, breakStartTime, breakEndTime): Observable<any> {
        const url = `/api/organizations/${clinicId}/clinic-appointment/check-break`;
        const params = new HttpParams()
            .set('clinicAppointmentId', clinicAppointmentId)
            .set('startTime', startTime)
            .set('endTime', endTime)
            .set('breakStartTime', breakStartTime)
            .set('breakEndTime', breakEndTime);

        return this.network.fetchResource(url, { params });
    }

    public getClinicSetup(clinicAppointmentId): Observable<any> {
        const url = `/api/organizations/clinic-schedule/${clinicAppointmentId}`;
        return this.network.fetchResource(url);
    }

    public updateClinicSchedule(clinicId, appointments): Observable<any> {
        const url = `/api/organizations/${clinicId}/clinic-appointment`;
        return this.network.putResource(url, appointments);
    }

    public getPatientGroups(clinicId): Observable<IPatientGroups> {
        const url = `/api/organizations/clinic-patient-groups/${clinicId}`;
        return this.network.fetchResource(url);
    }

    public getProductItemsPerClinic(clinicId): Observable<IProductItem[]> {
        const url = `/api/productItems/clinic/${clinicId}`;
        return this.network.fetchResource(url);
    }

    public getEventsByParentOrganization(clinicId): Observable<any> {
        const url = `/api/organizations/parent-events/${clinicId}`;
        return this.network.fetchResource(url);
    }

    public getAuditData(clinicId, appointmentIds, pageNUmber, pageSize): Observable<any> {
        const url = `/api/organizations/${clinicId}/clinic-setup/activities`;
        const params = new HttpParams()
            .set('clinicAppointmentIds', appointmentIds)
            .set('page', pageNUmber)
            .set('size', pageSize);

        return this.network.fetchResource(url, { params });
    }

    public deleteSlot(clinicId, appointmentId): Observable<any> {
        const url = `/api/organizations/${clinicId}/clinic-appointment/${appointmentId}`;

        return this.network.deleteResource(url);
    }

    public deleteSlots(clinicId, appointmentIds: string[]): Observable<any> {
        const url = `/api/organizations/${clinicId}/clinic-appointments/bulk-delete`;
        const body = { uuidList: appointmentIds };

        return this.network.postResource(url, body);
    }

    public rescheduleClinicLanes(clinicId, appointments): Observable<any> {
        const url = `/api/organizations/${clinicId}/reschedule-clinic-appointments`;
        return this.network.putResource(url, appointments);
    }

    public undoRescheduledClinics(clinicId, batchId, appointment): Observable<any> {
        const url = `/api/organizations/${clinicId}/undo-reschedule-clinic-appointments/${batchId}`;
        return this.network.putResource(url, appointment);
    }

    public cancelClinicLanes(clinicId, appointments): Observable<any> {
        const url = `/api/organizations/${clinicId}/cancel-clinic-appointments`;
        return this.network.putResource(url, appointments);
    }

    public undoCancelledClinicLanes(clinicId, appointments): Observable<any> {
        const url = `/api/organizations/${clinicId}/undo-cancel-clinic-appointments`;
        return this.network.putResource(url, appointments);
    }

    public getAuditTrailData(clinicId, appointmentIds): Observable<any> {
        const url = `/api/organizations/${clinicId}/clinic-appointment-audits?clinicAppointmentIds=${appointmentIds}`;
        return this.network.fetchResource(url);
    }

    public getPatientCount(clinicId, appointmentIds): Observable<any> {
        const url = `/api/organizations/${clinicId}/clinic-appointment-patient-count?clinicAppointmentIds=${appointmentIds}`;
        return this.network.fetchResource(url);
    }

    public notifyPatients(clinicId, appointment): Observable<any> {
        const url = `/api/organizations/${clinicId}/notify-clinic-appointment-patients`;
        return this.network.putResource(url, appointment);
    }
}
