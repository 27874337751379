import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IOrganization } from '../../../../core/models/organization.model';

export const RECENTLY_VIEWED_KEY = 'recentlyViewedOrganizations';

export interface IRecentlyViewed {
    [parentId: string]: {
        organizationId: string;
    }[];
}

@Injectable({
    providedIn: 'root',
})
export class RecentlyViewedOrganizationService {
    _recentlyViewedForLS: IRecentlyViewed;

    constructor() {}

    _recentlyViewed = new BehaviorSubject<IOrganization[]>([]);

    get recentlyViewed() {
        return this._recentlyViewed.getValue();
    }

    set recentlyViewed(value) {
        this._recentlyViewed.next(value);
    }

    recentlyViewed$ = this._recentlyViewed.asObservable();

    setRecentlyViewed(availableOrganizations: IOrganization[], parentId: string) {
        const recentlyViewedOrganization = this.getRecentlyViewedOrganization(parentId);

        this.recentlyViewed = [];

        if (recentlyViewedOrganization && recentlyViewedOrganization.length) {
            const recentlyViewedIds: string[] = recentlyViewedOrganization.map(({ organizationId }) => organizationId);

            recentlyViewedIds.forEach((id) => {
                const organizationIndex = availableOrganizations.findIndex((aO) => aO.id === id);

                if (organizationIndex >= 0) {
                    const organization = availableOrganizations[organizationIndex];

                    this.recentlyViewed.push(organization);
                }
            });

            availableOrganizations.filter(({ id: availableOrgId }) =>
                recentlyViewedIds.some((orgId) => orgId === availableOrgId),
            );
        }
    }

    addToRecentlyViewed(parentId: string, organization: IOrganization) {
        const isExistParentRecentlyViewed = this.getRecentlyViewedOrganization(parentId);

        if (isExistParentRecentlyViewed) {
            this.addRecentlyViewedOrganization(parentId, organization.id);
        } else {
            this.setRecentlyViewedOrganization(parentId, organization.id);
        }
    }

    removeFromRecentlyViewed(parentId: string, organization: IOrganization) {
        const recentlyViewedLS = this.getLSRecentlyViewed();
        const recentlyViewedOrganization = recentlyViewedLS[parentId];
        const orgViewedIndex = recentlyViewedOrganization.findIndex((rV) => rV.organizationId === organization.id);
        const recentlyViewedIndex =  this.recentlyViewed.findIndex(rV => rV.id === organization.id);

        recentlyViewedOrganization.splice(orgViewedIndex, 1);
        this.recentlyViewed.splice(recentlyViewedIndex, 1);
        this.setToLocalStorage(recentlyViewedLS);
    }

    getRecentlyViewedOrganization(parentId: string): { organizationId: string }[] | null {
        const recentlyViewedLS = this.getLSRecentlyViewed();
        return recentlyViewedLS ? recentlyViewedLS[parentId] : null;
    }

    setRecentlyViewedOrganization(parentId: string, organizationId: string) {
        let recentlyViewedLS = this.getLSRecentlyViewed();

        if (!recentlyViewedLS) {
            recentlyViewedLS = {};
        }
        recentlyViewedLS[parentId] = [{ organizationId }];
        this.setToLocalStorage(recentlyViewedLS);
    }

    addRecentlyViewedOrganization(parentId: string, organizationId: string) {
        const recentlyViewedLS = this.getLSRecentlyViewed();
        const recentlyViewedOrganization = recentlyViewedLS[parentId];
        const orgViewedIndex = recentlyViewedOrganization.findIndex((rV) => rV.organizationId === organizationId);

        if (orgViewedIndex >= 0) {
            recentlyViewedOrganization.splice(orgViewedIndex, 1);
        }
        recentlyViewedOrganization.unshift({ organizationId });
        this.setToLocalStorage(recentlyViewedLS);
    }

    setToLocalStorage(recentlyViewed: IRecentlyViewed) {
        localStorage.setItem(RECENTLY_VIEWED_KEY, JSON.stringify(recentlyViewed));
    }

    getLSRecentlyViewed(): IRecentlyViewed | null {
        const data = localStorage.getItem(RECENTLY_VIEWED_KEY);
        return data ? (JSON.parse(data) as IRecentlyViewed) : null;
    }
}
