import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { parse, setHours, setMinutes } from 'date-fns';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import JSZip from 'jszip';
import { BehaviorSubject, catchError, combineLatest, forkJoin, Observable, of, ReplaySubject, throwError } from 'rxjs';
import { map, mergeMap, shareReplay, tap } from 'rxjs/operators';
import { Appointment } from 'src/app/core/models/appointment.model';
import { ITask } from 'src/app/core/models/tasks.model';
import { TranslationISO } from 'src/app/core/models/translation.model';

import { environment } from '../../../../environments/environment';
import { ActivityFeedModel, AssessmentReportResource } from '../../../core/models/activity.model';
import { Address } from '../../../core/models/address.model';
import { NewCarePlan } from '../../../core/models/careplan.model';
import { ResponseWithLinks } from '../../../core/models/common.model';
import { Link } from '../../../core/models/link.model';
import { IOrganization } from '../../../core/models/organization.model';
import {
    IPatientMini,
    OnboardPatient,
    Patient,
    PatientModel,
    PatientParams,
    PatientStatsModel,
    PatientTeamMember,
    UpdatePatient,
} from '../../../core/models/patient.model';
import { PatientOverviewResponse } from '../../../core/models/patient-overview-model';
import { IPatientProgramStatus } from '../../../core/models/patient-program-status.interface';
import { ActivitiesService } from '../../../core/services/activities/activities.service';
import { DataBrokerService } from '../../../core/services/data-broker/data-broker.service';
import { ImageService } from '../../../core/services/image/image.service';
import { LanguageService } from '../../../core/services/language/language.service';
import { NetworkService } from '../../../core/services/network/network.service';
import { IPatientProgram } from '../../../core/services/programs/programs.interface';
import { UserSessionService } from '../../../core/services/user-session/user-session.service';
import { LinkUtil } from '../../../core/utils/link-util/link-util.service';
import { Utility } from '../../../core/utils/utility';
import { IPatientStatusType } from '../../programs/pages/program-overview/pages/program-analytics/services/program-analytics/program-analytics.interface';
import { TracedContact } from '../patient-overview/po-covid/components/traced-contacts-table/traced-contacts-table.component';

export interface INote {
    activityId: string;
    bucketName: string;
    fileName: string;
    fileType: string;
    id: string;
    links: Link[]; // Replace 'any' with a more specific type if the structure of links is known
    patientId: string;
}

export interface IVeteranInfo {
    lastName: string;
    firstName: string;
    ien: string;
    phoneNumber: string;
    gender: string;
    last4SSN: string | null;
    middleName: string;
    dateOfBirth: string; // ISO format date string
    email: string;
}

export interface INoteFile {
    messageType: string;
    stationNumber: string;
    veteranInfo: IVeteranInfo;
    s3DocumentType: string;
    version: string;
    applicationName: string;
    url: string;
    timestamp: string; // ISO format date-time string
}

@Injectable({ providedIn: 'root' })
export class PatientService {
    readonly quarantineContactUrl = '/api/quarantineContact';
    readonly dataStore: { patientOverview: any; };

    public patient: Patient;
    public address: Address;
    private _patientOverview: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private patientStats$: Observable<{ content: PatientStatsModel[]; totalCarePlanTemplateEngagementCount: number }>;
    private patientActivities$: Observable<ActivityFeedModel[]>;
    private patientCache: any = { res: new ReplaySubject(1) };
    private subscriptions = [];

    // language labels
    private diagnosisLabel: string;
    private cancerTypeLabel: string;
    private stageLabel: string;
    private treatmentStatusLabel: string;

    constructor(
        private session: UserSessionService,
        private network: NetworkService,
        private data: DataBrokerService,
        private linkUtil: LinkUtil,
        private imageService: ImageService,
        private languageService: LanguageService,
        private http: HttpClient,
        private activitiesService: ActivitiesService,
        private translate: TranslateService,
    ) {
        this.updateLanguage();
        this.subscriptions.push(this.data.subscribe<any>('language-update').subscribe(() => {
            this.updateLanguage();
        }));

        this.dataStore = { patientOverview: null };
        this._patientOverview = <BehaviorSubject<any>> new BehaviorSubject(null);
    }

    get store() {
        return this.dataStore;
    }

    get patientOverviewObservable(): Observable<any> {
        return this._patientOverview.getValue() ? this._patientOverview.asObservable() : this.getPatientWithImage();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

    setPatientOverview(patient) {
        this.dataStore.patientOverview = patient;
        this._patientOverview.next({ ...this.dataStore }.patientOverview);
    }

    /*
     * public getPatientsWithMetrics(link?: string): Observable<any> {
     *   return this.network.fetchResource(link || this.patientListUrl).pipe(
     *     mergeMap(({patientList: {content}, ...res}) => {
     *       return this.imageService
     *         .addImage(content, 'patientImg', 100)
     *         .pipe(map((patients) => ({...res, patientList: patients})));
     *     })
     *   );
     * }
     *
     * public setPatientListUrl(url: string) {
     *   this.patientListUrl = url;
     * }
     */

    public getPatientWithImage(): Observable<PatientOverviewResponse> {
        const patientUrl = localStorage.getItem('current-patient');

        if (patientUrl) {
            return this.network.fetchResource(patientUrl).pipe(
                mergeMap((response: any) => {
                    this.session.sessionAccount.currPatient = response;

                    return of(response);
                }),
                map((patient: PatientOverviewResponse) => {
                    this.setPatientOverview(patient);

                    return patient;
                }),
            );
        }

        return of(null);
    }

    public getPatientOverview(patientId: string): Observable<PatientOverviewResponse> {
        const url = `/api/patients/${ patientId }/overview`;

        return this.network.fetchResource(url);
    }

    public getTestResultPDF(taskId: string): any {
        const url = `/api/elis/order-result/${ taskId }/pdf`;

        return this.network.fetchResource(url, { responseType: 'blob' });
    }

    public getCarePlansByPatient(patientId: string): Observable<ResponseWithLinks<NewCarePlan>> {
        const url = `/api/patients/${ patientId }/carePlan`;

        return this.network.fetchResource(url);
    }

    public getCurrentPatient(): ReplaySubject<PatientModel> {
        if (!this.patientCache._lastCalled || Date.now() - this.patientCache._lastCalled > 500) {
            this.patientCache._lastCalled = Date.now();
            this.patientCache.res = this.network
                .fetchResource<PatientModel>('/api/patients/patient', { role: 'patient' })
                .pipe(shareReplay({ bufferSize: 1, refCount: true }));
        }

        return this.patientCache.res;
    }

    public getPatientFamilyMembers(patientId: string) {
        return this.network.fetchResource<{ teamMember: PatientTeamMember[] }>(`/api/patients/${ patientId }/family-members`, { role: 'patient' });
    }

    public getPatientChildren(patientId: string) {
        return this.network.fetchResource<IPatientMini[]>(`/api/patients/${ patientId }/children`, { role: 'patient' });
    }

    public getPatientFamilyMemberDetails(accountId: string) {
        return this.network.fetchResource<any>(`/api/session/${ accountId }/family-member-details`, { role: 'patient' });
    }

    public getFamilyMemberPatient(familyMemberPatientId: string) {
        return this.network.fetchResource<any>(`/api/patients/${ familyMemberPatientId }`, { role: 'patient' });
    }

    public getAllPublicServices(patientId = undefined, fetchCarePlan = false): Observable<any> {
        let url = '/api/meta/getAllPublicServiceType';

        url += patientId ? `?targetId=${ patientId }` : '';
        url += fetchCarePlan ? '&fetchCarePlan=true' : '';

        const res = this.network.fetchResource<any>(url, { headers: { 'Content-Language': TranslationISO[this.translate.currentLang] || 'en-US' }});

        return this.checkForFreeServices(res);
    }

    checkForFreeServices(service: any) {
    /** Check if Service has the flag of free, If the free flag is true set the value for Test to 0 */
        return service.pipe(map((services: any) => {
            services.forEach((service) => {
                if (service.hasOwnProperty('masterDataResourceList')) {
                    service.masterDataResourceList.forEach((data) => {
                        if (data.hasOwnProperty('attributes')) {
                            const attributes = JSON.parse(data.attributes);

                            if (attributes.free === true) {
                                data.nonDiscountedPrice = data.price;
                                data.price = 0;
                            }
                        }
                    });
                }
            });

            return services;
        }));
    }

    public getAvailableServicesForPatient(patientId: string): Observable<any> {
        const url = `/api/meta/getServiceTypesWithCarePlans?patientId=${ patientId }`;

        return this.network.fetchResource<any>(url);
    }

    public getTaskByID(id): Observable<ITask> {
        return this.network.fetchResource<ITask>(`/api/tasks/${ id }`);
    }

    public getCarePlansByServiceTypeId(serviceTypeId: string): Observable<any> {
        const link = `/api/carePlans/${ serviceTypeId }/serviceId`;

        return this.network.fetchResource(link);
    }

    public deleteAccount(id): Observable<any> {
        const link = `/api/patients/${ id }`;

        return this.network.deleteResource(link);
    }

    public purgeAccount(id): Observable<any> {
        const link = `/api/patients/purge/${ id }`;

        return this.network.deleteResource(link);
    }

    public getPatientsStats(
        carePlanId,
        { page = 0, size = 20, gender = '', age = '', orderType = '', asc = '', dobFrom = '', dobTo = '' } = {},
    ): Observable<{ content: PatientStatsModel[]; totalCarePlanTemplateEngagementCount: number }> {
        const link = `/api/carePlans/${ carePlanId }/patients-stats`;
        let httpParams = new HttpParams();

        if (page >= 0) httpParams = httpParams.set('page', page);
        if (size) httpParams = httpParams.set('size', size);
        if (gender) httpParams = httpParams.set('gender', gender);
        if (dobFrom) httpParams = httpParams.set('dobFrom', dobFrom);
        if (dobTo) httpParams = httpParams.set('dobTo', dobTo);
        if (age) httpParams = httpParams.set('age', age);
        if (orderType) httpParams = httpParams.set('orderType', orderType);
        if (asc) httpParams = httpParams.set('asc', asc);

        return this.network
            .fetchResource<{
            carePlanTemplateEngagementList: { content: PatientStatsModel[] };
            totalCarePlanTemplateEngagementCount: number;
        }>(link, { params: httpParams })
            .pipe(map(({ carePlanTemplateEngagementList, totalCarePlanTemplateEngagementCount }) => ({
                content: carePlanTemplateEngagementList.content,
                totalCarePlanTemplateEngagementCount,
            })));
    }

    public clearPatientStats() {
        this.patientStats$ = null;
    }

    public getPatientActivities(patientId: any, { page = 0, size = 10 }): Observable<ActivityFeedModel[]> {
        const url = `/api/patients/${ patientId }/activities`;
        let params = new HttpParams();

        if (page || page === 0) params = params.set('page', page.toString());
        if (size) params = params.set('size', size.toString());

        return this.network.fetchResource(url, { params }).pipe(map(({ content: activitiesResponse }: { content: ActivityFeedModel[] }) => activitiesResponse.map(({
            assessmentReportResource,
            assessmentReportResource: { score, dateCreated, extraValue = '' } = {},
            ...activity
        }) => {
            const conditionWarnings: any[] = this.activitiesService.parseExtraValue(extraValue.toString());
            const conditionWarningText = conditionWarnings.length ? conditionWarnings[0].text : '';
            const prevReport = activitiesResponse.find((a) => a.assessmentReportResource?.dateCreated < dateCreated)?.assessmentReportResource;

            return {
                ...activity,
                assessmentReportResource: {
                    ...assessmentReportResource,
                    conditionWarnings,
                    conditionWarningText,
                    lastScoreChange: prevReport ? score - prevReport.score : 0,
                },
            };
        })));
    }

    public getImagesForPatientActivities(activities) {
        const activitiesWithImage: Observable<ActivityFeedModel>[] = activities.map((activity: ActivityFeedModel) => {
            const arr = [this.imageService.addImage(activity.activityResource, 'actorImg', 100)];

            if (activity.observationResource) {
                arr.push(this.imageService.addImage(activity.observationResource, 'observationImage', 100));
            } else {
                arr.push(of(null));
            }

            if (activity.medicationReportResource) {
                const medicationTasks = activity.medicationReportResource.taskIds.map((id, i) => {
                    const names = activity.medicationReportResource.medicationName.split('/');
                    const url = `/api/meta/therapy/image/${ id }`;

                    return this.imageService.addImage({ taskImage: url, name: names[i] }, 'taskImage', 100);
                });

                arr.push(forkJoin(medicationTasks).pipe(map((tasks) => ({ ...activity.medicationReportResource, tasks }))));
            } else {
                arr.push(of(null));
            }

            if (activity.exerciseReportResource) {
                const exerciseTasks = activity.exerciseReportResource.setList.map((task: any) => {
                    task.taskImage = `/api/meta/therapy/image/${ task.taskId }`;

                    return this.imageService.addImage(task, 'taskImage', 100);
                });

                arr.push(forkJoin(exerciseTasks).pipe(map((setList) => ({ ...activity.exerciseReportResource, setList }))));
            } else {
                arr.push(of(null));
            }

            return combineLatest(arr).pipe(map(([
                activityResource,
                observationResource,
                medicationReportResource,
                exerciseReportResource,
            ]) => ({
                ...activity,
                activityResource,
                observationResource,
                medicationReportResource,
                exerciseReportResource,
            })));
        });

        return forkJoin(activitiesWithImage);
    }

    // Clear patientActivities$
    clearPatientActivityTasks() {
        this.patientActivities$ = null;
    }

    public getDailyAssessmentReports(id): Observable<AssessmentReportResource[]> {
        const link = `/api/surveys/patient/${ id }/daily-assessment-reports`;

        return this.network.fetchResource(link);
    }

    public updatePatientGroup(patientId, selectedPatientGroupsIds, removedPatientGroupsIds): Observable<any> {
        const selectedGroups$ = selectedPatientGroupsIds?.length
            ? forkJoin(selectedPatientGroupsIds.map((id) => {
                const url = `/api/patients/${ patientId }/patient-invite-code`;
                const params = new HttpParams().set('organizationId', id);

                return this.network.postResource(url, {}, { params });
            }))
            : of(null);
        const removedGroups$ = removedPatientGroupsIds?.length
            ? forkJoin(removedPatientGroupsIds.map((id) => {
                const url = `/api/patients/${ patientId }/removeOrganization`;
                const params = new HttpParams().set('organizationId', id);

                return this.network.postResource(url, {}, { params });
            }))
            : of(null);

        return selectedGroups$.pipe(mergeMap(() => removedGroups$));
    }

    public getQuarantineLocations(id): Observable<Address[]> {
        return of([]);
    }

    /* get traced contacts from the server */
    getQuarantineContacts(patientId: string): Observable<TracedContact[]> {
        return of([]);
    }

    /* New based on angular blog */
    public getPatients(
        performerId: string,
        {
            page = null,
            size = null,
            orderType = '',
            asc = true,
            name = '',
            treatmentStatus = '',
            carePlans = '',
            carePlanStatus = '',
            lastActiveTimeFrom = null,
            lastActiveTimeTo = null,
        }: PatientParams = {},
    ): Observable<any> {
        const url = '/api/performers/patients-with-metrics';
        let params = new HttpParams();

        if (performerId) params = params.set('performerId', performerId);
        if (page || page === 0) params = params.set('page', page.toString());
        if (size) params = params.set('size', size.toString());
        if (orderType) params = params.set('orderType', orderType.toString());
        if (typeof asc === 'boolean') params = params.set('asc', asc.toString());
        if (name) params = params.set('name', name.toString());
        if (treatmentStatus) params = params.set('treatmentStatus', treatmentStatus.toString());
        if (carePlans) params = params.set('carePlans', carePlans.toString());
        if (carePlanStatus) params = params.set('carePlanStatus', carePlanStatus.toString());
        if (lastActiveTimeFrom) params = params.set('lastActiveTimeFrom', lastActiveTimeFrom.toString());
        if (lastActiveTimeTo || lastActiveTimeTo === 0) {
            params = params.set('lastActiveTimeTo', lastActiveTimeTo.toString());
        }

        return this.network.fetchResource(url, { params });
    }

    public createPatient(newPatient: OnboardPatient): Observable<Patient> {
        const url = '/api/patients';

        return this.network.postResource(url, newPatient);
    }

    public getPatient(patientId: string): Observable<Patient> {
        const url = `/api/patients/${ patientId }`;

        return this.network.fetchResource(url);
    }

    public setPatientIsView(patientId: string, activityDate: number): Observable<any> {
        const url = `/api/activities/patient/${ patientId }/${ activityDate }/view`;

        return this.network.putResource(url, {});
    }

    public getActivityFull(activityId: string): Observable<ActivityFeedModel> {
        const url = `/api/activities/${ activityId }/full`;

        /*
         * resorting to setTimeout in websocket.service.ts (addNewActivity) and delay(2000)
         * in po-covid.component.ts constructor.
         * Fixes 404 error after creating new Note / activity but not a great solution tbh
         * original code below
         * Have to wait for Note to be created in DB before fetching
         */
        return <Observable<ActivityFeedModel>> this.network.fetchResource(url);

    /*
     * return <Observable<ActivityFeedModel>>this.network.fetchResource(url).pipe(
     *   retryWhen((e$) =>
     *     e$.pipe(
     *       // try again after 1 seconds
     *       delay(1000),
     *       // stop trying after 5 times
     *       take(4)
     *     )
     *   )
     * );
     */
    }

    updatePatient(patientId: string, data: UpdatePatient): Observable<Patient> {
        const url = `/api/patients/profile/${ patientId }`;

        return this.network.putResource(url, data);
    }

    getAppointments(patientId): Observable<Appointment[]> {
        const url = `/api/tasks/${ patientId }/appointments`;

        return this.network.fetchResource(url);
    }

    getPrograms(patientId): Observable<IPatientProgram[]> {
        const url = `/api/patients/programs/${ patientId }`;

        return this.network.fetchResource(url);
    }

    getFamilyAppointments(patientId): Observable<any[]> {
        const url = `/api/tasks/${ patientId }/family-member-appointments`;

        return this.network.fetchResource(url);
    }

    putTask(taskId: string, body) {
        const url = `/api/tasks/${ taskId }`;

        return this.network.putResource(url, body);
    }

    checkForSlotOpen(body): Observable<any> {
        const url = '/api/tasks/check-for-slot-open';

        return this.network.postResource(url, body);
    }

    completeTask(taskId: string) {
        const url = `/api/tasks/${ taskId }/complete`;

        return this.network.putResource(url, null);
    }

    putVaccineInfo(taskId: string, body) {
        const url = `/api/tasks/${ taskId }/vaccineInfo`;

        return this.network.putResource(url, body);
    }

    putObservationStatus(tasId: string, body) {
        const url = `/api/tasks/${ tasId }/observationStatus`;

        return this.network.putResource(url, body);
    }

    getAppointmentsInActiveGoals(patientId): Observable<any[]> {
        const url = `/api/tasks/${ patientId }/appointments-in-active-goals`;

        return this.network.fetchResource(url);
    }

    removeVaccineInfo(taskId: string, secondTaskId: string = null) {
        const url = `/api/tasks/${ taskId }/vaccineInfo`;
        let httpParams = new HttpParams();

        if (secondTaskId) httpParams = httpParams.append('task2', secondTaskId);

        return this.network.deleteResource(url, { params: httpParams });
    }

    cancelTask(taskId: string) {
        const url = `/api/tasks/${ taskId }/cancel`;

        return this.network.putResource(url, null);
    }

    patientInvitationCode(patientId, invitationCode) {
        const url = `/api/patients/${ patientId }/patient-invite-code?organizationId=${ invitationCode }`;

        return this.network.postResource(url, {});
    }

    deleteTaskPerformer(taskId) {
        const url = `/api/tasks/${ taskId }/performer`;

        return this.network.deleteResource(url);
    }

    addCarePlanToPatient(body: { patientId: string; careplanId?: number; careplanName?: string }): Observable<any[]> {
        const url = '/api/carePlans/addCarePlanToPatient';

        return this.network.postResource(url, body);
    }

    getPatientServices(patientId: string): Observable<any> {
        const url = `/api/patient-requests/patient/${ patientId }`;

        return this.network.fetchResource(url);
    }

    getPatientRequest(patientId: string): Observable<any> {
        const url = `/api/patient-requests/lite/patient/${ patientId }`;
        const headers = { headers: { performance: '1' }};

        return this.network.fetchResource(url, headers);
    }

    deletePatientRequest(patientRequestId) {
        const url = `/api/patient-requests/${ patientRequestId }`;

        return this.network.deleteResource(url);
    }

    getPatientPdf(patientId) {
        const url = `/api/patients/${ patientId }/vaccination-card/pdf/download`;

        return this.network.fetchResource(url, { responseType: 'blob' });
    }

    filterLanesBasedOnVaccineType(taskName, laneArray) {
        let vaccineType;
        const filteredLanes = [];

        if (taskName.includes('3') || taskName.includes('1') || taskName.includes('2') || taskName.includes('Booster')) {
            vaccineType = 'COVID';
        }
        if (taskName.toUpperCase().includes('FLU')) vaccineType = 'Flu';
        laneArray.forEach((lane) => {
            let siteType;

            if (lane && lane.inventoryType?.toLowerCase().includes('covid-19')) siteType = 'COVID';
            if (lane && !lane.inventoryType) siteType = 'COVID';
            if (lane && lane.inventoryType?.toLowerCase().includes('flu')) siteType = 'Flu';

            if (siteType == vaccineType) {
                if (taskName.includes('2') || taskName.includes('3')) {
                    if (!lane.inventoryName.includes('Johnson&Johnson')) {
                        filteredLanes.push(lane);
                    } else {
                        // Skipp
                    }
                } else {
                    filteredLanes.push(lane);
                }
            }
        });

        return filteredLanes;
    }

    public getPatientVaccinationInfo(patientId: string): Observable<any> {
        const url = `/api/session/${ patientId }/vaccinationInfo`;

        return this.network.fetchResource(url);
    }

    cancelAppointmentTask(patientId: string, taskId: string): Observable<any> {
        const url = `/api/tasks/bookAppointment/cancel?patientId=${ patientId }&taskId=${ taskId }`;

        return this.network.putResource<any>(url, {});
    }

    cancelAndRefundAppointmentTask(taskId: string): Observable<any> {
        const url = `/api/tasks/${ taskId }/cancel`;

        return this.network.putResource<any>(url, {});
    }

    bookAppointment(
        clinicSiteLocationId: string,
        clinicAppointmentId: string,
        bookingDateInTimes: number,
        appointmentTaskId: string,
        patientId: string,
        additionalLaneId?: string,
    ): Observable<any> {
        const body = {
            locationId: clinicSiteLocationId,
            dueDate: bookingDateInTimes,
            id: appointmentTaskId,
            patientId,
            clinicAppointmentId,
        };

        if (additionalLaneId) {
            body['additionalLaneId'] = additionalLaneId;
        }

        return this.network.putResource('/api/tasks/bookAppointment/mini', body);
    }

    bookAppointmentWithoutLoggedIn(
        clinicSiteLocationId: string,
        clinicAppointmentId: string,
        bookingDateInTimes: number,
        appointmentTaskId: string,
        patientId: string,
        headers: any,
    ) {
        const body = {
            locationId: clinicSiteLocationId,
            dueDate: bookingDateInTimes,
            id: appointmentTaskId,
            patientId,
            clinicAppointmentId,
        };
        const apiUrl = `${ environment.apiUrl }/api/tasks/bookAppointment/mini`;

        return this.http.put(apiUrl, body, { headers });
    }

    checkInvitationCode(invitationCode: string) {
        const url = `/api/organizations/${ invitationCode }/checkOrgId`;

        return this.network.fetchResource(url);
    }

    updatePatientProfile(patientId: string, body: any): Observable<PatientModel> {
        const url = `/api/patients/profile/${ patientId }`;

        return this.network.putResource(url, body);
    }

    updatePatientOrganizationByInvitationCode(patientId: string, organizationInvitationCode: string): Observable<any> {
        const url = `/api/patients/${ patientId }/organization/${ organizationInvitationCode }`;

        return this.network.putResource(url, {});
    }

    getAssessmentDetails(patientId: string): Observable<any> {
        const url = `/api/surveys/patient/${ patientId }/assessment-reports`;

        return this.network.fetchResource(url);
    }

    checkEmailExist(emailId) {
        const encodedEmail = encodeURIComponent(emailId);
        const url = `/api/accounts/exist?email=${ encodedEmail }`;

        return this.network.fetchResource(url);
    }

    updatePatientProfilePrefferedLanguageOnly(patientId: string, language: any): Observable<PatientModel> {
        const url = `/api/patients/profile/${ patientId }`;

        return this.network.putResource(url, { updatePersonalInfo: { language }});
    }

    dismissResultCard(id: string): Observable<PatientModel> {
        const url = `/api/observations/${ id }/true/update-checked-status`;

        return this.network.patchResource(url, {});
    }

    getAllOrganizations(patientId): Observable<IOrganization[]> {
        const url = `/api/patients/${ patientId }/patientOrganization`;

        return this.network.fetchResource(url);
    }

    deleteOrganizations(patientId, organId): Observable<any> {
        const url = `/api/patients/${ patientId }/removeOrganization?organizationId=${ organId }`;

        return this.network.postResource<any>(url, {});
    }

    generateTaskBody({ location, date, time, lane, taskId }) {
        let dueDate = date || null;

        if (dueDate && time) {
            const test = parse(time, 'hh:mm a', date);

            dueDate = setMinutes(setHours(date, test.getHours()), test.getMinutes());
        }

        const result: { [key: string]: any } = {
            id: taskId,
            dueDate: dueDate && dueDate != '' ? Utility.zonedTimeToUTC(dueDate, location.timeZoneId) : null,
            location: location ? location.address : null,
        };

        if (lane) {
            result.clinicAppointmentId = lane.entityId;
        }

        return result;
    }

    public getInsurancePayer(): Observable<any> {
        const url = '/api/insurance/payers';

        return this.network.fetchResource<any>(url);
    }

    downloadPaymentInvoicePdf(paymentCartID: string): Observable<any> {
        const url = `/api/paymentCart/download/invoice/${ paymentCartID }`;

        return this.network.fetchResource(url, { responseType: 'blob' });
    }

    dismissRomeCard(id: string): Observable<PatientModel> {
        const url = `/api/patients/${ id }/rumecard/false`;

        return this.network.putResource(url, {});
    }

    updateTestResult(observationId, value): Observable<any> {
        const url = `/api/observations/${ observationId }/updateValue/${ value }`;

        return this.network.postResource(url, {});
    }

    enrollInProgram(body: { patientId: string; careplanId?: string; programId?: string }): Observable<any[]> {
        const url = '/api/carePlans/addCarePlanToPatient';

        return this.network.postResource(url, body);
    }

    bookPatientAppointmentToClinic(data: {
        locationId: string,
        dueDate: Date,
        patientId: string,
        clinicAppointmentId: string,
        rescheduleAppointmentId?: string
    }) {
        const url = '/api/tasks/bookAppointment/mini';

        const req = {
            locationId: data.locationId,
            dueDate: data.dueDate.getTime(),
            patientId: data.patientId,
            clinicAppointmentId: data.clinicAppointmentId,
        };

        if (data.rescheduleAppointmentId) {
            req['id'] = data.rescheduleAppointmentId;
            req['sameLane'] = 'false';
        } else {
            req['enrollTaskAppointmentName'] = 'enrollTaskAppointmentName';
        }

        return this.network.putResource(url, req);
    }

    public getNotes(patientId: string): Observable<INote[]> {
        const url = `/api/file-uploads/patient/${ patientId }`;

        return this.network.fetchResource(url);
    }

    getNoteFile(fileUploadId): Observable<INoteFile> {
        const url = `/api/file-uploads/${ fileUploadId }/download`;

        return this.network.fetchResource(url, { responseType: 'blob' }).pipe(
            mergeMap(async(data: Blob) => {
                const dataAsJSON = await data.text();

                return JSON.parse(dataAsJSON);
            }),
            catchError((e) => {
                throw e;
            }),
        );
    }

    createPatientIndividual(body, organizationId: string, carePlanId?: string, carePlanName?: string): Observable<any> {
        let httpParams = new HttpParams();

        if (carePlanId) {
            httpParams = httpParams.append('carePlanId', carePlanId);
        }
        if (carePlanName) {
            httpParams = httpParams.append('carePlanName', carePlanName);
        }
        const url = `/api/organizations/${ organizationId }/patients/individual`;

        return this.network.postResource(url, body, { params: httpParams });
    }

    updatePatientProgramStatus(patientId: string, programId: string, status: IPatientStatusType): Observable<IPatientProgramStatus> {
        const url = `/api/patients/${ patientId }/log-patient-program-status`;

        if (!patientId || !programId) {
            return throwError(() => {
                return new Error('patientId and programId required to set patient program status.');
            });
        }

        return this.network.postResource(url, { programId, status });
    }

    private updateLanguage() {
        this.diagnosisLabel = this.languageService.language.patient_list_diagnosisLabel;
        this.cancerTypeLabel = this.languageService.language.patient_list_cancerTypeLabel;
        this.stageLabel = this.languageService.language.patient_list_stageLabel;
        this.treatmentStatusLabel = this.languageService.language.patient_list_treatmentStatusLabel;
    }

    /**
     * Returns a function that handles Http operation failures.
     * This error handler lets the app continue to run as if no error occurred.
     * @param operation - name of the operation that failed
     */
    private handleError<T>(operation = 'operation') {
        return (error: HttpErrorResponse): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            const message = error.error instanceof ErrorEvent
                ? error.error.message
                : `server returned code ${ error.status } with body "${ error.error }"`;

            // TODO: better job of transforming error for user consumption
            throw new Error(`${ operation } failed: ${ message }`);
        };
    }


    // TODO: Fix le spaghetti
    public downloadFullAssessmentsAndConsentsForPatient(patientId: string, programId: string) {
        const url = `/api/img-library/${ patientId }/full-enrollment-items?program_id=${ programId }`;

        const width = 400;
        const height = 400;

        return this.network.fetchResource(url, { responseType: 'blob' })
            .pipe(tap(async(file: any) => {
                const zip = new JSZip();
                const unzippedFiles = await zip.loadAsync(file);

                const pdf = new jsPDF();

                let isFirstPage = true;

                let signature: JSZip.JSZipObject | undefined = undefined;

                for (const fileName of Object.keys(unzippedFiles.files)) {
                    if (fileName.includes('-signatureOnly')) {
                        signature = unzippedFiles.files[fileName];
                        break;
                    }
                }

                let signatureImg: HTMLImageElement | undefined;

                if (signature) {
                    const signatureContent = await signature.async('blob');
                    const signatureUrl = URL.createObjectURL(signatureContent);

                    signatureImg = new Image();
                    signatureImg.src = signatureUrl;
                    await new Promise<void>((resolve) => {
                        signatureImg.onload = () => {
                            resolve();
                        };
                    });
                }

                for (const relativePath of Object.keys(unzippedFiles.files)) {
                    if (relativePath.includes('-signatureOnly')) continue;

                    const fileContent = await unzippedFiles.files[relativePath].async('blob');
                    const imageUrl = URL.createObjectURL(fileContent);
                    const img = new Image();

                    img.src = imageUrl;
                    await new Promise<void>((resolve) => {
                        img.onload = () => {
                            const imgWidth = img.width;
                            const imgHeight = img.height;
                            const orientation = imgWidth > imgHeight ? 'landscape' : 'portrait';

                            if (!isFirstPage) {
                                pdf.addPage([imgWidth, imgHeight + height + 20], orientation);
                            } else {
                                isFirstPage = false;
                                pdf.setPage(1);
                                pdf.internal.pageSize.width = imgWidth;
                                pdf.internal.pageSize.height = imgHeight + height + 20;
                            }

                            pdf.addImage(img, 'PNG', 0, 0, imgWidth, imgHeight);

                            if (signatureImg) {
                                const signatureWidth = width;
                                const signatureHeight = height;
                                const xPos = (imgWidth - signatureWidth) / 2;
                                const yPos = imgHeight + 10;

                                pdf.addImage(signatureImg, 'PNG', xPos, yPos, signatureWidth, signatureHeight);
                            }

                            URL.revokeObjectURL(imageUrl);
                            resolve();
                        };
                    });
                }

                const pdfBlob = pdf.output('blob');
                const fileName = `Enrollment items: ${ patientId }-${ programId }.pdf`;

                saveAs(pdfBlob, fileName);
            }));
    }


    // TODO: Fix le spaghetti
    downloadPhaseItem(patientId: string, phaseItemId: string) {
        const url = `/api/img-library/${ patientId }/full-enrollment-items?phase_item_id=${ phaseItemId }`;

        return this.network.fetchResource(url, { responseType: 'blob' })
            .pipe(tap(async(file: Blob) => {
                const zip = new JSZip();
                const unzippedFiles = await zip.loadAsync(file);

                let fileContent: Blob | null = null;
                let fileNameInZip: string | null = null;

                for (const relativePath in unzippedFiles.files) {
                    if (Object.prototype.hasOwnProperty.call(unzippedFiles.files, relativePath)) {
                        fileNameInZip = relativePath;
                        fileContent = await unzippedFiles.files[relativePath].async('blob');
                        break;
                    }
                }

                if (fileContent && fileNameInZip) {
                    const imageUrl = URL.createObjectURL(fileContent);
                    const img = new Image();

                    img.src = imageUrl;
                    img.onload = () => {
                        const pdf = new jsPDF({
                            orientation: img.width > img.height ? 'landscape' : 'portrait',
                            unit: 'px',
                            format: [img.width, img.height],
                        });

                        pdf.addImage(img, 'PNG', 0, 0, img.width, img.height);
                        pdf.save(`${ fileNameInZip.replace('.png', '') }.pdf`);
                        URL.revokeObjectURL(imageUrl);
                    };
                }
            }));
    }
}
