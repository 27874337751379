import { ImageFromLibrary } from '../../modules/image-library/image-library.service';
import { Address } from './address.model';
import { CareplanTaskModel } from './careplan-task-model';
import { ResponseWithLinks } from './common.model';
import { Link } from './link.model';
import { MetaDataModel } from './metadata.model';

export class CarePlan {
    id: string;
    name: string;
    img: string;
    carePlanType: string;
    unplannedAppointmentCount: number;
    patientCount: number;
    milestoneCount;
    number;
    appointmentCount: number;
    src: string;
    compliancyScore: string;
    desciption: string;
    links: Array<any>;
    content: Array<any>;
    careplanImg: string;
    usage: number;
    inHomeActivityCount: number;
    tasks: Array<any>;
    duration: number;
    durationWeeks: number;
    isTemplate: boolean;
    cptType: string;

    // For a non template care plan
    patientId: string;
    usedTemplateId: string;
    startDate: number;
    startDateStr: string;
    cycles: number;
    deletedTaskIds: Array<string>;
    taskList: Array<CareplanTaskModel>;
    parentTasks: CareplanTaskModel[];
    medicationCount: number;
    treatmentTasks: any[];
    owner: string;
    ownerId: string;
    progress: number;
    goals: any;
    statusAssignedDate: any;
    status: any;
    serviceTypeId: number;
    serviceType: string;
}

export class NewCarePlan {
    id?: string = null;
    name: string;
    img?: string;
    duration?: number;
    durationWeeks?: number;
    owner?: string;
    ownerId?: string;
    goals: Array<CarePlanGoal> = new Array<CarePlanGoal>();
    isTemplate: boolean;
    carePlanType?: string;
    careplanProgress?: number;
    compliancyScore?: number;
    usedTemplateId?: number; // only for onboarding
    startDate?: number;
    endDate?: number;
    startDateStr?: string;
    patientId?: string; // onboarding
    deletedTaskIds?: Array<string> = [];
    deletedGoalIds?: Array<string> = [];
    image?: any;
    carePlanImage?: string;
    links? = [];
    careplanImg?: string;
    serviceType?: any;
    serviceTypeId?: number;
    description?: string;
    imageLibrary?: ImageFromLibrary;
    createdByAccountId?: string;
    surveyId?: string;
    cptType?: string;
    isRecommended?: string;
    recommendText?: string;
    contentType?: string;
    programId?: string;
    programLead?: string;
    programLocation?: string;
    progressNoteBundleFrequency?: number;
    cost: number;
    default?: boolean;

    get durationStr() {
        let durationWeeks = `${ Math.floor(this.duration / 7) } Weeks`;

        if (this.duration % 7) {
            durationWeeks += ` ${ this.duration % 7 } Days`;
        }

        return durationWeeks;
    }
}

export class CovidPlan extends NewCarePlan {
    status: string;
}

export class CarePlanGoal {
    id?: string;
    name: string;
    carePlanId?: string;
    observations?: number;
    isDisplay?: boolean;
    color?: string;
    groupedTasks: Array<CarePlanTaskGroup> = new Array<CarePlanTaskGroup>(); // grouped by task type
    tasks: Array<CarePlanTask> = new Array<CarePlanTask>(); // ungrouped tasks
    startDay: number;
    durationUnit: any;
    duration: any;
    endDay: number;
    isTemplate: boolean;
    progress: number;
    goalStatus: string;
    isOpened: boolean;

    static isGoalReadOnly(goal): boolean {
        return goal.goalStatus ? ['COMPLETED'].includes(goal.goalStatus) : false;
    }
}

export class CarePlanTaskGroup {
    id?: number;
    name: string;
    image?: string;
    isDisplay?: boolean;
    tasks: Array<CarePlanTask> = new Array<CarePlanTask>();
    totalCompletedCount?: number;
}

export class CarePlanTask {
    id?: string;
    name?: string;
    dateCreated?: number;

    // provider: Performer = new Performer();
    parentId?: string;
    performerName?: string;
    performerImage?: string;
    patientId?: string;
    performerId?: any; // for post/put
    location?: Address = new Address();
    locationId?: any; // for post/put
    dueDate?: number | Date;
    dueTime?: any;
    image?: string;
    serviceTypeId?: number;
    serviceTypeName?: string;
    serviceUnitOfMeasurement?: string;
    taskType?: string | any;
    taskTypeId?: number;
    taskStatus?: string;
    therapyTypeId?: number;
    therapyType?: MetaDataModel = new MetaDataModel();
    serviceType?: any;
    dosage?: string;
    frequency?: string;
    material?: string;
    startDateOffset?: number;
    instructions?: boolean;
    education?: boolean;
    media?: boolean;
    devices?: boolean;
    observation?: boolean;
    observationGoals?: Array<ObservationGoals> = new Array<ObservationGoals>();
    isTemplate?: boolean;
    isGoalTrigger?: boolean;
    isRequiredForHome?: boolean;
    usedTemplateId?: number;
    locationType?: string;
    progress?: number;
    progressDots?: number[];
    carePlanId?: string;
    goalId?: string;
    carePlanGoalId?: string | number;
    stage?: number;
    clinicAppointmentResource?: any;
    links?: Link[];
    vaccinationInfo?: any;
    vaccinationInfoResource?: any;
    observations?: any[];
    observationResourceList?: any[];
    status?: any;
    observationType?: any;
    frequencyType?: string;
    providerAssisted?: boolean;
}

export class ObservationGoals {
    id?: string;
    observationType: any;
    observationTypeId: number;
    taskId: string;
    name: string;
    stringValue: string;
    minimum: number;
    maximum: number;
    lastUpdated: string;
}

export interface PlanTemplate {
    name: string;
    description: string;
    carePlanType: string;
    lastUpdated: string;
    notes: string;
    durationWeeks: number;
    duration: number;
    isTemplate: boolean;
    progress: number;
    compliancyScore: number;
    usage: number;
    appointmentCount: number;
    medicationCount: number;
    inHomeActivityCount: number;
    goalCount: number;
    links: Link[];
    id: string;
}

export interface CarePlanResponse {
    carePlanList: ResponseWithLinks<NewCarePlan>;
    carePlanTypeFilters: any[];
    totalCount: number;
}

export interface CarePlanTemplates {
    appointmentCount: number;
    carePlanType: string;
    duration: number;
    durationWeeks: number;
    goalCount: number;
    id: string;
    inHomeActivityCount: number;
    isPrivate: boolean;
    isTemplate: boolean;
    lastUpdated: string;
    links: Link[];
    medicationCount: number;
    name: string;
    patientCount: number;
    progress: number;
    unplannedAppointmentCount: number;
}
