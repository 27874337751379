import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MessageBusService } from './message-bus.service';

@Injectable({
    providedIn: 'root',
})
export class DataBrokerService {
    constructor(private bus: MessageBusService) {}

    public publish<T>(msg: T, channel: string) {
        this.bus.sendData<T>(msg, channel);
    }

    public subscribe<T>(channel: string): Observable<T> {
        return this.bus.getData(channel);
    }

    public unsubscribe(channel: string) {
        this.bus.unsubscribeData(channel);
    }

    public unsubscribeAll() {
        this.bus.unsubscribeAll();
    }
}
