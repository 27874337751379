import { OwlOptions } from 'ngx-owl-carousel-o';

export const menuBarOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    stagePadding: 50,
    responsive: {
        0: { items: 1 },
        554: { items: 2 },
        831: { items: 3 },
        1108: { items: 4 },
        1662: { items: 5 },
        1939: { items: 6 },
    },
    nav: false,
};
