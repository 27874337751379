/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Address } from '../../classes/address.class';
import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;
import PlaceResult = google.maps.places.PlaceResult;
import GeocoderResult = google.maps.GeocoderResult;
import LatLng = google.maps.LatLng;

@Injectable({
    providedIn: 'root',
})
export class GoogleMapsService {
    _isApiLoaded = new BehaviorSubject<boolean>(false);
    set isApiLoaded(value) {
        this._isApiLoaded.next(value);
    }

    get isApiLoaded() {
        return this._isApiLoaded.getValue();
    }

    isApiLoaded$ = this._isApiLoaded.asObservable();

    constructor(private httpClient: HttpClient) {}

    populateAddress(
        {
            address_components,
            geometry: {
                location: { lat, lng },
            },
        }:
        | GeocoderResult
        | PlaceResult
        | {
            address_components: GeocoderAddressComponent[];
            geometry: {
                location: LatLng;
            };
        },
        literal = false,
    ): Address {
        const hash = {
            street_number: 'streetNumber',
            route: 'route',
            locality: 'city',
            administrative_area_level_1: 'state',
            country: 'country',
            postal_code: 'zipcode',
            premise: 'premise',
            sublocality_level_5: 'sublocality_level_5',
            sublocality_level_4: 'sublocality_level_4',
            sublocality_level_3: 'sublocality_level_3',
            sublocality_level_2: 'sublocality_level_2',
            sublocality_level_1: 'sublocality_level_1',
        };
        const components: { [key: string]: any } = {};

        address_components.forEach(({ types, long_name, short_name }) => {
            const name = long_name || short_name;
            let type = types[0];
            for (let i = 0; i < types.length; i++) {
                if (types[i].includes('sublocality_level_')) {
                    type = types[i];
                }
            }

            if (type in hash && hash.hasOwnProperty(type)) {
                if (hash[type] == 'state') components[hash[type]] = short_name || long_name;
                else components[hash[type]] = name;
            }
        });

        if (components.route && components.streetNumber) {
            components.address1 = `${components.streetNumber} ${components.route}`;
        } else if (components.route) {
            components.address1 = components.route;
        } else if (
            components.premise
      && (components.sublocality_level_5
        || components.sublocality_level_4
        || components.sublocality_level_3
        || components.sublocality_level_2
        || components.sublocality_level_1)
        ) {
            let str = components.premise;
            components.sublocality_level_5 ? (str += ` ${components.sublocality_level_5}`) : null;
            components.sublocality_level_4 ? (str += ` ${components.sublocality_level_4}`) : null;
            components.sublocality_level_3 ? (str += ` ${components.sublocality_level_3}`) : null;
            components.sublocality_level_2 ? (str += ` ${components.sublocality_level_2}`) : null;
            components.sublocality_level_1 ? (str += ` ${components.sublocality_level_1}`) : null;
            components.address1 = str;
        }

        components.gpsLatitude = literal ? lat : lat();
        components.gpsLongitude = literal ? lng : lng();

        return new Address(components);
    }
}
