import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as Sentry from '@sentry/browser';
import { filter, fromEvent, merge } from 'rxjs';
import { v4 as uuid } from 'uuid';

import config from '../config';
import { environment } from '../environments/environment';
import { VersionCheckService } from './core/services/common/version-check.service';

export const SENTRY_SESSION_ID_KEY = 'sentryContextSessionId';

declare const gtag: (event: string, view: string, conf: any)=> void;

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'app';
    checkTimeout;

    timeoutVersionCheck: number = config.TIMEOUT_VERSION_CHECK;

    constructor(
        private versionCheckService: VersionCheckService,
        private router: Router,
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
    ) {
        this.setSentrySessionId();
        this.checkTimeout = new Date().getTime();

        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            if (typeof gtag !== 'undefined') {
                gtag('event', 'page_view', {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    page_path: event.urlAfterRedirects,
                });
            }
        });
        iconRegistry.addSvgIcon(
            'attached-video',
            sanitizer.bypassSecurityTrustResourceUrl('assets/shared/attachment/camera.svg'),
        );
        iconRegistry.addSvgIcon(
            'attached-audio',
            sanitizer.bypassSecurityTrustResourceUrl('assets/shared/attachment/mic.svg'),
        );
        iconRegistry.addSvgIcon(
            'attached-image',
            sanitizer.bypassSecurityTrustResourceUrl('assets/shared/attachment/image.svg'),
        );
        iconRegistry.addSvgIcon(
            'paper-clip',
            sanitizer.bypassSecurityTrustResourceUrl('assets/shared/attachment/paper_clip.svg'),
        );
        iconRegistry.addSvgIcon(
            'ohp-building-with-clock',
            sanitizer.bypassSecurityTrustResourceUrl('assets/icons/dashboard-navigation/building-with-clock.svg'),
        );
        iconRegistry.addSvgIcon(
            'ohp-multiple-users',
            sanitizer.bypassSecurityTrustResourceUrl('assets/icons/dashboard-navigation/multiple-users.svg'),
        );
        iconRegistry.addSvgIcon(
            'ohp-stethoscope',
            sanitizer.bypassSecurityTrustResourceUrl('assets/icons/dashboard-navigation/stethoscope.svg'),
        );
        iconRegistry.addSvgIcon(
            'ohp-calendar',
            sanitizer.bypassSecurityTrustResourceUrl('assets/icons/dashboard-navigation/calendar.svg'),
        );
        iconRegistry.addSvgIcon(
            'nothing-to-see',
            sanitizer.bypassSecurityTrustResourceUrl('assets/main/nothing-to-see-icon.svg'),
        );
        iconRegistry.addSvgIcon(
            'no-care-plan',
            sanitizer.bypassSecurityTrustResourceUrl('assets/icons/care-plan-no-data.svg'),
        );
        iconRegistry.addSvgIcon(
            'homeIcon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/svg/home-icon.svg'),
        );
        iconRegistry.addSvgIcon(
            'teamIcon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic-team.svg'),
        );
        iconRegistry.addSvgIcon(
            'downArrow',
            sanitizer.bypassSecurityTrustResourceUrl('assets/svg/down-arrow.svg'),
        );
        iconRegistry.addSvgIcon(
            'messageDarkIcon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/svg/message-dark-icon.svg'),
        );
        iconRegistry.addSvgIcon(
            'email',
            sanitizer.bypassSecurityTrustResourceUrl('assets/covid-clinic/email.svg'),
        );
        iconRegistry.addSvgIcon(
            'appointmentIcon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/svg/appointment-icon.svg'),
        );
        iconRegistry.addSvgIcon(
            'allServicesIcon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/svg/all-services-icon.svg'),
        );
        iconRegistry.addSvgIcon(
            'logo',
            sanitizer.bypassSecurityTrustResourceUrl('assets/VA_Logos/logo.svg'),
        );
        iconRegistry.addSvgIcon(
            'arrow-down',
            sanitizer.bypassSecurityTrustResourceUrl('assets/svg/arrow_down.svg'),
        );
        iconRegistry.addSvgIcon(
            'folder',
            sanitizer.bypassSecurityTrustResourceUrl('assets/icons/capacitygrid/folder.svg'),
        );
        iconRegistry.addSvgIcon(
            'check',
            sanitizer.bypassSecurityTrustResourceUrl('assets/icons/capacitygrid/check.svg'),
        );
        iconRegistry.addSvgIcon(
            'list-alt',
            sanitizer.bypassSecurityTrustResourceUrl('assets/svg/list-alt.svg'),
        );
        iconRegistry.addSvgIcon(
            'clearFilter',
            sanitizer.bypassSecurityTrustResourceUrl('assets/svg/clear_filter.svg'),
        );
        iconRegistry.addSvgIcon(
            'emptyStateTablets',
            sanitizer.bypassSecurityTrustResourceUrl('assets/svg/empty_state_tablets.svg'),
        );
        iconRegistry.addSvgIcon(
            'editIcon',
            sanitizer.bypassSecurityTrustResourceUrl('assets/main/temp/edit-icon.svg'),
        );
        iconRegistry.addSvgIcon(
            'ic-assignments',
            sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic-assignments.svg'),
        );
        iconRegistry.addSvgIcon(
            'ic-assignments',
            sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic-assignments.svg'),
        );
        iconRegistry.addSvgIcon(
            'list-alt',
            sanitizer.bypassSecurityTrustResourceUrl('assets/svg/list-alt.svg'),
        );
        iconRegistry.addSvgIcon(
            'list-alt',
            sanitizer.bypassSecurityTrustResourceUrl('assets/svg/list-alt.svg'),
        );
        iconRegistry.addSvgIcon(
            'ic-book',
            sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic-book.svg'),
        );
        iconRegistry.addSvgIcon(
            'ic-family',
            sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic-family.svg'),
        );
        iconRegistry.addSvgIcon(
            'ic-home',
            sanitizer.bypassSecurityTrustResourceUrl('assets/svg/ic-home.svg'),
        );
        this.lazyLoadAgGrid().then();
    }

    ngOnInit(): void {
        const keypress$ = fromEvent(document, 'keypress');
        const mouseUp$ = fromEvent(document, 'mouseup');

        merge(keypress$, mouseUp$)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const now = new Date().getTime();
                const timeout = this.timeoutVersionCheck * 60 * 1000;

                if (this.checkTimeout + timeout < now) {
                    this.checkTimeout = now;

                    if (environment.versionCheckURL) {
                        this.versionCheckService.initVersionCheck(environment.versionCheckURL);
                    }
                }
            });
    }

    async lazyLoadAgGrid() {
        const { LicenseManager } = await import('ag-grid-enterprise');

        LicenseManager.setLicenseKey(
            // eslint-disable-next-line max-len
            'CompanyName=Composite Apps Inc.,LicensedGroup=Development Team,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=10,LicensedProductionInstancesCount=3,AssetReference=AG-030262,SupportServicesEnd=18_August_2023_[v2]_MTY5MjMxMzIwMDAwMA==202dc95933c83e81b6c9e7daf6df6f15');
    }

    setSentrySessionId() {
        try {
            const sessionId = uuid();

            window[SENTRY_SESSION_ID_KEY] = sessionId;
            Sentry.setTag('session_id', sessionId);

        } catch (e) {
            console.error(e);
            Sentry.captureException(e);
        }
    }
}
