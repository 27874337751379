/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { format } from 'date-fns';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { AppointmentForClinic } from 'src/app/modules/organizations/org-overview/org-middle-dashboard/org-patient-groups-in-clinic/org-patient-groups-in-clinic.component';

import { environment } from '../../../../environments/environment';
import {
    IRecentlyViewed,
    RECENTLY_VIEWED_KEY,
} from '../../../modules/organizations/org-dashboard/recently-viewed-organizations/recently-viewed-organizations.component';
import { constants } from '../../../modules/patient-overview/shared/constants';
import { OrganizationTypes } from '../../../shared/enums/organization';
import { BreadcrumbsService } from '../../../shared/modules/breadcrumbs/breadcrumbs.service';
import { Branch } from '../../classes/branch.class';
import { IClinicAppointmentResource } from '../../models/clinic-appointment-resource.model';
import { ResponseWithLinks } from '../../models/common.model';
import { Link } from '../../models/link.model';
import { LocationTypesModel, LocationTypesResponse } from '../../models/location-types.model';
import { AdminInterface, IOrganization, OrganizationModel } from '../../models/organization.model';
import { IPatientMini } from '../../models/patient.model';
import { PerformerModel } from '../../models/performer.model';
import { DepartmentModel } from '../../models/provider.model';
import { LinkUtil } from '../../utils/link-util/link-util.service';
import { HttpUrlEncodingCodec } from '../authorization/http-url-encoding-codec';
import { ImageService } from '../image/image.service';
import { NetworkService } from '../network/network.service';
import { ICreateOrganizationRequestExtendedImage } from './createOrganization.interface';

export class ExtendedClinic extends Branch {
    selected?: boolean;
}

export interface CheckOrgIdResponse {
    type: OrganizationTypes;
    reason: string;
}

export interface IOrganizationPatientsOptions {
    page?: number;
    size?: number;
    carePlanStatus?: string;
    occupation?: string;
    priority?: string;
    orderType?: string;
    asc?: string;
    name?: string;
    email?: string;
    stringId?: string;
    phoneNumber?: string;
    patientType?: string;
    ageGroups?: string;
    ethnicity?: string;
    postal_code?: string;
    races?: string;
    patientCustomIdentifiers?: string;
    gender?: string;
    globalSearch?: boolean;
    userId?: string;
    parentName?: string;
    includeChildOrganizations?: boolean
}

export const DEFAULT_CLINIC_ID = 'defaultClinicId';

export interface ClinicPatientsTotalCounts {
    Capacity: number;
    CompletedCount: number;
    ScheduledCount: number;
}

@Injectable({ providedIn: 'root' })
export class OrganizationService {
    baseURL = '/api/organizations';

    patientClinicUpdate = new EventEmitter<Event>();

    // org title bar titleName
    titleBarName = new EventEmitter<any>();
    public userSearchSource = new BehaviorSubject(null);
    public userSearch = this.userSearchSource.asObservable();
    clinicUpdateEvent = new EventEmitter<any>();
    private organization: IOrganization;
    private patientGroups: IOrganization[];
    private departments: DepartmentModel[];
    private locationTypes: { content: LocationTypesModel[]; links: Link[] };

    // URLs
    private getOrganizationUrl: string;
    private getOrganizationsUrl: string;
    private _clinicSelected = new Subject<IOrganization[]>();
    private _numberOfPatient = new Subject<number>();
    private organisationUpdated = new Subject<boolean>();
    private showOnlyTopBarLayout = new Subject<boolean>();
    private appointmentForClinic = new Subject<AppointmentForClinic[]>();
    private _districtsList = new BehaviorSubject<any[]>([]);
    private _titleOrg = new BehaviorSubject<any>(null);

    constructor(
        private network: NetworkService,
        private http: HttpClient,
        private linkUtil: LinkUtil,
        private imageService: ImageService,
        private breadcrumbService: BreadcrumbsService,
    ) {
    }

    _checkOrgIdResponse = new BehaviorSubject<CheckOrgIdResponse>(null);

    checkOrgIdResponse$ = this._checkOrgIdResponse.asObservable();

    get checkOrgIdResponse() {
        return this._checkOrgIdResponse.getValue();
    }

    set checkOrgIdResponse(value) {
        this._checkOrgIdResponse.next(value);
    }

    set setDistrictsList(value) {
        this._districtsList.next(value);
    }

    get getDistrictsList() {
        return this._districtsList.getValue();
    }

    get defaultClinicId() {
        return localStorage.getItem(DEFAULT_CLINIC_ID);
    }

    set defaultClinicId(value: string) {
        localStorage.setItem(DEFAULT_CLINIC_ID, value);
    }

    get titleOrg() {
        return this._titleOrg;
    }

    set titleOrg(value) {
        this.titleOrg.next(value);
    }

    notifyOrganisationUpdated = (info: any) => this.organisationUpdated.next(info);

    listenOrganisationUpdated = (): Observable<any> => this.organisationUpdated.asObservable();

    notifyshowOnlyTopBarLayout = (info: any) => this.showOnlyTopBarLayout.next(info);

    listenshowOnlyTopBarLayout = (): Observable<any> => this.showOnlyTopBarLayout.asObservable();

    notifyAppointmentForClinic = (info: any) => this.appointmentForClinic.next(info);

    listenAppointmentForClinic = (): Observable<any> => this.appointmentForClinic.asObservable();

    fetchCheckOrgId(orgId: string): Observable<CheckOrgIdResponse> {
        const url = `/api/organizations/${ orgId }/checkOrgId`;

        return this.network.fetchResource<CheckOrgIdResponse>(url).pipe(tap((res) => this.checkOrgIdResponse = res));
    }

    getCheckOrgId(orgId: string): Observable<CheckOrgIdResponse> {
        if (this.checkOrgIdResponse) {
            return this.checkOrgIdResponse$;
        }

        return this.fetchCheckOrgId(orgId);
    }

    checkOrgByName(orgName: string): Observable<boolean> {
        const url = '/api/organizations/exist';
        const params = new HttpParams().set('name', orgName);

        return this.network.fetchResource(url, { params });
    }

    setOrganization(organization: IOrganization) {
        this.organization = organization;
    }

    clearOrganization() {
        this.organization = null;

        // this.breadcrumbService.reset();
    }

    // url for GET single organization
    setGetOrganizationUrl(url: string) {
        this.getOrganizationUrl = url;
    }

    // REMOVE

    removeOrganization() {
        this.organization = null;
    }

    removeGetOrganizationUrl() {
        this.getOrganizationUrl = null;
    }

    removeGetOrganizationsUrl() {
        this.getOrganizationsUrl = null;
    }

    setPatientGroups(patientGroups: IOrganization[]) {
        this.patientGroups = patientGroups;
    }

    getPatientGroups(): IOrganization[] {
        return this.patientGroups;
    }

    setNumberOfPatient = (info: number) => this._numberOfPatient.next(info);

    getNumberOfPatient = (): Observable<number> => this._numberOfPatient.asObservable();

    reset() {
        this.removeOrganization();
        this.removeGetOrganizationUrl();
        this.removeGetOrganizationsUrl();
    }

    public createOrganization(organization: ICreateOrganizationRequestExtendedImage | IOrganization): Observable<IOrganization> {
        const link = '/api/organizations';

        const organization$ = this.network.postResource<IOrganization>(link, organization);

        if (
            organization.organizationImage
            && organization.organizationImage !== 'DELETE'
            && organization.type !== 'FACILITY'
        ) {
            const { organizationImage } = organization;

            return organization$.pipe(mergeMap((res: IOrganization) => this.imageService.postImage('organizations', res.id, organizationImage).pipe(map(() => this.organization))));
        }

        return organization$;
    }

    fetchOrgOverview(orgId: string): Observable<OrganizationModel> {
        const url = `/api/organizations/${ orgId }/overview`;

        return this.network.fetchResource<OrganizationModel>(url)
            .pipe(tap((org: OrganizationModel) => {
                this.addOrgToRecentlyViewed(org as IOrganization);
            }));
    }

    fetchOrgBasicOverview(orgId: string): Observable<OrganizationModel> {
        const url = `/api/organizations/${ orgId }/basicInfo`;

        return this.network.fetchResource<OrganizationModel>(url);
    }

    public createOrganizationWithAdminWithoutCredentials(organization, admin): Observable<IOrganization> {
        const url = `${ environment.apiUrl }/api/organizations/withAdmin`;
        const body = {
            ...organization,
            admin,
        };

        return this.http.post<IOrganization>(url, body);
    }

    public updateOrganization(organization): Observable<any> {
        const orgId = organization.id;
        const link = `/api/organizations/${ orgId }`;

        delete organization.id;

        const organization$ = this.network.putResource<IOrganization>(link, organization);

        if (organization.organizationImage && organization.organizationImage !== 'DELETE') {
            const { organizationImage } = organization;

            return organization$.pipe(mergeMap((res: IOrganization) => this.imageService.postImage('organizations', res.id, organizationImage).pipe(map(() => res))));
        }

        if (organization.organizationImage === 'DELETE') {
            return organization$.pipe(mergeMap((res) => this.network.deleteResource(`/api/organizations/${ orgId }/picture`).pipe(map(() => res))));
        }

        return organization$;
    }

    public deleteOrganization(organizationId: string): any {
        const link = `/api/organizations/${ organizationId }`;

        return this.network.deleteResource(link);
    }

    public getDepartmentsByOrganization(organizationId: string): Observable<{
        content: DepartmentModel[];
        links: Link[]
    }> {
        const link = `/api/organizations/${ organizationId }/departments`;

        return this.network.fetchResource(link);
    }

    public addDepartment(body): Observable<any> {
        const link = '/api/departments';

        return this.network.postResource(link, body);
    }

    public updateDepartment(department: DepartmentModel): Observable<any> {
        const link = `/api/departments/${ department.id }`;

        return this.network.putResource(link, department);
    }

    public removeDepartment(id: string): any {
        const link = `/api/departments/${ id }`;

        return this.network.deleteResource(link);
    }

    public getDepartment() {
        if (this.departments) {
            return of(this.departments);
        }

        const link = '/api/departments/options';

        return this.network
            .fetchResource(link)
            .pipe(map((departments: DepartmentModel[]) => this.setDepartment(departments)));
    }

    public getLocationTypes(): Observable<LocationTypesResponse> {
        if (this.locationTypes) {
            return of(this.locationTypes);
        }

        const link = '/api/locations/locationTypes';

        return this.network
            .fetchResource(link)
            .pipe(map((locationTypesResponse: LocationTypesResponse) => this.setLocationTypes(locationTypesResponse)));
    }

    public getOrganizationPatients(
        id: string,
        {
            page = 0,
            size = 100,
            carePlanStatus = '',
            occupation = '',
            priority = '',
            orderType = '',
            asc = '',
            name = '',
            email,
            stringId,
            phoneNumber,
            patientType = '',
            ageGroups = '',
            ethnicity = '',
            postal_code = '',
            races = '',
            patientCustomIdentifiers = '',
            gender = '',
            userId = '',
            globalSearch = false,
            parentName = '',
            includeChildOrganizations = true,
        }: IOrganizationPatientsOptions = {},
    ) {
        let httpParams = new HttpParams({ encoder: new HttpUrlEncodingCodec() });
        const link = `/api/organizations/${ id }/patients`;

        if (page >= 0 && size !== -1) httpParams = httpParams.append('page', page.toString());
        if (size >= 0 || size === -1) httpParams = httpParams.append('size', size.toString());
        if (carePlanStatus) httpParams = httpParams.append('carePlanStatus', carePlanStatus);
        if (occupation) httpParams = httpParams.append('occupation', occupation);
        if (priority) httpParams = httpParams.append('priority', priority);
        if (orderType) httpParams = httpParams.append('orderType', orderType);
        if (asc) httpParams = httpParams.append('asc', asc.toString());
        if (name) httpParams = httpParams.set('fullName', name);
        if (email) httpParams = httpParams.set('email', email);
        if (stringId) httpParams = httpParams.set('stringId', stringId);
        if (phoneNumber) httpParams = httpParams.set('phoneNumber', phoneNumber);
        if (patientType) httpParams = httpParams.append('patientType', patientType);
        if (userId) httpParams = httpParams.append('userId', userId);
        if (ageGroups) httpParams = httpParams.append('ageGroups', ageGroups);
        if (ethnicity) httpParams = httpParams.append('ethnicity', ethnicity);
        if (postal_code) httpParams = httpParams.append('postal_code', postal_code);
        if (races) httpParams = httpParams.append('races', races);
        if (patientCustomIdentifiers) httpParams = httpParams.append('patientCustomIdentifiers', patientCustomIdentifiers);
        if (gender) httpParams = httpParams.append('gender', gender);
        if (name && globalSearch) httpParams = httpParams.append('global-search', String(globalSearch));
        if (parentName) httpParams = httpParams.set('parentName', parentName);
        if (includeChildOrganizations) httpParams = httpParams.set('includeChildOrganizations', includeChildOrganizations);

        return this.network.fetchResource<{
            totalPatientsCount: number;
            patientList: ResponseWithLinks<IPatientMini>;
        }>(link, { params: httpParams });
    }

    public getOrganizationDepartment(organizationId: string): Observable<ResponseWithLinks<DepartmentModel>> {
        const link = `/api/organizations/${ organizationId }/departments`;

        return this.network.fetchResource(link);
    }

    public getOrganizationOverview(orgId: string): Observable<IOrganization> {
        const url = `/api/organizations/${ orgId }/overview`;

        return this.network.fetchResource(url)
            .pipe(tap((org: IOrganization) => {
                this.addOrgToRecentlyViewed(org);
            }));
    }

    public addOrgToRecentlyViewed(org: IOrganization) {
        const prevDataJSON = localStorage.getItem(RECENTLY_VIEWED_KEY) || '{}';
        const prevData = JSON.parse(prevDataJSON) as IRecentlyViewed;
        const parentId = org?.parentId || 'self';

        prevData[parentId] ||= [];
        const alreadyInRecentlyViewed = prevData[parentId].some((o) => o.organizationId === org.id);

        if (alreadyInRecentlyViewed) return;
        prevData[parentId].push({ organizationId: org.id });

        // localStorage.setItem(RECENTLY_VIEWED_KEY, JSON.stringify(prevData));
    }

    public getVaccinationCounts(
        orgId: string,
        startDate: string,
        endDate: string,
    ): Observable<ClinicPatientsTotalCounts> {
        const url = `/api/organizations/${ orgId }/clinic-appointment-counts`;
        let httpParams = new HttpParams();

        if (startDate) httpParams = httpParams.append('startDate', startDate);
        if (endDate) httpParams = httpParams.append('endDate', endDate);

        return this.network.fetchResource(url, { params: httpParams });
    }

    public getOrganization(orgId: string): Observable<IOrganization> {
        const url = `/api/organizations/${ orgId }`;

        return this.network.fetchResource(url);
    }

    public getOrgAdminInfo(accountId: any) {
        return this.network.fetchResource<any>(`/api/accounts/${ accountId }`);
    }

    public getOrganizationQuarantineContacts(orgId: string): Observable<any[]> {
        const link = `/api/quarantineContact/organization/${ orgId }`;

        return this.network.fetchResource(link);
    }

    public getOrgProviders(orgId: string): Observable<ResponseWithLinks<PerformerModel>> {
        const url = `/api/organizations/${ orgId }/performers`;

        return this.network.fetchResource<ResponseWithLinks<PerformerModel>>(url);
    }

    public getOrgProvidersBrief(orgId: string): Observable<ResponseWithLinks<PerformerModel>> {
        const url = `/api/organizations/${ orgId }/performers_brief`;

        return this.network.fetchResource<ResponseWithLinks<PerformerModel>>(url);
    }

    public getOrgAppointment<T>(
        locationId: string,
        {
            search = '',
            dobFrom = null,
            dobTo = null,
            time = null,
            status = '',
            page = null,
            size = null,
            dueDateFrom = null,
            dueDateTo = null,
            orderType = '',
            asc = true,
            taskName = null,
            vaccineType = '',
        },
    ): Observable<T> {
        const url = `/api/tasks/${ locationId }/location`;
        let params = new HttpParams({ encoder: new HttpUrlEncodingCodec() })
            .set('taskType', 'appointment')
            .set('orderType', orderType)
            .set('asc', asc.toString());

        if (size && page) {
            params = params.append('size', size);
            params = params.append('page', page);
        }
        if (search) params = params.append('name', search);
        if (time) params = params.append('time', time);
        if (status) params = params.append('status', status);
        if (dobFrom) params = params.append('dobFrom', dobFrom);
        if (dobTo) params = params.append('dobTo', dobTo);
        if (dueDateFrom) params = params.append('dueDateFrom', dueDateFrom);
        if (dueDateTo) params = params.append('dueDateTo', dueDateTo);
        if (taskName) params = params.append('taskName', taskName);
        if (vaccineType) params = params.append('vaccineType', vaccineType);

        return this.network.fetchResource<T>(url, { params });
    }

    public getParentOrgPatientGroups(parentId: string): Observable<any> {
        // in progress
        const url = `/api/organizations/organizationPatientGroups/${ parentId }`;

        return this.network.fetchResource(url);
    }

    public bulkCancelTasksByLocation(locationId, dueDateFrom, dueDateTo): Observable<any> {
        const link = `/api/tasks/${ locationId }/location/bulk-cancel?dueDateFrom=${ dueDateFrom }&dueDateTo=${ dueDateTo }`;

        return this.network.putResource(link, {});
    }

    public planVaccinationForOrganization(
        organizationId,
        organizationAppointmentId,
        startDate,
        endDate,
    ): Observable<any> {
        const link = `/api/organizations/${ organizationId }/appointment/${ organizationAppointmentId }`;

        const body = {
            status: 'ACTIVE',
            startDate,
            endDate,
        };

        return this.network.putResource(link, body);
    }

    public addOrganizationAdmin(organizationId: string, admin: AdminInterface, privilegeId: number): Observable<any> {
        const url = '/api/accounts';
        const body = {
            organizationId,
            privilegeId,
            ...admin,
        };

        return this.network.postResource(url, body);
    }

    public deleteFacilityAppointment({ orgId, clinicId }: { orgId: string; clinicId: string }) {
        const url = `/api/organizations/${ orgId }/appointment/${ clinicId }`;

        return this.network.deleteResource(url);
    }

    public addFacilityAppointment({
        orgId,
        clinicId,
        startDate,
        endDate,
    }: {
        orgId: string;
        clinicId: string;
        startDate?: number;
        endDate?: number;
    }): Observable<any> {
        const url = `/api/organizations/${ orgId }/appointment`;
        const body = {
            status: 'ACTIVE',
            startDate,
            endDate,
            clinicId,
        };

        return this.network.postResource(url, body);
    }

    public getAllOrganizationClinics(orgId, date: Date = null): Observable<any> {
        // endpoint in progress
        const url = `/api/organizations/${ orgId }/getAllOrganizationClinic`;

        let httpParams = new HttpParams();

        if (date) {
            const formattedDate = format(date, 'MM/dd/yyyy');

            httpParams = httpParams.set('date', formattedDate);
        }

        return this.network.fetchResource(url, { params: httpParams });
    }

    public getAllOrganizationClinicsBrief(orgId, date: Date = null): Observable<IOrganization[]> {
        // endpoint in progress
        const url = `/api/organizations/${ orgId }/getAllOrganizationClinic_brief`;
        let httpParams = new HttpParams();

        if (date) {
            const formattedDate = format(date, 'MM/dd/yyyy');

            httpParams = httpParams.set('date', formattedDate);
        }

        return this.network.fetchResource(url, { params: httpParams });
    }

    public getAllOrganizationStaff(orgId): Observable<PerformerModel[]> {
        const url = `/api/organizations/${ orgId }/allStaff`;

        /*
         * let params = new HttpParams()
         *   .set('page', page.toString())
         *   .set('size', size.toString())
         *   .set('orderType', orderType.toString())
         *   .set('asc', asc.toString());
         */

        return this.network.fetchResource(url);
    }

    public getOpenDaysForOrganizationOld({
        organizationId,
        patientId,
        taskId,
    }: {
        organizationId: string;
        patientId?: string;
        taskId?: string;
    }): Observable<string[]> {
        const url = `/api/organizations/${ organizationId }/clinic-appointments/open-days`;
        let httpParams = new HttpParams();

        if (patientId) httpParams = httpParams.set('targetPatientId', patientId);
        if (taskId) httpParams = httpParams.set('taskId', taskId);

        return this.network.fetchResource(url, { params: httpParams });
    }

    public getOpenDaysForOrganization(
        organizationId,
        patientId: string = null,
        vaccineTypes: string[] = [],
        contentType?: string,
    ): Observable<any> {
        const url = `/api/organizations/${ organizationId }/clinic-appointments/open-days`;
        let httpParams = new HttpParams();

        if (patientId) httpParams = httpParams.set('targetPatientId', patientId);
        if (vaccineTypes?.length) {
            const vaccinesEncoded = encodeURIComponent(vaccineTypes.join());

            httpParams = httpParams.set('vaccineType', vaccinesEncoded);
        }

        if (contentType) {
            const contentTypeEncoded = encodeURIComponent(vaccineTypes.join());

            httpParams = httpParams.set('contentType', contentTypeEncoded);
        }

        return this.network.fetchResource(url, { params: httpParams });
    }

    public bookAppointmentForTask(locationId, dueDate, task, clinicAppointmentId = null): Observable<any> {
        if (
            task.dueDate != dueDate
            || task.location.id != locationId
            || task.clinicAppointmentResource.id != clinicAppointmentId
        ) {
            return this.bookAppointment(locationId, dueDate, task.id, clinicAppointmentId);
        }

        return of(task);
    }

    public bookAppointment(
        locationId,
        dueDate,
        taskId,
        clinicAppointmentId = null,
        performerId = null,
        params = { checkForSlotOpen: true },
    ): Observable<any> {
        const url = '/api/tasks/book-first-appointment-for-patient';

        const body: { [key: string]: any } = {
            locationId,
            dueDate,
            id: taskId,
        };

        if (clinicAppointmentId) body.clinicAppointmentId = clinicAppointmentId;

        const httpParams = new HttpParams().set('checkForSlotOpen', params.checkForSlotOpen.toString());

        if (performerId) body.performerId = performerId;

        return this.network.putResource(url, body, { params: httpParams });
    }

    public bookAppointmentMini(body): Observable<any> {
        const url = '/api/tasks/bookAppointment/mini';

        return this.network.putResource(url, body);
    }

    public cancelAppointment(patientId: string, taskId: string): Observable<any> {
        const url = `/api/tasks/bookAppointment/cancel?patientId=${ patientId }&taskId=${ taskId }`;

        return this.network.putResource(url, {});
    }

    public getOrgActiveFutureClinics(orgId, inventoryType): Observable<any> {
        const url = `/api/lanes/${ orgId }/active-clinics?inventoryType=${ inventoryType }`;

        return this.network.fetchResource(url, {});
    }

    public getOrgPublicClinics(orgId, date, patientId: string = null, serviceType?): Observable<any> {
        const formattedDate = format(date, 'MM/dd/yyyy');
        const url = `/api/organizations/organizationublicClinics/${ orgId }/web`;
        let params = new HttpParams().set('date', formattedDate);

        params = params.set('age', 0);
        if (patientId) params = params.set('targetPatientId', patientId);
        if (serviceType) {
            params = params.set('serviceType', serviceType);
        }
        const isAddFamilyMember = localStorage.getItem(constants.LocalStorageKeys.NEW_FAMILY_MEMBER);

        if (isAddFamilyMember) {
            params = params.set('isAddFamilyMember', true);
        }

        return this.network.fetchResource(url, { params });
    }

    public getOrgPublicClinicsNextAvailable(
        orgId,
        dates: string[] = [],
        vaccineType: string,
        patientId: string = null,
    ): Observable<any> {
        const url = `/api/organizations/organizationublicClinics/${ orgId }/web/next`;
        let httpParams = new HttpParams();

        if (patientId) httpParams = httpParams.set('targetPatientId', patientId);
        if (dates?.length) {
            const _dates = encodeURIComponent(dates.join(','));

            httpParams = httpParams.set('date', _dates);
        }
        httpParams = httpParams.set('vaccineType', vaccineType);

        return this.network.fetchResource(url, { params: httpParams });
    }

    clinicSelected(clinic: IOrganization[]) {
        this._clinicSelected.next(clinic);
    }

    getSuggestingClinics(orgId: string): Observable<IOrganization[]> {
        const url = `/api/organizations/${ orgId }/getClinicSuggestionsForPatientGroup`;

        return this.network.fetchResource(url);
    }

    public getOrganizationEventListing(clinicId): Observable<any> {
        // return this.http.get(environment.apiUrl + `/api/eventScan/pastEvents/${clinicId}`);
        const url = `/api/eventScan/pastEvents/${ clinicId }`;

        return this.network.fetchResource(url);
    }

    getCommunityAppointments(organizationId): Observable<any[]> {
        const url = `/api/organizations/clinic-patient-groups/${ organizationId }/community-appointment`;

        return this.network.fetchResource(url);
    }

    refreshInviteCode(orgId: string): Observable<string> {
        const url = `/api/organizations/${ orgId }/refresh-invitation-code`;

        return this.network.putResource<string>(url, {}, { responseType: 'text' });
    }

    getAttachment(accountId: string, imageId: string) {
        const url = `/api/accounts/${ accountId }/picture/${ imageId }`;

        return this.network.fetchResource(url);
    }

    getAllRootOrganizations(): Observable<any[]> {
        const url = '/api/organizations/rootOrganizations';

        return this.network.fetchResource(url);
    }

    updateTriageEventOrganization(eventId: string, body: { organizationId: string }): Observable<any[]> {
        const url = `/api/organizations/${ eventId }/change-organization`;

        return this.network.putResource(url, body);
    }

    getClinicsByOrgId(orgId: string): Observable<any[]> {
        const url = `${ this.baseURL }/${ orgId }/branches-brief`;
        const httpParams = new HttpParams().set('type', OrganizationTypes.Clinic);

        return this.network.fetchResource(url, { params: httpParams });
    }

    getPatientGroupsByOrgId(orgId: string): Observable<any[]> {
        const url = `${ this.baseURL }/organizationPatientGroups/${ orgId }`;

        return this.network.fetchResource(url);
    }

    public getOrganizationSummaryCounts(orgId: string): Observable<any> {
        const url = `/api/organizations/${ orgId }/summary-counts`;

        return this.network.fetchResource(url);
    }

    public getProgramAccessibilityCounts(orgId: string, dateFrom: Date, dateTo: Date): Observable<{ [key: string]: number }> {
        const url = `/api/organizations/${ orgId }/program-accessibility-counts`;
        let httpParams = new HttpParams();

        if (dateFrom) {
            const formattedDate = format(dateFrom, 'MM/dd/yyyy');

            httpParams = httpParams.append('dateFrom', formattedDate);
        }
        if (dateTo) {
            const formattedDate = format(dateTo, 'MM/dd/yyyy');

            httpParams = httpParams.append('dateTo', formattedDate);
        }

        return this.network.fetchResource(url, { params: httpParams });
    }

    public getOrganizationNameById(id: string): Observable<string> {
        const url = `/api/organizations/${ id }/name`;

        return this.network.fetchResource(url, { responseType: 'text' });
    }

    getClinicAppointments(clinicId: string, fromDate: Date, toDate: Date, serviceName: string, status?: string) {
        const url = `/api/organizations/${ clinicId }/clinic-appointments`;
        let params = new HttpParams();

        if (fromDate) {
            const formattedDate = format(fromDate, 'MM/dd/yyyy');

            params = params.append('startDate', formattedDate);
        }
        if (toDate) {
            const formattedDate = format(toDate, 'MM/dd/yyyy');

            params = params.append('endDate', formattedDate);
        }
        if (serviceName) {
            params = params.append('inventoryName', serviceName);
        }
        if (status) {
            params = params.append('status', status);
        }

        return this.network.fetchResource<IClinicAppointmentResource[]>(url, { params });
    }

    getClinicLanes(clinicId: string, params): Observable<any[]> {
        const url = `/api/lanes/${ clinicId }`;
        let httpParams = new HttpParams();

        if (params.date) httpParams = httpParams.set('date', params.date);
        if (params.inventoryType) httpParams = httpParams.set('inventoryType', params.inventoryType);

        return this.network.fetchResource(url, { params: httpParams });
    }

    getAggregatedStatsForLanesFromClinic(clinicId: string, params): Observable<any[]> {
        const url = `/api/lanes/${ clinicId }/aggregated-stats`;
        let httpParams = new HttpParams();

        if (params.dateFrom) {
            const formattedDate = format(params.dateFrom, 'MM/dd/yyyy');

            httpParams = httpParams.set('dateFrom', formattedDate);
        }
        if (params.inventoryName) httpParams = httpParams.set('inventoryName', params.inventoryName);

        return this.network.fetchResource(url, { params: httpParams });
    }

    getAggregatedStatsForLanesForAllClinics(orgId: string, params): Observable<any[]> {
        const url = `/api/lanes/${ orgId }/aggregated-stats-for-all-clinics`;
        let httpParams = new HttpParams();

        if (params.dateFrom) {
            const formattedDate = format(params.dateFrom, 'MM/dd/yyyy');

            httpParams = httpParams.set('dateFrom', formattedDate);
        }
        if (params.inventoryName) httpParams = httpParams.set('inventoryName', params.inventoryName);

        return this.network.fetchResource(url, { params: httpParams });
    }

    getSchedulingCountsForClinic(clinicId: string, dateFrom: Date, dateTo: Date, inventoryNames): Observable<any[]> {
        const url = `/api/lanes/${ clinicId }/scheduling-counts`;
        let httpParams = new HttpParams();

        if (dateFrom) {
            const formattedDate = format(dateFrom, 'MM/dd/yyyy');

            httpParams = httpParams.append('dateFrom', formattedDate);
        }
        if (dateTo) {
            const formattedDate = format(dateTo, 'MM/dd/yyyy');

            httpParams = httpParams.append('dateTo', formattedDate);
        }
        if (inventoryNames) {
            httpParams = httpParams.append('inventoryName', inventoryNames);
        }

        return this.network.fetchResource(url, { params: httpParams });
    }

    getSchedulingCountsForAllClinics(orgId: string, dateFrom: Date, dateTo: Date, inventoryNames): Observable<any[]> {
        const url = `/api/lanes/${ orgId }/scheduling-counts-for-all-clinics`;
        let httpParams = new HttpParams();

        if (dateFrom) {
            const formattedDate = format(dateFrom, 'MM/dd/yyyy');

            httpParams = httpParams.append('dateFrom', formattedDate);
        }
        if (dateTo) {
            const formattedDate = format(dateTo, 'MM/dd/yyyy');

            httpParams = httpParams.append('dateTo', formattedDate);
        }
        if (inventoryNames) {
            httpParams = httpParams.append('inventoryName', inventoryNames);
        }

        return this.network.fetchResource(url, { params: httpParams });
    }

    updateExtraSettings(organizationId: string, body: { extraSettings: string }) {
        const url = `/api/organizations/${ organizationId }/extraSettings`;

        return this.network.putResource(url, body);
    }

    private setDepartment(departments: DepartmentModel[]) {
        return this.departments = departments;
    }

    private setLocationTypes(locationTypesResponse: LocationTypesResponse) {
        this.locationTypes = locationTypesResponse;

        return locationTypesResponse;
    }

    getInventoryNamesFromClinic(clinicId: string, dateFrom: Date, dateTo: Date): Observable<any[]> {
        const url = `/api/lanes/${ clinicId }/inventoryNames`;
        let httpParams = new HttpParams();

        if (dateFrom) {
            const formattedDate = format(dateFrom, 'MM/dd/yyyy');

            httpParams = httpParams.append('dateFrom', formattedDate);
        }
        if (dateTo) {
            const formattedDate = format(dateTo, 'MM/dd/yyyy');

            httpParams = httpParams.append('dateTo', formattedDate);
        }

        return this.network.fetchResource(url, { params: httpParams });
    }

}
