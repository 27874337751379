import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { isEqual } from 'lodash-es';
import {
    BehaviorSubject, firstValueFrom, Observable, of, ReplaySubject, Subject, throwError
} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { MobileApiUserSession, UserSession } from '../../models/user-session.model';
import { ApiService } from '../api/api.service';
import { NetworkService } from '../network/network.service';
import { UserSessionService } from './user-session.service';

@Injectable({
    providedIn: 'root',
})
export class OperationUserSessionService {
    private _isPatient = new BehaviorSubject<boolean>(false);
    set isPatient(value) {
        this._isPatient.next(value);
    }

    get isPatient() {
        return this._isPatient.getValue();
    }

    private patientData = new Subject<any>();
    notifyToSetUserInfo = (info: any) => this.patientData.next(info);
    listenToSetUserInfo = (): Observable<any> => this.patientData.asObservable();

    private mobile = new ReplaySubject<MobileApiUserSession>(0);
    private mobileLastFetchedID: string;
    private mobileData: MobileApiUserSession;

    constructor(private network: NetworkService, private userSession: UserSessionService) {}

    public getUserSession(fetch: boolean = false): Observable<UserSession> {
        const session = this.userSession.getSession();

        this.userSession.token = JSON.parse(localStorage.getItem('keycloak'));

        if (session && !fetch) {
            return of(session);
        }
        return this.network.fetchResource(ApiService.fetchSession()).pipe(
            map((userSession: UserSession) => {
                this.isPatient = Number(userSession.privilege.id) !== 4 ? false : !userSession.isPerformer;
                this.userSession.setAccountSession(userSession);
                this.userSession.setIsLoggedIn(true);
                return userSession;
            }),
            catchError(this.handleError),
        );
    }

    public fetchMobileApiSessionForPatientWithoutLogin(headers): Observable<MobileApiUserSession> {
        headers['X-Role'] = 'patient';
        const apiUrl = '/api/session/mobile';
        return this.network.fetchResource(apiUrl, { headers });
    }

    // familyAccountId: pass value in this parameter if needed family member session data, else pass null
    // fetchFresh: if true, the cached session data will be refreshed
    public getMobileApiSessionForPatient(
        familyAccountId: string,
        fetchFresh: boolean = false,
        contentLanguage?: string,
    ): Observable<MobileApiUserSession> {
        if (!this.isPatient) {
            return of(null);
        }
        const session = this.userSession.getMobileApiSession();
        if (session && !fetchFresh) {
            return of(session);
        }
        const headers: { [key: string]: any } = {};
        headers['X-Role'] = 'patient';
        if (contentLanguage) headers['Content-Language'] = contentLanguage;
        let apiUrl = '/api/session/mobile';
        if (familyAccountId) {
            apiUrl += `?accountId=${familyAccountId}`;
        }
        return this.network.fetchResource(apiUrl, { headers }).pipe(
            map((mobileApiUserSession: MobileApiUserSession) => {
                mobileApiUserSession = this.addDownloadLinks(mobileApiUserSession);
                mobileApiUserSession = this.addAppointmentStatus(mobileApiUserSession);
                this.userSession.setMobileApiAccountSession(mobileApiUserSession);
                this.setMobileData(mobileApiUserSession);
                return mobileApiUserSession;
            }),
            catchError(this.handleError),
        );
    }

    /* Returns a replay stream which updates when ever new data is received, functionally same as getMobileApiSession
   * but emits new data when received
   */
    getMobileApiSessionForPatientObservable(id?: string, fetchFresh?: boolean) {
        if (this.mobileData && !fetchFresh) {
            if (id && id === this.mobileLastFetchedID) {
                return this.mobile;
            }
            if (!id && this.isPatient) {
                return this.mobile;
            }
        }

        firstValueFrom(this.getMobileApiSessionForPatient(id, true)).then(() => {});

        return this.mobile;
    }

    private setMobileData(newMobileData: MobileApiUserSession) {
        if (!isEqual(newMobileData, this.mobileData)) {
            /** Check if there is an update to data. */
            this.mobile.next(newMobileData);
        }
    }

    private addAppointmentStatus(session: MobileApiUserSession) {
        session.observationList.forEach((observation) => {
            if (!observation?.stringValue) return;
            switch (observation.stringValue) {
                case '0':
                    observation.status = 'NEGATIVE';
                    break;
                case '1':
                    observation.status = 'POSITIVE';
                    break;
                case '-1':
                    observation.status = 'INVALID';
                    break;
                case '2':
                case '6':
                case '7':
                case '8':
                    observation.status = 'SENT_TO_LAB';
                    break;
            }
        });
        return session;
    }

    private addDownloadLinks(session: MobileApiUserSession) {
        session.observationList.forEach((appointment) => {
            appointment.downloadResult = () => {
                const url = `/api/elis/test-result/${appointment.taskId}/pdf`;
                network.fetchResource(url, { responseType: 'blob' }).subscribe((file: any) => {
                    const blob = new Blob([file], { type: 'application/pdf' });
                    saveAs(blob, `${appointment.taskId}.pdf`);
                });
            };
            const { network } = this;
        });
        return session;
    }

    handleError(error: HttpErrorResponse) {
        return throwError(error.error);
    }
}
