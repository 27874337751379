import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, map, Observable, of, OperatorFunction } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
    ClinicElement
} from '../../../modules/programs/pages/program-overview/pages/program-analytics/pages/pa-accessibility-page/components/clinics-table/clinics-table.component';
import { ResponseWithLinks } from '../../models/common.model';
import { PatientModel } from '../../models/patient.model';
import { IPatientCounts } from '../../models/program/analytics/patient-counts';
import { IProgramCategory } from '../../models/program-category.model';
import { IProgramType } from '../../models/program-type.model';
import { NetworkService } from '../network/network.service';
import { ProductItem } from '../product-item/product-item.class';
import { IProductItem } from '../product-item/product-items.interface';
import { Program } from './program.class';
import {
    IProgram,
    IProgramRequest,
    IProgramStatus,
    IQualification,
    IQualificationWithRank
} from './programs.interface';

export interface ClinicHoursOfOperation {
    days: {
        date: string;
        newCount: number;
        totalCount: number;
    }[];
    id: string;
    name: string;
}

@Injectable({
    providedIn: 'root',
})
export class ProgramsService {
    private baseURL = '/api/programs';

    constructor(private networkService: NetworkService) {
    }

    /* Create a new program */
    create(programDetails: IProgramRequest): Observable<Program> {
        return this.networkService.postResource(`${this.baseURL}/create-program`, programDetails)
            .pipe(
                map((program: IProgram) => new Program(program))
            );
    }

    getProgram(programId: string): Observable<IProgram> {
        return this.networkService.fetchResource<IProgram>(`${this.baseURL}/${programId}`);
    }

    /* get a specific program given you have its id */
    get(programId: string): Promise<IProgram> {
        return firstValueFrom(this.getProgram(programId));
    }

    /* Enroll in a pre-existing program */
    enroll(programDetails: IProgramRequest): Observable<any> {
        return this.networkService.postResource(`${this.baseURL}/enroll-program`, programDetails);
    }

    getClinicIPrograms(clinicId: string): Observable<IProgram[]> {
        const url = `${this.baseURL}/clinic/${clinicId}`;

        return this.networkService.fetchResource<IProgram[]>(url);
    }

    getClinicPrograms(clinicId: string): Observable<Program[]> {
        return this.getClinicIPrograms(clinicId)
            .pipe(
                this.convertIProgramsToProgramClass(),
                catchError(() => of([])),
            );
    }

    /* Select program for clinic */
    enrollClinic(programId: string, clinicId: string): Observable<any> {
        return this.networkService.postResource(`${this.baseURL}/clinic/${clinicId}/program/${programId}`, {});
    }

    /* deSelect program for clinic */
    deEnrollClinic(programId: string, clinicId: string): Observable<any> {
        return this.networkService.deleteResource(`${this.baseURL}/clinic/${clinicId}/program/${programId}`, {});
    }

    /* update a program given an object that fits IProgram interface */
    update(program: IProgram): Promise<IProgram> {
        return firstValueFrom<IProgram>(this.networkService.putResource(`${this.baseURL}/${program.id}`, program));
    }

    /* update image with a new image for the given program */
    updateImage(uploadData: { link: string; imageData: string }) {
        return this.networkService.putResource(uploadData.link, { imageData: uploadData.imageData });
    }

    /* Delete image of a given program */
    deleteImage(link: string) {
        return this.networkService.deleteResource(link);
    }

    deleteProgramById(id: string): Observable<void> {
        return this.networkService.deleteResource<void>(`${this.baseURL}/${id}`);
    }

    /* delete a program given its id */
    delete(programId: string): Promise<void> {
        this.networkService.deleteResource<void>(`${this.baseURL}/${programId}`).subscribe();
        return Promise.resolve();
    }

    getITemplates(): Observable<IProgram[]> {
        return this.networkService.fetchResource<IProgram[]>(`${this.baseURL}/templates`);
    }
    /* Get a List of all available templates */
    getTemplates(): Observable<Program[]> {
        return this.getITemplates().pipe(
            this.convertIProgramsToProgramClass(),
            catchError(() => of([])),
        );
    }

    /* Get a paged list of all patients in a given program. */
    getPatientsFromProgramId(
        programId: string,
        {
            page = 0,
            size = 100,
            name,
            email,
            phoneNumber,
            carePlanStatus,
            occupation,
            asc,
            orderType,
            userId,
            parentName
        }: {
            page: number;
            size: number;
            asc?: string;
            orderType?: string;
            name?: string;
            email?: string;
            phoneNumber?: string;
            carePlanStatus?: string;
            occupation?: string;
            userId?: string;
            parentName?: string;
        } = {
            page: 0,
            size: 100,
        },
    ): Observable<{
            data: ResponseWithLinks<PatientModel>;
            totalCount: number;
        }> {
        let params = new HttpParams();
        if (page >= 0) params = params.set('page', page.toString());
        if (size >= 0) params = params.set('size', size.toString());
        if (name) params = params.set('fullName', name);
        if (email) params = params.set('email', email);
        if (phoneNumber) params = params.set('phoneNumber', phoneNumber);
        if (userId) params = params.set('userId', userId);
        if (carePlanStatus) params = params.set('carePlanStatus', carePlanStatus);
        if (occupation) params = params.set('occupation', occupation);
        if (asc) params = params.set('asc', asc);
        if (orderType) params = params.set('orderType', orderType);
        if (parentName) params = params.set('parentName', parentName);

        return this.networkService.fetchResource(`${this.baseURL}/${programId}/patients`, { params });
    }

    getEligibleNotEnrolledPatientsByProgramId(programId: string, {
        name, ageGroups, genders, grades, invited, orderType, asc
    }: {
        name?: string;
        ageGroups?: string[];
        genders?: string[];
        grades?: string[];
        invited?: boolean;
        orderType?: string;
        asc?: boolean;
    } = {},): Observable<{
            data: ResponseWithLinks<PatientModel>;
            totalCount: number;
        }> {
        const body = {
            search: name || null,
            ageGroups: ageGroups && ageGroups.length ? ageGroups : null,
            genders: genders && genders.length ? genders : null,
            grades: grades && grades.length ? grades : null,
            invited,
            orderType,
            asc,
        };

        return this.networkService.postResource(`${this.baseURL}/${programId}/patients/eligible-not-enrolled`, body);
    }

    getITemplatesMini(organizationId = null) {
        let url = `${this.baseURL}/templates-mini`;
        if (organizationId) {
            url += `?organizationId=${organizationId}`;
        }

        return this.networkService.fetchResource<IProgram[]>(url).pipe(catchError(() => of([])));
    }

    getTemplatesMini(organizationId = null): Observable<Program[]> {
        return this.getITemplatesMini(organizationId).pipe(
            this.convertIProgramsToProgramClass(),
            catchError(() => of([])),
        );
    }

    /* Return a list of all available programs for an organization */
    getPrograms(organizationId: string, statuses: IProgramStatus[], patientId: string = null): Observable<Program[]> {
        let params = new HttpParams();
        if (statuses) params = params.set('statuses', statuses.toString());
        if (patientId) params = params.set('patientId', patientId);

        return this.networkService
            .fetchResource<IProgram[]>(`${this.baseURL}/organization/${organizationId}`, { params })
            .pipe(this.convertIProgramsToProgramClass());
    }

    getIProductsItems(programId: string): Observable<IProductItem[]> {
        const url = `${this.baseURL}/${programId}/product-items`;
        return this.networkService.fetchResource(url);
    }

    getProductsItems(programId: string): Observable<ProductItem[]> {
        return this.getIProductsItems(programId).pipe(this.convertIProductsItemsToProductsItemsClass());
    }

    addNewCarePlan({ programId, carePlanId }): Observable<any> {
        const url = `${this.baseURL}/${programId}/care-plan/${carePlanId}`;

        return this.networkService.postResource(url, {});
    }

    deleteCarePlan({ programId, carePlanId }): Observable<void> {
        const url = `${this.baseURL}/${programId}/care-plan/${carePlanId}`;
        return this.networkService.deleteResource(url);
    }

    createProgramCriteria(programId: string, body: IQualification): Observable<IProgram> {
        const url = `${this.baseURL}/${programId}/qualification`;

        return this.networkService.postResource(url, body);
    }

    updateProgramCriteria(programId: string, body: IQualificationWithRank) {
        const { rank, ...requestBody } = body;
        const url = `${this.baseURL}/${programId}/qualification/${rank}`;

        return this.networkService.putResource(url, requestBody);
    }

    deleteProgramCriteria(programId: string, rank: number) {
        const url = `${this.baseURL}/${programId}/qualification/${rank}`;

        return this.networkService.deleteResource(url);
    }

    setSubProduct(programId: string, { id, ...subProduct }: IProductItem): Observable<any> {
        const url = `${this.baseURL}/${programId}/product-item/${id}`;

        return this.networkService.postResource(url, subProduct);
    }

    deleteSubProduct(programId: string, id: string): Observable<any> {
        const url = `${this.baseURL}/${programId}/product-item/${id}`;

        return this.networkService.deleteResource(url);
    }

    getYearlyActiveCounts(programId: string) {
        const url = `/api/programs/${programId}/yearly-active-counts`;

        // return of(
        //   {2023: {activeClinics: 4, activeProviders: 15},
        //     2022: {activeClinics: 10, activeProviders: 30},
        //     2021: {activeClinics: 5, activeProviders: 20}}
        // );
        return this.networkService.fetchResource(url);
    }

    getPatientGroupsCounts(programId: string): Observable<ClinicElement[]> {
        const url = `/api/programs/${programId}/patient-groups-counts`;

        return this.networkService.fetchResource<ClinicElement[]>(url);
    }

    getClinicHoursDailyCounts(
        programId: string,
        { dateFrom, dateTo }: { dateFrom: string; dateTo: string },
    ): Observable<ClinicHoursOfOperation[]> {
        const url = `/api/programs/${programId}/clinic-hours-daily-counts`;
        const httpParams = new HttpParams().set('dateFrom', dateFrom).set('dateTo', dateTo);

        return this.networkService.fetchResource(url, { params: httpParams });
    }

    getServiceTypes(programId: string): Observable<any[]> {
        const url = `/api/programs/${programId}/service-types`;
        return this.networkService.fetchResource(url);
    }

    getSummaryCounts(programId: string): Observable<{ [key: string]: number }> {
        const url = `/api/programs/${programId}/summary-counts`;
        return this.networkService.fetchResource(url);
    }

    invitePatients(body: any): Observable<any> {
        const url = `${this.baseURL}/invite-patients`;
        return this.networkService.postResource(url, body);
    }

    getProgramNameById(id: string): Observable<string> {
        const url = `${this.baseURL}/${id}/name`;
        return this.networkService.fetchResource(url, { responseType: 'text' });
    }

    getProgramType(): Observable<IProgramType[]> {
        const url = '/api/program-type';
        return this.networkService.fetchResource(url);
    }

    getProgramCategory(): Observable<IProgramCategory[]> {
        const url = '/api/program-category';
        return this.networkService.fetchResource(url);
    }

    getPatientAnalyticsCounts(programId: string): Observable<IPatientCounts> {
        const url = `${this.baseURL}/${programId}/analytics/patient-counts`;
        return this.networkService.fetchResource(url);
    }

    /* Internal Function: map IPrograms Array to class of Programs Array */
    private convertIProgramsToProgramClass(): OperatorFunction<IProgram[], Program[]> {
        return map((programsArray: IProgram[]) => programsArray.map((program: IProgram) => new Program(program)));
    }

    private convertIProductsItemsToProductsItemsClass(): OperatorFunction<IProductItem[], ProductItem[]> {
        return map((res) => res.map((item) => new ProductItem(item)));
    }
}
