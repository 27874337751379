import { CommonModule, DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UntilDestroy } from '@ngneat/until-destroy';
import { differenceInCalendarDays } from 'date-fns';
import { SetupVaccinationSlotsServiceService } from 'src/app/modules/setup-vaccination-slots/setup-vaccination-slots-service.service';

import { SnackbarService } from '../../../core/services/snackbar.service';
import { MassRescheduleResponsDialogComponent } from '../mass-reschedule-respons-dialog/mass-reschedule-respons-dialog.component';

export interface DialogData {
    fieldArray: any;
    onLaneSelectionChange: any;
    onLaneChanges: any;
    organization: any;
    dateFormatSafari: any;
    events: any;
    date: string;
}

@UntilDestroy()
@Component({
    selector: 'app-mass-reschedule-dialog',
    templateUrl: './mass-reschedule-dialog.component.html',
    styleUrls: ['./mass-reschedule-dialog.component.scss'],
    imports: [
        CommonModule,
        MatChipsModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatSelectModule,
        FormsModule,
        MatIconModule,
        MatTooltipModule,
    ],
    standalone: true,
    providers: [DatePipe],
})
export class MassRescheduleDialogComponent {
    private ONE_HOST_SELECTED_MESSAGE: string = 'One Host Selected';
    private MULTIPLE_HOSTS_SELECTED_MESSAGE: string = 'Multiple Hosts Selected';

    public isUpdate: boolean = false;
    public isCancel: boolean = false;
    public isMessage: boolean = false;
    public selectedLanesCount: number = 0;
    public fieldArray: any;
    public events: [] = [];
    public field: any;
    public rescheduleDate: any;
    public appointmentIds: [] = [];
    public newHost: any;
    public hostPlaceholder: string;
    public message: string = '';
    public formattedRescheduleDate;

    public isAccept: boolean = false;
    public filteredEvents: any;
    public selectedLanes: any;
    public cancelAppointments = [];
    public minDate = new Date();
    public cancelList = [
        'Bad Weather',
        'Staffing issues',
        'Insufficient supplies',
        'Logistic reason',
        'Other',
    ];

    public selectedCancelReason;
    public clinicAppointmentIds;
    public patientsAffectedCount: number = 0;
    public LaneIds = [];
    private data: DialogData;
    private eventIndex = 0;
    public isOther: boolean = false;
    public otherReasonDesc: string;
    public isCancelReasonSelected: boolean = false;
    public isHostChanged: boolean = false;
    public isNewDate: boolean = false;
    public newHostName: string = null;
    public setupDate: string;
    public oldFieldArray;
    public isPatientCount: boolean = false;
    public batchId;
    public currentScheduledDate: any;
    public isClicked: boolean = false;
    constructor(
        private setupClinicService: SetupVaccinationSlotsServiceService,
        private dialog: MatDialog,
        public datePipe: DatePipe,
        public dialogRef: MatDialogRef<MassRescheduleDialogComponent>,
        private snackbarService: SnackbarService,
        @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    ) {
        this.currentScheduledDate = this.datePipe.transform(dialogData.date, 'MM/dd/yyyy');
        this.setupDate = dialogData.date;
        this.data = dialogData;
        this.fieldArray = dialogData.fieldArray;
        this.rescheduleDate = dialogData.dateFormatSafari;
        this.events = this.filteredEvents = dialogData.events;
        this.formattedRescheduleDate = this.datePipe.transform(this.rescheduleDate, 'MM/dd/yyyy');
        dialogData.onLaneSelectionChange.subscribe((fieldArray) => {
            this.fieldArray = fieldArray;
            this.selectedLanes = fieldArray.filter((field) => field.isSelected);
            if (this.selectedLanes) {
                this.selectedLanes.forEach((lane) => {
                    this.LaneIds.push(lane.id);
                });
                this.getPatientCount();
            }
            this.selectedLanesCount = this.selectedLanes.length;
            this.hostPlaceholder = this.ONE_HOST_SELECTED_MESSAGE;
            if (this.selectedLanes.filter((lane) => lane.host && this.selectedLanes.filter((lane) => lane.host.id !== this.selectedLanes[0].host.id).length != 0)) {
                this.hostPlaceholder = this.MULTIPLE_HOSTS_SELECTED_MESSAGE;
            }
            if (this.selectedLanesCount == 0) this.onClose();
        });
    }

    public onClose(isConfirmed = null): void {
        this.selectedLanesCount = 0;
        this.patientsAffectedCount = 0;
        this.dialogData.onLaneSelectionChange.unsubscribe();
        this.dialogRef.close({
            isConfirmed,
            fieldArray: this.fieldArray,
        });
    }

    public updateMassRescheduleDialog() {
        this.isUpdate = !this.isUpdate;
    }

    public cancelMassRescheduleDialog() {
        this.isCancel = !this.isCancel;
    }

    public messageMassRescheduleDialog() {
        this.isMessage = !this.isMessage;
    }

    private filterEvents(name: string) {
        const filterValue = name.toLowerCase();

        return this.events.filter((p: any) => p.name.toLowerCase().includes(filterValue));
    }

    getAutoCompleterEventValue(index) {
        this.eventIndex = index;
        this.filteredEvents = this.filterEvents(this.newHost);
    }

    displayFn(host?: any): string | undefined {
        return host
            ? host.name
            : undefined;
    }

    changeDate() {
        this.formattedRescheduleDate = this.datePipe.transform(this.rescheduleDate, 'MM/dd/yyyy');
        if (this.rescheduleDate != this.data.dateFormatSafari) {
            this.isNewDate = true;
        }
    }

    onHostChange() {
        if (this.newHost) {
            this.isHostChanged = true;
            this.newHostName = this.newHost.name;
        }
    }

    onChangeReason() {
        this.isCancelReasonSelected = true;
        if (this.selectedCancelReason === 'Other') {
            this.isOther = true;
        } else {
            this.isOther = false;
        }
    }

    updateLanes() {
        this.isClicked = true;
        const selectedLaneIds = [];
        let hostId = null;

        this.selectedLanes.forEach((lane) => selectedLaneIds.push(lane.id));
        if (this.newHost) hostId = this.newHost.id;

        const diff = differenceInCalendarDays(new Date(this.formattedRescheduleDate), new Date(this.currentScheduledDate));

        const appointments = {
            clinicAppointmentIds: selectedLaneIds,
            host: hostId,
            dateString: this.formattedRescheduleDate,
            diff,
        };

        this.setupClinicService.rescheduleClinicLanes(this.data.organization.id, appointments).subscribe({
            next: (res: any) => {
                this.batchId = res.batchId;
                const isDateChanged = this.rescheduleDate != this.data.dateFormatSafari;
                const allLanesSelected = this.fieldArray.length == selectedLaneIds.length;
                const allLanesDateRescheduled = isDateChanged && allLanesSelected;

                if (isDateChanged && !allLanesSelected) {
                    this.fieldArray = this.fieldArray.filter((field) => !selectedLaneIds.includes(field.id));
                }
                if (allLanesDateRescheduled) {
                    this.fieldArray = [];
                } else if (this.newHost) {
                    this.fieldArray.forEach((field) => {
                        if (field.isSelected) field.host = this.newHost;
                    });
                }
                this.dialog.open(MassRescheduleResponsDialogComponent, {
                    hasBackdrop: false,
                    position: {
                        top: '3%',
                        right: '7%',
                    },
                    data: {
                        batchId: this.batchId,
                        lanesAffected: `${ this.selectedLanesCount } lanes updated successfully!`,
                        newHost: this.newHostName,
                        newDate: this.formattedRescheduleDate,
                        patientAffected: `${ this.patientsAffectedCount } patients affected.`,
                        organization: this.data.organization,
                        fieldArray: this.fieldArray,
                        isMessage: false,
                        clinicAppointmentIds: selectedLaneIds,
                        date: this.setupDate,
                        onLaneChanges: this.data.onLaneChanges,
                        isUpdate: true,
                        diff,
                    },
                });
                this.onClose(true);
            },
            error: (err: any) => {
                this.snackbarService.error('Lanes could not update');
            },
        });
    }

    cancelLanes() {
        this.isClicked = true;
        const selectedLaneIds = [];

        this.selectedLanes.forEach((lane) => selectedLaneIds.push(lane.id));
        if (this.selectedCancelReason === 'Other') {
            if (this.otherReasonDesc) this.selectedCancelReason = `Other - ${ this.otherReasonDesc }`;
        }

        const cancelAppointments = {
            clinicAppointmentIds: selectedLaneIds,
            cancellationReason: this.selectedCancelReason,
        };

        this.setupClinicService.cancelClinicLanes(this.data.organization.id, cancelAppointments).subscribe({
            next: (res: any) => {
                this.fieldArray = this.fieldArray.filter((field) => !selectedLaneIds.includes(field.id));
                this.dialog.open(MassRescheduleResponsDialogComponent, {
                    hasBackdrop: false,
                    position: {
                        top: '3%',
                        right: '7%',
                    },
                    data: {
                        lanesAffected: `${ this.selectedLanesCount } lanes cancelled successfully!`,
                        patientAffected: `${ this.patientsAffectedCount } patients affected.`,
                        organization: this.data.organization,
                        fieldArray: this.fieldArray,
                        clinicAppointmentIds: selectedLaneIds,
                        onLaneChanges: this.data.onLaneChanges,
                        cancelledResponse: res,
                        isCancel: true,
                    },
                });
                this.onClose(true);
            },
            error: (err: any) => {
                this.snackbarService.error('Could not cancel lanes');
            },
        });
    }

    messageLanes() {
        this.isClicked = true;
        const selectedLaneIds = [];

        this.selectedLanes.forEach((lane) => selectedLaneIds.push(lane.id));
        const appointments = {
            clinicAppointmentIds: selectedLaneIds,
            message: this.message,
        };

        this.setupClinicService.notifyPatients(this.data.organization.id, appointments).subscribe({
            next: (res: any) => {
                this.dialog.open(MassRescheduleResponsDialogComponent, {
                    hasBackdrop: false,
                    position: {
                        top: '3%',
                        right: '7%',
                    },
                    data: {
                        lanesAffected: 'Your message has been sent.',
                        patientAffected: `${ this.patientsAffectedCount } patients affected.`,
                        isMessage: true,
                    },
                });
                this.onClose(true); this.onClose(true);
            },
            error: (err: any) => {
                this.snackbarService.error('Message could not send');
            },
        });
    }

    getPatientCount() {
        const selectedLaneIds = [];

        this.selectedLanes.forEach((lane) => selectedLaneIds.push(lane.id));
        this.setupClinicService.getPatientCount(this.data.organization.id, selectedLaneIds).subscribe({
            next: (res: any) => {
                this.patientsAffectedCount = res;
                if (this.patientsAffectedCount > 0) {
                    this.isPatientCount = true;
                } else {
                    this.isPatientCount = false;
                }
            },
            error: (err: any) => {
            },
        });
    }
}
