import { InjectorInstance } from 'src/app/app.module';

import { Base } from '../../classes/base.class';
import { ProductItem } from '../product-item/product-item.class';
import { IProduct, IProductRequest } from './products.interface';
import { ProductsService } from './products.service';

export class Products extends Base {
    private parentProductItem: ProductItem;
    private productsService = InjectorInstance.get(ProductsService);

    constructor(private productItem: ProductItem) {
        super({ id: productItem.id, links: [] });
        this.parentProductItem = productItem;
    }

    /** Return the product class this product belongs to */
    getParentProductItem() {
        return this.parentProductItem;
    }

    /** return a list of all available products */
    getAllProducts() {
        return this.productsService.getAllProducts();
    }

    /** Get a specific product using an ID. */
    getProduct(productId: string) {
        return this.productsService.getProduct(productId);
    }

    /** update a product given you have its id */
    updateProduct(product: IProduct) {
        return this.productsService.updateProduct(product);
    }

    deleteProduct(productId: string) {
        return this.productsService.deleteProduct(productId);
    }

    /** can not add under a product-item, waiting.. */
    async createProduct(productRequest: IProductRequest) {
        await this.productsService.createProduct(productRequest);
    }
}
