/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Config } from './app/core/models/config.model';
import { environment } from './environments/environment';

const config: Config = {
    APP_NAME: 'CURA Patient',
    LOCAL_API: 'http://curapatient.compositeapps.net/api',
    ROOT_URL: '',
    TOKEN: '',
    SKIP_HEADER: 'X-Skip-Interceptor',
    PASS_PATTERN:
        '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*(),.?":{}|<>/\\\\=\\[\\]/+;_`~/-])[A-Za-z\\d!@#$%^&*(),.?":{}|<>/\\\\=\\[\\]/+;_`~/-]{12,}$',
    NAME_PATTERN: '^(?=.{1,40}$)\\s*[a-zA-Z]+(?:[-\' ][a-zA-Z]+)*\\s*$',
    NAME_PATTERN_EX: '^(?=.{1,40}$)\\s*[a-zA-Z,.]+(?:[-\' ][a-zA-Z,.]+)*\\s*$',
    FUllNAME_PATTERN: '^[a-zA-Z]+(?: [a-zA-Z]+)*$',
    TRANSLATION_LANGUAGES: ['en', 'es', 'vi', 'ko', 'zh-TW', 'zh-CN', 'ja-JP', 'hi-IN', 'gu-IN', 'tl-PH'],
    TUMOR_SIZE: {
        TX: 'Tumors are too small for testing or can\'t be found with tests.',
        T0: 'No primary tumor has been found.',
        Tis: 'There are abnormal or cancer cells in airways that haven\'t invaded lung tissue.',
        T1: 'Tumors are in the lungs only and are not larger than 3cm.',
        T1mi: 'Tumors grow only along the surface of lung tissue. Aras of invasion are no deeper than 0.5cm.',
        T1a: 'Tumors are 1cm or smaller and have invaded at least 0.5cm.',
        T1b: 'Tumors are larger than 1cm but not larger than 2cm. Areas of invasion are at least 0.5cm.',
        T1c: 'Tumors are more than 2cm but not larger than 3cm. Areas of invasion are at least 0.5cm.',
        T2: 'Tumors are larger than 3cm but not larger than 5cm; have grown into the main bronchus, have grown into the lung\'s inner lining (visceral pleura), or have caused lung collapse (atelectasis) or swelling (pneumonitis).',
        T2a: 'Tumors are 4cm or smaller. Other features may or may not be present. Smaller tumors with other features are also rated T2a.',
        T2b: 'Tumors are larger than 4cm. Other features may or may not be present.',
        T3: 'Tumors are larger than 5cm but not larger than 7cm; have grown into the lung\'s outer lining (parietal pleura) or nearby sites including chest wall, phrenic nerve, or the heart\'s lining (pericardium), or there are primary and secondary tumors in the same lobe.',
        T4: 'Tumors are larger than 7cm; have grown into the diaphragm, mediastinum, heart or its major blood vessels, windpipe (trachea), recurrent laryngeal nerve, carina, esophagus, or spine; or there are secondary tumors in the same lung but a different lobe than the primary tumor.',
    },
    LYMPH_NODE: {
        NX: 'Nearby lymph nodes have not been tested.',
        N0: 'No cancer in nearby lymph nodes.',
        N1: 'The cancer has spread to the peribronchial nodes and/or to the hilar and intrapulmonary nodes of the lung with the primary tumor.',
        N2: 'The cancer has spread to mediastinal lymph nodes, which include subcardinal nodes, near the lung with the primary tumor.',
        N3: 'The cancer has spread to the mediastinal or hilar nodes near the lung without the primary tumor, or to any supraclavicular or scalene lymph nodes.',
    },
    NATIONALITIES: [
        'United States',
        'Afghanistan',
        'Albania',
        'Algeria',
        'Andorra',
        'Angola',
        'Anguilla',
        'Antigua & Barbuda',
        'Argentina',
        'Armenia',
        'Aruba',
        'Australia',
        'Austria',
        'Azerbaijan',
        'Bahamas',
        'Bahrain',
        'Bangladesh',
        'Barbados',
        'Belarus',
        'Belgium',
        'Belize',
        'Benin',
        'Bermuda',
        'Bhutan',
        'Bolivia',
        'Bosnia & Herzegovina',
        'Botswana',
        'Brazil',
        'British Virgin Islands',
        'Brunei',
        'Bulgaria',
        'Burkina Faso',
        'Burundi',
        'Cambodia',
        'Cameroon',
        'Canada',
        'Cape Verde',
        'Cayman Islands',
        'Chad',
        'Chile',
        'China',
        'Colombia',
        'Congo',
        'Cook Islands',
        'Costa Rica',
        'Cote D Ivoire',
        'Croatia',
        'Cruise Ship',
        'Cuba',
        'Cyprus',
        'Czech Republic',
        'Denmark',
        'Djibouti',
        'Dominica',
        'Dominican Republic',
        'Ecuador',
        'Egypt',
        'El Salvador',
        'Equatorial Guinea',
        'Estonia',
        'Ethiopia',
        'Falkland Islands',
        'Faroe Islands',
        'Fiji',
        'Finland',
        'France',
        'French Polynesia',
        'French West Indies',
        'Gabon',
        'Gambia',
        'Georgia',
        'Germany',
        'Ghana',
        'Gibraltar',
        'Greece',
        'Greenland',
        'Grenada',
        'Guam',
        'Guatemala',
        'Guernsey',
        'Guinea',
        'Guinea Bissau',
        'Guyana',
        'Haiti',
        'Honduras',
        'Hong Kong',
        'Hungary',
        'Iceland',
        'India',
        'Indonesia',
        'Iran',
        'Iraq',
        'Ireland',
        'Isle of Man',
        'Israel',
        'Italy',
        'Jamaica',
        'Japan',
        'Jersey',
        'Jordan',
        'Kazakhstan',
        'Kenya',
        'Kuwait',
        'Kyrgyz Republic',
        'Laos',
        'Latvia',
        'Lebanon',
        'Lesotho',
        'Liberia',
        'Libya',
        'Liechtenstein',
        'Lithuania',
        'Luxembourg',
        'Macau',
        'Macedonia',
        'Madagascar',
        'Malawi',
        'Malaysia',
        'Maldives',
        'Mali',
        'Malta',
        'Mauritania',
        'Mauritius',
        'Mexico',
        'Moldova',
        'Monaco',
        'Mongolia',
        'Montenegro',
        'Montserrat',
        'Morocco',
        'Mozambique',
        'Namibia',
        'Nepal',
        'Netherlands',
        'Netherlands Antilles',
        'New Caledonia',
        'New Zealand',
        'Nicaragua',
        'Niger',
        'Nigeria',
        'Norway',
        'Oman',
        'Pakistan',
        'Palestine',
        'Panama',
        'Papua New Guinea',
        'Paraguay',
        'Peru',
        'Philippines',
        'Poland',
        'Portugal',
        'Puerto Rico',
        'Qatar',
        'Reunion',
        'Romania',
        'Russia',
        'Rwanda',
        'Saint Pierre & Miquelon',
        'Samoa',
        'San Marino',
        'Satellite',
        'Saudi Arabia',
        'Senegal',
        'Serbia',
        'Seychelles',
        'Sierra Leone',
        'Singapore',
        'Slovakia',
        'Slovenia',
        'South Africa',
        'South Korea',
        'Spain',
        'Sri Lanka',
        'St Kitts & Nevis',
        'St Lucia',
        'St Vincent',
        'St. Lucia',
        'Sudan',
        'Suriname',
        'Swaziland',
        'Sweden',
        'Switzerland',
        'Syria',
        'Taiwan',
        'Tajikistan',
        'Tanzania',
        'Thailand',
        'Timor L\'Este',
        'Togo',
        'Tonga',
        'Trinidad & Tobago',
        'Tunisia',
        'Turkey',
        'Turkmenistan',
        'Turks & Caicos',
        'Uganda',
        'Ukraine',
        'United Arab Emirates',
        'United Kingdom',
        'Uruguay',
        'Uzbekistan',
        'Venezuela',
        'Vietnam',
        'Virgin Islands (US)',
        'Yemen',
        'Zambia',
        'Zimbabwe',
    ],
    US_TIMEZONES_WITH_TITLE: [
        { value: 'America/Los_Angeles', title: 'Pacific Time (PT)' },
        { value: 'America/Denver', title: 'Mountain Time (MT)' },
        { value: 'America/Chicago', title: 'Central Time (CT)' },
        { value: 'America/New_York', title: 'Eastern Time (ET)' },
        { value: 'America/Anchorage', title: 'Alaska Time (AKT)' },
        { value: 'Pacific/Honolulu', title: 'Hawaii-Aleutian Time (HAT)' }
    ],
    get US_TIMEZONES() {
        return this.US_TIMEZONES_WITH_TITLE.map((v) => v.value);
    },
    STATES: [
        { title: 'AK', value: 'Alaska' },
        { title: 'AL', value: 'Alabama' },
        { title: 'AR', value: 'Arkansas' },
        { title: 'AS', value: 'American Samoa' },
        { title: 'AZ', value: 'Arizona' },
        { title: 'CA', value: 'California' },
        { title: 'CO', value: 'Colorado' },
        { title: 'CT', value: 'Connecticut' },
        { title: 'DC', value: 'District of Columbia' },
        { title: 'DE', value: 'Delaware' },
        { title: 'FL', value: 'Florida' },
        { title: 'GA', value: 'Georgia' },
        { title: 'GU', value: 'Guam' },
        { title: 'HI', value: 'Hawaii' },
        { title: 'IA', value: 'Iowa' },
        { title: 'ID', value: 'Idaho' },
        { title: 'IL', value: 'Illinois' },
        { title: 'IN', value: 'Indiana' },
        { title: 'KS', value: 'Kansas' },
        { title: 'KY', value: 'Kentucky' },
        { title: 'LA', value: 'Louisiana' },
        { title: 'MA', value: 'Massachusetts' },
        { title: 'MD', value: 'Maryland' },
        { title: 'ME', value: 'Maine' },
        { title: 'MI', value: 'Michigan' },
        { title: 'MN', value: 'Minnesota' },
        { title: 'MO', value: 'Missouri' },
        { title: 'MS', value: 'Mississippi' },
        { title: 'MT', value: 'Montana' },
        { title: 'NC', value: 'North Carolina' },
        { title: 'ND', value: 'North Dakota' },
        { title: 'NE', value: 'Nebraska' },
        { title: 'NH', value: 'New Hampshire' },
        { title: 'NJ', value: 'New Jersey' },
        { title: 'NM', value: 'New Mexico' },
        { title: 'NV', value: 'Nevada' },
        { title: 'NY', value: 'New York' },
        { title: 'OH', value: 'Ohio' },
        { title: 'OK', value: 'Oklahoma' },
        { title: 'OR', value: 'Oregon' },
        { title: 'PA', value: 'Pennsylvania' },
        { title: 'PR', value: 'Puerto Rico' },
        { title: 'RI', value: 'Rhode Island' },
        { title: 'SC', value: 'South Carolina' },
        { title: 'SD', value: 'South Dakota' },
        { title: 'TN', value: 'Tennessee' },
        { title: 'TX', value: 'Texas' },
        { title: 'UT', value: 'Utah' },
        { title: 'VA', value: 'Virginia' },
        { title: 'VI', value: 'Virgin Islands' },
        { title: 'VT', value: 'Vermont' },
        { title: 'WA', value: 'Washington' },
        { title: 'WI', value: 'Wisconsin' },
        { title: 'WV', value: 'West Virginia' },
        { title: 'WY', value: 'Wyoming' },
        { title: 'Other', value: 'Other' },
    ],
    STATE_NAMES: [
        'Alaska',
        'Alabama',
        'Arkansas',
        'American Samoa',
        'Arizona',
        'California',
        'Colorado',
        'Connecticut',
        'District of Columbia',
        'Delaware',
        'Florida',
        'Georgia',
        'Guam',
        'Hawaii',
        'Iowa',
        'Idaho',
        'Illinois',
        'Indiana',
        'Kansas',
        'Kentucky',
        'Louisiana',
        'Massachusetts',
        'Maryland',
        'Maine',
        'Michigan',
        'Minnesota',
        'Missouri',
        'Mississippi',
        'Montana',
        'North Carolina',
        'North Dakota',
        'Nebraska',
        'New Hampshire',
        'New Jersey',
        'New Mexico',
        'Nevada',
        'New York',
        'Ohio',
        'Oklahoma',
        'Oregon',
        'Pennsylvania',
        'Puerto Rico',
        'Rhode Island',
        'South Carolina',
        'South Dakota',
        'Tennessee',
        'Texas',
        'Utah',
        'Virginia',
        'Virgin Islands',
        'Vermont',
        'Washington',
        'Wisconsin',
        'West Virginia',
        'Wyoming',
        'Other',
    ],
    PRIVILEGEID: {
        SUPER_ADMIN: 1,
        ORGANIZATION_ADMIN: 2,
        ORGANIZATION_MANAGER: 3,
        USER: 4,
        COORDINATOR: 5,
        ANALYTICS_ADMIN: 6,
        CLINIC_ADMINISTRATOR: 7,
        TESTING_COORDINATOR: 12,
        SCHEDULER: 14,
    },
    PRIVILEGE_HIERARCHY_BY_ID: [14, 12, 11, 10, 9, 8, 6, 5, 4, 7, 3, 2, 1], // from low to high
    TASKTYPES: {
        APPOINTMENT: 1,
        ASSESSMENT: 2,
        EDUCATION: 3,
        MEDICATION: 4,
        EXERCISE: 5,
        FOOD: 6,
        TEAM_TASK: 7,
    },
    SERVICE_TYPES: {
        DAILY_ASSESSMENT: 28,
    },
    DAYSOFWEEK: {
        SUN: 'S',
        MON: 'M',
        TUE: 'T',
        WED: 'W',
        THU: 'TH',
        FRI: 'F',
        SAT: 'SA',
    },
    LICENSE_TYPES: [
        { label: 'Doctor of Osteopathy', value: 'D.O' },
        { label: 'Doctor of Medicine', value: 'M.D.' },
        { label: 'Nurse Anesthetist', value: 'N.A' },
        { label: 'Nurse Practitioner', value: 'N.P' },
        { label: 'Physician Assistant', value: 'P.A.' },
        { label: 'Registered Nurse', value: 'R.N.' },
        { label: 'Licensed Clinical Social Worker', value: 'LCSW' },
        { label: 'Master of Social Work', value: 'MSW' },
        { label: 'Licensed Educational Psychologist', value: 'LEP' },
        { label: 'Associate Clinical Social Worker', value: 'ACSW/ASW' },
    ],
    PROGRESS_OPTIONS: [
        { label: 'Very Much Improved', value: 7 },
        { label: 'Much Improved', value: 6 },
        { label: 'Minimally Improved', value: 5 },
        { label: 'No Change', value: 4 },
        { label: 'Minimally Worse', value: 3 },
        { label: 'Much Worse', value: 2 },
        { label: 'Very Much Worse', value: 1 },
    ],
    VA: {
        orgName: 'Veteran Affairs Hospital',
    },
    images: {
        maleProfileIcon: './assets/VA_Logos/male-profile-icon-1.svg',
        carePlanNoImageIcon: './assets/main/careplan/careplan-no-image.svg',
        assessmentNoImageIcon: './assets/main/assessment/assessment-no-image.svg',
        organizationNoImage: './assets/icons/no-image.svg',
        startDateIcon: './assets/main/calendar/start-date-icon.svg',
        closeIcon: '/assets/main/temp/close-icon.svg',
        filterIcon: '',
        questionNoImage: '',
        educationCenterNoImage: '/assets/main/Group1221512x.png',
        profileImage: './assets/patient-profile-image.png',
        noImage: './assets/icons/no-image.svg',
    },
    TIMEOUT_API: 50000,
    TIMEOUT_VERSION_CHECK: 1, // in minutes,
    CARE_PLAN_STATUSES: [
        {
            name: 'Awaiting Testing',
            value: 'AwaitingTesting',
            observationValue: -1,
        },
        {
            name: 'Awaiting Test Results',
            value: 'AwaitingTestResults',
            observationValue: 2,
        },
        {
            name: 'Tested Positive',
            value: 'TestedPositive',
            observationValue: 1,
        },
        {
            name: 'Tested Negative',
            value: 'TestedNegative',
            observationValue: 0,
        },
        {
            name: 'Patient Closed Out',
            value: 'PatientClosedOut',
            observationValue: 3,
        },
    ],
    googleMap: {
        marker: {
            icons: {
                home: './assets/main/patient/ic-home.svg',
                inCompliance: './assets/main/patient/ic-in-compliance.svg',
                out: './assets/main/patient/ic-out.svg',
                circle: './assets/main/patient/ic-one-mile.svg',
                possiblyOut: './assets/main/patient/ic-possibly-out.svg',
                blue: './assets/main/patient/ic-blue.svg',
                green: './assets/main/patient/ic-green.svg',
                grey: './assets/main/patient/ic-grey.svg',
                orange: './assets/main/patient/ic-orange.svg',
            },
        },
    },
    webSocket: {
        url: environment.apiSocketUrl,
        options: {
            query: {
                EIO: '3',
            },
            path: '/socket.io',
            transports: ['websocket'],
            reconnection: false,
        },
    },
    gender: {
        identity: [
            { value: 'female', translationKey: 'COMMON.FEMALE' },
            { value: 'male', translationKey: 'COMMON.MALE' },
            { value: 'transgender-transfeminine', translationKey: 'COMMON.TRANSGENDER_TRANSFEMININE' },
            { value: 'transgender-transmasculine', translationKey: 'COMMON.TRANSGENDER_TRANSMASCULINE' },
            { value: 'nonconforming', translationKey: 'COMMON.GENDER_NONCONFORMING' },
            { value: 'other', translationKey: 'COMMON.OTHER_GENDER' },
            { value: 'decline-to-state', translationKey: 'COMMON.DECLINE_TO_STATE' },
        ],
    },
    pronouns: [ // https://terminology.hl7.org/ValueSet-pronouns.html
        { value: 'he/him', label: 'he/him/his/his/himself' },
        { value: 'she/her', label: 'she/her/her/hers/herself' },
        { value: 'they/them', label: 'they/them/their/theirs/themselves' },
    ],
    sexes: [ // https://terminology.hl7.org/CodeSystem-v2-0001.html
        { value: 'M', label: 'Male' },
        { value: 'F', label: 'Female' },
        { value: 'O', label: 'Other' },
        { value: 'U', label: 'Unknown' },
        { value: 'A', label: 'Ambiguous' },
        { value: 'N', label: 'Not applicable' },
        { value: 'X', label: 'Non-Binary' },
    ],
    races: [ // http://hl7.org/fhir/us/core/STU6.1/StructureDefinition-us-core-race.html
        {
            value: 'American Indian or Alaska Native',
            label: 'American Indian or Alaska Native'
        },
        {
            value: 'Asian',
            label: 'Asian'
        },
        {
            value: 'Black or African American',
            label: 'Black or African American'
        },
        {
            value: 'Native Hawaiian or Other Pacific Islander',
            label: 'Native Hawaiian or Other Pacific Islander'
        },
        {
            value: 'White',
            label: 'White'
        },
        {
            value: 'Other Race',
            label: 'Other Race'
        },
        {
            value: 'Unknown',
            label: 'Unknown'
        }
    ],
    ethnicities: [
        {
            value: 'Not Hispanic or Latino',
            label: 'Not Hispanic or Latino'
        },
        {
            value: 'Hispanic or Latino',
            label: 'Hispanic or Latino'
        },
        {
            value: 'Spaniard',
            label: 'Spaniard'
        },
        {
            value: 'Andalusian',
            label: 'Andalusian'
        },
        {
            value: 'Asturian',
            label: 'Asturian'
        },
        {
            value: 'Castillian',
            label: 'Castillian'
        },
        {
            value: 'Catalonian',
            label: 'Catalonian'
        },
        {
            value: 'Belearic Islander',
            label: 'Belearic Islander'
        },
        {
            value: 'Gallego',
            label: 'Gallego'
        },
        {
            value: 'Valencian',
            label: 'Valencian'
        },
        {
            value: 'Canarian',
            label: 'Canarian'
        },
        {
            value: 'Spanish Basque',
            label: 'Spanish Basque'
        },
        {
            value: 'Mexican',
            label: 'Mexican'
        },
        {
            value: 'Mexican American',
            label: 'Mexican American'
        },
        {
            value: 'Mexicano',
            label: 'Mexicano'
        },
        {
            value: 'Chicano',
            label: 'Chicano'
        },
        {
            value: 'La Raza',
            label: 'La Raza'
        },
        {
            value: 'Mexican American Indian',
            label: 'Mexican American Indian'
        },
        {
            value: 'Central American',
            label: 'Central American'
        },
        {
            value: 'Costa Rican',
            label: 'Costa Rican'
        },
        {
            value: 'Guatemalan',
            label: 'Guatemalan'
        },
        {
            value: 'Honduran',
            label: 'Honduran'
        },
        {
            value: 'Nicaraguan',
            label: 'Nicaraguan'
        },
        {
            value: 'Panamanian',
            label: 'Panamanian'
        },
        {
            value: 'Salvadoran',
            label: 'Salvadoran'
        },
        {
            value: 'Central American Indian',
            label: 'Central American Indian'
        },
        {
            value: 'Canal Zone',
            label: 'Canal Zone'
        },
        {
            value: 'South American',
            label: 'South American'
        },
        {
            value: 'Argentinean',
            label: 'Argentinean'
        },
        {
            value: 'Bolivian',
            label: 'Bolivian'
        },
        {
            value: 'Chilean',
            label: 'Chilean'
        },
        {
            value: 'Colombian',
            label: 'Colombian'
        },
        {
            value: 'Ecuadorian',
            label: 'Ecuadorian'
        },
        {
            value: 'Paraguayan',
            label: 'Paraguayan'
        },
        {
            value: 'Peruvian',
            label: 'Peruvian'
        },
        {
            value: 'Uruguayan',
            label: 'Uruguayan'
        },
        {
            value: 'Venezuelan',
            label: 'Venezuelan'
        },
        {
            value: 'South American Indian',
            label: 'South American Indian'
        },
        {
            value: 'Criollo',
            label: 'Criollo'
        },
        {
            value: 'Latin American',
            label: 'Latin American'
        },
        {
            value: 'Puerto Rican',
            label: 'Puerto Rican'
        },
        {
            value: 'Cuban',
            label: 'Cuban'
        },
        {
            value: 'Dominican',
            label: 'Dominican'
        }
    ],
    grades: [
        { value: 'K', label: 'Kindergarten' },
        { value: '1', label: '1st Grade' },
        { value: '2', label: '2nd Grade' },
        { value: '3', label: '3rd Grade' },
        { value: '4', label: '4th Grade' },
        { value: '5', label: '5th Grade' },
        { value: '6', label: '6th Grade' },
        { value: '7', label: '7th Grade' },
        { value: '8', label: '8th Grade' },
        { value: '9', label: '9th Grade (Freshman)' },
        { value: '10', label: '10th Grade (Sophomore)' },
        { value: '11', label: '11th Grade (Junior)' },
        { value: '12', label: '12th Grade (Senior)' }
    ] as const,
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    hours: [
        '12 AM',
        '1 AM',
        '2 AM',
        '3 AM',
        '4 AM',
        '5 AM',
        '6 AM',
        '7 AM',
        '8 AM',
        '9 AM',
        '10 AM',
        '11 AM',
        '12 PM',
        '1 PM',
        '2 PM',
        '3 PM',
        '4 PM',
        '5 PM',
        '6 PM',
        '7 PM',
        '8 PM',
        '9 PM',
        '10 PM',
        '11 PM',
    ],
    ages: ['0-9', '10-19', '20-29', '30-39', '40-49', '50-59', '60-69', '70-79', { value: '80-200', label: '80+' }],
};

export default config;
