import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as moment from "moment";
import { firstValueFrom } from "rxjs";
import { IInventoryName } from "src/app/core/models/inventory.model";

import { environment } from "../../../../../environments/environment";
import { NetworkService } from "../../network/network.service";

export interface IRetrieveAppointments {
    date: Date /** all the clinic slots will be retrieved for only this one day */;
    testingType: string;
    manufacture: IInventoryName;
    organizationId: string;
    state?: string;
    targetPatientId?: string;
}

export interface IAllDatesRequest {
    clinics: any[];
    dates: string[];
}

@Injectable({
    providedIn: "root",
})
export class AllAppointmentsService {
    constructor(private networkService: NetworkService) {}

    /** Returns  data */
    getAppointments(requestParams: IRetrieveAppointments): Promise<IAllDatesRequest> {
        if (!requestParams.testingType) throw Error("testingType is required to get available appointment slots");
        if (!requestParams.organizationId) throw Error("organizationId is required to get available appointment slots");
        if (!requestParams.date) throw Error("date is required to get available appointment slots");

        return this.requestData(requestParams);
    }

    /** request all appointments for a given category from server */
    private requestData(requestParams: IRetrieveAppointments): Promise<IAllDatesRequest> {
        const date = moment(requestParams.date).format("MM/DD/YYYY");

        let httpParams = new HttpParams({
            fromObject: {
                date,
                vaccine: requestParams.testingType,
                state: requestParams.state,
            },
        });

        if (requestParams?.manufacture) {
            httpParams = httpParams.append("manufacture", encodeURIComponent(requestParams.manufacture));
        }

        if (requestParams.state) httpParams.append("state", requestParams.state);
        if (requestParams.targetPatientId) httpParams.append("targetPatientId", requestParams.targetPatientId);

        const url = `/api/organizations/organizationublicClinics/${requestParams.organizationId || environment.patientRegistration.organizationId}/allDateV2`;
        return firstValueFrom(this.networkService.fetchResource(url, { params: httpParams }));
    }
}
