import { AssessmentAnswer, AssessmentQuestion } from '../../../../core/models/assessment.model';
import { TranslationISO } from '../../../../core/models/translation.model';

export class QuestionBase {
    private _value: AssessmentQuestion;
    private _editing: boolean;
    private _multiple: boolean;
    private _answersDeletedIds: string[] = [];
    public _expanded = false;

    set value(value: AssessmentQuestion) {
        this._value = value;
    }

    get value() {
        return this._value;
    }

    set editing(value: boolean) {
        this._editing = value;
    }

    get editing() {
        return this._editing;
    }

    set isMultiple(value: boolean) {
        this._multiple = value;
    }

    get isMultiple() {
        return this._multiple;
    }

    set answersDeletedIds(ids) {
        this._answersDeletedIds = ids;
    }

    get answersDeletedIds() {
        return this._answersDeletedIds;
    }

    set expanded(value) {
        this._expanded = value;
    }

    get expanded() {
        return this._expanded;
    }

    constructor(options?: AssessmentQuestion) {
        this._value = {
            id: options.id || '',
            question: options.question || '',
            subtitle: options.subtitle || '',
            description: options.description || '',
            answerCode: options.answerCode || 0,
            rank: options.rank ? options.rank : 1,
            answerType: options.answerType || '',
            choiceList: options.choiceList ? options.choiceList.map((cL) => new AnswerBase(cL)) : [],
            duplicated: options.duplicated ? options.duplicated : false,
            questionType: options.questionType ? options.questionType : '',
            questionShortName: options.questionShortName ? options.questionShortName : '',
            weighting: options.weighting ? options.weighting : 0,
            locale: options.locale ? options.locale : TranslationISO.en,
            page: options.page ? options.page : '',
            section: options.section ? options.section : '',
            answerUom: options.answerUom ? options.answerUom : '',
            attributes: options.attributes ? options.attributes : null,
            links: options.links || [],
            zipCodeAsAddress: options.zipCodeAsAddress,
            attachment: options.attachment || null,
            numbersOnly: options.numbersOnly,
        };
        this.setEditing();
        this.setMultiple();
    }

    duplicate(): AssessmentQuestion {
        const { id, question, rank, choiceList, ...value } = this.value;

        return {
            ...value,
            choiceList: choiceList.map((cL) => ({ ...cL, id: null })),
            rank: rank + 1,
            question: `Copy ${ question }`,
            duplicated: true,
        };
    }

    update({ choiceList, ...value }: AssessmentQuestion) {
        this.value = { ...this.value, ...value };
        if (choiceList) {
            this.value.choiceList = choiceList.map((cL) => new AnswerBase(cL));
        }
        this.setMultiple();
    }

    setEditing() {
        const { id } = this.value;

        this.editing = !id;
    }

    setMultiple() {
        const { answerType } = this.value;

        this.isMultiple = answerType && ['MULTIPLE_CHOICE', 'MULTIPLE_OPTIONS'].includes(answerType);
    }

    addDeletedAnswerId(id) {
        this._answersDeletedIds.push(id);
    }

    clearDeletedAnswersIds() {
        this._answersDeletedIds = [];
    }
}

export class AnswerBase {
    id: string;
    answerText: string;
    weight: number;
    answerCode: number;
    offset: number;
    answerUom: string;
    score: number;
    code: string;
    noneAbove?: boolean;
    other?: boolean;

    constructor(option: AssessmentAnswer = {}) {
        this.id = option.id || '';
        this.answerText = option.answerText || '';
        this.weight = option.weight || 0;
        this.answerCode = option.answerCode ? option.answerCode : 1;
        this.offset = option.offset >= 0 || option.offset < 0 ? option.offset : null;
        this.answerUom = option.answerUom ? option.answerUom : '';
        this.score = option.score ? option.score : undefined;
        this.code = option.code || '';
        this.noneAbove = option.noneAbove ? option.noneAbove : false;
        this.other = option.other ? option.other : false;
    }
}
