import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import config from 'src/config';

import { OrganizationModel } from '../../../core/models/organization.model';
import { NetworkService } from '../../../core/services/network/network.service';
import { UserSessionService } from '../../../core/services/user-session/user-session.service';
import {Router} from "@angular/router";

export interface Breadcrumb {
    label: string;
    url?: string;
    id?: string;
    denyBack?: boolean;
    path?: string;
    emitSingClaimView?: boolean;
}

@Injectable({ providedIn: 'root' })
export class BreadcrumbsService {
    private _breadcrumbsList = new BehaviorSubject<Breadcrumb[]>([]);
    private _breadcrumbsUpdated = new BehaviorSubject<Breadcrumb[]>([]);
    private _breadcrumbsSet = new BehaviorSubject<any>({});

    public breadcrumbsUpdated$ = this._breadcrumbsUpdated.asObservable();
    public breadcrumbsSet$ = this._breadcrumbsSet.asObservable();
    public breadcrumbsList$ = this._breadcrumbsList.asObservable();

    public clearBreadcrumbs = new Subject<void>();

    set breadcrumbsList(value) {
        this._breadcrumbsList.next(value);
    }

    get breadcrumbsList() {
        return this._breadcrumbsList.getValue();
    }

    constructor(
        private session: UserSessionService,
        private network: NetworkService,
        private router: Router,
    ) {
    }

    setBreadcrumbs(breadcrumbs: Breadcrumb[], positionFrom) {
        this._breadcrumbsSet.next({ breadcrumbs, index: positionFrom });
    }

    pushBreadcrumb(crumb: Breadcrumb) {
        const crumbs = this._breadcrumbsSet.value.breadcrumbs || [];

        crumbs.push(crumb);
        this.setBreadcrumbs(crumbs, 1);
    }

    updateBreadcrumbs(breadcrumbs: Breadcrumb[]): void {
        this._breadcrumbsUpdated.next(breadcrumbs);
    }

    reset() {
        this._breadcrumbsUpdated.next([]);
        this._breadcrumbsSet.next([]);
    }

    fetchOrganization(orgId: any) {
        if (orgId) {
            this.fetchOrgOverview(orgId).subscribe((resp) => {
                if (resp) {
                    const { parents = null } = resp;
                    const parentBreadcrumbs = [];
                    const hierarchyParents = [];
                    const url = '/curapatient/organizations/organization';
                    const label = `${ resp.name }'s Overview`;
                    const { sessionAccount: { privilege: { id: privilegeId = null } = {}, organizationId } = {}} = this.session;

                    if (parents?.content?.length && resp.id != organizationId) { //  check if the org and account org is different
                        const allParents = parents;

                        allParents.content.forEach((aParent) => {
                            if (resp.parentId == aParent.id) { // found immdediate parent
                                hierarchyParents.push(aParent); // one Level
                                if (aParent.id !== organizationId) {
                                    allParents.content.forEach((bParent) => {
                                        if (aParent.parentId == bParent.id) {
                                            hierarchyParents.push(bParent); // Second Level
                                        }
                                    });
                                }
                            }
                        });
                    }
                    if (
                        [
                            config.PRIVILEGEID.COORDINATOR,
                            config.PRIVILEGEID.CLINIC_ADMINISTRATOR,
                            config.PRIVILEGEID.ORGANIZATION_MANAGER,
                            config.PRIVILEGEID.TESTING_COORDINATOR,
                        ].indexOf(+privilegeId) === -1
            && hierarchyParents?.length
            && organizationId !== resp.id
                    ) {
                        hierarchyParents.forEach((parent) => {
                            parentBreadcrumbs.unshift({
                                url,
                                label: parent.name,
                                id: parent.id,
                            });
                        });
                    }
                    parentBreadcrumbs.push({ url, label });
                    if (+privilegeId === config.PRIVILEGEID.SUPER_ADMIN) {
                        this.setBreadcrumbs(parentBreadcrumbs, 1);
                    } else {
                        this.setBreadcrumbs(parentBreadcrumbs, 0);
                    }
                }
            });
        }
    }

    fetchOrgOverview(orgId: string): Observable<OrganizationModel> {
        const url = `/api/organizations/${ orgId }/overview`;

        return this.network.fetchResource<OrganizationModel>(url);
    }

    updateBreadcrumbByPath({ label, url }: { label?: string, url?: string }, path: string) {
        const breadcrumbs = this.breadcrumbsList;
        const index = breadcrumbs.findIndex((breadcrumb) => breadcrumb.path === path);

        if (index >= 0) {
            if (label) breadcrumbs[index].label = label;
            if (url) breadcrumbs[index].url = url;
            this.breadcrumbsList = breadcrumbs;
        }
    }
    hideHeaderClaimView(){
        if (this.router?.url.includes("single-claim")) {
            return true;
        }
    }
    public claimView(isAdd: boolean) {
        if (isAdd) {
            const breadCrumb = {
                label: "Single Claim",
                url: "#",
                id: "SingleClaimView",
                denyBack: true,
                disabled: true,
            }
            const breadcrumbsList = this.breadcrumbsList;

            if (breadcrumbsList[breadcrumbsList.length - 1]?.id === "SingleClaimView") {
                breadcrumbsList.pop();
            }
            breadcrumbsList[breadcrumbsList.length - 1].emitSingClaimView = true;
            if (this.router.url.includes("claims-list") || this.router.url.includes("dispute-packages")) {
                breadcrumbsList[breadcrumbsList.length - 2].emitSingClaimView = true;
            }
            breadcrumbsList.push(breadCrumb);
        } else {
            const breadcrumbsList = this.breadcrumbsList;
            breadcrumbsList.pop();
            breadcrumbsList[breadcrumbsList.length - 1].emitSingClaimView = false;
            if (this.router.url.includes("claims-list") || this.router.url.includes("dispute-packages")) {
                breadcrumbsList[breadcrumbsList.length - 2].emitSingClaimView = false;
            }
            // this.payerDashboardService.isSingleClaimViewOpen = false;
        }

    }

    public closeSingleClaimView() {
       /* if (this.payerDashboardService.isSingleClaimViewOpen) {
            this.payerDashboardService.singleClaimView = {
                claimNumber: null,
                isShow: false
            };
            this.claimView(false);
        }
*/
    }
}
