import { Observable } from 'rxjs';
import { InjectorInstance } from 'src/app/app.module';

import { Base } from '../../classes/base.class';
import { IProgram } from '../programs/programs.interface';
import { ProductItem } from './product-item.class';
import { ProductItemsService } from './product-items.service';

export class ProductItems extends Base {
    readonly parentProgram: IProgram;
    private productItemsService = InjectorInstance.get(ProductItemsService);

    constructor(program: IProgram) {
        super({ id: program.id, links: [] });
        this.parentProgram = program;
    }

    /* Readonly: Provides information about which parent program was used.
   * Usage: [productItem].getParentProgram();
   */
    getParentProgram(): IProgram {
        return JSON.parse(JSON.stringify(this.parentProgram));
    }

    /* All productItems are based on a template, used when creating a new product item
   *   Usage: [Program].productItems().getTemplates().subscribe(templates => {console.log(templates))})
   */
    getTemplates(): Observable<ProductItem[]> {
        return this.productItemsService.getTemplates();
    }

    /*
   * Get a specific product item
   *  Usage: getProductItem('')
   */
    getProductItem(productId: string) {
        return this.productItemsService.getProductItem(productId);
    }

    /* Create a new productItem. */
    createProductItem(productItem: ProductItem) {
        return this.productItemsService.createProductItem(productItem.data);
    }

    /* Update existing productItem */
    updateProductItem(productItem: ProductItem) {
        return this.productItemsService.updateProductItem(productItem.data);
    }

    /* Get all product items allowed for a program
   *  Example Usage:
   *    [organizationClass].programs().getTemplates()
   *      .subscribe(programs => { programs[0].getProductItemsForProgram().subscribe(data => console.log(data))})
   *
   */
    getProductItemsForProgram(): Promise<ProductItem[]> {
        return this.productItemsService.getProductItemsForProgram(this.parentProgram.id);
    }
}
