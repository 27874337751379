/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Link } from '../../models/link.model';
import { NetworkService } from '../network/network.service';
import { HttpParams } from '@angular/common/http';
import { format } from 'date-fns';

export interface IClinicHours {
    id?: string;
    reason?: string;
    specialScheduleDate?: string;
    from?: string;
    to?: string;
    dayOfWeek: number;
    isOpen: boolean;
    type: string;
    specialScheduleType?: string;
    links?: Link[];
}

@Injectable({
    providedIn: 'root',
})
export class ScheduleService {
    baseURL = '/api/schedule';

    constructor(private network: NetworkService) {}

    public getRegular(orgId: string): Observable<IClinicHours[]> {
        const url = `${this.baseURL}/${orgId}/regular`;
        return this.network.fetchResource(url);
    }

    public saveRegular(orgId: string, hours: IClinicHours[]): Observable<IClinicHours[]> {
        const url = `${this.baseURL}/${orgId}/regular`;
        return this.network.postResource(url, hours);
    }

    public getSpecial(orgId: string): Observable<IClinicHours[]> {
        const url = `${this.baseURL}/${orgId}/special`;
        return this.network.fetchResource(url);
    }

    public saveSpecial(orgId: string, hours: IClinicHours[]): Observable<IClinicHours[]> {
        const url = `${this.baseURL}/${orgId}/special`;
        return this.network.postResource(url, hours);
    }

    getHoursOfOperation(orgId: string, fromDate: Date, toDate: Date, isParent?: boolean): Observable<any> {
        let url = `${this.baseURL}/${orgId}/hoursOfOperation`;
        let params = new HttpParams();
        if (fromDate) {
            const formattedDate = format(fromDate, 'MM/dd/yyyy');
            params = params.append('from', formattedDate);
        }
        if (toDate) {
            const formattedDate = format(toDate, 'MM/dd/yyyy');
            params = params.append('to', formattedDate);
        }
        if (isParent) {
            url = `${this.baseURL}/${orgId}/hoursOfOperationParent`;
        }
        return this.network.fetchResource<any>(url, { params });
    }

    deleteSpecialHour(organizationId: string, hourId: string) {
        const url = `${this.baseURL}/${organizationId}/special/${hourId}`;
        return this.network.deleteResource(url);
    }
}
