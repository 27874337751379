// Main Modules
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import localeTl from '@angular/common/locales/en-PH';

// Locales
import localeEs from '@angular/common/locales/es';
import localeGu from '@angular/common/locales/gu';
import localeHi from '@angular/common/locales/hi';
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import localeVi from '@angular/common/locales/vi';
import localeZh from '@angular/common/locales/zh';
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DefaultUrlSerializer, Router, UrlSerializer, UrlTree } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { MarkdownModule } from 'ngx-markdown';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { QuillConfigModule } from 'ngx-quill';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { GlobalExceptionHandlerService } from './core/services/global-exception-handler/global-exception-handler.service';
import { HeaderModifierInterceptorService } from './core/services/interceptor/header-modifier-interceptor.service';
import { HttpInterceptorService } from './core/services/interceptor/Interceptor-global-error-handler.service';

// Services
import { SERVICES } from './core/services/services';
import { MassRescheduleDialogComponent } from './shared/modals/mass-reschedule-dialog/mass-reschedule-dialog.component';

registerLocaleData(localeEs, 'es');
registerLocaleData(localeKo, 'ko');
registerLocaleData(localeVi, 'vi');
registerLocaleData(localeZh, 'zh-CN');
registerLocaleData(localeZh, 'zh-TW');
registerLocaleData(localeJa, 'ja-JP');
registerLocaleData(localeHi, 'hi-IN');
registerLocaleData(localeGu, 'gu-IN');
registerLocaleData(localeTl, 'tl-PH');

// TODO for using sign '+' in patient screening invitation token
export default class CustomUrlSerializer implements UrlSerializer {
    private _defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

    parse(url: string): UrlTree {
        // Encode "+" to "%2B"
        url = url.replace(/\+/gi, '%2B');


        // Use the default serializer.
        return this._defaultUrlSerializer.parse(url);
    }

    serialize(tree: UrlTree): string {
        return this._defaultUrlSerializer.serialize(tree).replace(/\+/gi, '%2B');
    }
}

export let InjectorInstance: Injector;

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => new TranslateHttpLoader(
                    http,
                    './assets/i18n/',
                    `.json?cacheBuster=${ new Date().getTime() }`,
                ),
                deps: [HttpClient],
            },
        }),
        NgCircleProgressModule.forRoot({}),
        CarouselModule,
        ToastrModule,
        MatDialogModule,
        MatSnackBarModule,
        ToastrModule.forRoot(),
        HttpClientJsonpModule,
        MatProgressBarModule,
        QuillConfigModule.forRoot({
            modules: {
                toolbar: [
                    [
                        'bold',
                        'italic',
                        'underline',
                        'strike',
                    ],
                    ['blockquote', 'code-block'],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    [{ script: 'sub' }, { script: 'super' }],
                    [{ indent: '-1' }, { indent: '+1' }],
                    [{ direction: 'rtl' }],
                    [{
                        size: [
                            'small',
                            false,
                            'large',
                            'huge',
                        ],
                    }],
                    [{
                        header: [
                            1,
                            2,
                            3,
                            4,
                            5,
                            6,
                            false,
                        ],
                    }],
                    [{ color: [] }, { background: [] }],
                    [{ font: [] }],
                    [{ align: [] }],
                    ['clean'],
                ],
            },
        }),
        MarkdownModule.forRoot(),

        MassRescheduleDialogComponent,
    ],
    providers: [
        SERVICES,
        GlobalExceptionHandlerService,
        {
            provide: UrlSerializer,
            useClass: CustomUrlSerializer,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderModifierInterceptorService,
            multi: true,
        },
        provideEnvironmentNgxMask(),
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                minWidth: 600,
                autoFocus: false,
                disableClose: true,
            } as MatDialogConfig,
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler(),
            useClass: GlobalExceptionHandlerService,
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(private injector: Injector, trace: Sentry.TraceService) {
        InjectorInstance = injector;
    }
}
