export const constants: Constants = {
    DashboardChildComponentNames: {
        UpcomingTasks: 'UpcomingTasks',
        FamilyMembers: 'FamilyMembers',
        AvailableServices: 'AvailableServices',
    },
    TaskType: {
        Appointment: 'Appointment',
        Assessment: 'Assessment',
        Recommendation: 'RECOMMENDATION',
        Education: 'Education',
    },
    AppointmentTaskName: {
        Covid19Round1: 'Vaccination (ROUND 1)',
        Covid19Round2: 'Vaccination (ROUND 2)',
        Covid19Round3: 'Vaccination (ROUND 3)',
        Covid19RoundBooster: 'Vaccination (ROUND Booster)',
        Covid19RoundSecondBooster: 'Vaccination (ROUND Second Booster)',
        Flu: 'Flu Shot',
        PickUpFromHome: 'Pickup (Home)',
        PickUpFromAppointment: 'Pickup (Appointment)',
        DropOffToHome: 'Drop-off (Home)',
        DropOffToAppointment: 'Drop-off (Appointment)',
        Testing: 'COVID-19 TESTING',
        TestingCarePlanName: 'COVID-19 Testing On Site',
        TestingServiceName: 'Covid-19 Testing On-Site',
        Covid19: 'COVID-19',
        MonkeypoxTesting: 'MONKEYPOX TESTING',
    },
    LocationType: {
        HOME: 'Home',
    },
    AssessmentTaskName: {
        DayOfAssessmentPart: 'Day-Of Assessment',
    },
    LocalStorageKeys: {
        ImpersonatedFamilyPatientId: 'IMPERSONATED_FAMILY_PATIENT_ID',
        ADD_FAMILY_MEMBER: 'add-or-update-family-member',
        FAMILY_MEMBER_ID: 'family-member-id',
        PROVIDER_PATIENT_SEARCH: 'provider-patient-search',
        NEW_FAMILY_MEMBER: 'new-family-member',
        PROVIDER_ENCOUNTER_TITLE: 'provider-encounter-title',
        PROVIDER_LICENSES: 'provider-licenses'
    },
    ServiceNames: {
        Covid19VaccineService: 'COVID-19 Vaccine',
    },
    ProfileChildComponentNames: {
        ProfileHead: 'ProfileHead',
        FamilyMembers: 'FamilyMembers',
        ProfileQuickAccess: 'ProfileQuickAccess',
        ProfileCompletenessScore: 0.5,
    },
    TaskStatus: {
        PENDING: 'PENDING',
        ACTIVE: 'ACTIVE',
        SCHEDULED: 'SCHEDULED',
        ALL: 'ALL',
        COMPLETED: 'COMPLETED',
        MISSED: 'MISSED',
        READY: 'READY',
    },

    currentStatus: {
        BOOKAPPOINTMENT: 'BOOK-APPOINTMENT',
        VACCINATIONCOMPLETED: 'VACCINATION-COMPLETED',
    },
    TaskName: {
        COVID19TESTING: 'COVID-19 Testing',
    },
    GoalStatus: {
        ACTIVE: 'ACTIVE',
        INACTIVE: 'INACTIVE',
    },
    VaccineTypeNames: {
        Pfizer: 'Pfizer',
        Comirnaty: 'Comirnaty',
        Moderna: 'Moderna',
        JohnsonAndJohnson: 'Johnson&Johnson',
    },
    ServiceRequestType: {
        Homebound: 'Homebound',
        Rideshare: 'Rideshare',
        Ride_Request: 'Ride Request',
        Flu_Shot_Care_Plan: 'Flu Shot Care Plan',
    },
    ServiceStatus: {
        APPROVED: 'APPROVED',
        REQUESTED: 'REQUESTED',
        ALLOCATED: 'ALLOCATED',
        DUPLICATE: 'DUPLICATE',
        CANCELLED: 'CANCELLED',
        REJECTED: 'REJECTED',
        COMPLETED: 'COMPLETED',
    },
    FamilyDashboardQuickaccess: {
        Completed: 'COMPLETED',
    },
    Result: {
        POSITIVE: 'POSITIVE',
        NEGATIVE: 'NEGATIVE',
        INVALID: 'INVALID',
        RESULT_AWAITED: 'RESULT AWAITED',
    },
    RecordsStatus: {
        pending: 'pending',
        scheduled: 'scheduled',
        missed: 'missed',
        complete: 'complete',
    },
    valueType: {
        COVID19LAB: 'COVID19LAB',
    },
    EventStatus: {
        PENDING: 'PENDING',
        TRIAGED: 'TRIAGE',
        CONFIRMED: 'CONFIRMED',
        ONBOARD: 'ONBOARD',
    },
    AssessmentNotRequiredServicesList: [],
    DiagnosticCovid19PcrTestName: 'Diagnostic COVID-19 PCR Test',
    RapidAntigenCovid19Test: 'Rapid Antigen Covid-19 Test',
    TestingDataType: {
        covid19testing: 'COVID-19 TESTING',
        monkeypoxTesting: 'MONKEYPOX TESTING',
        respiratoryTesting: 'RESPIRATORY TESTING',
    },
    InsuranceStatus: {
        VERIFIED: 'VERIFIED',
        MISSING: 'MISSING',
        NONE: 'NONE',
    }
};

export interface Constants {
    DashboardChildComponentNames: {
        UpcomingTasks: string;
        FamilyMembers: string;
        AvailableServices: string;
    };
    TaskType: {
        Appointment: string;
        Assessment: string;
        Recommendation: string;
        Education: string;
    };
    AppointmentTaskName: {
        Covid19Round1: string;
        Covid19Round2: string;
        Covid19Round3: string;
        Covid19RoundBooster: string;
        Covid19RoundSecondBooster: string;
        Flu: string;
        PickUpFromHome: string;
        PickUpFromAppointment: string;
        DropOffToHome: string;
        DropOffToAppointment;
        Testing: string;
        TestingCarePlanName: string;
        TestingServiceName: string;
        Covid19: string;
        MonkeypoxTesting: string;
    };
    LocationType: {
        HOME: string;
    };
    AssessmentTaskName: {
        DayOfAssessmentPart: string;
    };
    LocalStorageKeys: {
        ImpersonatedFamilyPatientId: string;
        ADD_FAMILY_MEMBER: string;
        FAMILY_MEMBER_ID: string;
        NEW_FAMILY_MEMBER: string;
        PROVIDER_PATIENT_SEARCH: string;
        PROVIDER_ENCOUNTER_TITLE: string;
        PROVIDER_LICENSES: string;
    };
    ServiceNames: {
        Covid19VaccineService: string;
    };
    ProfileChildComponentNames: {
        ProfileHead: string;
        FamilyMembers: string;
        ProfileQuickAccess: string;
        ProfileCompletenessScore: number;
    };
    TaskStatus: {
        PENDING: string;
        ACTIVE: string;
        ALL: string;
        COMPLETED: string;
        SCHEDULED: string;
        MISSED: string;
        READY: string;
    };
    currentStatus: {
        BOOKAPPOINTMENT: string;
        VACCINATIONCOMPLETED: string;
    };
    TaskName: {
        COVID19TESTING: string;
    };
    GoalStatus: {
        ACTIVE: string;
        INACTIVE: string;
    };
    VaccineTypeNames: {
        Pfizer: string;
        Comirnaty: string;
        Moderna: string;
        JohnsonAndJohnson: string;
    };
    ServiceRequestType: {
        Homebound: string;
        Rideshare: string;
        Ride_Request: string;
        Flu_Shot_Care_Plan: string;
    };
    ServiceStatus: {
        APPROVED: string;
        REQUESTED: string;
        ALLOCATED: string;
        DUPLICATE: string;
        CANCELLED: string;
        REJECTED: string;
        COMPLETED: string;
    };
    FamilyDashboardQuickaccess: {
        Completed: string;
    };
    Result: {
        POSITIVE: string;
        NEGATIVE: string;
        INVALID: string;
        RESULT_AWAITED: string;
    };
    RecordsStatus: {
        pending: string;
        scheduled: string;
        missed: string;
        complete: string;
    };
    valueType: {
        COVID19LAB: string;
    };
    EventStatus: {
        PENDING: string;
        TRIAGED: string;
        CONFIRMED: string;
        ONBOARD: string;
    };
    AssessmentNotRequiredServicesList: string[];
    DiagnosticCovid19PcrTestName: string;
    RapidAntigenCovid19Test: string;
    TestingDataType: {
        covid19testing: string;
        monkeypoxTesting: string;
        respiratoryTesting: string;
    };
    InsuranceStatus: {
        VERIFIED: string;
        MISSING: string;
        NONE: string;
    }
}

export const DialogDataconstant = {
    name: 'Profile Image',
};

export interface DialogData {
    profileImagePath: string;
    name: string;
    patientId: string;
    username: any;
}

// for provider
export interface ScheduleDialogInterface {
    cancelButtonLabel: string;
    confirmButtonLabel: string;
    dialogHeader: string;
    dialogContent?: string;
    appointmentData?: any;
    returnCallbackMethod?: (action: number) => void;
    callbackMethod?: () => void;
}

// eslint-disable-next-line no-shadow
export enum TaskStatus {
    pending = 'PENDING',
    active = 'ACTIVE',
    all = 'ALL',
    scheduled = 'SCHEDULED',
    rescheduled = 'RESCHEDULED',
    completed = 'COMPLETED',
    missed = 'MISSED',
    ready = 'READY',
    available = 'AVAILABLE',
    noShow = 'NO SHOW'
}
