import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GetActivityTextPipe } from './activity/get-activity-text.pipe';
import { AddPlusPipe } from './add-plus.pipe';
import { AddressFormatterPipe } from './address-formatter/address-formatter.pipe';
import { AddressFormatterTwoLinesPipe } from './address-formatter-two-lines/address-formatter-two-lines.pipe';
import { AgePipe } from './age/age.pipe';
import { CapitalizePipe } from './capitalize.pipe';
import { CompletedTasksPipe } from './care-plan/completed-tasks.pipe';
import { GoalStartsAtPipe } from './care-plan/goal-starts-at.pipe';
import { ColorZoneLabelPipe } from './color-zone-label/color-zone-label.pipe';
import { MinMaxRangePipe } from './date/min-max-range.pipe';
import { FormatDatePipe } from './date-formatter/format-date.pipe';
import { GetFrequencyPipe } from './date-formatter/get-frequency.pipe';
import { GetFrequencyDaysPipe } from './date-formatter/get-frequency-days.pipe';
import { GetTimeFromTimestampPipe } from './date-formatter/get-time-from-timestamp.pipe';
import { TimeAgoPipe } from './date-formatter/time-ago.pipe';
import { DownloadPipe } from './download.pipe';
import { FilteredCountPipe } from './filtered-count.pipe';
import { FullNamePipe } from './full-name.pipe';
import { GenderFormatterPipe } from './gender-formatter/gender-formatter.pipe';
import { ImagePipe } from './image.pipe';
import { LinkPipe } from './link.pipe';
import { PrettyJsonPipe } from './prettyjson.pipe';
import { GetMissingFieldsPipe } from './task/get-missing-fields.pipe';
import { TruncateTextPipe } from './text/truncate-text.pipe';
import {SafeUrlPipe} from './safe.url.pipe';
import {SafeHtmlPipe} from './safe.html.pipe';
import {WordCountPipe} from "./word-count.pipe";
import {CharactersPipe} from "./characters.pipe";

@NgModule({
    declarations: [
        GenderFormatterPipe,
        AddressFormatterPipe,
        AddressFormatterTwoLinesPipe,
        FormatDatePipe,
        ColorZoneLabelPipe,
        GetFrequencyPipe,
        GetTimeFromTimestampPipe,
        CompletedTasksPipe,
        GoalStartsAtPipe,
        MinMaxRangePipe,
        TimeAgoPipe,
        GetFrequencyDaysPipe,
        GetMissingFieldsPipe,
        AddPlusPipe,
        ImagePipe,
        GetActivityTextPipe,
        FilteredCountPipe,
        LinkPipe,
        DownloadPipe,
        FullNamePipe,
        TruncateTextPipe,
        AgePipe,
        CapitalizePipe,
        PrettyJsonPipe,
        SafeUrlPipe,
        SafeHtmlPipe,
        WordCountPipe,
        CharactersPipe
    ],
    imports: [CommonModule],
    exports: [
        GenderFormatterPipe,
        AddressFormatterPipe,
        AddressFormatterTwoLinesPipe,
        FormatDatePipe,
        ColorZoneLabelPipe,
        GetFrequencyPipe,
        GetTimeFromTimestampPipe,
        CompletedTasksPipe,
        GoalStartsAtPipe,
        MinMaxRangePipe,
        TimeAgoPipe,
        GetFrequencyDaysPipe,
        GetMissingFieldsPipe,
        AddPlusPipe,
        ImagePipe,
        GetActivityTextPipe,
        FilteredCountPipe,
        LinkPipe,
        DownloadPipe,
        FullNamePipe,
        TruncateTextPipe,
        AgePipe,
        CapitalizePipe,
        PrettyJsonPipe,
        SafeUrlPipe,
        SafeHtmlPipe,
        WordCountPipe,
        CharactersPipe
    ],
})
export class PipesModule {}
