import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, Router, UrlTree } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class HelperService {
    constructor(private _router: Router) {}

    /**
   * @param {string} url
   * @param {string} paramName
   * @param {string} paramValue
   * @return {string}
   */
    public updateQueryParam(url: string, paramName: string, paramValue: string): string {
        if (typeof url !== 'string') {
            throw new Error('HelperService.updateQueryParam: Not a string!');
        }

        const tree: UrlTree = new DefaultUrlSerializer().parse(url);
        const { queryParams } = tree;

        queryParams[paramName] = paramValue;
        tree.queryParams = queryParams;

        return this._router.serializeUrl(tree);
    }
}
