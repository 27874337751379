import { LanguageType } from './language.enum';
import { LanguageModel } from './language.model';
import data from './resources/chinese.json';

export class ChineseLanguageModel extends LanguageModel {
    public constructor() {
        super(data);

        this.type = LanguageType.chinese;
    }
}
