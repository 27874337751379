import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FieldArrayService {
    private fieldArray = new ReplaySubject<any>(1);

    constructor() { }

    get() {
        return this.fieldArray;
    }

    set(data:any) {
        this.fieldArray.next(data);
    }
}
