import { formatNumber } from '@angular/common';
import {
    Injectable,
    Renderer2,
    RendererFactory2,
} from '@angular/core';
import {
    AbstractControl,
    UntypedFormGroup,
} from '@angular/forms';
import { format } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

// eslint-disable-next-line max-len
import globals from '../../../config';
import { CarePlan } from '../models/careplan.model';
import { CPLocation } from '../models/location.model';

// eslint-disable-next-line max-len
const mobiles = /(android|bb\\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i;
// eslint-disable-next-line max-len
const secondMobiles = /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i;

@Injectable({
    providedIn: 'root',
})
export class Utility {
    public screenWidth: number;
    public activityMenuState: string;
    private renderer: Renderer2;

    static random(min: number, max: number, round: number = 1): number {
        round = round === 0 ? 1 : 10 ** round;

        return Math.round((Math.random() * (max - min) + min) * round) / round;
    }

    static cloneCarePlan(carePlan: CarePlan): CarePlan {
        const clone = new CarePlan();
        clone.id = carePlan.id;
        clone.isTemplate = carePlan.isTemplate;
        clone.taskList = carePlan.taskList;
        clone.tasks = carePlan.tasks;
        clone.parentTasks = carePlan.parentTasks;
        clone.carePlanType = carePlan.carePlanType;
        clone.deletedTaskIds = carePlan.deletedTaskIds;
        clone.desciption = carePlan.desciption;
        clone.duration = carePlan.duration;
        clone.name = carePlan.name;
        clone.cycles = carePlan.cycles;
        clone.img = carePlan.img;
        clone.startDate = carePlan.startDate;
        clone.startDateStr = carePlan.startDateStr;
        clone.ownerId = carePlan.ownerId;
        clone.owner = carePlan.owner;
        clone.patientId = carePlan.patientId;
        clone.usedTemplateId = carePlan.usedTemplateId;
        clone.durationWeeks = !carePlan.durationWeeks && !!carePlan.duration ? Math.floor(+carePlan.duration / 7) : carePlan.durationWeeks;

        return clone;
    }

    /**
   * Address Formatter
   *
   * @param {CPLocation, Address} location
   * @param {string} data default value if location doesnt exist
   *
   * @returns {string}
   */
    static addressFormatter(location: any, ...data: Array<string>): string {
        let locationModel = new CPLocation();

        let defaultValue = 'N/A';
        if (data && data.length) {
            defaultValue = data[0];
        }
        let address = defaultValue;
        if (location) {
            locationModel = { ...locationModel, ...location };

            if (locationModel.address1) {
                // address1 exist
                address = locationModel.address1;

                if (locationModel.address2) {
                    address += ` ${locationModel.address2}`;
                }

                if (locationModel.city) {
                    address += `, ${locationModel.city}`;
                }

                if (locationModel.state) {
                    address += `, ${locationModel.state}`;
                }

                if (locationModel.zipcode) {
                    address += `, ${locationModel.zipcode}`;
                }

                if (locationModel.country) {
                    address += `, ${locationModel.country}`;
                }
            } else if (!locationModel.address1) {
                // address1 doesnt exist
                if (locationModel.city && locationModel.state) {
                    // city and state exist
                    address = `${locationModel.city}, ${locationModel.state}`;
                    if (locationModel.country) {
                        address += `, ${locationModel.country}`;
                    }
                } else if (!locationModel.city && locationModel.state) {
                    // city doesnt exist but state exist
                    address = locationModel.state;
                    if (locationModel.country) {
                        address += `, ${locationModel.country}`;
                    }
                } else if (locationModel.city && !locationModel.state) {
                    // city exist but state doesnt exist
                    address = locationModel.city;
                    if (locationModel.country) {
                        address += `, ${locationModel.country}`;
                    }
                } else if (!locationModel.city && !locationModel.state && locationModel.country) {
                    address = `${locationModel.country}`;
                }
            }
        }

        return address;
    }

    static genderFormatter(gender: string): string {
        switch (gender) {
            case 'M':
                return 'Male';
            case 'F':
                return 'Female';
            case 'O':
                return 'Other';
            case 'T':
                return 'Trans';
        }
        return 'N/A';
    }

    static getValidField(control: UntypedFormGroup | AbstractControl, field: string = '', isSubmitted: boolean): boolean {
        return control && field
            ? control.get(field) && control.get(field).invalid && isSubmitted
            : !field
                ? control.invalid && isSubmitted
                : false;
    }

    static isGoalReadOnly(goal): boolean {
        return goal.goalStatus ? !['ACTIVE', 'INACTIVE'].includes(goal.goalStatus) : false;
    }

    static goalHasDailyAssessment(taskTypeId, serviceTypeIds, goal): boolean {
        const DAILY_ASSESSMENT_ID = globals.SERVICE_TYPES.DAILY_ASSESSMENT;
        const tasksLength = goal.tasks.length;

        if (+taskTypeId === globals.TASKTYPES.ASSESSMENT && serviceTypeIds.includes(DAILY_ASSESSMENT_ID)) {
            for (let i = 0; i < tasksLength; i++) {
                if (+goal.tasks[i].serviceTypeId === DAILY_ASSESSMENT_ID) {
                    return true;
                }
            }
        }

        return false;
    }

    static set(object: any, path: Array<string | number> | string, value: any): any | null {
        const fieldList = Array.isArray(path) ? path : path.split('.');
        const len = fieldList.length;

        for (let i = 0; i < len - 1; i++) {
            const field = fieldList[i];

            if (!object[field]) return null;
            object = object[field];
        }

        object[fieldList[len - 1]] = value;

        return value;
    }

    constructor(private rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
        this.screenWidth = window.innerWidth;
    }

    public getScreenWidth(): number {
        return this.screenWidth;
    }

    // UTC to date for given time zone
    public static utcToZonedDate(utcTime: any, timeZoneId: string): any {
        return utcToZonedTime(utcTime, timeZoneId);
    }

    // utc to time component for given timezone
    public static formatZonedTime(time: any, timeZoneId: string, timeFormat: string = null): string {
        timeFormat ||= 'HH:mm';
        return format(Utility.utcToZonedDate(time, timeZoneId), timeFormat);
    }

    // convert datetime in given timezone to utc
    public static zonedTimeToUTC(time: any, timeZoneId: string) {
        const d = new Date(time);
        const zonedTime = zonedTimeToUtc(d, timeZoneId);
        return zonedTime.getTime();
    }

    public setActivityMenuState(data: string): void {
        this.activityMenuState = data;
    }

    public getActivityMenuState(): string {
        return this.activityMenuState;
    }

    public css(el: HTMLElement, styles: { [key: string]: any }): void {
        for (const prop in styles) {
            if (styles.hasOwnProperty(prop)) {
                this.renderer.setStyle(el, prop, styles[prop]);
            }
        }
    }

    public getDistance(p1: google.maps.LatLngLiteral, p2: google.maps.LatLngLiteral) {
        const rad = (x) => (x * Math.PI) / 180;
        const R = 6378137; // Earth’s mean radius in meter
        const dLat = rad(p2.lat - p1.lat);
        const dLong = rad(p2.lng - p1.lng);
        const a = Math.sin(dLat / 2) ** 2
      + Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) ** 2;
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c; // returns the distance in km
    }

    public openInNewTab(event: any, href: string) {
        if (event.ctrlKey) {
            const a = document.createElement('a');

            a.setAttribute('target', '_blank');
            a.setAttribute('href', href);

            a.click();
        } else {
            window.location.href = href;
        }
    }

    public hasValue(value): boolean {
        return (
            value !== undefined
      && value !== null
      && (!Array.isArray(value) || value.length > 0)
      && (typeof value !== 'string' || value.trim().length > 0)
        );
    }

    public convertTimeForResults(timeForResults) {
        if (!timeForResults) return '';
        try {
            let str = '';
            const times = timeForResults.split(':');
            if (times[0] && times[1] && +times[1] == 24) {
                str = `${times[0]}-${+times[0] + 1} day(s) `;
            } else {
                if (times[0] && +times[0] > 0) {
                    str = `${times[0]} day(s) `;
                }
                if (times[1] && +times[1] > 0) {
                    str += `${times[1]} hour(s)`;
                }
            }
            return str;
        } catch (e) {
            return '';
        }
    }

    public getRandomPassword(length: number) {
        let password = '';
        const charCaseNumbers = '0123';
        let dynamicCharCaseNumbers = charCaseNumbers;
        for (let i = 0; i < length; i++) {
            if (!dynamicCharCaseNumbers?.length) {
                dynamicCharCaseNumbers = charCaseNumbers;
            }
            const charCaseNumber = this.getRandomString(1, dynamicCharCaseNumbers);
            password += this.getPasswordCharacter(charCaseNumber);
            dynamicCharCaseNumbers = dynamicCharCaseNumbers.replace(charCaseNumber, '');
        }
        return password;
    }

    private getRandomString(length: number, fromCharacterSet: string) {
        let value = '';
        for (let i = 0; i < length; i++) {
            value += fromCharacterSet.charAt(Math.floor(Math.random() * fromCharacterSet.length));
        }
        return value;
    }

    private getPasswordCharacter(charCaseNumber) {
        switch (charCaseNumber) {
            case '0': // lower char
                return this.getRandomString(1, 'abcdefghijklmnopqrstuvwxyz');
            case '1': // upper char
                return this.getRandomString(1, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ');
            case '2': // number char
                return this.getRandomString(1, '1234567890');
            case '3': // special char
                return this.getRandomString(1, '!@#$%&*-');
            default:
                // random any char
                return this.getRandomString(1, 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890!@#$%&*-');
        }
    }

    static addressFormat(locationModel: any, part: number) {
        let address = '';
        if (locationModel.address1) {
            if (part === 1) {
                address = locationModel.address1;

                if (locationModel.address2) {
                    address += ` ${locationModel.address2}`;
                }

                if (locationModel.city) {
                    address += `, ${locationModel.city}`;
                }
            } else if (part === 2) {
                if (locationModel.state) {
                    address = `${locationModel.state}`;
                }

                if (locationModel.zipcode) {
                    address += `, ${locationModel.zipcode}`;
                }

                if (locationModel.country) {
                    address += `, ${locationModel.country}`;
                }
            }
        }
        return address;
    }

    static fullName(
        { firstName, lastName }: { firstName: string; lastName: string; [key: string]: any } = { firstName: '', lastName: '' },
    ) {
        if ((typeof firstName !== 'string' || typeof lastName !== 'string') && (!firstName || !lastName)) {
            throw new Error('Incoming' + ' data' + ' without' + ' fields(firstName, lastName)');
        }
        return `${firstName} ${lastName}`;
    }

    static isValidJSON(str: string): boolean {
        if (str === 'null') return false;
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    static getGeolocation() {
        if ('geolocation' in window.navigator) {
            return new Promise<{ position: { lat: number; lng: number }; error?: GeolocationPositionError }>((resolve) => {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        resolve({ position: { lat: position.coords.latitude, lng: position.coords.longitude } });
                    },
                    (error) => {
                        resolve({ position: { lat: undefined, lng: undefined }, error });
                    },
                );
            });
        }
        console.error('Geolocation is not supported.');
    }

    static isMobile() {
        let check = false;
        (function (a) {
            if (mobiles.test(a) || secondMobiles.test(a.slice(0, 4))) check = true;
        }(navigator.userAgent || navigator.vendor || window['opera']));
        return check;
    }

    static formatToKorM(value: number) {
        if (value >= 1000000) return formatNumber(value / 1000000, 'en-us', '1.0-1') + 'M';
        if (value >= 1000) return formatNumber(value / 1000, 'en-us', '1.0-1') + 'K';
        return value;
    }

    static isSafari(browser: string) {
        return (navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0);
    }
}
