import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Subscription, interval, take } from 'rxjs';

@Component({
    selector: 'app-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatSnackBarModule, MatIconModule, MatTooltipModule, MatProgressBarModule],
})
export class SnackbarComponent{
    snackBarRef = inject(MatSnackBarRef);

    get message() {
        return this.snackBarRef.containerInstance.snackBarConfig.data?.message
            ? this.snackBarRef.containerInstance.snackBarConfig.data.message
            : '';
    }

    get icon(): string {

        const type = this.snackBarRef.containerInstance.snackBarConfig.data?.type
            ? this.snackBarRef.containerInstance.snackBarConfig.data.type
            : 'NOTIFY';

        switch (type) {
            case 'ERROR':
                return 'error';
            case 'WARN':
                return 'warning';
            case 'INFO':
                return 'info';
            case 'NOTIFY':
                return 'check_circle';
            default:
                return 'check_circle';
        }
    }
}
