import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { TranslationISO } from '../../../../../core/models/translation.model';

const DEFAULT_LANG = TranslationISO.en;

@Injectable({
    providedIn: 'root',
})
export class QuestionsTranslationsService {
    private _language = new BehaviorSubject<TranslationISO>(DEFAULT_LANG);
    private _isSelectedDefaultLang = new BehaviorSubject<boolean>(true);
    private _defaultLang = new BehaviorSubject<TranslationISO>(DEFAULT_LANG);

    public language$ = this._language.asObservable();
    set language(value) {
        this.isSelectedDefaultLang = value === DEFAULT_LANG;
        this._language.next(value);
    }
    get language() {
        return this._language.getValue();
    }

    public isSelectedDefaultLang$ = this._isSelectedDefaultLang.asObservable();
    set isSelectedDefaultLang(value) {
        this._isSelectedDefaultLang.next(value);
    }
    get isSelectedDefaultLang() {
        return this._isSelectedDefaultLang.getValue();
    }

    set defaultLang(value) {
        this._defaultLang.next(value);
    }
    get defaultLang() {
        return this._defaultLang.getValue();
    }

    resetLang() {
        this._language.next(this.defaultLang);
        this._isSelectedDefaultLang.next(true);
    }
}
