import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of as observableOf, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { InjectorInstance } from 'src/app/app.module';

import { environment } from '../../../../environments/environment';
import { DateUtility } from '../../utils/date-utility';
import ErrorLog from '../error-log/error-log';
import { LanguageService } from '../language/language.service';
import { SnackbarService } from '../snackbar.service';
import { UserSessionService } from '../user-session/user-session.service';

const { apiUrl } = environment;

interface NetworkOptions {
    headers?: { [key: string]: any } | null;
    params?: HttpParams | null;
    responseType?: any;
    role?: string;
}

@Injectable({ providedIn: 'root' })
export class NetworkService {
    constructor(
        private http: HttpClient,
        private session: UserSessionService,
        private languageService: LanguageService,
    ) {}

    static handleError(error: HttpErrorResponse) {
        console.error(error);
        new ErrorLog(error);
        const snackbarService = InjectorInstance.get(SnackbarService);

        if (error.status === 401 || error.status === 403) {
            snackbarService.error('Your session is expired. Please login again');
        } else if (error.status !== 409) {
            // 409 is processed in each component
            if (environment.environmentType != 'uat') {
                snackbarService.error('An error occurred while trying to process the request.');
            }
        }
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else if (error.status >= 400) {
            /*
             * The backend returned an unsuccessful response code.
             * The response body may contain clues as to what went wrong,
             */
            console.error(`Backend returned code ${ error.status }, ` + 'body was: ');
            console.error(error.error);

            return throwError(error);
        }


        // return an observable with a user-facing error message
        return throwError(error);
    }

    public fetchResource<T>(url: string, options: NetworkOptions = {}): Observable<T> {
        const { headers = null, params = null, ...rest } = options;
        let defaultHeaders: HttpHeaders = new HttpHeaders({
            'Accept-Language': this.languageService.getCurrLanguage(),
            'X-TimeZone': DateUtility.prototype.getTimeZone(),
            'X-Role': 'doctor',
        });

        if (this.session.loggedInStatus) {
            const keycloak = JSON.parse(localStorage.getItem('keycloak'));
            const access_token = keycloak?.access_token;

            if (!access_token) {
                const e = new HttpErrorResponse({
                    error: 'User Not Logged In! Please login again',
                    status: 401,
                    statusText: 'User Not Logged In',
                });

                return NetworkService.handleError(e);
            }

            defaultHeaders = defaultHeaders.set('Authorization', `Bearer ${ access_token }`);
        }

        return this.http
            .get<T>(apiUrl + url, {
                params,
                headers: NetworkService.updateHeaders(defaultHeaders, headers),
                ...rest,
            })
            .pipe(catchError(NetworkService.handleError));
    }

    // TODO: Needs body parameter
    public postResource<T>(url: string, body: any, options: NetworkOptions = {}): Observable<T> {
        const { headers = null, params = null, ...rest } = options;
        const defaultHeaders = {
            Authorization: `Bearer ${ this.session.token.access_token }`,
            'Accept-Language': this.languageService.getCurrLanguage(),
            'X-TimeZone': DateUtility.prototype.getTimeZone(),
            'X-Role': 'doctor', // TODO: Dynamically populate X-Role field with type
        };
        const mergedHeaders = headers ? { ...defaultHeaders, ...headers } : defaultHeaders;

        return this.http
            .post<T>(apiUrl + url, body, { headers: new HttpHeaders(mergedHeaders), params, ...rest })
            .pipe(catchError(NetworkService.handleError));
    }

    // TODO: Needs body parameter
    public putResource<T>(url: string, body: any, options: NetworkOptions = {}): Observable<T> {
        const { headers = null, params = null, ...rest } = options;
        const defaultHeaders = {
            Authorization: `Bearer ${ this.session.token.access_token }`,
            'Accept-Language': this.languageService.getCurrLanguage(),
            'X-TimeZone': DateUtility.prototype.getTimeZone(),
            'X-Role': 'doctor', // TODO: Dynamically populate X-Role field with type
        };
        const mergedHeaders = headers ? { ...defaultHeaders, ...headers } : defaultHeaders;

        return this.http
            .put<T>(apiUrl + url, body, { headers: new HttpHeaders(mergedHeaders), params, ...rest })
            .pipe(catchError(NetworkService.handleError));
    }

    // TODO: Needs body parameter
    public patchResource<T>(url: string, body: any, options: NetworkOptions = {}): Observable<T> {
        const { headers = null, params = null, ...rest } = options;
        const defaultHeaders = {
            Authorization: `Bearer ${ this.session.token.access_token }`,
            'Accept-Language': this.languageService.getCurrLanguage(),
            'X-TimeZone': DateUtility.prototype.getTimeZone(),
            'X-Role': 'doctor', // TODO: Dynamically populate X-Role field with type
        };
        const mergedHeaders = headers ? { ...defaultHeaders, ...headers } : defaultHeaders;

        return this.http
            .patch<T>(apiUrl + url, body, { headers: new HttpHeaders(mergedHeaders), params, ...rest })
            .pipe(catchError(NetworkService.handleError));
    }

    public deleteResource<T>(url: string, options: NetworkOptions = {}): Observable<T> {
        const { headers = null, params = null, ...rest } = options;
        const defaultHeaders: HttpHeaders = new HttpHeaders({
            'Accept-Language': this.languageService.getCurrLanguage(),
            'X-TimeZone': DateUtility.prototype.getTimeZone(),
            'X-Role': 'doctor', // TODO: Dynamically populate X-Role field with type
        });

        return this.http
            .delete<T>(apiUrl + url, {
                headers: NetworkService.updateHeaders(this.addToken(defaultHeaders), headers),
                params,
                ...rest,
            })
            .pipe(catchError(NetworkService.handleError));
    }

    public getImage(url: string): Observable<Blob | string> {
        if (!this.session.token) return observableOf('');
        const headers = new HttpHeaders({
            Authorization: `Bearer ${ this.session.token.access_token }`,
            'Accept-Language': this.languageService.getCurrLanguage(),
            'X-TimeZone': DateUtility.prototype.getTimeZone(),
            'X-Role': 'doctor', // TODO: Dynamically populate X-Role field with type
        });

        return this.http
            .get(apiUrl + url, { headers, responseType: 'blob' })
            .pipe(catchError(NetworkService.handleError));
    }

    public getImageWithoutCredential(url: string): Observable<Blob> {
        return this.http.get(apiUrl + url, { responseType: 'blob' }).pipe(catchError(NetworkService.handleError));
    }

    addToken(headers: HttpHeaders): HttpHeaders {
        let defaultHeaders = headers;

        if (this.session.loggedInStatus || headers?.['X-Role']) {
            const keycloak = JSON.parse(localStorage.getItem('keycloak'));
            const sessionTokenAccessToken = this.session?.token ? this.session.token.access_token : null;
            const accessToken = keycloak ? keycloak.access_token : sessionTokenAccessToken;

            if (accessToken) {
                defaultHeaders = defaultHeaders.set('Authorization', `Bearer ${ accessToken }`);
            }
        }

        return defaultHeaders;
    }

    static updateHeaders(httpHeaders: HttpHeaders, newHeaders: { [key: string]: string } | null): HttpHeaders {
        let headers = httpHeaders;

        if (newHeaders) {
            Object.entries(newHeaders).forEach(([key, value]) => {
                headers = headers.set(key, value);
            });
        }

        return headers;
    }
}
