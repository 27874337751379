import { InjectorInstance } from 'src/app/app.module';
import { SetupVaccinationSlotsServiceService } from 'src/app/modules/setup-vaccination-slots/setup-vaccination-slots-service.service';

import { ResponseWithLinks } from '../models/common.model';
import { BranchModel, IOrganization, OrganizationModel } from '../models/organization.model';
import { DepartmentModel } from '../models/provider.model';
import { Programs } from '../services/programs/programs.class';
import { Address } from './address.class';
import { Base } from './base.class';

export class Organization extends Base {
    private clinicService = InjectorInstance.get(SetupVaccinationSlotsServiceService);

    id: string;
    name: string;
    businessName: string;
    businessNpi: string;
    address: Address;
    cliaAddress?: Address;
    employerIdNumber?: string;
    businessLicense?: string;
    cliaNumber?: number;
    vinNumber?: string;
    isOpen?: boolean;
    isPublic?: boolean;
    podType?: string;
    description?: string;
    profileImage?: string;
    organizationImage?: string;
    departments?: DepartmentModel[];
    compliancyScore?: number;
    providerCount?: number;
    patientCount?: number;
    parentName?: string;
    parentType?: 'VA' | 'FACILITY' | 'CLINIC';
    vaccinationCount?: number;
    bedCount?: number;
    bedOccupancyRate?: number;
    bedTurnoverRate?: number;
    branches?: ResponseWithLinks<BranchModel>;
    deathRate?: number;
    parents?: ResponseWithLinks<OrganizationModel>;
    expanded?: boolean;
    isHeadquarter?: boolean;
    shortName?: string;
    type?: string;
    parentId?: string;
    loadedAdditionalData?: boolean;
    globalCarePlansCount?: any;
    globalAssessmentsCount?: any;
    extraSettings?: any;
    ledFirstName?: string;
    ledLastName?: string;
    ledNpi?: string;
    ledType?: string;
    orderingPhysicianFirstName?: string;
    orderingPhysicianLastName?: string;
    orderingPhysicianNPI?: string;
    orderingPhysicianMedicalLicenseType?: string;
    orgTimezone?: string;

    programs: Programs;
    eventCount: number;

    constructor(organizationData?: IOrganization) {
        super({ id: organizationData.id, links: organizationData?.links });
        this.name = organizationData.name;
        this.address = new Address(organizationData.address || {});
        if (organizationData) Object.assign(this, organizationData);
        this.programs = new Programs(organizationData.id);
    }

    getProductItems() {
        return this.clinicService.getProductItemsPerClinic(this.id);
    }
}
export class ClinicEdit {
    clinic:IOrganization;
    action: string;
}
