import { InjectorInstance } from 'src/app/app.module';

import { Base } from '../../classes/base.class';
import { Products } from '../products/products.class';
import { IProductItem } from './product-items.interface';
import { ProductItemsService } from './product-items.service';

/* make productId optional */
type IProductItemUpdate = Pick<Partial<IProductItem>, 'productId'> & Omit<IProductItem, 'productId'>;

export class ProductItem extends Base {
    readonly productItemData: IProductItem;
    readonly _products: Products;

    private productItemsService = InjectorInstance.get(ProductItemsService);

    constructor(product: IProductItem) {
        super({ id: product.productId, links: [] });
        this.productItemData = product;
        this._products = new Products(this);
    }

    /* Update existing productItem */
    updateProductItem(productItem: IProductItemUpdate) {
        this.productItemsService.updateProductItem(Object.assign(productItem, { productId: this.productItemData.productId }));
    }

    /* Read Only: Input data for the current product. */
    get data(): IProductItem {
        return this.productItemData;
    }

    refresh() {
        throw Error('not implemented');
    }

    delete() {
        throw Error('not implemented');
    }

    /* List all actions and data available under this productItem
   *  Example Usage:
   *    [ProductItem].products();
   */
    get products() {
        return this._products;
    }
}
