import { Component } from '@angular/core';

@Component({
    selector: 'app-icon-list',
    templateUrl: './icon-list.component.html',
    styleUrls: ['./icon-list.component.scss'],
})
export class IconListComponent {
    filterIcons = [
        'ic-filter-1',
        'ic-filter-2',
        'ic-filter-3',
        'ic-filter-4',
        'ic-filter-5',
        'ic-filter-6',
        'ic-filter-7',
        'ic-filter-8',
        'ic-filter-9',
        'ic-filter-10', // date of birth
    ];
}
