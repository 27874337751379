import {
    HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, shareReplay, tap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { AssessmentsService } from '../../../modules/assessments/assessments.service';
import { AuthToken } from '../../models/auth-token.model';
import { ApiService } from '../api/api.service';
import { DataBrokerService } from '../data-broker/data-broker.service';
import { LanguageService } from '../language/language.service';
import { NetworkService } from '../network/network.service';
import { OperationUserSessionService } from '../user-session/operation-user-session.service';
import { UserSessionService } from '../user-session/user-session.service';
import { HttpUrlEncodingCodec } from './http-url-encoding-codec';

interface LoginModel {
    username: string;
    password?: string;
    smsCode?: string;
}

export interface CustomHttpError {
    status: number;
    statusText: string;
    message: string;
}

@Injectable({
    providedIn: 'root',
})
export class AuthorizationService {
    private token: string;
    private username: string;

    static handleError(error: HttpErrorResponse) {
        let errorMessage;

        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${error.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Backend returned code ${error.error.code}, body was: ${error.error.message}`;
        }
        console.error(errorMessage);
        // return an observable with a user-facing error message
        return throwError(error.error);
    }

    constructor(
        private api: ApiService,
        private http: HttpClient,
        private session: UserSessionService,
        private languageService: LanguageService,
        private operationUserSessionService: OperationUserSessionService,
        private networkService: NetworkService,
        private router: Router,
        private dataBrokerService: DataBrokerService,
        private assessmentsService: AssessmentsService
    ) {
    }

    public submitLogin({ username, password, smsCode }: LoginModel): Observable<AuthToken> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
        });

        let body = new HttpParams({ encoder: new HttpUrlEncodingCodec() })
            .set('grant_type', 'password')
            .set('username', username)
            .set('client_id', 'public');

        if (password) body = body.set('password', password);
        if (smsCode) body = body.set('smsCode', smsCode);

        return this.http
            .post<AuthToken>(environment.apiUrl + ApiService.getAuthorizationLogin(), body.toString(), { headers })
            .pipe(shareReplay(), catchError(AuthorizationService.handleError));
    }

    // If sms string is empty, it will REQUEST an SMS code to the assigned number for the entered account
    public submitSmsLogin(username: string, sms: string): Observable<AuthToken> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
        });

        // TODO: Get the header name
        const body = new HttpParams()
            .set('grant_type', 'password')
            .set('username', username)
            .set('smsCode', sms)
            .set('client_id', 'public');

        return this.http
            .post<AuthToken>(ApiService.getAuthorizationLogin(), body.toString(), { headers })
            .pipe(catchError(AuthorizationService.handleError));
    }

    public updatePassword({ newPassword, oldPassword }): Observable<any> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.session.token.access_token}`,
            'Accept-Language': this.languageService.getCurrLanguage(),
            'X-Role': 'doctor',
        });

        const url = `${environment.apiUrl}/api/accounts/${this.session.getSession().accountId}/password`;

        return this.http.post(url, { oldPassword, newPassword }, { headers });
    }

    public updatePasswordForAnotherUser(userId, body, access_token?: string): Observable<any> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${access_token || this.session.token.access_token}`,
            'Accept-Language': this.languageService.getCurrLanguage(),
            'X-Role': 'doctor',
        });

        const url = `${environment.apiUrl}/api/accounts/${userId}/password-without-check`;

        return this.http.post(url, body, { headers, observe: 'response' }).pipe(catchError(AuthorizationService.handleError));
    }

    // public logout(): Observable<any> {
    //   // let headers = new HttpHeaders({
    //   //   "Content-Type":"application/json",
    //   //   "Authorization":`Bearer: ${this.session.token}`,
    //   // }).set(globals.SKIP_HEADER, '');
    //
    //   return this.http.delete(this.api.invalidateSession())
    //     .pipe(catchError(AuthorizationService.handleError));
    // }

    public forgotPassword(
        body: { user: string; byPhoneNumber?: boolean } = { user: '', byPhoneNumber: false }
    ): Observable<HttpResponse<any>> {
        const link = `${environment.apiUrl}/api/accounts/forgot-password`;

        return this.http
            .post<HttpResponse<any>>(link, body, { observe: 'response' })
            .pipe(catchError(this.handleForgotError));
    }

    public confirmNewPassword(
        body: { confirmationCode: string; email: string; newPassword: string; } = { confirmationCode: '', email: '', newPassword: '' }
    ): Observable<HttpResponse<any>> {
        const link = `${environment.apiUrl}/api/accounts/confirm-new-password`;

        return this.http
            .post<HttpResponse<any>>(link, body, { observe: 'response' })
            .pipe(catchError(this.handleForgotError));
    }

    public updatePasswordNew(body): Observable<HttpResponse<any>> {
        const link = `${environment.apiUrl}/api/accounts/password`;

        return this.http
            .post<HttpResponse<any>>(link, body, { observe: 'response' })
            .pipe(catchError(AuthorizationService.handleError));
    }

    public login(username, password): Observable<AuthToken> {
        return this.submitLogin({ username, password }).pipe(
            tap((res) => {
                this.session.token = res;
                this.session.setIsLoggedIn(true);
                localStorage.setItem('keycloak', JSON.stringify(res));
            })
        );
    }

    public loginWithOutSessionUpdate(username, password, isOrgNewPatiet: boolean, isProvider?: boolean): Observable<AuthToken> {
        return this.submitLogin({ username, password }).pipe(
            tap((res) => {
                if (isOrgNewPatiet && isOrgNewPatiet == true) {
                    // Do nothing.. continue with org session
                } else if (isProvider) {
                    // Do nothing for provider as well keep the provider session
                } else {
                    this.session.token = res;
                    this.session.setIsLoggedIn(true);
                    localStorage.setItem('keycloak', JSON.stringify(res));
                }
            })
        );
    }

    public setParams(param) {
        this.token = param.token;
        this.username = param.username;
    }

    public getParams() {
        return {
            token: this.token,
            username: this.username,
        };
    }

    logout() {
    // Hey Dima -- commented out below works on covid19.compositeapps.net
    // const url = environment.apiUrl + '/api/accounts/logout';
        const url = '/api/accounts/logout';

        return this.networkService.postResource(url, {}).pipe(
            tap(() => {
                const { isSSOLogin } = this.session;
                this.session.invalidateSession();
                this.dataBrokerService.unsubscribeAll();
                this.resetWebAppResources();
                this.assessmentsService.clean();
                this.router.navigate(['/login']).then();
            })
        );
    }

    public resetWebAppResources() {
        const webappLoginBg = localStorage.getItem('webappLoginBg') ? localStorage.getItem('webappLoginBg') : null;
        const webappLoginLogo = localStorage.getItem('webappLoginLogo')
            ? localStorage.getItem('webappLoginLogo')
            : './assets/app-logo.svg';
        const webappFavicon = localStorage.getItem('webappFavicon')
            ? localStorage.getItem('webappFavicon')
            : './assets/favicon_va.ico';
        const lang = localStorage.getItem('lang');
        const _arr = localStorage.getItem('testing_results');

        const patientGrid = localStorage.getItem('savePatientGroupGrid');
        const patientGridFilter = localStorage.getItem('savePatientGroupGridFilter');
        const clinicGrid = localStorage.getItem('saveClinicsGrid');
        const clinicsGridFilter = localStorage.getItem('saveClinicsGridFilter');
        const schoolGrid = localStorage.getItem('savePatientGroupGridSchool');
        const schoolGridFilter = localStorage.getItem('savePatientGroupGridFilterSchool');
        const facilityGrid = localStorage.getItem('savePatientGroupGridFacility');
        const facilityGridFilter = localStorage.getItem('savePatientGroupGridFilterFacility');
        const communityGrid = localStorage.getItem('savePatientGroupGridCommunity');
        const communityGridFilter = localStorage.getItem('savePatientGroupGridFilterCommunity');

        localStorage.clear();
        if (lang) localStorage.setItem('lang', lang);
        if (_arr) {
            localStorage.setItem('testing_results', _arr);
        }
        if (webappLoginBg) {
            localStorage.setItem('webappLoginBg', webappLoginBg);
        }
        localStorage.setItem('webappLoginLogo', webappLoginLogo);
        localStorage.setItem('webappFavicon', webappFavicon);

        localStorage.setItem('savePatientGroupGrid', patientGrid);
        localStorage.setItem('savePatientGroupGridFilter', patientGridFilter);
        localStorage.setItem('saveClinicsGrid', clinicGrid);
        localStorage.setItem('saveClinicsGridFilter', clinicsGridFilter);
        localStorage.setItem('savePatientGroupGridSchool', schoolGrid);
        localStorage.setItem('savePatientGroupGridFilterSchool', schoolGridFilter);
        localStorage.setItem('savePatientGroupGridCommunity', facilityGrid);
        localStorage.setItem('savePatientGroupGridFilterCommunity', facilityGridFilter);
        localStorage.setItem('savePatientGroupGridCommunity', communityGrid);
        localStorage.setItem('savePatientGroupGridFilterCommunity', communityGridFilter);
    }

    handleForgotError({ status, error }: HttpErrorResponse) {
        let customError: CustomHttpError = {
            status,
            statusText: 'Bad Request',
            message: error.message,
        };
        if (error.reason && error.reason.includes('not found')) {
            customError = {
                status: 404,
                statusText: 'Not Found',
                message: error.reason,
            };
        }

        return throwError(customError);
    }

    public saveSSOToken(authToken:AuthToken): void {
        this.saveAuthToken(authToken);
        this.session.isSSOLogin = true;
    }

    private saveAuthToken(authToken: AuthToken) {
        this.session.token = authToken;
        this.session.setIsLoggedIn(true);
        localStorage.setItem('keycloak', JSON.stringify(authToken));
    }

    isLogged():boolean {
        return this.session.loggedInStatus;
    }
    refreshToken():Observable<AuthToken> {
        if (this.session.loggedInStatus) {
            const keycloak = JSON.parse(localStorage.getItem('keycloak'));
            const refreshToken = keycloak
                ? keycloak.refresh_token
                : this.session && this.session.token
                    ? this.session.token.refresh_token
                    : null;

            return this.submitRefresh(refreshToken).pipe(
                tap((res) => {
                    this.session.token.access_token = res.access_token;
                    this.session.setIsLoggedIn(true);
                    const keycloak = JSON.parse(localStorage.getItem('keycloak'));
                    keycloak.access_token = res.access_token;
                    localStorage.setItem('keycloak', JSON.stringify(keycloak));
                })
            );
        }
    }
    public submitRefresh(refreshToken: string): Observable<AuthToken> {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.session.token.access_token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
        });
        const params = new HttpParams();
        params.set('refreshToken', refreshToken);
        const url = `${environment.apiUrl + ApiService.getRefreshToken()}?refreshToken=${refreshToken}`;
        return this.http
            .post<AuthToken>(url, null, { headers, params });
    }
}
