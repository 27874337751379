import {HttpParams} from '@angular/common/http';
import {EventEmitter, Injectable, Output} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';

import {
    CarePlanGoal,
    CarePlanResponse,
    CarePlanTask,
    CarePlanTaskGroup,
    CarePlanTemplates,
    NewCarePlan,
    PlanTemplate,
} from '../../models/careplan.model';
import {ResponseWithLinks} from '../../models/common.model';
import {Patient} from '../../models/patient.model';
import {TaskTypeModel} from '../../models/task-type';
import {DateUtility} from '../../utils/date-utility';
import {LinkUtil} from '../../utils/link-util/link-util.service';
import {DataBrokerService} from '../data-broker/data-broker.service';
import {ImageService} from '../image/image.service';
import {NetworkService} from '../network/network.service';

interface CarePlansModel {
    carePlanList: ResponseWithLinks<CarePlanTemplates>;
    carePlanTypeFilters: string[];
}

@Injectable({
    providedIn: 'root',
})
export class CarePlanService {
    @Output() patientBioData: EventEmitter<string> = new EventEmitter();
    @Output() patientComplianceData: EventEmitter<any> = new EventEmitter();
    @Output() carePlanProgressData: EventEmitter<any> = new EventEmitter();
    @Output() apptListIndex: EventEmitter<number> = new EventEmitter<any>();
    chartData: any;
    baseURL = '/api/carePlans';
    private carePlanOverviewData: NewCarePlan;

    constructor(
        private network: NetworkService,
        private linkUtil: LinkUtil,
        private dateUtil: DateUtility,
        private data: DataBrokerService,
        private imageService: ImageService,
    ) {
    }

    static populateGroupedTasks(carePlan: NewCarePlan): NewCarePlan {
        const { goals } = carePlan;

        if (!goals) return;

        goals.map((goal: CarePlanGoal) => {
            const { tasks } = goal;
            const groupedTasks = [];

            tasks.forEach((task) => {
                const { taskType, taskTypeId }: CarePlanTask = task;
                const groupIndex = groupedTasks.findIndex((group) => group.name === taskType);

                if (groupIndex >= 0) {
                    groupedTasks[groupIndex].tasks.push(task);
                } else {
                    let oldGroupedTasks;
                    if (goal.groupedTasks) {
                        oldGroupedTasks = goal.groupedTasks.find((gt) => gt.id === taskTypeId);
                    }

                    const taskGroup: CarePlanTaskGroup = {
                        name: taskType,
                        id: taskTypeId,
                        tasks: [task],
                        isDisplay: oldGroupedTasks ? oldGroupedTasks.isDisplay : null,
                    };

                    groupedTasks.push(taskGroup);
                }
            });
            goal.groupedTasks = groupedTasks;

            return goal;
        });

        return carePlan;
    }

    public setCarePlanOverview(careplan: NewCarePlan) {
        this.carePlanOverviewData = careplan;
    }

    public getCarePlanOverview<T>(carePlanUrl: string): Observable<T> {
        this.setCarePlanOverview(null);

        return this.network
            .fetchResource<T>(carePlanUrl)
            .pipe(mergeMap((carePlan) => this.imageService.addImage(carePlan, 'carePlanImage', 100)));
    }

    getPatientCount(carePlanId: number) {
        return this.network.fetchResource(`${this.baseURL}/${carePlanId}/patients/count`);
    }

    public removeCarePlan(carePlanId: string) {
        const link = `/api/carePlans/${carePlanId}`;

        return this.network.deleteResource(link);
    }

    public createTask(task: CarePlanTask): Observable<CarePlanTask> {
        const link = '/api/carePlans/task';

        return this.network
            .postResource<CarePlanTask>(link, task)
            .pipe(mergeMap((res) => this.imageService.addImage(res, 'image', 100)));
    }

    public completeTeamTask(taskId: string): Observable<CarePlanTask> {
        const link = `/api/tasks/${taskId}/complete`;

        return this.network.putResource(link, {});
    }

    public activateTeamTask(taskId: string): Observable<CarePlanTask> {
        const link = `/api/tasks/${taskId}/activate`;

        return this.network.putResource(link, {});
    }

    public updateTask(task: CarePlanTask): Observable<CarePlanTask> {
        const link = `/api/carePlans/task/${task.id}`;

        return this.network.putResource(link, task);
    }

    public removeTask(careplanId: string, taskId: string): Observable<CarePlanTask> {
        const link = `/api/carePlans/${careplanId}/task/${taskId}`;

        return this.network.deleteResource(link);
    }

    public getCarePlanTypes() {
        return this.network.fetchResource<any>('/api/carePlans/carePlanTypes');
    }

    public preparePlanForSaving(careplan) {
        for (const goal of careplan.goals) {
            if (goal.id && goal.id.startsWith('temp_id_')) {
                delete goal.id;
            }

            for (const task of goal.tasks) {
                delete task.goalId;
            }

            delete goal.groupedTasks;
        }
    }

    public populateUngroupedTasks(careplan, allowDeleteGoalAndTaskIdAndTherapyType = true) {
        // also save tasks into ungrouped tasks
        const isCreateCarePlan = !careplan.id && careplan.isTemplate && !careplan.usedTemplateId;
        const isOnboarding = careplan.id && careplan.isTemplate && careplan.usedTemplateId;

        for (const goal of careplan.goals) {
            if ((isCreateCarePlan || isOnboarding) && allowDeleteGoalAndTaskIdAndTherapyType) {
                delete goal.id; // necessary
            }

            // start fresh
            goal.tasks = [];
            let tasks = [];

            // grab all tasks from each grouped tasks
            if (goal.groupedTasks) {
                for (const group of goal.groupedTasks) {
                    tasks = tasks.concat(group.tasks.filter((t) => t.name));
                }
            }

            // add grabbed tasks to list
            goal.tasks = goal.tasks.concat(tasks);
        }

        // go through all tasks and edit them for saving
        for (const goal of careplan.goals) {
            if (goal.tasks && goal.tasks.length > 0) {
                for (const task of goal.tasks) {
                    delete task.image;

                    if ((isCreateCarePlan || isOnboarding) && allowDeleteGoalAndTaskIdAndTherapyType) {
                        delete task.id; // necessary
                    }

                    if (isOnboarding && task.therapyType) {
                        // For the therapies that we didn't modify, we need to set the used template id
                        if (!task.therapyType.usedTemplateId) {
                            task.therapyType.usedTemplateId = task.therapyTypeId;

                            delete task.therapyTypeId;
                        } else {
                            console.error('need an alternate case');
                        }
                        if (allowDeleteGoalAndTaskIdAndTherapyType){
                            task.therapyType.id = null; // don't send therapyId when onboarding patient
                        }
                    }

                    if (task.location) {
                        task.locationId = task.location.id;
                    }

                    if (task.observationGoals && task.observationGoals.length > 0) {
                        for (const observation of task.observationGoals) {
                            if (observation.id) {
                                observation.observationTypeId = parseInt(observation.observationType.id, 10);
                                if ((isCreateCarePlan || isOnboarding) && allowDeleteGoalAndTaskIdAndTherapyType) {
                                    //If it is a existing task than observation goals should also be existing. So, do not delete the id to update existing.
                                    delete observation.id; // necessary
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    public getTaskTypes(): Observable<TaskTypeModel[]> {
        return this.network.fetchResource<TaskTypeModel[]>('/api/meta/task');
    }

    public getTherapyTypesImage(therapyTypes: any[]) {
        const therapyTypesWithImage = therapyTypes.map((tT) => {
            const link = this.linkUtil.parseLink(tT.links, 'image');

            if (link) {
                return this.imageService.getImageNew(link, 100).pipe(
                    map((image) => ({ ...tT, image: image || '' })),
                    catchError(() => of({ ...tT, image: '' })),
                );
            }
            return of({ ...tT, image: '' });
        });

        return forkJoin(therapyTypesWithImage);
    }

    public getCarePlanList(performerId: string): Observable<CarePlansModel> {
        const link = `/api/performers/${performerId}/care-plans`;
        return this.network.fetchResource(link);
    }

    public getCarePlanByUrl(
        link,
        {
            page = 0, size = 20, orderType = '', asc = false, name = '',
        } = {},
    ): Observable<CarePlanResponse> {
        let params = new HttpParams()
            .set('page', page.toString())
            .set('size', size.toString())
            .set('orderType', orderType.toString())
            .set('asc', asc.toString());

        if (name) {
            params = params.append('name', name);
        }

        return this.network.fetchResource(link, { params });
    }

    public getCarePlan(carePlanId: string, withoutImage?: boolean): Observable<NewCarePlan> {
        const link = `/api/carePlans/${carePlanId}`;

        if (withoutImage) {
            return this.network.fetchResource(link);
        }

        return this.network
            .fetchResource(link)
            .pipe(mergeMap((carePlan) => this.imageService.addImage(carePlan, 'carePlanImage', 100)));
    }

    // NEW
    getCarePlanWithImage(patientId: string = null): Observable<NewCarePlan> {
        const link = `/api/carePlans/${patientId}`;

        return this.network.fetchResource(link);
    }

    getPatientCarePlansByServiceTypeId(serviceTypeId: string, patientId: string) {
        const link = `/api/carePlans/${serviceTypeId}/serviceId?patientId=${patientId}`;
        return this.network.fetchResource<NewCarePlan>(link);
    }

    getCarePlanTemplates(): Observable<ResponseWithLinks<PlanTemplate | { name: string }>> {
        const link = '/api/carePlans/templates';

        return this.network.fetchResource(link);
    }

    patientCarePlanFromTemplate(body): Observable<NewCarePlan> {
        const url = '/api/carePlans/patient-care-plan-from-template';

        return this.network.postResource(url, body);
    }

    setCarePlanStatus(carePlanId: string, body): Observable<string> {
        const url = `/api/carePlans/${carePlanId}/care-plan-status`;

        return this.network.postResource(url, body);
    }

    addObservation(body) {
        const url = '/api/observations';

        return this.network.postResource(url, body);
    }

    getPublicTemplateCarePlans({
        page = 0,
        size = 20,
        orderType = 'name',
        asc = null,
        name = null,
        carePlanType = null,
        serviceTypeId = null,
        stats = null,
    }): Observable<CarePlanResponse> {
        const url = '/api/carePlans/public-templates';
        let params = new HttpParams();

        if (page && page >= 0) params = params.set('page', page.toString());
        if (size) params = params.set('size', size.toString());
        if (orderType) params = params.set('orderType', orderType.toString());
        if (asc !== null) params = params.set('asc', asc.toString());
        if (name) params = params.set('name', name);
        if (carePlanType) params = params.set('carePlanType', carePlanType);
        if (serviceTypeId) params = params.set('serviceTypeId', serviceTypeId);
        if (stats !== null) params = params.set('stats', stats.toString());

        return this.network.fetchResource(url, { params });
    }

    getObservationType() {
        const url = '/api/meta/observation';
        return this.network.fetchResource(url);
    }

    updateObservationType(body): Observable<any> {
        const url = '/api/carePlans/task/observation-goal';
        return this.network.postResource(url, body);
    }

    getPatientsOnPlanStats(planId: string): Observable<Patient[]> {
        const url = `${this.baseURL}/${planId}/patients-on-plan-stats`;

        return this.network.fetchResource(url);
    }

    updateAllUsersCarePlan(carePlanId: string) {
        const url = `${this.baseURL}/${carePlanId}/patients/updateExistingPatientCarePlan`;
        return this.network.fetchResource(url);
    }
}
