import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';

import { NetworkService } from '../network/network.service';
import { IProgramPhaseItem, IProgramPhaseItemRequest } from './program-phase-item.interface';

@Injectable({
    providedIn: 'root',
})
export class ProgramPhaseItemService {
    private baseURL = '/api/program-phase-item';

    constructor(private _networkService: NetworkService) {}

    /* Create a new phase item */
    create(programPhaseItem: IProgramPhaseItemRequest): Observable<IProgramPhaseItem> {
        return this._networkService.postResource(`${this.baseURL}`, programPhaseItem);
    }

    /* Get a specific phase item given you have its id */
    get(programPhaseItemId: string): Promise<IProgramPhaseItem> {
        return firstValueFrom<IProgramPhaseItem>(
            this._networkService
                .fetchResource<IProgramPhaseItem>(`${this.baseURL}/${programPhaseItemId}`)
        );
    }

    /* update a program phase Item given an object that fits IProgramPhaseItem interface */
    update(programPhaseItemId: string, programPhaseItem: IProgramPhaseItemRequest): Observable<IProgramPhaseItem> {
        return this._networkService.putResource<IProgramPhaseItem>(
            `${this.baseURL}/${programPhaseItemId}`,
            programPhaseItem,
        );
    }

    /* delete a program phase item given its id */
    delete(programPhaseItemId: string): Observable<void> {
        return this._networkService.deleteResource(`${this.baseURL}/${programPhaseItemId}`, { responseType: 'text' });
    }
}
