import { Link } from '../models/link.model';

export class Base {
    id: string;
    links: Link[];

    constructor(options?: { id: string; links: Link[] }) {
        this.id = options.id ? options.id : '';
        this.links = options.links ? options.links : [];
    }
}
