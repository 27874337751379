import { Injectable } from '@angular/core';
import {
    BehaviorSubject, map, Observable, of, tap
} from 'rxjs';

import { NetworkService } from '../network/network.service';
import { Product } from './product.class';
import { IProduct, IProductRequest } from './products.interface';

@Injectable({
    providedIn: 'root',
})
export class ProductsService {
    private baseURL = '/api/products';

    private cacheAllProduct = new BehaviorSubject<Product[]>([]);
    private cachePerProduct = new Map<string, Product>();

    constructor(private networkService: NetworkService) {}

    /* return a list of all available products */
    getAllProducts(fetchFresh?: boolean): Observable<Product[]> {
        if (!fetchFresh && this.cacheAllProduct.value.length) return this.cacheAllProduct; /** return pre-cached value */
        return this.networkService
            .fetchResource<IProduct[]>('/api/products') /** fetch fresh */
            .pipe(this.convertIProductsToProductsClass())
            .pipe(
                tap((products) => {
                    this.cacheAllProduct.next(products);
                })
            );
    }

    /* Get a specific product using an ID. */
    getProduct(productId: string, fetchFresh?: boolean): Observable<Product> {
        const cachedValue = this.getCached(productId);
        if (!fetchFresh && cachedValue) return of(cachedValue);
        return this.networkService
            .fetchResource<IProduct>(`${this.baseURL}/${productId}`)
            .pipe(this.convertIProductToProductClass())
            .pipe(tap((product) => this.cachePerProduct.set(product.id, product)));
    }

    /* Create a new product */
    createProduct(productRequest: IProductRequest): Observable<Product> {
        return this.networkService
            .postResource<IProduct>(this.baseURL, productRequest)
            .pipe(this.convertIProductToProductClass());
    }

    /* Update existing product using id */
    updateProduct(productRequest: IProduct) {
        return this.networkService.putResource<IProduct>(`${this.baseURL}/${productRequest.id}`, productRequest);
    }

    /* Update existing product using id */
    deleteProduct(productId): Observable<any> {
        return this.networkService.deleteResource<void>(`${this.baseURL}/${productId}`, productId);
    }

    /* Internal Function: map IProduct to class of Product */
    private convertIProductToProductClass() {
        return map((product: IProduct) => new Product(product));
    }

    /* Internal Function: map IProducts Array to class of Products Array */
    private convertIProductsToProductsClass() {
        return map((products: IProduct[]) => products.map((product: IProduct) => new Product(product)));
    }

    /* Internal Function: check cache to see if the product was already pulled */
    private getCached(productId: string) {
        return (
            this.cachePerProduct.get(productId)
      || this.cacheAllProduct.getValue().filter((product) => product.id === productId)[0]
        );
    }
}
