import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs/operators';

import { VersionCheckDialogComponent } from '../../../shared/components/version-check-dialog/version-check-dialog.component';

@Injectable({ providedIn: 'root' })
export class VersionCheckService {
    // this will be replaced by actual hash post-build.cjs
    public currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

    /**
     * Checks if hash has changed.
     * This file has the JS hash, if it is a different one than in the version.json
     * we are dealing with version change
     * @param currentHash
     * @param newHash
     * @returns {boolean}
     */
    static hasHashChanged(currentHash, newHash) {
        if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
            return false;
        }

        return currentHash !== newHash;
    }

    constructor(
        private http: HttpClient,
        public dialog: MatDialog,
    ) {}

    /**
     * Checks in every set frequency the version of frontend application
     * @param url
     */
    public initVersionCheck(url: string): void {
        this.checkVersion(url);
    }

    /**
     * Will do the call and check if the hash has changed or not
     * @param {string} url
     */
    private checkVersion(url: string) {
        // timestamp these requests to invalidate caches
        this.http
            .get(`${ url }?t=${ new Date().getTime() }`)
            .pipe(first())
            .subscribe(
                (response: any) => {
                    const { hash } = response;
                    const hashChanged = VersionCheckService.hasHashChanged(this.currentHash, hash);

                    // If new version, do something
                    if (hashChanged) {
                        this.openDialog();
                    }
                },
                (error) => {
                    console.error(error, 'Could not get version');
                },
            );
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(VersionCheckDialogComponent, {
            width: '250px',
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe((data) => {
            if (data.ok) {
                location.reload();
            }
        });
    }
}
