import { Observable } from 'rxjs';
import { InjectorInstance } from 'src/app/app.module';
import { Base } from 'src/app/core/classes/base.class';

import { ProgramPhaseItems } from '../phase-item/program-phase-items.class';
import { Program } from '../programs/program.class';
import { IProgramPhase } from './program-phase.interface';
import { ProgramPhasesService } from './program-phases.service';

export class ProgramPhase extends Base {
    private programPhaseService = InjectorInstance.get(ProgramPhasesService);

    private _phaseData: IProgramPhase;
    private _phaseItems: ProgramPhaseItems;
    private _parentProgram: Program;

    constructor(phase: IProgramPhase, parentProgram: Program) {
        super({ id: phase.id, links: [] });
        this.setData(phase, parentProgram);
    }

    get data(): IProgramPhase {
        return this._phaseData;
    }

    /** Internal Function: set data for a given phase. */
    private setData(phase: IProgramPhase, parentProgram: Program) {
        this._phaseData = phase;
        this._phaseItems = new ProgramPhaseItems(this);
        this._parentProgram = parentProgram;
    }

    refreshData(): Observable<IProgramPhase> {
        return this.programPhaseService.getProgramPhaseById(this._phaseData.id);
    }

    /* reloads the data from server */
    refresh(): Promise<IProgramPhase> {
        return this.programPhaseService.get(this._phaseData.id).then((data: IProgramPhase) => {
            this._phaseData = data;
            return data;
        });
    }

    /* update this phase class with new data. */
    update(data: IProgramPhase): Promise<IProgramPhase> {
        return this.programPhaseService.update(data).then((res) => {
            this._phaseData = res;
            return res;
        });
    }

    /* Deletes this phase class */
    delete(): Promise<void> {
        return this.programPhaseService.delete(this._phaseData.id).then(() => {
            // remove phase from parent program.
            this._parentProgram.data.programPhases.forEach((phase, index) => {
                if (phase.id === this._phaseData.id) {
                    this._parentProgram.data.programPhases.splice(index, 1);
                    this._parentProgram.programPhases().updatePhases(this._parentProgram);
                }
            });
        });
    }

    /* Get all phase items contained with-in this phase */
    get phaseItems() {
        return this._phaseItems;
    }
}
