import { AddressModel } from '../models/address.model';
import { Base } from './base.class';

export class Address extends Base {
    locationType?: string;
    name?: string;
    dateCreated?: number;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipcode: number;
    gpsLatitude?: number;
    gpsLongitude?: number;
    streetNumber?: any;
    route?: any;
    country?: string;

    constructor({ id, links, ...option }: AddressModel = {}) {
        super({ id, links });
        this.locationType = option.locationType || '';
        this.name = option.name || '';
        this.dateCreated = option.dateCreated || null;
        this.address1 = option.address1 || '';
        this.address2 = option.address2 || '';
        this.city = option.city || '';
        this.state = option.state || '';
        this.zipcode = option.zipcode || null;
        this.gpsLatitude = option.gpsLatitude || null;
        this.gpsLongitude = option.gpsLongitude || null;
        this.streetNumber = option.streetNumber || '';
        this.route = option.route || '';
        this.country = option.country || '';
    }

    toString() {
        const address1Str = this.address1 ? ` ${this.address1}` : '';
        const address2Str = this.address2 ? ` ${this.address2}` : '';
        const cityStr = this.city ? `, ${this.city}` : '';
        const stateStr = this.state ? `, ${this.state}` : '';
        const zipcodeStr = this.zipcode ? `, ${this.zipcode}` : '';
        const countryStr = this.country ? `, ${this.country}` : '';

        return `${address1Str}${address2Str}${cityStr}${stateStr}${zipcodeStr}${countryStr}`.trim();
    }

    hasGeo() {
        return this.gpsLongitude && this.gpsLatitude;
    }

    format(format: string): string {
        if (typeof format !== 'string') throw new Error('Not a string');
        const fields = format.match(/\b(\w+)\b/g);

        fields.forEach((field) => {
            format = format.replace(field, this[field] ? this[field] : '');
        });

        return format;
    }
}
