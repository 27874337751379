import { Injectable } from '@angular/core';
import {
    MatSnackBar,
    MatSnackBarConfig,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

import { SnackbarComponent } from '../../components/snackbar/snackbar.component';

interface ISnack {
    message: string;
    config: MatSnackBarConfig;
    type: 'ERROR' | 'WARN' | 'INFO' | 'NOTIFY';
}

@Injectable({ providedIn: 'root' })
export class SnackbarService {
    messageQueue: ISnack[] = [];
    isDisplayingMessage = false;

    startHorizontalPosition: MatSnackBarHorizontalPosition = 'right';
    topVerticalPosition: MatSnackBarVerticalPosition = 'top';
    private snackBarConf: MatSnackBarConfig = {
        duration: 0,
        horizontalPosition: this.startHorizontalPosition,
        verticalPosition: this.topVerticalPosition,
    };

    constructor(private _snackBar: MatSnackBar) {}

    notify(message: string, duration = 6000) {
        const config: MatSnackBarConfig = { ...this.snackBarConf, duration, panelClass: 'app-notification-success' };

        if (message) {
            this.addMessageToQueue({ message, config, type: 'NOTIFY' });
        }
    }

    warn(message: string, duration = 6000) {
        const config: MatSnackBarConfig = { ...this.snackBarConf, duration, panelClass: 'app-notification-warn' };

        if (message) {
            this.addMessageToQueue({ message, config, type: 'WARN' });
        }
    }

    info(message: string, duration = 6000) {
        const config: MatSnackBarConfig = { ...this.snackBarConf, duration, panelClass: 'app-notification-info' };

        if (message) {
            this.addMessageToQueue({ message, config, type: 'INFO' });
        }
    }

    error(message: string, duration = 6000) {
        const config: MatSnackBarConfig = { ...this.snackBarConf, duration, panelClass: 'app-notification-error' };

        if (message) {
            this.addMessageToQueue({ message, config, type: 'ERROR' });
        }
    }

    private addMessageToQueue(snack: ISnack) {
        this.messageQueue.push(snack);
        this.showMessage();
    }

    private showMessage() {
        if (this.isDisplayingMessage) return;

        const snack = this.messageQueue.shift();

        if (snack) {
            this.isDisplayingMessage = true;
            const snackbar = this._snackBar.openFromComponent(SnackbarComponent, {
                ...snack.config,
                data: { ...snack },
            });

            snackbar.afterDismissed().subscribe(() => {
                this.isDisplayingMessage = false;
                this.showMessage();
            });
        }
    }
}
