import { Injectable } from '@angular/core';
import {
    forkJoin, Observable, Observer, of as observableOf
} from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { LinkUtil } from '../../utils/link-util/link-util.service';
import { HelperService } from '../helper/helper.service';
import { NetworkService } from '../network/network.service';

@Injectable({
    providedIn: 'root',
})
export class ImageService {
    constructor(private network: NetworkService, private linkUtil: LinkUtil, private _helperService: HelperService) {}

    public getImageNew(url: string, width = 100) {
        if (!url || url == '') return observableOf(null);
        const newUrl = this._helperService.updateQueryParam(url, 'width', width.toString());

        return this.network.getImage(newUrl).pipe(
            mergeMap((blob) => {
                if (!blob) return observableOf(null);
                return this.readFile(blob);
            })
        );
    }

    public getImageWithoutCredential(url: string, width?: number) {
        const newUrl = this._helperService.updateQueryParam(url, 'width', width.toString());

        return this.network.getImageWithoutCredential(newUrl).pipe(
            mergeMap((blob) => {
                if (!blob) return observableOf(null);
                return this.readFile(blob);
            })
        );
    }

    readFile(blob) {
        return new Observable((observer: Observer<any>) => {
            if (!(blob instanceof Blob)) {
                observer.error(new Error('`blob` must be an instance of File or Blob.'));
                return;
            }

            const reader = new FileReader();

            reader.onerror = (err) => observer.error(err);
            reader.onabort = (err) => observer.error(err);
            reader.onload = () => observer.next(reader.result);
            reader.onloadend = () => observer.complete();

            return reader.readAsDataURL(blob);
        });
    }

    public getImage(url: string, width?: number): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const newUrl = this._helperService.updateQueryParam(url, 'width', width ? width.toString() : '');

            if (!url) {
                reject('no image url provided');
            }

            this.network.getImage(newUrl).subscribe(
                (blob) => {
                    if (blob instanceof Blob) {
                        this.createImageFromBlob(blob as Blob).then((base64Img) => {
                            resolve(base64Img);
                        });
                    } else {
                        resolve(blob);
                    }
                },
                (error) => reject(error)
            );
        });
    }

    public createImageFromBlob(image: Blob): Promise<string> {
        return new Promise<string>((resolve) => {
            const reader = new FileReader();

            reader.addEventListener(
                'load',
                (readerListener: any) => {
                    const csv: string | ArrayBuffer = readerListener.target.result;

                    if (typeof csv === 'string') {
                        resolve(csv);
                    } else {
                        resolve(csv.toString());
                    }
                },
                false
            );

            if (image) {
                reader.readAsDataURL(image);
            }
        });
    }

    // converts an image from assets into base64Img
    // takes in directory path to the image (e.g './assets/main/careplan/careplan-no-image.png')
    // will not work with svg
    public convertAssetToImage(url, callback) {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
            const reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            };
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    public postImage(type: string, id: string, image: string): Observable<any> {
        const link = `/api/${type}/${id}/picture`;

        return this.network.postResource(link, { data: image });
    }

    /**
   * Add image to observable element or array
   * @param data {{any}}
   * @param fieldName {{string}}
   * @param width {{number}}
   * @param renameField {{number}}
   */
    public addImage(data, fieldName: string = null, width: number = 100, renameField: string = null): Observable<any> {
        const getLink = (el) => (typeof el[fieldName] === 'string' ? el[fieldName] : this.linkUtil.parseLink(el.links, fieldName));

        fieldName = renameField || fieldName;

        if (Array.isArray(data)) {
            if (!data.length) return observableOf([]);

            const elements: Observable<any>[] = data.map((el) => {
                const link = getLink(el);

                if (link) {
                    return this.getImageNew(link, width).pipe(
                        map((image: string) => ({ ...el, [fieldName]: image })),
                        catchError(() => observableOf({ ...el, [fieldName]: '' }))
                    );
                }

                return observableOf({ ...el, [fieldName]: '' });
            });

            return forkJoin(elements);
        }
        const link = getLink(data);

        if (!link || link == '') {
            return observableOf({ ...data, [fieldName]: '' });
        }

        return this.getImageNew(link, width).pipe(
            map((image: string) => ({ ...data, [fieldName]: image })),
            catchError(() => observableOf({ ...data, [fieldName]: '' }))
        );
    }
}
