import { Injectable } from '@angular/core';
import {
    addMinutes,
    differenceInCalendarDays,
    format,
    getMinutes,
    parse,
    setHours,
    setMinutes,
    startOfDay,
} from 'date-fns';
import {
    formatInTimeZone, getTimezoneOffset, utcToZonedTime, zonedTimeToUtc
} from 'date-fns-tz';
import moment, { Moment } from 'moment';

import globals from '../../../config';
import config from '../../../config';

@Injectable({
    providedIn: 'root',
})
export class DateUtility {
    public today: Moment = moment().startOf('day');
    public static timesArray = [
        { title: 'Eastern Time(New_York)', value: 'America/New_York' },
        { title: 'Central Time(Chicago)', value: 'America/Chicago' },
        { title: 'Mountain Time(Phoenix)', value: 'America/Phoenix' },
        { title: 'Pacific Time(Los_Angeles)', value: 'America/Los_Angeles' },
    ];
    static exceptedTimeZones: string[] = [
        'America/New_York',
        'America/Chicago',
        'America/Phoenix',
        'America/Los_Angeles',
    ];

    constructor() {
        moment.updateLocale('en', {
            relativeTime: {
                future: '%s',
                past(s) {
                    if (s !== 'just now') {
                        return `${s} ago`;
                    }
                    return s;
                },
                s: 'just now',
                ss: '%d seconds',
                m: 'a minute',
                mm: '%d minutes',
                h: 'an hour',
                hh: '%d hours',
                d: 'a day',
                dd: '%d days',
                M: 'a month',
                MM: '%d months',
                y: 'a year',
                yy: '%d years',
            },
        });
    }

    static timeRange(start = '8:00', end = '16:30', duration = 15, isTwentyFour = false): string[] {
        const startDay = parse(start, 'HH:mm', new Date());
        const endDay = parse(end, 'HH:mm', new Date());
        let day = startDay;
        const formatStr = isTwentyFour ? 'HH:mm' : 'hh:mm a';
        const result: string[] = endDay > startDay ? [format(day, formatStr)] : [];

        while (day.getTime() < endDay.getTime()) {
            day = addMinutes(day, duration);
            result.push(format(day, formatStr));
        }

        return result;
    }

    static timeSlots(startDate: Date | number, endDate: Date | number, interval: number): Date[] {
        const isMultiples = (v, i) => v === 0 || (v >= i && v % i === 0);
        let start = startDate;
        let end = endDate;
        const minutesStart = getMinutes(start);
        const isStartMultiple = isMultiples(minutesStart, interval);

        if (!isStartMultiple) {
            const startLess = minutesStart < interval;
            start = setMinutes(
                startDate,
                startLess ? minutesStart + (interval % minutesStart) : minutesStart - (minutesStart % interval) + interval,
            );
        }

        const minutesEnd = getMinutes(endDate);
        const isEndMultiple = isMultiples(minutesEnd, interval);
        if (!isEndMultiple) {
            end = setMinutes(endDate, minutesEnd - (minutesEnd % interval));
        }

        const slots = [];

        for (let i = typeof start === 'number' ? new Date(start) : start; i < end; i = addMinutes(i, interval)) {
            slots.push(i);
        }

        return slots;
    }

    /**
   * gen time list
   * @param start start time
   * @param end end time
   * @param duration minutes
   */
    static genTimeList(start = 8, end = 16.5, duration = 15): Date[] {
        const startTime = setHours(startOfDay(new Date()), start);
        let curr = start;
        const timeList: Date[] = [startTime];
        let currTime = startTime;

        while (curr < end) {
            currTime = addMinutes(currTime, duration);
            timeList.push(currTime);
            curr += duration / 60;
        }

        return timeList;
    }

    static getMonthByDate(
        date: Date,
        formatType: string = 'YYYY-MM-DD',
    ): { startOfMonth: Date | string; endOfMonth: Date | string } {
        const startOf = moment(date).startOf('month');
        const endOf = moment(date).endOf('month');
        const startOfMonth = formatType ? startOf.format(formatType) : startOf.toDate();
        const endOfMonth = formatType ? endOf.format(formatType) : endOf.toDate();

        return {
            startOfMonth,
            endOfMonth,
        };
    }

    /**
   * Return time in iso8601 format
   * @param fullTime {string}
   */
    static timeInIso8601(fullTime: string = '00:00 AM'): string {
        const [time, timeOfDay] = fullTime.split(' ');

        if (timeOfDay === 'PM') {
            const [hour, minutes] = time.split(':');

            if (+hour !== 12) {
                return `${+hour + 12}:${minutes}`;
            }
        }

        return time;
    }

    public getDateDifferenceFromToday(date: any): number {
        if (typeof date === typeof 0) {
            date = new Date(date);
        }

        const diff = moment.duration(moment(this.today).diff(date));
        return Math.ceil(diff.asDays());
    }

    public getDateDifferenceFromTodayTimeZone(dateInput: string | number | Date, timezone: string) {
        if (typeof timezone !== 'string') {
            throw new Error('Invalid timezone');
        }
        const today = new Date();
        const zonedToday = utcToZonedTime(today, timezone);

        let validDate: string | number | Date;
        if (typeof dateInput === 'number' || dateInput instanceof Date || typeof dateInput === 'string') {
            validDate = new Date(dateInput);
        } else {
            throw new Error('Invalid date input');
        }

        if (isNaN(validDate.getTime())) {
            throw new Error('Invalid date input');
        }
        const zonedDate = utcToZonedTime(validDate, timezone);
        const diff = differenceInCalendarDays(zonedDate, zonedToday);
        return Math.ceil(diff);
    }

    /** Get Date Difference
   * Takes in 2 argument(s).
   *
   * @param {Date, Moment} date1 starting date.
   * @param {Date, Moment} date2 due date.
   *
   * @return {number} returns the days between now and the input date
   */
    public getDateDifference(date1: any, date2: any): number {
        if (typeof date1 === typeof 0) {
            date1 = new Date(date1);
        }

        if (typeof date2 === typeof 0) {
            date2 = new Date(date2);
        }

        return moment.duration(moment(date2).diff(date1)).asDays();
    }

    public getToday(): Moment {
        return this.today;
    }

    public formatDate(date: Date, formatType: string = 'MM/DD/YYYY', locale: string = 'en'): string {
        if (date) {
            if (locale) {
                return moment(date).locale(locale).format(formatType);
            }
            return moment(date).format(formatType);
        }
        return 'MM/DD/YY';
    }

    public convertToTimestamp(date: any): any {
        const type = typeof date;

        switch (type) {
            case typeof new Date():
                return moment(date).toDate().getTime();
            case typeof 0:
                return moment(new Date(date)).toDate().getTime();
            case typeof '':
                return moment(new Date(date)).toDate().getTime();
        }
    }

    public convertToMoment(date: any): Moment {
        const type = typeof date;
        switch (type) {
            case typeof new Date():
                return moment(date);
            case typeof 0:
                return moment(date);
            case typeof '':
                return moment(new Date(date));
        }
    }

    public getTimeZoneOffSetByTimeZoneId(date, timeZoneId) {
        const offset = getTimezoneOffset(timeZoneId, new Date(date));
        const sign = offset > 0 ? '-' : '+';
        const absOffset = Math.abs(offset);
        const hours = Math.floor(absOffset / 3600000);
        const minutes = Math.floor((absOffset % 3600000) / 60000);
        return `${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    }

    public getTimeZoneDisplayName(date, timeZoneId) {
        const d = zonedTimeToUtc(date, timeZoneId);
        return new Intl.DateTimeFormat('en-us', { timeZoneName: 'short' })
            .formatToParts(d)
            .find((part) => part.type == 'timeZoneName')
            .value;
    }

    public getTimeFromTimestamp(date: number): string {
        if (date) {
            return this.convertToMoment(date).format('hh:mm a');
        }
        return '';
    }

    public getTimeZone(): string {
        const date = new Date();
        const offsetInHours = date.getTimezoneOffset() / 60;

        if (offsetInHours > 0) {
            return `GMT-${offsetInHours}`;
        } if (offsetInHours === 0) {
            return 'GMT';
        }
        return `GMT+${offsetInHours * -1}`;
    }

    public getFrequencyDays(therapyType) {
        if (therapyType.weekDays && [globals.TASKTYPES.MEDICATION].indexOf(therapyType.taskTypeId) >= 0) {
            if (therapyType.weekDays.length < 7) {
                return therapyType.weekDays.map((wd) => globals.DAYSOFWEEK[wd]).join(',');
            }
            return 'Everyday';
        } if ([globals.TASKTYPES.ASSESSMENT].indexOf(therapyType.taskTypeId) >= 0) {
            return therapyType.weekly === 7 ? 'Everyday' : therapyType.weekly;
        }
        return '-';
    }

    public getFrequency(task) {
        let frequency = '';
        const { therapyType } = task;

        if ([globals.TASKTYPES.EXERCISE, globals.TASKTYPES.FOOD].indexOf(therapyType.taskTypeId) >= 0) {
            if (therapyType.weekly) {
                frequency += `D${therapyType.weekly}`;
            }
            if (therapyType.daily) {
                frequency += `/T${therapyType.daily}`;
            }
            if (therapyType.eachTime) {
                frequency += `/S${therapyType.eachTime}`;
            }
            if (therapyType.eachSet) {
                frequency += `/R${therapyType.eachSet}`;
            }
        } else if ([globals.TASKTYPES.MEDICATION, globals.TASKTYPES.ASSESSMENT].indexOf(therapyType.taskTypeId) >= 0) {
            frequency = this.getFrequencyDays(therapyType);
        } else if ([globals.TASKTYPES.APPOINTMENT].indexOf(therapyType.taskTypeId) >= 0) {
            frequency = `${this.formatDate(task['dueDate'])} at ${this.getTimeFromTimestamp(task['dueDate'])}`;
        }

        return frequency;
    }

    static fetchTimePassedByTimestamp(timestamp: number, withoutSuffix = false): string {
        const date = new Date(timestamp);
        return moment(date).fromNow(withoutSuffix);
    }

    // new
    public newChangeDate(direction, date): Moment {
        const hash = {
            previous: 'subtract',
            next: 'add',
        };

        return hash[direction] ? moment(date)[hash[direction]](1, 'day') : this.today;
    }

    public checkIfToday(date) {
        return moment(date).startOf('day').diff(this.today, 'days') === 0;
    }

    public checkIfYesterday(date) {
        return moment(date).startOf('day').diff(this.today, 'days') === 1;
    }

    public getWeekInfo(offset: number) {
        offset *= 7;
        const weekStart = moment().startOf('week').add(offset, 'day');
        const weekEnd = moment()
            .startOf('week')
            .add(offset + 6, 'day');

        return {
            startDate: weekStart,
            endDate: weekEnd,
        };
    }

    public getTimeZoneForUSA() {
        return config.US_TIMEZONES_WITH_TITLE;
    }

    public getUSTimes() {
        const timeZoneList = config.US_TIMEZONES;
        let timeZoneObjList = [];
        timeZoneObjList = timeZoneObjList.concat(DateUtility.timesArray);
        const filteredTimeZoneList = timeZoneList.filter((item) => DateUtility.exceptedTimeZones.indexOf(item) == -1);
        filteredTimeZoneList.forEach((timeZone) => {
            timeZoneObjList.push({
                value: timeZone,
                title: timeZone,
            });
        });
        return timeZoneObjList;
    }

    getDateFromEpochIncludingOffset(date: number, timeZoneID: string, formatType: string = 'MM/DD/YYYY hh:mm A z') {
        const d = new Date(date);
        const zonedTime = zonedTimeToUtc(d, timeZoneID);
        return format(zonedTime, formatType);
    }

    getCurrentDate() {
        return moment().toDate();
    }

    public static startOfWeek(date: Date) {
        const _date = moment(date);
        return _date.clone().startOf('week').toDate();
    }

    public static endOfWeek(date: Date) {
        const _date = moment(date);
        return _date.clone().endOf('week').toDate();
    }

    public dayOfWeek(_dayOfWeek) {
        const weekday = [];
        weekday[0] = 'SUN';
        weekday[1] = 'MON';
        weekday[2] = 'TUE';
        weekday[3] = 'WED';
        weekday[4] = 'THU';
        weekday[5] = 'FRI';
        weekday[6] = 'SAT';
        return weekday[_dayOfWeek];
    }

    public toAMPM(_hours) {
        const _h = _hours.split(':');
        let hours = _h[0];
        const suffix = hours >= 12 ? ' PM' : ' AM';
        hours = hours > 12 ? hours - 12 : hours;

        hours = hours == '00' ? 12 : hours;
        return `${hours}:${_h[1]}${suffix}`;
    }

    public getDatesOfWeek(date: Date) {
        const dates = [];
        const _date = moment(date);
        const startDate = _date.clone().startOf('week').toDate();
        const endDate = _date.clone().endOf('week').toDate();
        const currDate = moment(startDate).startOf('day');
        const lastDate = moment(endDate).startOf('day');
        dates.push(currDate.clone().toDate());
        while (currDate.add(1, 'days').diff(lastDate) <= 0) {
            dates.push(currDate.clone().toDate());
        }
        return dates;
    }

    public dateDiff(date1: Date, date2: Date) {
        const _m_date1 = moment(date1);
        const _date1 = moment(_m_date1.clone()).startOf('day');
        const _m_date2 = moment(date2);
        const _date2 = moment(_m_date2.clone()).startOf('day');

        return moment.duration(moment(_date2).diff(_date1)).asDays();
    }

    public startOfDay(date) {
        const _d = moment(date);
        return moment(_d.clone()).startOf('day').toDate();
    }

    public timeZoneDisplay(timeZoneName: string) {
        return this.getTimeZoneDisplayName(new Date(), timeZoneName);
    }

    public isSameDayInClinicTimezone(a, b, timezone): boolean {
        return (
            formatInTimeZone(a, timezone, 'yyy-MM-dd')
      === formatInTimeZone(b, timezone, 'yyy-MM-dd')
        );
    }

    static convertTZ(date: Date | string | number, timeZone: string) {
        return new Date((typeof date === 'string' || typeof date === 'number' ? new Date(date) : date).toLocaleString('en-US', {timeZone}));
    }

    static isToday(date: Date) {
      return   moment(date).isSame(Date.now(), 'day');
    }
}
