import { BehaviorSubject } from 'rxjs';
import { InjectorInstance } from 'src/app/app.module';
import { Base } from 'src/app/core/classes/base.class';

import { IProgramPhaseItem } from './program-phase-item.interface';
import { ProgramPhaseItemService } from './program-phase-item.service';

export class ProgramPhaseItem extends Base {
    private programPhaseItemService = InjectorInstance.get(ProgramPhaseItemService);

    /** Current Phase Initial Data */
    private _phaseData = new BehaviorSubject<IProgramPhaseItem>(undefined);

    constructor(phase: IProgramPhaseItem) {
        super({ id: phase.itemEntityId, links: [] });
        this._phaseData.next(phase);
    }

    /** get data of the given phase */
    get(): BehaviorSubject<IProgramPhaseItem> {
        return this._phaseData;
    }

    /** re-get the data from the server for given phase item */
    refresh() {
        return this.programPhaseItemService.get(this._phaseData.value.id).then((item) => {
            this._phaseData.next(item);
            return item;
        });
    }

    /** update a given phase item with new data */
    update(programPhaseItem: IProgramPhaseItem) {
        return this.programPhaseItemService.update(this._phaseData.value.id, programPhaseItem);
    }

    /** deleted the selected phase item */
    delete() {
        return this.programPhaseItemService.delete(this._phaseData.value.id);
    }
}
