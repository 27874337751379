import { BranchModel } from '../models/organization.model';
import { Performer } from '../models/performer.model';
import { DepartmentModel } from '../models/provider.model';
import { Address } from './address.class';
import { Base } from './base.class';

export class Branch extends Base {
    address: Address;
    departments: DepartmentModel[];
    description: string;
    isHeadquarter: boolean;
    name: string;
    patientCount: number;
    providerCount: number;
    compliancyScore: number;
    vaccinationCount: number;
    shortName: string;
    type: string;
    podType: string;
    isOpen: boolean;
    organizationAppointments: any[];
    _performers: Performer[];
    _stringPerformers: string;
    approvalStatus?: string;
    facilityDetails?: string;
    numOfPatients?: number;
    expectedTimeDuration?: number;
    haveFacility?: boolean;
    canRegisterIndividuals?: boolean;
    vaccine: string;
    applicationDate?: string;
    priority?: string;
    requestId?: string;
    childrenCommunities?: Branch[];
    inventoryType?: string;
    selected?: boolean;
    parentType?: string;
    eventOrganizations?: any[];
    dateModified?: number;
    modifiedBy?: string;
    ledFirstName?: string;
    ledLastName?: string;
    ledNpi?: string;
    ledType?: string;

    get stringPerformers() {
        return this._stringPerformers;
    }

    get performers() {
        return this._performers;
    }

    _status: BranchStatus;
    set status(value) {
        this._status = value;
    }

    get status() {
        return this._status;
    }

    get customStatus() {
        if (this.approvalStatus === 'PENDING' || this.approvalStatus === 'CONFIRMED' || this.approvalStatus === 'ONBOARD' || this.approvalStatus === 'DENIED'
            || this.approvalStatus === 'NEW EVENTS PENDING' || this.approvalStatus === 'ALL EVENTS PENDING' || this.approvalStatus === 'NO EVENTS ADDED' || this.approvalStatus === 'NO EVENTS PENDING') {
            return this.approvalStatus;
        }
        return this._status;
    }

    constructor({ id = '', links = [], ...options }: BranchModel) {
        super({ id, links });

        this.id = id;
        this.links = links;
        this.address = new Address(options.address || {});
        this.departments = options.departments || [];
        this.description = options.description || '';
        this.isHeadquarter = options.isHeadquarter || false;
        this.isOpen = options.isOpen;
        this.name = options.name || '';
        this.patientCount = options.patientCount || 0;
        this.providerCount = options.providerCount || 0;
        this.vaccinationCount = options.vaccinationCount || 0;
        this.organizationAppointments = options.organizationAppointments || [];
        this._performers = [];
        this.applicationDate = options.applicationDate || '';
        if (options.compliancyScore) {
            this.compliancyScore = options.compliancyScore;
        }
        this.shortName = options.shortName || '';
        this.type = options.type;
        this._status = this.organizationAppointments.length ? BranchStatus.Planned : BranchStatus.NotScheduled;
        this.approvalStatus = options.approvalStatus || '';
        this.facilityDetails = options.facilityDetails || '';
        this.numOfPatients = options.numOfPatients;
        this.expectedTimeDuration = options.expectedTimeDuration;
        this.haveFacility = options.haveFacility;
        this.canRegisterIndividuals = options.canRegisterIndividuals;
        this.vaccine = options.vaccine || '';
        this.requestId = options.requestId || '';
        this.priority = options.priority || '';
        this.childrenCommunities = options.childrenCommunities;
        this.inventoryType = options.inventoryType;
        this.selected = options.selected || false;
        this.modifiedBy = options.modifiedBy || '';
        this.dateModified = options.dateModified || null;
        this.ledFirstName = options.ledFirstName;
        this.ledLastName = options.ledLastName;
        this.ledNpi = options.ledNpi;
        this.ledType = options.ledType;
    }

    addPerformer(performer: Performer) {
        const index = this._performers.findIndex((p) => p.id === performer.id);

        if (index < 0) {
            this._performers.push(performer);
            this._stringPerformers = this._performers.map((p) => p.name).join(', ');
        }
    }
}

export enum BranchStatus {
    NotScheduled = 'Not Yet Scheduled',
    Planned = 'Planned',
    Scheduled = 'Scheduled',
    Completed = 'Completed',
}
