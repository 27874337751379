import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
    body: string;
    showOk: boolean;
    errors: string[];
    fieldErrors: boolean;
}

@Component({
    selector: 'app-global-error-handler-modal',
    templateUrl: './global-error-handler-modal.component.html',
    styleUrls: ['./global-error-handler-modal.component.scss'],
})
export class GlobalErrorHandlerModalComponent implements OnInit {
    public errorMessage: string[] = [];

    constructor(
        public dialogRef: MatDialogRef<GlobalErrorHandlerModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) { }

    ngOnInit(): void {
        if (this.data?.fieldErrors && this.data?.errors?.length > 0) {
            this.errorMessage = this.data.errors;
        }
    }
}
