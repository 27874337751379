import globals from '../../../config';
import config from '../../../config';
import { IProgramPhaseItem } from '../services/phase-item/program-phase-item.interface';
import { IProgram } from '../services/programs/programs.interface';
import { NewCarePlan } from './careplan.model';
import { Link } from './link.model';
import { IObservation } from './observation.model';
import { Patient } from './patient.model';
import { ITask } from './tasks.model';

export class UserSession {
    accountId: string;
    performerId: string;
    firstName: string;
    lastName: string;
    role: string;
    isPerformer: boolean;
    patients: Array<Patient>;
    currPatient: any;
    organizationName: string;
    organizationType: string;
    sessionAccount: any;
    organizationId: string;
    privilege: Privilege;
    locale: string;
    email: string;
    accountOrganizationList: Array<AccountOrganizationInfo>;
    seenWelcome: boolean;
    patientId: string;
    links = [];
    ssoUser: boolean;
    get isAccountFromVAOrganization() {
        return this.organizationName === globals.VA.orgName;
    }

    get isUserProvider() {
        return this.isPerformer && this.privilege && this.privilege.name == 'User';
    }
}

export class Privilege {
    description = '';
    id: string;
    isAdmin = false;
    links: Link[];
    name: string;
    level: number;

    constructor({ name, id, isAdmin, description }: PrivilegeModel) {
        this.name = name;
        this.id = id;
        this.isAdmin = isAdmin;
        this.description = description;
        this.level = config.PRIVILEGE_HIERARCHY_BY_ID.findIndex((privilegeId) => privilegeId === +id);
    }
}

export interface PrivilegeModel {
    name: string;
    id: string;
    description?: string;
    isAdmin?: boolean;
    links?: Link[];
}

export interface IMobileCarePlanItem extends NewCarePlan {
    currentCareplanGoalTitle: string;
    lastUpdated?: string;
    timeZoneId?: string;
    timeZoneOffset?: string;
    timeZoneDisplayName?: string;
    goalStatusList?: Array<string>;
}

export class MobileApiUserSession {
    accountId: string;
    accountList: string[];
    accountListName: string[];
    activeTasks?: any;
    age: string;
    assessmentReports?: any[];
    carePlanStatus: string;
    carePlanStatusAssignedDate: any;
    carePlans?: {
        content: IMobileCarePlanItem[],
        links: Link[],
    };

    completedTasks?: any;
    dateRegistered: any;
    dob: string;
    email: string;
    firstName: string;
    genderIdentity: string;
    industry: string;
    insurance?: any;
    insuranceVerified: boolean;
    lastName: string;
    links?: any;
    location?: any;
    mobileNumber: string;
    motherFirstName: string;
    observationList?: IObservation[];
    organizationId: string;
    organizationLocationId: string;
    organizationName: string;
    otherTasks?: Array<any>;
    patientActivities?: Array<any>;
    patientId: string;
    pendingAppointments?: {
        content: ITask[];
        links: Link[]
    };

    privilege: Privilege;
    privilegeId: number;
    priority: string;
    profileCompleteness: any;
    race: string;
    recommendation: any;
    recommendations: Array<any>;
    recommendProgramResourceList: Array<IProgram>;
    role: string;
    sex: string;
    unconfirmedMembers?: any;
    missingConsentOrganizations?: any;
    currentStatus: string;
    isRumeAllowed: boolean;
    parentOrganizationId?: string;
    mostRelevantHighlight?: any;
    programResourceList?: IProgram[];
    recommendEducationResourceList: Array<any>;
    completedPhaseItems: IProgramPhaseItem[];
}

export class AccountOrganizationInfo {
    organizationId: string;
    orgName: string;
    roleAtOrganization: string;
}
