import { Link } from './link.model';

export class Address {
    id?: string;
    locationType?: string;
    name?: string;
    dateCreated?: number;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipcode: number;
    gpsLatitude?: number;
    gpsLongitude?: number;
    streetNumber?: any;
    route?: any;
    country?: string;
}

export interface AddressModel {
    id?: string;
    locationType?: string;
    name?: string;
    dateCreated?: number;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zipcode?: number;
    gpsLatitude?: number;
    gpsLongitude?: number;
    streetNumber?: any;
    route?: any;
    country?: string;
    links?: Link[];
}
