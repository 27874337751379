import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ServiceBookingDetailsModel } from 'src/app/core/models/patient.model';

@Injectable({
    providedIn: 'root'
})
export class ServiceBookingDataShareService {
    _serviceBookingData = new BehaviorSubject<ServiceBookingDetailsModel>(null);
    _serviceList = new BehaviorSubject<any>(null);
    _activeStepperId = new BehaviorSubject<string>(null);
    private _filterDistance: number;
    private _filterZipCode: number;

    private _serviceAvailable: any;
    private _paymentIntentResult: any;

    constructor() { }

    set serviceBookingData(value) {
        this._serviceBookingData.next(value);
    }

    get serviceBookingData() {
        return this._serviceBookingData.getValue();
    }

    getServiceBookingDataSubject() {
        return this._serviceBookingData;
    }

    set serviceList(value) {
        this._serviceList.next(value);
    }

    get serviceList() {
        return this._serviceList.getValue();
    }

    set serviceAvailable(value) {
        this._serviceAvailable = value;
    }
    get serviceAvailable() {
        return this._serviceAvailable;
    }

    set setActveStepperId(value) {
        this._activeStepperId.next(value);
    }

    get actveStepperId() {
        return this._activeStepperId.getValue();
    }

    get paymentIntentResult() {
        return this._paymentIntentResult;
    }
    set paymentIntentResult(paymentIntentResult) {
        this._paymentIntentResult = paymentIntentResult;
    }
}
