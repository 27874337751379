<div class="mass-reschedule bottom-position" *ngIf="!(isUpdate || isCancel || isMessage)">
  <div mat-dialog-content class="p-0 flex-row" >
    <div class="update-cancel-lane-btns d-flex">
      <div class="f-2 d-flex">
        <i class="icon-close" (click)="onClose(false)"></i>
        <mat-chip-option>{{selectedLanesCount}} Lanes Selected</mat-chip-option>
      </div>
      <div class="f-1 d-flex">
        <button mat-stroked-button class="rechedule-btns message-rechedule-btn"(click)="messageMassRescheduleDialog()"><i class="icon-message"></i>  Message</button>
        <button mat-stroked-button class="rechedule-btns update-rechedule-btn" (click)="updateMassRescheduleDialog()"><i class="icon-calendar"></i> Update Lanes</button>
        <button mat-stroked-button class="rechedule-btns cancel-rechedule-btn mr-0" (click)="cancelMassRescheduleDialog()"><i class="icon-close"></i> Cancel Lanes</button>
      </div>
    </div>
  </div>
</div>

<!-- Update lanes -->
<div class="update-lanes bottom-position" *ngIf="isUpdate && !isCancel && !isMessage">
  <div mat-dialog-content class="p-0 flex-row" >
    <div class="d-flex">
      <div class="f-2 d-flex mt-3">
        <i class="icon-arrow" (click)="updateMassRescheduleDialog()"></i>
        <div class="selected-lanes-chip ">
          <mat-chip-option>{{selectedLanesCount}} Lanes Selected</mat-chip-option>
        </div>
      </div>
      <div class="f-2 host mr-4">
        <label class="input_label fs-13 ml-3 mb-1 d-block lh-1">Host</label>
        <mat-form-field>
          <input type="text" matInput [(ngModel)]="newHost" [matAutocomplete]="auto" placeholder="{{hostPlaceholder}}" (input)="getAutoCompleterEventValue($event)"  (ngModelChange)="onHostChange()"/>
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let host of filteredEvents" [value]="host" [matTooltip]="host.name">
              {{host.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="f-1 date-picker">
        <label class="input_label fs-13 ml-3 mb-1 d-block lh-1">Date</label>
        <mat-form-field appearance="outline">
          <input matInput [matDatepicker]="picker" [min]="minDate" [(ngModel)]="rescheduleDate" (dateChange)="changeDate()">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="accept-changes-container">
      <div class="main_gray_08 fs-14 bg-dark-lightgray d-flex">
        <div class="f-1 accept-change br-right p-3">
          <div class="mt-3 d-flex" [matTooltip]="'Please choose new host or new date.'" [matTooltipDisabled]="isHostChanged || isNewDate">
            <mat-checkbox class="mat-checkbox lane-checkbox mat-accent mr-2 mt-0" [(ngModel)]="isAccept" [disabled]="!isNewDate && !isHostChanged"></mat-checkbox>
            <div class="">Accept Changes? </div>
          </div>
        </div>
        <div class="patient-affected br-right d-flex p-3">
          <div class="patient-affected-count">{{patientsAffectedCount}}</div>
          <div class="mt-1">PATIENTS <br>AFFECTED </div>
        </div>
        <div class="f-3 accept-changes-message pl-3 pr-3">
          <p class="mt-0 mb-0" *ngIf="newHost || isNewDate"><span class="text-blue">{{patientsAffectedCount}} Patients</span> <span class="text-blue" *ngIf="selectedLanesCount > 1"> at Multiple Hosts </span> on <span class="text-blue">{{currentScheduledDate}}</span> will be moved <span class="text-blue" *ngIf="newHost">to {{newHost.name}}</span> <span *ngIf="formattedRescheduleDate"> on <span class="text-blue">{{formattedRescheduleDate}}</span></span></p>
          <p class="accept-changes-note mt-3 mb-0">Click 'Host' or 'Date' to update the selected lanes</p>
        </div>
        <div class="p-3" [matTooltip]="'0 Patients selected'" [matTooltipDisabled]="isPatientCount">
          <button mat-stroked-button class="rechedule-btns update-rechedule-btn" [disabled]="!isPatientCount || !isAccept || isClicked" [ngClass]="{'active': isAccept && isPatientCount && !isClicked}" (click)="updateLanes()"><i class="icon-calendar"></i> Update Lanes</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Cancel Lanes -->
<div class="update-lanes cancel-lanes" *ngIf="isCancel && !isUpdate && !isMessage">
  <div mat-dialog-content class="p-0 flex-row">
    <div class="d-flex">
      <div class="f-2 d-flex mt-3">
        <i class="icon-arrow" (click)="cancelMassRescheduleDialog()"></i>
        <div class="selected-lanes-chip ">
          <mat-chip-option>{{selectedLanesCount}} Lanes Selected</mat-chip-option>
        </div>
      </div>
      <div class="f-2 host mr-4">
        <label class="input_label fs-13 mb-1 d-block lh-1">Reason for Canceling </label>
        <mat-form-field class="selectreason">
          <mat-select [(ngModel)]="selectedCancelReason" placeholder="Select reason for canceling"  (ngModelChange)="onChangeReason()">
            <mat-option *ngFor="let cancelreason of cancelList" [value]="cancelreason" [matTooltip]="cancelreason">
              {{cancelreason}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- If cancellation reason is other -->
        <div *ngIf="isOther">
          <mat-form-field class="example-full-width" >
            <input
            type="text"
            matInput
            [(ngModel)]="otherReasonDesc"
            class="mat-reason-input"
            placeholder="Please describe reason for cancellation">
          </mat-form-field>
        </div>
      </div>

      <div class="f-1 date-picker">
        <label class="input_label fs-13 mb-1 d-block lh-1">Date</label>
        <mat-form-field appearance="outline">
          <input matInput [matDatepicker]="picker" [disabled]="true" [ngModel]="rescheduleDate">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="accept-changes-container">
      <div class="main_gray_08 fs-14 bg-dark-lightgray d-flex">
        <div class="f-1 accept-change br-right p-3">
          <div class="mt-3 d-flex" [matTooltip]="'Please select reason for cancel.'" [matTooltipDisabled]="isCancelReasonSelected">
            <mat-checkbox class="mat-checkbox lane-checkbox mat-accent mr-2 mt-0" [(ngModel)]="isAccept" [disabled]="!isCancelReasonSelected"></mat-checkbox>
            <div class="">Accept Changes?</div>
          </div>
        </div>
        <div class="patient-affected br-right d-flex p-3">
          <div class="patient-affected-count">{{patientsAffectedCount}}</div>
          <div class="mt-1">PATIENTS <br>AFFECTED </div>
        </div>
        <div class="f-3 accept-changes-message pl-3 pr-3">
          <p class="mt-0 mb-0" *ngIf="selectedCancelReason"><span class="text-blue">{{patientsAffectedCount}} Patients</span> <span class="text-blue" *ngIf="selectedLanesCount > 1"> at Multiple Hosts </span> on <span class="text-blue">{{currentScheduledDate}}</span> will canceled for <span class="text-blue">{{selectedCancelReason}}</span></p>
          <p class="accept-changes-note mt-3 mb-0">Select reason to ‘Cancel Lanes’ from above to continue</p>
        </div>
        <div class="p-3">
          <button mat-stroked-button class="rechedule-btns cancel-rechedule-btn d-flex" [disabled]="!isAccept" [ngClass]="{'active': isAccept && !isClicked}" (click)="cancelLanes()"><i class="icon-close"></i> Cancel Lanes</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Message -->
<div class="update-lanes cancel-lanes" *ngIf="isMessage && !isCancel && !isUpdate">
  <div mat-dialog-content class="p-0 flex-row">
    <div class="d-flex">
      <div class="f-2 d-flex mt-3">
        <i class="icon-arrow" (click)="messageMassRescheduleDialog()"></i>
        <div class="selected-lanes-chip ">
          <mat-chip-option>{{selectedLanesCount}} Lanes Selected</mat-chip-option>
        </div>
      </div>
      <div class="f-1 date-picker">
        <label class="input_label fs-13 mb-1 d-block lh-1">Date</label>
        <mat-form-field appearance="outline">
          <input matInput [matDatepicker]="picker" [disabled]="true" [ngModel]="rescheduleDate">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="accept-changes-container">
      <div class="main_gray_08 fs-14 bg-dark-lightgray d-flex">
        <div class="patient-affected br-right d-flex p-3">
          <div class="patient-affected-count">{{patientsAffectedCount}}</div>
          <div class="mt-1">PATIENTS <br>AFFECTED </div>
        </div>
        <mat-form-field class="example-full-width message-input" >
          <textarea
            type="text"
            matInput
            [(ngModel)]="message"
            class="message-input-height">
          </textarea>
        </mat-form-field>
        <div class="p-3" [matTooltip]="'0 Patients selected'" [matTooltipDisabled]="isPatientCount">
          <button type="button" mat-stroked-button class="rechedule-btns update-rechedule-btn" [disabled]="message === '' || !isPatientCount || isClicked" [ngClass]="{'active': message !== '' && isPatientCount && !isClicked}" (click)="messageLanes()"><mat-icon class="message-icon">chat_bubble_outline</mat-icon> Message</button>
        </div>
      </div>
    </div>
  </div>
</div>
