export enum OrganizationTypes {
    CommunityGroup = 'COMMUNITYGROUP',
    Jurisdiction = 'JURISDICTION',
    PatientGroup = 'GROUP',
    Facility = 'FACILITY',
    Clinic = 'CLINIC',
    OpenPod = 'openPod',
    School = 'SCHOOL',
    SubOrg = 'SUB-ORG',
}

export const organizationTypesArray = [
    'SUB-ORG',
    'HOSPITAL',
    'PRIVATE PRACTICE',
    'NON-PROFIT',
    'COMMUNITYGROUP',
    'JURISDICTION',
    'GROUP',
    'FACILITY',
    'CLINIC',
    'openPod',
    'SCHOOL'
] as const;

export type IOrganizationTypes = typeof organizationTypesArray[number];
