<div>
	<h1 mat-dialog-title class="p-3 mb-0 fs-30">The application has been updated. Please reload this page.</h1>
	<div mat-dialog-content></div>
	<div mat-dialog-actions>
		<div class="d-flex align-items-center justify-content-end flex-nowrap">
			<div class="button_container mr-2">
		  <button mat-button (click)="onNoClick()" class="back_btn">No Thanks</button>
		</div>
		<div class="button_container">
		  <button mat-button (click)="onOkClick()" class="next_btn">Ok</button>
		</div>
		</div>
		
	</div>
</div>
