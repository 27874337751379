import { Injectable } from '@angular/core';
import { firstValueFrom, map, Observable } from 'rxjs';
import { NetworkService } from 'src/app/core/services/network/network.service';

import { ProductItem } from './product-item.class';
import { IProductItem, IProductItemCreate, RequestProductItem } from './product-items.interface';

@Injectable({
    providedIn: 'root',
})
export class ProductItemsService {
    readonly baseURL = '/api/productItems';

    constructor(private networkService: NetworkService) {}

    /* All productItems are based on a template, used when creating a new product item */
    getProductItemsTemplates(): Observable<IProductItem[]> {
        return this.networkService.fetchResource<IProductItem[]>(`${this.baseURL}/template`);
    }

    getTemplates(): Observable<ProductItem[]> {
        return this.getProductItemsTemplates().pipe(this.iProductItemArrToClass());
    }

    /* Get a specific product item */
    getIProductItem(productId: string): Observable<IProductItem> {
        return this.networkService
            .fetchResource<IProductItem>(`${this.baseURL}/${productId}`);
    }
    getProductItem(productId: string): Observable<ProductItem> {
        return this.getIProductItem(productId).pipe(this.iProductItemToClass());
    }

    /* Create a new productItem. */
    createProductItem(productItem: IProductItemCreate): Observable<ProductItem> {
        return this.networkService.postResource<IProductItem>(this.baseURL, productItem).pipe(this.iProductItemToClass());
    }

    /* Update existing productItem */
    updateProductItem(productItem: RequestProductItem): Observable<ProductItem> {
        return this.networkService
            .putResource<IProductItem>(`${this.baseURL}/${productItem.productItemId}`, productItem)
            .pipe(this.iProductItemToClass());
    }

    deleteSubProduct(productItemId: string): Observable<void> {
        return this.networkService.deleteResource(`${this.baseURL}/${productItemId}`);
    }

    /* Get all product items allowed for a program */
    getProductItemsForProgram(programId: string): Promise<ProductItem[]> {
        return firstValueFrom(
            this.networkService
                .fetchResource<IProductItem[]>(`/api/programs/${programId}/product-items`)
                .pipe(map((productItemsArray) => productItemsArray.map((productItem) => new ProductItem(productItem)))),
        );
    }

    /* Internal Function: Covert an array of IProductItem to array of class ProductItem  */
    private iProductItemArrToClass() {
        return map((productItems: IProductItem[]) => productItems.map((productItem: IProductItem) => new ProductItem(productItem)),);
    }

    /* Internal Function: Covert an array of IProductItem to array of class ProductItem  */
    private iProductItemToClass() {
        return map((productItem: IProductItem) => new ProductItem(productItem));
    }
}
