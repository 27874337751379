import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { PatientTeamMember } from '../../../../../../../../../../core/models/patient.model';
import { Program } from '../../../../../../../../../../core/services/programs/program.class';
import { SnackbarService } from '../../../../../../../../../../core/services/snackbar.service';
import { PatientService } from '../../../../../../../../../patients/services/patient.service';

@Injectable({ providedIn: 'root' })
export class ProgramPhasesDashboardService {
    // additional family members selected to finish the enrolling process with
    private additionalFamilyMembers = new BehaviorSubject<PatientTeamMember[]>(undefined);

    constructor(
        private patientService: PatientService,
        private snackbarService: SnackbarService,
    ) {}

    setAdditionalFamilyMembers(members: PatientTeamMember[]) {
        this.additionalFamilyMembers.next(members);
    }

    getAdditionalFamilyMembers() {
        return this.additionalFamilyMembers.asObservable();
    }

    updateEnrollmentStatus(program: Program) {
        const newMembers = this.additionalFamilyMembers.value;

        if (!newMembers || !newMembers.length) {
            return;
        }

        // Update the status for new members
        newMembers.forEach((member) => {
            this.patientService
                .updatePatientProgramStatus(member?.patientId, program?.id, 'STARTED_ENROLLMENT')
                .subscribe({
                    next: (data) => {
                        member['patientProgramStatusEnrollmentId'] = data.id;
                    },
                    error: (e) => {
                        console.error(e);
                        this.snackbarService.error('Error: Could not update the enrollment status: ' + member?.firstName ||
                                member?.name ||
                                member?.lastName);
                    },
                });
        });
    }

    clearMembers() {
        this.additionalFamilyMembers.next(undefined);
        this.additionalFamilyMembers.complete();
        this.additionalFamilyMembers = new BehaviorSubject<PatientTeamMember[]>(undefined);
    }
}
