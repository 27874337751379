export enum TranslationISO {
    en = 'en-US',
    'zh-TW' = 'zh-TW',
    'zh-CN' = 'zh-CN',
    es = 'es-MX',
    vi = 'vi-VN',
    ko = 'ko-KR',
    'ja-JP' = 'ja-JP',
    'hi-IN' = 'hi-IN',
    'gu-IN' = 'gu-IN',
    'tl-PH' = 'tl-PH',
}

export interface TranslateLanguage {
    name: string;
    code: TranslationISO;
}
