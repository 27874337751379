import { Injectable } from '@angular/core';

const API = {
    authStr: '/api/accounts/login',
    refreshTokenStr: '/api/accounts/refreshToken',
    passResetStr: '/api/accounts/{accountId}/{password}/{oldPassword}',
    invalidateStr: '', // use this to invalidate the current user's session
    doctorSession: '/api/session/web', /// {{cp_url}}/api/session/doctor
    updateCareplan: '/api/carePlans/{careplanId}',
    metaData: '/api/meta/REPLACE_ME',
};

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    static getAuthorizationLogin(): string {
        return API.authStr;
    }

    static getRefreshToken(): string {
        return API.refreshTokenStr;
    }

    // public invalidateSession(): string {
    //   localStorage.clear();
    //   return API.invalidateStr;
    // }

    static fetchSession(): string {
        return API.doctorSession;
    }

    static updateCareplans(id: string): string {
        return API.updateCareplan.replace('{careplanId}', id);
    }

    static addMetaData(meta: string): string {
        return API.metaData.replace('REPLACE_ME', meta);
    }

    // public updateMetaData(meta: string, id: string): string {
    //   return API.metaData.replace('REPLACE_ME', meta) + '/' + id;
    // }
}
