<div class="brdr-box p-5 h-100 o-auto bg-main_gray_01">
  <h3>
    Icons Font - sizes and colors can be changed by adding the "fs-.." and color-related classes which are listed in
    font-sizes.scss and styles.css
  </h3>

  <div class="d-flex flex-wrap">
    <!-- Filters Icons -->
    <div class="d-flex">
      <div *ngFor="let filterIcon of filterIcons" class="p-2 m-3 bg-white">
        <div class="fs-12 main_gray_08 mb-3">{{ filterIcon }}</div>
        <i [ngClass]="filterIcon"></i>
      </div>
    </div>

    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-left-arr</div>
      <i class="icon-left-arr"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-close</div>
      <i class="icon-close"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-cross</div>
      <i class="icon-cross"></i>
    </div>

    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-add</div>
      <i class="icon-add"></i>
    </div>

    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-nav-back</div>
      <i class="icon-nav-back"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-arrow</div>
      <i class="icon-arrow"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-expand</div>
      <i class="icon-expand"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-next</div>
      <i class="icon-next"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-back</div>
      <i class="icon-back"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-back-arrow</div>
      <i class="icon-back-arrow"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-back-left</div>
      <i class="icon-back-left"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-collapse</div>
      <i class="icon-collapse"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-checked</div>
      <i class="icon-checked"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-plus</div>
      <i class="icon-plus"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-dropdown</div>
      <i class="icon-dropdown"></i>
    </div>

    <div class="w-100 d-flex">
      <div class="p-2 m-3 bg-white">
        <div class="fs-12 main_gray_08 mb-3">ic-circle + any icon class</div>
        <i class="icon-call ic-circle"></i>
      </div>
      <div class="p-2 m-3 bg-white">
        <div class="fs-12 main_gray_08 mb-3">ic-sm + any icon class</div>
        <i class="ic-sm icon-email"></i>
      </div>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-call</div>
      <i class="icon-call"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-email</div>
      <i class="icon-email"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-message</div>
      <i class="icon-message"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-edit</div>
      <i class="icon-edit"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-delete</div>
      <i class="icon-delete"></i>
    </div>

    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-menu</div>
      <i class="icon-menu"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-filter</div>
      <i class="icon-filter"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-select</div>
      <i class="icon-select"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-feed</div>
      <i class="icon-feed"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-goal</div>
      <i class="icon-goal"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-off-track</div>
      <i class="icon-off-track"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-task-complete</div>
      <i class="icon-task-complete"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-all, icon-All</div>
      <i class="icon-all"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-daily-status</div>
      <i class="icon-daily-status"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-reschedule</div>
      <i class="icon-reschedule"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-observation, <br />icon-undefined</div>
      <i class="icon-observation"></i>
    </div>

    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-team</div>
      <i class="icon-team"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-team-task</div>
      <i class="icon-team-task"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-calendar, <br />icon-appointment, <br />icon-Appointment</div>
      <i class="icon-calendar"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-date</div>
      <i class="icon-date"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-date-of-birth</div>
      <i class="icon-date-of-birth"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-activity-lvl</div>
      <i class="icon-activity-lvl"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-interactions</div>
      <i class="icon-interactions"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-gender</div>
      <i class="icon-gender"></i>
    </div>

    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-qr-code</div>
      <i class="icon-qr-code"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-pwd</div>
      <i class="icon-pwd"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-billing</div>
      <i class="icon-billing"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-star-icon</div>
      <i class="icon-star-icon"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-profile</div>
      <i class="icon-profile"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-assessment, <br />icon-Assessment</div>
      <i class="icon-assessment"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-medication, <br />icon-Medication</div>
      <i class="icon-medication"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-clone, <br />icon-question-duplicate</div>
      <i class="icon-clone"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-upload-image</div>
      <i class="icon-upload-image"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-start-date</div>
      <i class="icon-start-date"></i>
    </div>

    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-logout</div>
      <i class="icon-logout"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-change-plan, <br />icon-reset, <br />icon-change</div>
      <i class="icon-change-plan"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-unplanned, <br />icon-critical</div>
      <i class="icon-unplanned"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-unpl-inact</div>
      <i class="icon-unpl-inact"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-alerts</div>
      <i class="icon-alerts"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-milestone-duration</div>
      <i class="icon-milestone-duration"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">
        icon-running, <br />icon-exercise, <br />icon-lifestyle, <br />icon-Exercise, <br />icon-ssi
      </div>
      <i class="icon-running"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-goods</div>
      <i class="icon-goods"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-chemo</div>
      <i class="icon-chemo"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-inhome</div>
      <i class="icon-inhome"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-organization</div>
      <i class="icon-organization"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-family</div>
      <i class="icon-family"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-therapy</div>
      <i class="icon-therapy"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-heart, <br />icon-careplan</div>
      <i class="icon-heart"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-edit-cp</div>
      <i class="icon-edit-cp"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-education, <br />icon-Education</div>
      <i class="icon-education"></i>
    </div>

    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-available-team</div>
      <i class="icon-available-team"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-provider</div>
      <i class="icon-provider"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-patient</div>
      <i class="icon-patient"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-patient-info</div>
      <i class="icon-patient-info"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-admin-list</div>
      <i class="icon-admin-list"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-goals</div>
      <i class="icon-goals"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-address</div>
      <i class="icon-address"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-cycle</div>
      <i class="icon-cycle"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-masterdata</div>
      <i class="icon-masterdata"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-paper-plane</div>
      <i class="icon-paper-plane"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-arrow-dots</div>
      <i class="icon-arrow-dots"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-activity</div>
      <i class="icon-activity"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-care-team</div>
      <i class="icon-care-team"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-completeness</div>
      <i class="icon-completeness"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-diagnosis</div>
      <i class="icon-diagnosis"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-engagement-chart</div>
      <i class="icon-engagement-chart"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-msg</div>
      <i class="icon-msg"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-on-schedule</div>
      <i class="icon-on-schedule"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-quality-life</div>
      <i class="icon-quality-life"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-duration</div>
      <i class="icon-duration"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-calendar-add</div>
      <i class="icon-calendar-add"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-search</div>
      <i class="icon-search"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-notification</div>
      <i class="icon-notification"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-setting</div>
      <i class="icon-setting"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-food, <br />icon-Food</div>
      <i class="icon-food"></i>
    </div>

    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-checkon</div>
      <i class="icon-checkon"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-checkon today</div>
      <i class="icon-checkon today"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-checkon nodata</div>
      <i class="icon-checkon nodata"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-warning</div>
      <i class="icon-warning"></i>
    </div>

    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-upcondition</div>
      <i class="icon-upcondition"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-condition</div>
      <i class="icon-condition"></i>
    </div>
  </div>

  <h3>
    Base64 icons - that means you can change only the size by adding the size-related classes from fast-sizes.scss to
    "i.icon-.." elements.
  </h3>
  <div class="d-flex flex-wrap">
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-phase</div>
      <i class="icon-phase"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">note_status</div>
      <i class="note_status"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">note_status public</div>
      <i class="note_status public"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-info-blue</div>
      <i class="icon-info-blue"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-notes ic-bg</div>
      <i class="icon-notes ic-bg w30x30"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-tasks ic-bg</div>
      <i class="icon-tasks ic-bg w30x30"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-traffic ic-bg</div>
      <i class="icon-traffic ic-bg w30x30"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-total-pts</div>
      <i class="icon-total-pts"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-active-patients</div>
      <i class="icon-active-patients"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-inactive-pt</div>
      <i class="icon-inactive-pt"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-never-active-pt</div>
      <i class="icon-never-active-pt"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-check</div>
      <i class="icon-check"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-newactivity</div>
      <i class="icon-newactivity"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-key</div>
      <i class="icon-key"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-print</div>
      <i class="icon-print"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-enlarge</div>
      <i class="icon-enlarge"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-vrt-appoint</div>
      <i class="icon-vrt-appoint"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-er-visit</div>
      <i class="icon-er-visit"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-onboard</div>
      <i class="icon-onboard"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-status-update</div>
      <i class="icon-status-update"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-sum-1</div>
      <i class="icon-sum-1"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-sum-2</div>
      <i class="icon-sum-2"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-sum-3</div>
      <i class="icon-sum-3"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-sum-4</div>
      <i class="icon-sum-4"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-sum-6</div>
      <i class="icon-sum-6"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-location</div>
      <i class="icon-location"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-note, <br />icon-upd-assessment</div>
      <i class="icon-note"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-free</div>
      <i class="icon-free"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-positive-res</div>
      <i class="icon-positive-res"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-tested</div>
      <i class="icon-tested"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-arrow-red-down, <br />icon-arrow-red</div>
      <i class="icon-arrow-red-down"></i>
    </div>

    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-arrow-red-up</div>
      <i class="icon-arrow-red-up"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-arrow-orange-up</div>
      <i class="icon-arrow-orange-up"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-arrow-orange, <br />icon-arrow-orange-down</div>
      <i class="icon-arrow-orange"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-arrow-green-down</div>
      <i class="icon-arrow-green-down"></i>
    </div>

    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-arrow-green, <br />icon-arrow-green-up</div>
      <i class="icon-arrow-green"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-telehealth</div>
      <i class="icon-telehealth"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-reconciliation</div>
      <i class="icon-reconciliation"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-order</div>
      <i class="icon-order"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-comment-feed</div>
      <i class="icon-comment-feed"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-expand-all</div>
      <i class="icon-expand-all"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-symptoms</div>
      <i class="icon-symptoms"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-temperature</div>
      <i class="icon-temperature"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-oxygen</div>
      <i class="icon-oxygen"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-pact-edit</div>
      <i class="icon-pact-edit"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-key</div>
      <i class="icon-key"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-status-update-mob</div>
      <i class="icon-status-update-mob"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-vrt-appoint-mob</div>
      <i class="icon-vrt-appoint-mob"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-upd-assessment-mob</div>
      <i class="icon-upd-assessment-mob"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">ic-pact</div>
      <i class="ic-pact d-flex"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">ic-patient</div>
      <i class="ic-patient d-flex"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">ic-traced</div>
      <i class="ic-traced d-flex"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">in-ic-pact</div>
      <i class="in-ic-pact d-flex"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">in-ic-patient</div>
      <i class="in-ic-patient d-flex"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">in-ic-traced</div>
      <i class="in-ic-traced d-flex"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-appointment-active</div>
      <i class="icon-appointment-active"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-appointment-complete</div>
      <i class="icon-appointment-complete"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-appointment-missed</div>
      <i class="icon-appointment-missed"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-assessment-active</div>
      <i class="icon-assessment-active"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-assessment-complete</div>
      <i class="icon-assessment-complete"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-assessment-missed</div>
      <i class="icon-assessment-missed"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-medication-active</div>
      <i class="icon-medication-active"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-medication-complete</div>
      <i class="icon-medication-complete"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-medication-missed</div>
      <i class="icon-medication-missed"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">ic-filter-provider</div>
      <i class="ic-filter-provider"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-filter-status</div>
      <i class="icon-filter-status"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">ic-filter-cp</div>
      <i class="ic-filter-cp"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-qu-1</div>
      <i class="icon-qu-1"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-qu-2</div>
      <i class="icon-qu-2"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-qu-3</div>
      <i class="icon-qu-3"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-qu-4</div>
      <i class="icon-qu-4"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-qu-5</div>
      <i class="icon-qu-5"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-form</div>
      <i class="icon-form"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-patient-next</div>
      <i class="icon-patient-next"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-selfq-nodata</div>
      <i class="icon-selfq-nodata"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-vitals-nodata</div>
      <i class="icon-vitals-nodata"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-curcond-green</div>
      <i class="icon-curcond-green"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-curcond-orange</div>
      <i class="icon-curcond-orange"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-curcond-red</div>
      <i class="icon-curcond-red"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-marker-not-scheduled</div>
      <i class="icon-marker-not-scheduled"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-marker-planned</div>
      <i class="icon-marker-planned"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-marker-completed</div>
      <i class="icon-marker-completed"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-marker-scheduled</div>
      <i class="icon-marker-scheduled"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-pfizer</div>
      <i class="icon-pfizer"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-astrazenca</div>
      <i class="icon-astrazenca"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-moderna</div>
      <i class="icon-moderna"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-merck</div>
      <i class="icon-merck"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">icon-facility-location</div>
      <i class="icon-facility-location"></i>
    </div>
  </div>

  <h3>
    ic-filter font
  </h3>
  <div class="d-flex flex-wrap">
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">ic-filter-1</div>
      <i class="ic-filter-1"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">.ic-filter-2</div>
      <i class="ic-filter-2"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">.ic-filter-3</div>
      <i class="ic-filter-3"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">.ic-filter-4</div>
      <i class="ic-filter-4"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">ic-filter-5</div>
      <i class="ic-filter-5"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">.ic-filter-6</div>
      <i class="ic-filter-6"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">.ic-filter-7</div>
      <i class="ic-filter-7"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">.ic-filter-8</div>
      <i class="ic-filter-8"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">.ic-filter-9</div>
      <i class="ic-filter-9"></i>
    </div>
    <div class="p-2 m-3 bg-white">
      <div class="fs-12 main_gray_08 mb-3">.ic-filter-10</div>
      <i class="ic-filter-10"></i>
    </div>
  </div>
</div>
