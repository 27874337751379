import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SetupVaccinationSlotsServiceService } from 'src/app/modules/setup-vaccination-slots/setup-vaccination-slots-service.service';

import { SnackbarService } from '../../../core/services/snackbar.service';
import { FieldArrayService } from '../../../modules/setup-vaccination-slots/field-array.service';

export interface DialogData {
    lanesAffected: string;
    newHost: string;
    newDate: string;
    patientAffected: string;
    organization: any;
    clinicAppointmentIds: any;
    date: string;
    onLaneChanges: any,
    cancelledResponse: any
    isUpdate: boolean;
    isCancel: boolean;
    isMessage: boolean;
    batchId: string;
    diff: any;
}

@Component({
    selector: 'app-mass-reschedule-respons-dialog',
    templateUrl: './mass-reschedule-respons-dialog.component.html',
    styleUrls: ['./mass-reschedule-respons-dialog.component.scss']
})
export class MassRescheduleResponsDialogComponent {
    typesOptionsArray: string[][] = [];
    public fieldArray: any;
    public organization: any;
    public data: DialogData;
    public selectedClinicIds: any;
    public setupDate: string;
    public canceleResponseData: any;
    public patientCount: any;
    public batchId: string;

    constructor(
        private setupClinicService: SetupVaccinationSlotsServiceService,
        public dialogRef: MatDialogRef<MassRescheduleResponsDialogComponent>,
        private snackbarService: SnackbarService,
        private router: Router,
        private fieldArrayService: FieldArrayService,
        @Inject(MAT_DIALOG_DATA) public dialogData: DialogData

    ) {
        this.setupDate = dialogData.date;
        this.data = dialogData;
        this.canceleResponseData = dialogData.cancelledResponse;
        this.batchId = dialogData.batchId;
    }

    ngOnInit() {
        this.dialogRef.afterOpened().subscribe((_) => {
            setTimeout(() => {
                this.dialogRef.close();
            }, 300000);
        });
    }

    reload() {
        window.location.reload();
    }

    undoReschedulingUpdataes() {
        this.fieldArrayService.get().subscribe((data) => {
            this.fieldArray = data;
            const timeSlots = this.fieldArray.map((app, index) => {
                const tempDate = this.setupDate.split('-');
                tempDate.unshift(tempDate.pop());
                const newStartTime = this.parseDate(tempDate.join('-'));
                const newEndTime = this.parseDate(tempDate.join('-'));
                const startTime = newStartTime;
                const endTime = newEndTime;
                if (app.startDate && app.startDate.split(':').length > 1) {
                    startTime.setHours(app.startDate.split(':')[0]);
                    startTime.setMinutes(app.startDate.split(':')[1]);
                    startTime.setSeconds(0);
                }

                if (app.endDate && app.endDate.split(':').length > 1) {
                    endTime.setHours(app.endDate.split(':')[0]);
                    endTime.setMinutes(app.endDate.split(':')[1]);
                    endTime.setSeconds(0);
                }
                let diff = 0;
                if (this.data.diff) {
                    diff = this.data.diff * -1;
                }

                return {
                    startDate: startTime.getTime(),
                    endDate: endTime.getTime(),
                    clinicAppointmentId: app.id,
                    host: (app.host && app.host.id) ? app.host.id : undefined,
                    diff
                };
            });

            this.setupClinicService.undoRescheduledClinics(this.data.organization.id, this.batchId, timeSlots).subscribe({
                next: (res: any) => {
                    this.dialogRef.close();
                    this.snackbarService.notify('Undo successfully!');
                    this.reload();
                },
                error: (err: any) => {
                    this.dialogRef.close();
                    this.snackbarService.error('Could not undo rescheduled clinics');
                }
            });
        });
    }

    undoCancelLanes() {
        this.setupClinicService.undoCancelledClinicLanes(this.data.organization.id, this.canceleResponseData).subscribe({
            next: (res: any) => {
                this.dialogRef.close();
                this.snackbarService.notify('Cancelled lanes restored successfully!');
                this.reload();
            },
            error: (err: any) => {
                this.dialogRef.close();
                if (err.includes('Something bad happened; please try again later.')) {
                    this.snackbarService.notify('Cancelled lanes restored successfully!');
                    this.reload();
                } else {
                    this.snackbarService.error('Could not undo cancelled lanes');
                }
            }
        });
    }

    // parse a date in yyyy-mm-dd format
    parseDate(input) {
        const parts = input.split('-');
        // new Date(year, month [, day [, hours[, minutes[, seconds[, ms]]]]])
        return new Date(parts[0], parts[1] - 1, parts[2]); // Note: months are 0-based
    }
}
