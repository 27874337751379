import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Subject } from 'rxjs';
import { Organization } from 'src/app/core/classes/organization.class';
import { OrganizationService } from 'src/app/core/services/organization/organization.service';
import { Program } from 'src/app/core/services/programs/program.class';
import { ProgramsService } from 'src/app/core/services/programs/programs.service';

@Injectable({
    providedIn: 'root',
})
export class ProgramDashboardService {
    private selectedOrganization = new BehaviorSubject<Organization>(undefined);
    private selectedProgram = new BehaviorSubject<Program>(undefined);
    private _patientCount = new BehaviorSubject<any>(undefined);
    private _programAnalytics = new BehaviorSubject<any>(undefined);
    selectedProgram$ = this.selectedProgram.asObservable();

    reloadProductItemsSubject = new Subject<void>();

    programChangedSubject = new Subject<string>();

    constructor(private programsService: ProgramsService, private organizationService: OrganizationService) {}

    /* ------------------------------ Organization ------------------------------ */

    setSelectedOrganization(organization: Organization) {
        this.selectedOrganization.next(organization);
    }

    getSelectedOrganization(): BehaviorSubject<Organization> {
        return this.selectedOrganization;
    }

    setSelectedOrganizationById(id: string): BehaviorSubject<Organization> {
        firstValueFrom(this.organizationService.getOrganization(id)).then((organization) => {
            const newOrganization = new Organization(organization);
            this.selectedOrganization.next(newOrganization);
        });
        return this.selectedOrganization;
    }

    /* --------------------------------- Programs -------------------------------- */

    /* Given a program set it to be show inside the dashboard */
    setSelectedProgram(program: Program, forceRefresh: boolean = false) {
        if (program && this.selectedProgram.value?.id !== program.id || forceRefresh) {
            this.selectedProgram.next(program);
        }
    }

    destroySelectedProgram() {
        this.selectedProgram.next(null);
    }

    /* Given a program id, get the given program and set it to be shown inside dashboard */
    setSelectedProgramById(programId: string, forceRefresh: boolean = false) {
        if (programId) {
            this.programsService.get(programId).then((program) => {
                if (program) {
                    this.setSelectedProgram(new Program(program), forceRefresh);
                }
            });
        }
    }

    /* Get the program that should be shown on the program dashboard. */
    getSelectedProgram(): BehaviorSubject<Program> {
        return this.selectedProgram;
    }

    /** Reload the currently selected program. */
    refresh() {
        this.selectedProgram.value.refresh().then((data) => {
            this.selectedProgram.next(data);
        });
    }

    set patientCount(value) {
        this._patientCount.next(value);
    }

    get patientCount() {
        return this._patientCount;
    }

    reloadProductItems() {
        this.reloadProductItemsSubject.next();
    }

    set programAnalytics(value) {
        this._programAnalytics.next(value);
    }

    get programAnalytics() {
        return this._programAnalytics;
    }

    programChanged(id: string) {
        this.programChangedSubject.next(id);
    }
}
