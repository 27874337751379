<div class="rescheduleresponsedialog">
    <div class="d-flex">
        <div class=" bg-green p-3 text-center">
            <mat-icon class="check-icon">check</mat-icon>
        </div>
        <div class="f-2 p-2">
            <div class="d-flex">
                <h1 mat-dialog-title class="success-title">{{data.lanesAffected}}</h1>
                <button mat-button class="close-icon close-button" [mat-dialog-close]="true" (click)="reload()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            <div mat-dialog-content class="pl-0 pr-0">
                <p *ngIf="data.newHost">New Host : <span class="text-blue">{{data.newHost}}</span> </p>
                <p *ngIf="data.newDate">New Date : <span class="text-blue">{{data.newDate}}</span> </p>
                <div class="d-flex mt-3 ">
                    <p class="f-3 text-blue">{{data.patientAffected}}</p>
                    <p class="f-1 undo text-blue text-right update-undo" *ngIf="data.isUpdate" ><span class="undo" (click)="undoReschedulingUpdataes()">Undo</span></p>
                    <p class="f-1 undo text-blue text-right cancel-undo" *ngIf="data.isCancel" ><span class="undo" (click)="undoCancelLanes()">Undo</span></p>
                </div>
            </div>
        </div>
    </div>
</div>