import { BehaviorSubject } from 'rxjs';
import { InjectorInstance } from 'src/app/app.module';

import { Program } from '../programs/program.class';
import { ProgramPhase } from './program-phase.class';
import { IProgramPhaseRequest } from './program-phase.interface';
import { ProgramPhasesService } from './program-phases.service';

export class ProgramPhases {
    private _phasesClasses: BehaviorSubject<ProgramPhase[]> = new BehaviorSubject([]);
    private _parentProgram: Program;

    private programPhaseService = InjectorInstance.get(ProgramPhasesService);

    constructor(program: Program) {
        this.updateThisProgramPhase(program);
    }

    /* Internal function to fill this classes data */
    private updateThisProgramPhase(program: Program) {
        this._parentProgram = program;
        const newPhaseClasses = [];
        program.data.programPhases.forEach((phase) => {
            newPhaseClasses.push(new ProgramPhase(phase, program));
        });
        this._phasesClasses.next(newPhaseClasses);
    }

    /* returns an array of Phases contained within the program */
    get data(): BehaviorSubject<ProgramPhase[]> {
        return this._phasesClasses;
    }

    /* Returns the parent program which contains all the phases */
    get parent(): Program {
        return this._parentProgram;
    }

    /* Create a new phase within this parent program. */
    create(programPhase: Pick<Partial<IProgramPhaseRequest>, 'programId'> & Omit<IProgramPhaseRequest, 'programId'>) {
    // Add the parent id so the phase gets added user parent program.
        const newPhase: IProgramPhaseRequest = Object.assign(programPhase, { programId: this._parentProgram.id });
        return this.programPhaseService.create(newPhase).then((res) => {
            this._parentProgram.data.programPhases.push(res); // add to array so all subscribed programs receive an update
            this.updateThisProgramPhase(this._parentProgram); // push update to observable.
            return res;
        });
    }

    updatePhases(program: Program) {
        this.updateThisProgramPhase(program);
    }
}
