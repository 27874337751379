import { firstValueFrom, Observable, tap } from 'rxjs';
import { InjectorInstance } from 'src/app/app.module';

import { Base } from '../../classes/base.class';
import { IProduct, IProductRequest } from './products.interface';
import { ProductsService } from './products.service';

export class Product extends Base {
    private _data: IProduct;
    private productsService = InjectorInstance.get(ProductsService);

    constructor(product: IProduct) {
        super({ id: product.id, links: [] });
        this._data = product;
    }

    get data(): IProduct {
        return this._data;
    }

    create(productData: IProductRequest): Observable<Product> {
        return this.productsService.createProduct(productData);
    }

    /* Update the currently selected product.
   *  Example Usage:
   *    update({ productName: 'Tissue', ifu: 'Put on nose, and Blow', description: 'A soft paper to wipe your nose.' })
   */
    update(productData: Pick<Partial<IProduct>, 'id'> & Omit<IProduct, 'id'>): Promise<IProduct> {
        return firstValueFrom<IProduct>(
            this.productsService.updateProduct(Object.assign(productData, { id: this.data.id })).pipe(
                tap((data) => {
                    this._data = data;
                })
            )
        );
    }

    /* Get fresh data from the server for the given product.
   *  Example Usage:
   *    [productClass].refresh()
   */
    refresh(): Promise<Product> {
        return firstValueFrom<Product>(this.productsService.getProduct(this.id)).then((data) => {
            this._data = data.data;
            return this;
        });
    }

    /* Delete the currently selected product.
   *  Example Usage:
   *    [productClass].delete()
   */
    delete(): Observable<void> {
        return this.productsService.deleteProduct(this.id);
    }
}
