import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ObservableStore } from '@codewithdan/observable-store';
import { ReduxDevToolsExtension } from '@codewithdan/observable-store-extensions';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
} else {
    ObservableStore.globalSettings = { trackStateHistory: true };
    ObservableStore.addExtension(new ReduxDevToolsExtension());
}


Sentry.init({
    environment: environment.environmentType,
    enabled: environment.environmentType != 'local',
    dsn: 'https://2b2555c07cf172bc9e04b2390d73ec48@o4505999135539200.ingest.us.sentry.io/4507511013572608',
    integrations: [

        /*
         * Registers and configures the Tracing integration,
         * which automatically instruments your application to monitor its
         * performance, including custom Angular routing instrumentation
         */
        Sentry.browserTracingIntegration(),

        /*
         * Registers the Replay integration,
         * which automatically captures Session Replays
         */
        Sentry.replayIntegration()],

    /*
     * Set tracesSampleRate to 1.0 to capture 100%
     * of transactions for tracing.
     * We recommend adjusting this value in production
     */
    tracesSampleRate: 0.01,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/curapatient-be\.demo-hcc\.curapatient\.com\/api/],

    /*
     * Capture Replay for 10% of all sessions,
     * plus for 100% of sessions with an error
     */
    replaysSessionSampleRate: 0.001,
    replaysOnErrorSampleRate: 1.0,
});


platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
