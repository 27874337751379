import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { ProgramDashboardService } from 'src/app/modules/programs/services/program-dashboard.service';
import { ServiceBookingDataShareService } from 'src/app/shared/modules/service-booking/app-services/service-booking-data-share.service';

import { ProgramPhasesDashboardService } from '../../modules/patient-groups/pages/patient-group/pages/patient-group-patients/containers/program-phases/services/program-phases-dashboard/program-phases-dashboard.service';
import { PatientDataShareService } from '../../modules/patient-overview/shared/services/patient-data-share.service';
import { PatientService } from '../../modules/patients/services/patient.service';
import { BreadcrumbsService } from '../../shared/modules/breadcrumbs/breadcrumbs.service';
import { ApiService } from './api/api.service';
import { AssessmentService } from './assessment/assessment.service';
import { AuthorizationService } from './authorization/authorization.service';
import { CarePlanService } from './care-plan/care-plan.service';
import { DataBrokerService } from './data-broker/data-broker.service';
import { ImageService } from './image/image.service';
import { LanguageService } from './language/language.service';
import { LocationService } from './location/location.service';
import { MasterDataService } from './master-data/master-data.service';
import { ModalService } from './modal/modal.service';
import { NetworkService } from './network/network.service';
import { NoteService } from './note/note.service';
import { ScheduleService } from './organization/schedule.service';
import { ProviderService } from './provider/provider.service';
import { UserSessionService } from './user-session/user-session.service';
import { WebsocketService } from './websocket/websocket.service';

export const SERVICES: any[] = [
    ModalService,
    WebsocketService,
    UserSessionService,
    ProviderService,
    PatientService,
    NoteService,
    NetworkService,
    MasterDataService,
    LanguageService,
    ImageService,
    DataBrokerService,
    CarePlanService,
    BreadcrumbsService,
    AuthorizationService,
    AssessmentService,
    ApiService,
    ServiceBookingDataShareService,
    SnackbarService,
    ProgramDashboardService,
    ScheduleService,
    MatSnackBar,
    LocationService,
    PatientDataShareService,
    ProgramPhasesDashboardService,
];
