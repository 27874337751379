import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {BehaviorSubject} from 'rxjs';

import { Address } from '../../models/address.model';
import { GoogleMapsService } from '../google-maps/google-maps.service';
import GeocodingLibrary = google.maps.GeocodingLibrary;
import CoreLibrary = google.maps.CoreLibrary;

export interface IGpsLocation {
    gpsDisabled: boolean,
    address: Address,
    locationPulledVia: 'ZIP_CODE' | 'GPS' | 'CACHE' | 'NOT_FOUND';
}
/**
 *
 *
 * @export
 * @class LocationService
 * Tries to get users location based on their current location or tries to use an
 * older cached gps location if a new location is not available.
 */
@Injectable({
    providedIn: 'root'
})
export class LocationService {
    private currentLocation = new BehaviorSubject<IGpsLocation>({
        address: undefined,
        gpsDisabled: true,
        locationPulledVia: 'NOT_FOUND',
    });

    private initialized = false;
    googleMapsCoreLibrary: CoreLibrary;
    googleMapsGeocodingLibrary: GeocodingLibrary;
    geocoder: google.maps.Geocoder;

    constructor(private activeRoute: ActivatedRoute, private googleMapsService: GoogleMapsService) {
        this.init();
    }

    async init() {
        /** only init once */
        if (this.initialized) return;
        this.initialized = true;

        /** check url for zip code */
        const zipCodeFromRoute = Number(this.activeRoute.snapshot.queryParams.zipcode);
        await this.initMap();

        if (zipCodeFromRoute) {
            this.getAddressFromZipCode(zipCodeFromRoute);
        } else {
            /** Temporarily use cache until location is able to be pulled by gps */
            this.getLocationUsingCache();

            /** Try to get location using navigator api */
            this.getLocationUsingGPS();
        }
    }

    async initMap() {
        this.googleMapsGeocodingLibrary = await google.maps.importLibrary('geocoding') as GeocodingLibrary;
        this.googleMapsCoreLibrary = await google.maps.importLibrary('core') as CoreLibrary;
        this.geocoder = new this.googleMapsGeocodingLibrary.Geocoder();
    }

    getLocation() {
        return this.currentLocation;
    }

    cacheLocation(address: Address) {
        window.localStorage.setItem('userLocation', JSON.stringify(address));
    }

    private async getAddressFromZipCode(zipCode) {
        this.geocoder.geocode({ address: zipCode?.toString() }, (results, status) => {
            const {OK} = google.maps.GeocoderStatus;

            if (status === OK) {
                const newLocation = new Address();
                newLocation.zipcode = zipCode;
                newLocation.gpsLatitude = results[0].geometry.location.lat();
                newLocation.gpsLongitude = results[0].geometry.location.lng();
                this.setLocation(newLocation, true, 'ZIP_CODE');
            }
        });
    }

    private setLocation(address: Address, gpsDisabled: boolean, locationPulledVia: IGpsLocation['locationPulledVia']) {
        this.currentLocation.next({
            address,
            gpsDisabled,
            locationPulledVia
        });
    }

    private getLocationUsingCache() {
        const obj = window.localStorage.getItem('userLocation');

        if (obj) {
            const gps = JSON.parse(obj);
            if (gps?.gpsLatitude && gps?.gpsLongitude) {
                this.getAddressByLatLong(gps.gpsLatitude, gps.gpsLongitude)
                    .then((address) => {
                        this.setLocation(address, true, 'CACHE');
                    })
                    .catch((err) => {
                        throw new Error(err);
                    });
            }
        }
    }

    private getLocationUsingGPS() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { longitude } = position.coords;
                const { latitude } = position.coords;

                this.getAddressByLatLong(latitude, longitude)
                    .then((address) => {
                        window.localStorage.setItem('userLocation', JSON.stringify(address));
                        this.setLocation(address, false, 'GPS');
                    })
                    .catch((err) => {
                        throw new Error(err);
                    });
            });
        }
    }

    getAddressByLatLong(lat, lng): Promise<Address> {
        return new Promise((res, rej) => {
            const location = new this.googleMapsCoreLibrary.LatLng(lat, lng);

            this.geocoder.geocode({ location }, (results, status) => {
                const {OK} = google.maps.GeocoderStatus;

                if (status === OK) {
                    const address = this.googleMapsService.populateAddress(results[0]);
                    res(address);
                } else {
                    rej(status);
                }
            });
        });
    }
}
