import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { DateUtility } from '../../utils/date-utility';
import { UserSessionService } from '../user-session/user-session.service';

@Injectable({
    providedIn: 'root'
})
export class HeaderModifierInterceptorService {
    constructor(private session: UserSessionService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Include timezone header for all backend API's call exclude others e.g. keycloak
        if (request.url.indexOf(environment.apiUrl) == 0) {
            // Add X-Timezone in header
            request = request.clone({
                setHeaders: { 'X-TimeZone': DateUtility.prototype.getTimeZone() }
            });
        }
        return next.handle(request);
    }
}
