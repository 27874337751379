import { firstValueFrom } from 'rxjs';
import { InjectorInstance } from 'src/app/app.module';

import { Program } from './program.class';
import { IProgram, IProgramRequest } from './programs.interface';
import { ProgramsService } from './programs.service';

export class Programs {
    private organizationId: string;
    private programsService = InjectorInstance.get(ProgramsService);

    constructor(organizationId: string) {
        this.organizationId = organizationId;
    }

    /** Create a new program for a given organization */
    create(programDetails: IProgramRequest): any {
        if (!this.organizationId) throw new Error('organizationId not set.');
        return this.programsService.create(Object.assign(programDetails, { organizationId: this.organizationId }));
    }

    /** Enroll in a pre-existing program */
    enroll(programDetails: IProgramRequest): any {
        if (!this.organizationId) throw new Error('organizationId not set.');
        return this.programsService.enroll(Object.assign(programDetails, { organizationId: this.organizationId }));
    }

    /** Get a List of all available templates */
    getTemplates(): Promise<Program[]> {
        return firstValueFrom<Program[]>(this.programsService.getTemplates());
    }

    updateProgram(program: IProgram) {
        return this.programsService.update(program);
    }

    getProgram(programId: string): Promise<Program> {
        return this.programsService.get(programId)
            .then((data) => new Program(data));
    }
}
