<div class="model-container-box">
    <div class="headding">
        <h2 class="">
            <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="53.826" height="51.464" viewBox="0 0 53.826 51.464">
                    <defs>
                        <style>
                            .cls-1 {
                                fill: #ffa700;
                            }
                        </style>
                    </defs>
                    <g id="bx-error" transform="translate(-3.989 -4.707)">
                        <path
                            id="Path_64390"
                            data-name="Path 64390"
                            class="cls-1"
                            d="M16.5 15h5.762v14.406H16.5zm0 17.287h5.762v5.763H16.5z"
                            transform="translate(11.52 9.478)"
                        />
                        <path
                            id="Path_64391"
                            data-name="Path 64391"
                            class="cls-1"
                            d="M36 7.767a5.768 5.768 0 0 0-10.188 0L4.665 47.712a5.762 5.762 0 0 0 5.09 8.459h42.29a5.762 5.762 0 0 0 5.094-8.459zM9.755 50.409 30.9 10.466l21.161 39.943z"
                        />
                    </g>
                </svg>
            </span>
            {{ 'PATIENT_OVERVIEW.INVALID_DATA_ENTERED' | translate }}
        </h2>
    </div>
    <ul class="option-list">
        @for (error of errorMessage; track error) {
            <li>
                {{ error?.['message'].split("'")[0] }}
                <span>{{
                    error?.['message']?.split("'") && error?.['message'].split("'").length > 1
                        ? "'" + error?.['message'].split("'")[1] + "'"
                        : ''
                }}</span>
            </li>
        }
    </ul>
    <div class="action-btn">
        <button mat-button mat-dialog-close>{{ 'REGISTER_FORM.OK' | translate }}</button>
    </div>
</div>
