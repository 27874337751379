import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ObservableStore } from '@codewithdan/observable-store';
import {
    forkJoin, iif, Observable, of as observableOf, Subject
} from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { AssessmentAnswer, AssessmentQuestion } from '../../../../../core/models/assessment.model';
import { ResponseWithLinks } from '../../../../../core/models/common.model';
import { Link } from '../../../../../core/models/link.model';
import { TranslationISO } from '../../../../../core/models/translation.model';
import { AssessmentService } from '../../../../../core/services/assessment/assessment.service';
import { ImageService } from '../../../../../core/services/image/image.service';
import { NetworkService } from '../../../../../core/services/network/network.service';
import { StoreState } from '../../../../../core/store/store-state';
import { AssessmentOverviewService } from '../../../services/assessment-overview.service';
import { QuestionBase } from '../question-base';
import { QuestionsTranslationsService } from './questions-translations.service';

@Injectable({
    providedIn: 'root',
})
export class AssessmentQuestionsService extends ObservableStore<StoreState> {
    private reloadAssessment = new Subject<void>();
    public questionLoading = false;

    get questions() {
        return this.getState().assessmentOverview.questions;
    }

    get apiUrl() {
        const assessmentId = localStorage.getItem('current-assessment-id');
        return `/api/surveys/${assessmentId}/questions`;
    }

    constructor(
        private imageService: ImageService,
        private networkService: NetworkService,
        private assessmentService: AssessmentService,
        private assessmentOverviewService: AssessmentOverviewService,
        private questionsTranslationsService: QuestionsTranslationsService,
    ) {
        super({});
    }

    fetchQuestions(lang?: TranslationISO, reset = true): Observable<AssessmentQuestion[]> {
        const headers = lang ? { 'Content-Language': lang } : null;
        const httpParams = new HttpParams().set('page', '0').set('size', '100');

        this.questionLoading = true;

        if (reset) {
            this.resetQuestions();
        }

        return this.networkService
            .fetchResource<ResponseWithLinks<AssessmentQuestion>>(this.apiUrl, { headers, params: httpParams })
            .pipe(
                map<ResponseWithLinks<AssessmentQuestion>, AssessmentQuestion[]>(({ content }) => content),
                map((questions) => {
                    const questionsObj: { [key: string]: any } = { questions };

                    if (lang === TranslationISO.en) {
                        questionsObj.defaultQuestions = questions;
                    }

                    this.setState(
                        (prevState) => ({
                            assessmentOverview: {
                                ...prevState.assessmentOverview,
                                ...questionsObj,
                            },
                        }),
                        AssessmentQuestionsStateActions.GetAssessmentQuestionsAction,
                    );

                    this.questionLoading = false;

                    return questions;
                }),
            );
    }

    getQuestions(lang?: any): Observable<AssessmentQuestion[]> {
        const state = this.getState();

        if (state && state.assessmentOverview && state.assessmentOverview.questions) {
            return observableOf(state.assessmentOverview.questions);
        }

        return this.fetchQuestions(lang);
    }

    resetQuestions() {
        this.setState(
            (prevState) => ({
                assessmentOverview: {
                    ...prevState.assessmentOverview,
                    questions: null,
                },
            }),
            AssessmentQuestionsStateActions.ResetQuestions,
        );
    }

    updateQuestion(surveyId: string, question): Observable<AssessmentQuestion> {
        const lang = this.questionsTranslationsService.language;
        const headers = lang ? { 'Content-Language': lang } : null;
        const url = `${this.apiUrl}/${question.id}`;

        return this.networkService.putResource<AssessmentQuestion>(url, question, { headers });
    }

    updateLocalQuestion(newQuestion: AssessmentQuestion) {
        const {
            assessmentOverview: { questions },
        } = this.getState();
        const questionIndex = this.questions.findIndex((q) => q.id === newQuestion.id);

        if (questionIndex >= 0) {
            const newQuestions = [...questions];

            newQuestions[questionIndex] = newQuestion;

            this.setState(
                (prevState) => ({
                    assessmentOverview: {
                        ...prevState.assessmentOverview,
                        questions: newQuestions,
                    },
                }),
                AssessmentQuestionsStateActions.UpdateQuestionAction,
            );
        }
    }

    addQuestion(): void {
        const {
            assessmentOverview: { questions },
        } = this.getState();

        questions.push(new QuestionBase({ rank: questions.length + 1, questionType: 'Other' }).value);

        this.setState(
            (prevState) => ({ assessmentOverview: { ...prevState.assessmentOverview, questions } }),
            AssessmentQuestionsStateActions.AddQuestionAction,
        );
    }

    createQuestion(question, lang: string, surveyId: string): Observable<AssessmentQuestion> {
        const headers = lang ? { 'Content-Language': lang } : null;

        if (!surveyId) return observableOf(null);

        return this.networkService.postResource<AssessmentQuestion>(this.apiUrl, question, { headers });
    }

    updateQuestionImage(surveyId: string, questionId: string, imageId: string): Observable<Link> {
        const url = `/api/surveys/${surveyId}/questions/${questionId}/image`;

        return this.networkService.putResource<Link>(url, { imageId });
    }

    saveChoices(questionId, choices: AssessmentAnswer[]): Observable<any> {
        const lang = this.questionsTranslationsService.language;
        const choices$ = choices.map((choice) => iif(
            () => Boolean(choice.id),
            this.updateChoice(questionId, choice, lang),
            this.addChoice(questionId, choice, lang),
        ),);

        return forkJoin(choices$);
    }

    addChoice(questionId: string, choice: AssessmentAnswer, lang: string): Observable<AssessmentAnswer> {
        return this.assessmentService.createAnswer(questionId, choice, lang).pipe(
            map((assessmentAnswer) => {
                assessmentAnswer.offset = choice.offset;
                this.addLocalChoice(questionId, assessmentAnswer, lang);

                return assessmentAnswer;
            }),
        );
    }

    addLocalChoice(id: string, choice: AssessmentAnswer, lang: string): void {
        const { questions, defaultQuestions } = this.getState().assessmentOverview;
        const questionIndex = questions.findIndex((q) => q.id === id);

        if (questionIndex >= 0) {
            const question = questions[questionIndex];

            question.choiceList.push(choice);

            const newState: { [key: string]: any } = { questions };

            if (lang === TranslationISO.en) {
                const defaultQuestion = defaultQuestions[questionIndex];

                defaultQuestion.choiceList.push(choice);

                newState.defaultQuestions = defaultQuestions;
            }

            this.setState(
                (prevState) => ({
                    assessmentOverview: { ...prevState.assessmentOverview, ...newState },
                }),
                AssessmentQuestionsStateActions.AddAnswerAction,
            );
        }
    }

    updateChoice(questionId: string, answer: AssessmentAnswer, lang: string): Observable<AssessmentAnswer> {
        return this.assessmentService.updateAnswer(answer, lang).pipe(
            map(() => {
                this.updateLocalChoice(questionId, answer, lang);

                return answer;
            }),
        );
    }

    updateLocalChoice(questionId: string, choice: AssessmentAnswer, lang: string): void {
        const { questions, defaultQuestions } = this.getState().assessmentOverview;
        const questionIndex = questions.findIndex((q) => q.id === questionId);

        if (questionIndex >= 0) {
            const choices = questions[questionIndex].choiceList;
            const choiceIndex = choices.findIndex((c) => c.id === choice.id);

            if (choiceIndex >= 0) {
                choices[choiceIndex] = choice;
            }

            const newState: { [key: string]: any } = { questions };

            if (lang === TranslationISO.en) {
                const choices = defaultQuestions[questionIndex].choiceList;
                const choiceIndex = choices.findIndex((c) => c.id === choice.id);

                if (choiceIndex >= 0) {
                    choices[choiceIndex] = choice;
                }

                newState.defaultQuestions = defaultQuestions;
            }

            this.setState(
                (prevState) => ({ assessmentOverview: { ...prevState.assessmentOverview, ...newState } }),
                AssessmentQuestionsStateActions.UpdateAnswerAction,
            );
        }
    }

    duplicateQuestion(question: QuestionBase): void {
        const {
            assessmentOverview: { questions },
        } = this.getState();
        const duplicate = question.duplicate();

        questions.splice(duplicate.rank - 1, 0, duplicate);

        this.setState(
            (prevState) => ({
                assessmentOverview: {
                    ...prevState.assessmentOverview,
                    questions: questions.map((q, i) => {
                        if (i === duplicate.rank) {
                            return { ...q, rank: q.rank + 1 };
                        }
                        return q;
                    }),
                },
            }),
            'duplicate_question',
        );
    }

    deleteQuestion(questionId: string): Observable<boolean> {
        return this.networkService.deleteResource(`${this.apiUrl}/${questionId}`).pipe(
            map(() => true),
            catchError(() => observableOf(false)),
            tap(() => {
                this.deleteLocalQuestions(questionId);
            }),
        );
    }

    deleteLocalQuestions(id: string): void {
        const { questions, defaultQuestions } = this.getState().assessmentOverview;
        const questionIndex = questions.findIndex((q) => q.id === id);

        if (questionIndex >= 0) {
            questions.splice(questionIndex, 1);

            const newState: { [key: string]: any } = { questions };

            if (this.questionsTranslationsService.isSelectedDefaultLang) {
                defaultQuestions.splice(questionIndex, 1);

                newState.defaultQuestions = defaultQuestions;
            }

            this.setState(
                (prevState) => ({ assessmentOverview: { ...prevState.assessmentOverview, ...newState } }),
                AssessmentQuestionsStateActions.DeleteQuestionAction,
            );
        }

        this.updateNumOfQuestions(questions.length);
    }

    updateNumOfQuestions(length) {
        const {
            assessmentOverview: { assessment },
        } = this.getState();

        this.assessmentOverviewService.updateAssessment({ ...assessment, numOfQuestions: length });
    }

    deleteChoice(questionId: string, choiceId): Observable<boolean> {
        return this.assessmentService.deleteAnswer(choiceId).pipe(
            map(() => {
                this.deleteLocalChoice(questionId, choiceId);

                return true;
            }),
            catchError(() => observableOf(false)),
        );
    }

    private deleteLocalChoice(questionId: string, choiceId: string): void {
        const { questions, defaultQuestions } = this.getState().assessmentOverview;
        const questionIndex = questions.findIndex((q) => q.id === questionId);

        if (questionIndex >= 0) {
            const choices = questions[questionIndex].choiceList;
            const choiceIndex = choices.findIndex((c) => c.id === choiceId);

            if (choiceIndex >= 0) {
                choices.splice(choiceIndex, 1);
            }

            const newState: { [key: string]: any } = { questions };

            if (this.questionsTranslationsService.isSelectedDefaultLang) {
                const choices = defaultQuestions[questionIndex].choiceList;
                const choiceIndex = choices.findIndex((c) => c.id === choiceId);

                if (choiceIndex >= 0) {
                    choices.splice(choiceIndex, 1);
                }

                newState.defaultQuestions = defaultQuestions;
            }

            this.setState(
                (prevState) => ({ assessmentOverview: { ...prevState.assessmentOverview, ...newState } }),
                AssessmentQuestionsStateActions.DeleteAnswerAction,
            );
        }
    }

    getDefaultQuestion(index: number) {
        const state = this.getState();

        if (
            state
      && state.assessmentOverview
      && state.assessmentOverview.defaultQuestions
      && state.assessmentOverview.defaultQuestions.length
        ) {
            return state.assessmentOverview.defaultQuestions[index];
        }
        return null;
    }

    triggerAssessmentReload() {
        this.reloadAssessment.next();
    }

    getAssessmentReloadTrigger(): Observable<any> {
        return this.reloadAssessment.asObservable();
    }

    emptyQuestionFields(question: AssessmentQuestion): AssessmentQuestion {
        return {
            ...question,
            question: '',
            questionShortName: '',
            description: '',
            subtitle: '',
            choiceList: question.choiceList.map((cL) => ({
                ...cL,
                answerText: '',
            })),
        };
    }

    updateQuestionOrder(prevIndex, currIndex) {
        const state = this.getState();
        if (state && state.assessmentOverview && state.assessmentOverview.questions) {
            const { questions } = state.assessmentOverview;
            const newState: { [key: string]: any } = { questions };
            const currQuestion = questions[currIndex];
            const prevQuestion = questions[prevIndex];

            questions.splice(prevIndex, 1, currQuestion);
            questions.splice(currIndex, 1, prevQuestion);

            if (this.questionsTranslationsService.isSelectedDefaultLang) {
                newState.defaultQuestions = questions;
            }

            this.setState((prevState) => ({ assessmentOverview: { ...prevState.assessmentOverview, ...newState } }), 'aaaaa');
        }
    }

    ascSort = (a, b) => (a > b ? 1 : a < b ? -1 : 0);
}

// eslint-disable-next-line no-shadow
export enum AssessmentQuestionsStateActions {
    GetAssessmentQuestionsAction = 'get_assessment_questions',
    AddQuestionAction = 'add_question',
    SaveQuestionAction = 'save_question',
    DeleteQuestionAction = 'delete_question',
    AddAnswerAction = 'add_answer',
    UpdateAnswerAction = 'update_answer',
    DeleteAnswerAction = 'delete_answer',
    ResetQuestions = 'reset_questions',
    UpdateQuestionAction = 'update_question',
}
