import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, Observable, throwError } from 'rxjs';
import { GlobalErrorHandlerModalComponent } from 'src/app/shared/modals/global-error-handler-modal/global-error-handler-modal.component';
import { switchMap } from 'rxjs/operators';
import { AuthorizationService } from '../authorization/authorization.service';
import { UserSessionService } from '../user-session/user-session.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
    urlsToNotUse = ['accounts'];

    constructor(
        public router: Router,
        private dialog: MatDialog,
        private authService: AuthorizationService,
        private session: UserSessionService,
    ) {}

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            catchError((error) => {
                if (error.status === 401 || error.status === 403) {
                    return this.handle401Error(req, next, error);
                }
                if (this.isValidRequestForInterceptor(req.url) && error.status === 422) {
                    if (error.error.fieldErrors && error.error.fieldErrors.length) {
                        this.dialog.open(GlobalErrorHandlerModalComponent, {
                            data: { errors: error.error.fieldErrors, fieldErrors: true },
                        });
                    }
                    return throwError(() => error);
                }
                return throwError(() => error);
            }),
        );
    }

    private handle401Error(
        request: HttpRequest<unknown>,
        next: HttpHandler,
        originalError: unknown,
    ): Observable<HttpEvent<unknown>> {
        if (this.authService.isLogged()) {
            return this.authService.refreshToken().pipe(
                switchMap(() => {
                    const updatedRequest = request.clone({
                        headers: request.headers
                            .delete('Authorization')
                            .set('Authorization', `Bearer ${this.session.token.access_token}`),
                    });
                    return next.handle(updatedRequest);
                }),
                catchError(() => {
                    this.router.navigate(['/login']).then(() => throwError(originalError));
                    return throwError(originalError);
                }),
            );
        }
    }

    private isValidRequestForInterceptor(requestUrl: string): boolean {
        const positionIndicator: string = 'api/';
        const position = requestUrl.indexOf(positionIndicator);

        if (position > 0) {
            const destination: string = requestUrl.substr(position + positionIndicator.length);

            for (const address of this.urlsToNotUse) {
                if (new RegExp(address).test(destination)) {
                    return false;
                }
            }
        }
        return true;
    }
}
