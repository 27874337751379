import { firstValueFrom } from 'rxjs';
import { InjectorInstance } from 'src/app/app.module';

import { Base } from '../../classes/base.class';
import { ProgramPhases } from '../phases/program-phases.class';
import { ProductItems } from '../product-item/product-items.class';
import { IProgram } from './programs.interface';
import { ProgramsService } from './programs.service';

export class Program extends Base {
    /* Current Programs Initial Data */
    private _programData: IProgram;
    private _productItems: ProductItems;
    private _programPhases: ProgramPhases;
    private programsService = InjectorInstance.get(ProgramsService);

    constructor(program: IProgram) {
        super({ id: program.id, links: [] });
        this.updateProgram(program);
    }

    /* Internal function to populate this program with correct data */
    private updateProgram(program: IProgram) {
        this.id = program.id;
        this._programData = program;
        this._productItems = new ProductItems(program);
        if (program.programPhases) {
            this._programPhases = new ProgramPhases(this);
        }
    }

    /* Retrieve all product items contained within this program */
    productItems() {
        return this._productItems;
    }

    /* Return all phases within the given program */
    programPhases() {
        return this._programPhases;
    }

    /* returns unprocessed data which was received from the server */
    get data(): IProgram {
        return this._programData;
    }

    /* Update the current program with new data */
    update(data: Partial<IProgram>) {
        if (!this._programData?.id) throw Error('Program Id Not found');

        const newData = Object.assign(this._programData, data);
        delete newData.carePlans;

        return this.programsService.update(newData).then((res) => {
            this.updateProgram(res);
        });
    }

    /* Update profile image for the current program */
    updateImage(imageData: string) {
        const link = `/api/programs/${this._programData.id}/image`;
        return firstValueFrom(
            this.programsService.updateImage({
                link,
                imageData,
            }),
        );
    }

    /* Remove image from the current program */
    deleteImage() {
        const imageLink = this._programData.links.filter((link) => link.rel === 'image')[0].href;
        return firstValueFrom(this.programsService.deleteImage(imageLink));
    }

    /* Get new data from the server for this program */
    refresh() {
        if (!this._programData?.id) throw Error('Program Id Not found');
        return this.programsService.get(this._programData.id).then((data) => {
            this.updateProgram(data);
            return new Program(data);
        });
    }

    /* Remove this program from the server. */
    delete() {
        if (!this._programData?.id) throw Error('Program Id Not found');
        return this.programsService.deleteProgramById(this._programData?.id);
    }
}
