import { Link } from './link.model';

export class CPLocation {
    address1: string;
    address2: string;
    city: string;
    country: string;
    state: string;
    gpsLatitude: number;
    gpsLongitude: number;
    id: string;
    name: string;
    zipcode: string;
    locationType: string;
    locationName: string;
    links: Link[];
}

export type ILocationTypeName = 'Clinic' | 'Home' | 'Other';
