import { Injectable } from '@angular/core';
import { ObservableStore } from '@codewithdan/observable-store';

import { StoreState } from '../../../core/store/store-state';

@Injectable({
    providedIn: 'root',
})
export class ReferralCodeService extends ObservableStore<StoreState> {
    constructor() {
        super({});
    }

    setReferralCode(referralCode: string): void {
        this.setState({ referralCode }, ReferralCodeActions.setReferralCode);
    }

    updateReferralCode(referralCode: string) {
        this.setState({ referralCode }, ReferralCodeActions.updateReferralCode);
    }
}

// eslint-disable-next-line no-shadow
enum ReferralCodeActions {
    setReferralCode = 'set_referral_code',
    updateReferralCode = 'update_referral_code',
}
