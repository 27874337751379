import {
    HttpClient, HttpErrorResponse, HttpHeaders, HttpParams
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ObservableStore } from '@codewithdan/observable-store';
import { UntilDestroy } from '@ngneat/until-destroy';
import { format, startOfDay } from 'date-fns';
import * as fileSaver from 'file-saver';
import {
    Observable, of as observableOf, Subject, throwError
} from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HourlyCovidVaccinationCount } from 'src/app/shared/components/calendar/calendar.component';

import { environment } from '../../../../environments/environment';
import {
    BranchModel,
    ExtendedOrganizationModel,
    IOrganization,
    OrganizationModel,
} from '../../../core/models/organization.model';
import { NetworkService } from '../../../core/services/network/network.service';
import { ExtendedClinic, OrganizationService } from '../../../core/services/organization/organization.service';
import { UserSessionService } from '../../../core/services/user-session/user-session.service';
import { StoreState } from '../../../core/store/store-state';
import { ClinicAnalyticsInfo } from '../org-overview/org-middle-dashboard/org-clinic-list/org-clinic-list.component';
import { AppointmentForClinic } from '../org-overview/org-middle-dashboard/org-patient-groups-in-clinic/org-patient-groups-in-clinic.component';
import { ReferralCodeService } from './referral-code.service';

export const ORGANIZATION_ID = 'organization-id';
export const ORGANIZATION_ID_SCHEDULE = 'organization-id-schedule';
export const ORGANIZATION_ID_REGISTER_NEW_PATIENT = 'organization-id-register-new-patient';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class OrgOverviewService extends ObservableStore<StoreState> {
    private _invitationSend = new Subject<string[]>();
    public invitationSend$ = this._invitationSend.asObservable();
    private _clinicAdded = new Subject<IOrganization>();
    public clinicAdded$ = this._clinicAdded.asObservable();

    constructor(
        private networkService: NetworkService,
        private organizationService: OrganizationService,
        private referralCodeService: ReferralCodeService,
        private session: UserSessionService,
        private http: HttpClient,
    ) {
        super({});
        this.setState({ performerOrganization: null }, 'init_performer_org');
    }

    fetchOrgOverview(orgId?: string): Observable<OrganizationModel> {
        const performerOrgId = this.session.sessionAccount.organizationId;
        if (!orgId) orgId = localStorage.getItem(ORGANIZATION_ID) || performerOrgId;

        return this.organizationService.fetchOrgOverview(orgId).pipe(
            map((res) => {
                this.setState({ performerOrganization: res }, OrgOverviewStateActions.PerformerOrganization);
                if (res.referralCode) {
                    this.referralCodeService.setReferralCode(res.referralCode);
                }

                return res;
            }),
            catchError(this.handleError)
        );
    }

    // fetchOrgBasicOverview(orgId?: string): Observable<OrganizationModel> {
    //   const performerOrgId = this.session.sessionAccount.organizationId;
    //   if (!orgId) orgId = localStorage.getItem(ORGANIZATION_ID) || performerOrgId;
    //
    //   return this.organizationService.fetchOrgBasicOverview(orgId).pipe(
    //     map((res) => {
    //       this.setState({performerOrganization: res}, OrgOverviewStateActions.PerformerOrganization);
    //
    //       return res;
    //     }),
    //     catchError(this.handleError)
    //   );
    // }

    getOrgOverview(orgId?: string): Observable<OrganizationModel> {
        const { performerOrganization } = this.getState();

        if (performerOrganization && (!orgId || orgId == performerOrganization.id)) {
            return observableOf(performerOrganization);
        }

        return this.fetchOrgOverview(orgId);
    }

    // getOrgBaiscOverview(orgId?: string): Observable<OrganizationModel> {
    //   const {performerOrganization} = this.getState();
    //
    //   if (performerOrganization) {
    //     return observableOf(performerOrganization);
    //   }
    //
    //   return this.fetchOrgBasicOverview(orgId);
    // }

    clearOrgOverview() {
        this.setState({ performerOrganization: null }, OrgOverviewStateActions.ClearOrgOverview);
    }

    // fetchOrgProviders(orgId: string): Observable<ResponseWithLinks<PerformerModel>> {
    //   return this.organizationService.getOrgProviders(orgId).pipe(
    //     map((res) => {
    //       this.setState(
    //         (prevState) => ({
    //           performerOrganization: {
    //             ...prevState.performerOrganization,
    //             performers: res,
    //           },
    //         }),
    //         OrgOverviewStateActions.AddPerformerOrgPerformers
    //       );
    //       return res;
    //     })
    //   );
    // }

    // getOrgProviders(orgId: string): Observable<ResponseWithLinks<PerformerModel>> {
    //   if (!orgId) orgId = localStorage.getItem(ORGANIZATION_ID) || this.session.sessionAccount.organizationId;
    //
    //   return this.fetchOrgProviders(orgId);
    // }

    exportPatients(
        organization: ExtendedOrganizationModel,
        {
            asc = null,
            orderType = null,
            name = null,
            carePlanStatus = null,
            occupation = null,
            priority = null,
            ageGroups = '',
            ethnicity = '',
            postal_code = '',
            races = '',
            patientCustomIdentifiers = '',
            gender = '',
        } = {}
    ): Observable<any> {
        const url = `/api/organizations/${organization.id}/patients/csv/download`;
        let httpParams = new HttpParams();

        if (asc) httpParams = httpParams.set('asc', asc);
        if (orderType) httpParams = httpParams.set('orderType', orderType);
        if (name) httpParams = httpParams.set('name', name);
        if (carePlanStatus) httpParams = httpParams.set('carePlanStatus', carePlanStatus);
        if (occupation) httpParams = httpParams.set('occupation', occupation);
        if (priority) httpParams = httpParams.set('priority', priority);
        if (ageGroups) httpParams = httpParams.set('ageGroups', ageGroups);
        if (ethnicity) httpParams = httpParams.set('ethnicity', ethnicity);
        if (postal_code) httpParams = httpParams.set('postal_code', postal_code);
        if (races) httpParams = httpParams.set('races', races);
        if (patientCustomIdentifiers) httpParams = httpParams.set('patientCustomIdentifiers', patientCustomIdentifiers);
        if (gender) httpParams = httpParams.set('gender', gender);

        return this.networkService.fetchResource(url, { params: httpParams, responseType: 'blob' });
    }

    exportHomeBound(orgId: string, { statuses, startDate, endDate }) {
        const url = `/api/organizations/${orgId}/homebound/csv/download`;
        let httpParams = new HttpParams()
            .set('startDate', format(startOfDay(startDate), 'MM/dd/yyyy'))
            .set('endDate', format(startOfDay(endDate), 'MM/dd/yyyy'));

        statuses.forEach((status) => (httpParams = httpParams.append('status', status)));

        return this.networkService
            .fetchResource<BlobPart>(url, { params: httpParams, responseType: 'blob' })
            .pipe(
                map((file) => {
                    const blob = new Blob([file], { type: 'text/csv' });
                    fileSaver.saveAs(blob, 'housebound.csv');
                })
            );
    }

    exportAppointment(startDate: any, endDate: any, org: any) {
        const url = `/api/organizations/${org.id}/appointments/excel/download`;
        let httpParams = new HttpParams();
        httpParams = httpParams.append('startDate', startDate);
        httpParams = httpParams.append('endDate', endDate);
        this.networkService.fetchResource(url, { params: httpParams, responseType: 'blob' }).subscribe((file: any) => {
            const blob = new Blob([file], { type: 'application/vnd.ms-excel' });
            fileSaver.saveAs(blob, `${org.name}_appointments.csv`);
        });
    }

    exportCompletedTests(startDate: any, endDate: any, org: any) {
        const url = `/api/organizations/${org.id}/completed-tests/excel/download`;
        let httpParams = new HttpParams();
        httpParams = httpParams.append('startDate', startDate);
        httpParams = httpParams.append('endDate', endDate);
        this.networkService.fetchResource(url, { params: httpParams, responseType: 'blob' }).subscribe((file: any) => {
            const blob = new Blob([file], { type: 'application/vnd.ms-excel' });
            fileSaver.saveAs(blob, `${org.name}_completed_tests.csv`);
        });
    }

    exportBilling({ startDate, endDate, insured }: { startDate: string; endDate: string; insured: boolean }, org: any) {
        const url = `/api/organizations/${org.id}/billing/excel/download`;
        let httpParams = new HttpParams();

        httpParams = httpParams.append('startDate', startDate);
        httpParams = httpParams.append('endDate', endDate);
        if (insured) {
            httpParams = httpParams.append('insured', insured);
        }

        return this.networkService.fetchResource(url, { params: httpParams, responseType: 'blob' }).pipe(
            tap((file: any) => {
                const blob = new Blob([file], { type: 'application/vnd.ms-excel' });
                fileSaver.saveAs(blob, `${org.name}_billing.csv`);
            })
        );
    }

    exportFailedRows(uploadLink: string, uploadName: string) {
        const url = `${uploadLink}/download`;

        this.networkService.fetchResource(url, { responseType: 'blob' }).subscribe((file: any) => {
            const blob = new Blob([file], { type: 'text/csv' });
            fileSaver.saveAs(blob, `${uploadName}-failed.csv`);
        });
    }

    addedClinic(clinic?: IOrganization) {
        this._clinicAdded.next(clinic);
    }

    getClinicAppointmentsByDateRange(orgId, startDate, endDate) {
        const url = `/api/organizations/${orgId}/clinic-appointments`;
        let httpParams = new HttpParams();
        httpParams = httpParams.append('startDate', startDate);

        if (endDate) {
            httpParams = httpParams.append('endDate', endDate);
        }

        return this.networkService.fetchResource<AppointmentForClinic[]>(url, { params: httpParams });
    }

    getAllActiveClinicAppointment(orgId) {
        const url = `/api/organizations/clinic-patient-groups/${orgId}/clinic-appointment`;
        return this.networkService.fetchResource<AppointmentForClinic[]>(url);
    }

    getAllCommunityClinicAppointments(parentOrgId: string, startDate: string, endDate: string) {
        const url = `/api/organizations/clinic-patient-groups/${parentOrgId}/all`;
        let httpParams = new HttpParams();
        httpParams = httpParams.append('start-date', startDate);
        httpParams = httpParams.append('end-date', endDate);
        return this.networkService.fetchResource<AppointmentForClinic[]>(url, { params: httpParams });
    }

    /* getAllMobileVaccineServiceClinicStats(startDate: string, endDate: string) {
    const url = `/api/organizations/clinics-vaccination-status/with-date-range`;
    let httpParams = new HttpParams();
    httpParams = httpParams.append('startDate', startDate);
    httpParams = httpParams.append('endDate', endDate);
    return this.networkService.fetchResource<AppointmentForClinic[]>(url, {params: httpParams});
  } */

    getAllMobileVaccineServiceClinicStatsByOrg(startDate: string, endDate: string, parentOrgId: string) {
        const url = `/api/organizations/clinics-vaccination-status/with-date-range/${parentOrgId}`;
        let httpParams = new HttpParams();
        httpParams = httpParams.append('startDate', startDate);
        httpParams = httpParams.append('endDate', endDate);
        return this.networkService.fetchResource<AppointmentForClinic[]>(url, { params: httpParams });
    }

    scheduleClinicAppointment(body: any, defaultClinicId: string) {
        const url = `/api/organizations/${defaultClinicId}/clinic-appointment`;
        return this.networkService.putResource(url, body);
    }

    /* getClinicStats(clinicId: string, startDate: string, endDate: string) {
    const url = `/api/organizations/${clinicId}/clinic-appointments`;
    let httpParams = new HttpParams();
    httpParams = httpParams.append('startDate', startDate);
    httpParams = httpParams.append('endDate', endDate);
    return this.networkService.fetchResource<any>(url, {params: httpParams});
  } */

    createOrganization(body: any) {
        const url = '/api/organizations';
        return this.networkService.postResource<any>(url, body);
    }

    changeOrganizationApprovalStatus(orgId: any, body: any) {
        const url = `/api/organizations/${orgId}/change-status`;
        return this.networkService.putResource(url, body);
    }

    changeOrganizationApprovalStatusOnEventCreation(orgId: any) {
        const url = `/api/organizations/${orgId}/approvalStatus`;
        return this.networkService.postResource(url, {});
    }

    getClinicAnalytics(orgId, startDate) {
        const url = `/api/organizations/${orgId}/clinics-analytics`;
        let httpParams = new HttpParams();
        httpParams = httpParams.append('startDate', startDate);

        return this.networkService.fetchResource<ClinicAnalyticsInfo[]>(url, { params: httpParams });
    }
    getClinicAnalyticsAll(orgId, startDate, endDate) {
        const url = `/api/organizations/${orgId}/clinics-analytics-all`;
        let httpParams = new HttpParams();
        httpParams = httpParams.append('startDate', startDate);
        httpParams = httpParams.append('endDate', endDate);
        return this.networkService.fetchResource<ClinicAnalyticsInfo[]>(url, { params: httpParams });
    }

    getBranches(
        orgId: string,
        params?: { size?: number; page?: number; types?: string | string[]; search?: string },
        stats = false
    ): Observable<any> {
        const url = `/api/organizations/${orgId}/branches`;
        let httpParams = new HttpParams();

        if (params) {
            const {
                size = -1, page = -1, types = '', search = undefined
            } = params;
            if (size) httpParams = httpParams.append('size', size.toString());
            if (page >= 0) httpParams = httpParams.append('page', page.toString());
            if (search) httpParams = httpParams.append('name', search);
            if (types) {
                if (Array.isArray(types)) {
                    (types as []).forEach((t) => (httpParams = httpParams.append('type', t)));
                } else {
                    const type = types as string;

                    httpParams = httpParams.append('type', type);
                }
            }
        }
        if (stats) httpParams = httpParams.append('stats', true);

        return this.networkService.fetchResource<any>(url, { params: httpParams });
    }

    getOrganizationDataByType(
        orgId: string,
        params?: { size?: number; page?: number; types?: string | string[] }
    ): Observable<any> {
        const url = `/api/organizations/${orgId}/organization-branches-count`;
        let httpParams = new HttpParams();

        if (params) {
            const { size = -1, page = -1, types = '' } = params;

            if (size) httpParams = httpParams.append('size', size.toString());
            if (page >= 0) httpParams = httpParams.append('page', page.toString());
            if (types) {
                if (Array.isArray(types)) {
                    (types as []).forEach((t) => (httpParams = httpParams.append('type', t)));
                } else {
                    const type = types as string;

                    httpParams = httpParams.append('type', type);
                }
            }
        }

        return this.networkService.fetchResource<any>(url, { params: httpParams });
    }

    getCommunityGroupandSchoolEvents(
        orgId: string,
        startDate,
        endDate,
        params?: { page?: number; status: string, orgType?: string }
    ): Observable<any> {
        const url = `/api/organizations/${orgId}/communitygroup-and-school-pending-events`;
        let httpParams = new HttpParams();

        if (params) {
            const { page = -1, status, orgType } = params;

            if (page >= 0) httpParams = httpParams.append('page', page.toString());
            if (status) httpParams = httpParams.append('status', status);
            if (orgType) httpParams = httpParams.append('type', orgType);
        }
        if (startDate) httpParams = httpParams.append('startDate', startDate);
        if (endDate) httpParams = httpParams.append('endDate', endDate);
        return this.networkService.fetchResource<any>(url, { params: httpParams });
    }

    getTriageEvents(orgId: string, startDate, endDate, params?: { page?: number; status: string }): Observable<any> {
        const url = `/api/organizations/${orgId}/triage-events`;
        let httpParams = new HttpParams();

        if (params) {
            const { page = -1, status } = params;

            if (page >= 0) httpParams = httpParams.append('page', page.toString());
            if (status) httpParams = httpParams.append('status', status);
        }
        if (startDate) httpParams = httpParams.append('startDate', startDate);
        if (endDate) httpParams = httpParams.append('endDate', endDate);
        return this.networkService.fetchResource<any>(url, { params: httpParams });
    }

    getAllMobileVaccineServicePodTypeClinics() {
        return this.networkService.fetchResource<any>('/api/organizations/clinics/pod-type/mobile-vaccine-service', {});
    }

    // getClinicIds(date): Observable<any> {
    //   const url = `/api/organizations/${date}/getClinicIds`;
    //   return this.networkService.fetchResource<any>(url);
    // }

    getHourlyCovidVaccinationCount(orgId, startDate, endDate): Observable<HourlyCovidVaccinationCount[]> {
    // let sample: HourlyCovidVaccinationCount []
    // = [{start:startDate, end: moment(startDate).add(1, "h").toDate(), meta: {
    //   scheduled: 5, completed:5, capacity: 20}},
    //   {start:moment(startDate).add(1, "h").toDate(), end: moment(startDate).add(2, "h").toDate(), meta: {
    //     scheduled: 10, completed:10, capacity: 40}}];
    // return sample;
        const url = `/api/organizations/${orgId}/hourly-clinic-counts/${startDate}/${endDate}`;
        return this.networkService.fetchResource<HourlyCovidVaccinationCount[]>(url);
    }

    // getQueues(organizationId: string): Observable<any[]> {
    //   const url = `/api/organizations/${organizationId}/queues`;
    //
    //   return this.networkService.fetchResource(url);
    // }

    public getOrgClinics(orgId): Observable<any[]> {
        const url = `/api/organizations/parent-clinics/${orgId}`;
        return this.networkService.fetchResource(url);
    }

    getPatientsDepartmentsList(orgId: string): Observable<string[]> {
        const url = `/api/organizations/${orgId}/patients-departments`;

        return this.networkService.fetchResource(url);
    }

    getOrganizationCounts(orgId: string): Observable<any> {
        const url = `/api/organizations/${orgId}/status-aggregation`;
        return this.networkService.fetchResource(url);
    }

    getOrganizationOverviewCounts(orgId: string): Observable<any> {
        const url = `/api/organizations/${orgId}/overview-card-analytics`;
        return this.networkService.fetchResource(url);
    }

    updateCommunityGroupClinic(organizationId: any, newClinicId: any) {
        const url = `/api/organizations/${organizationId}/update-clinicId?clinic_id=${newClinicId}`;
        return this.networkService.putResource(url, {});
    }

    // updateCommunityGroupVaccineLot(organizationId: any, vaccineLot: string) {
    //   const url = `/api/organizations/${organizationId}/update-vaccineLot?vaccineLot=${vaccineLot}`;
    //   return this.networkService.putResource(url, {}, {responseType: 'blob'});
    // }

    updateCommunityVaccineDetails(
        organizationId: any,
        toggle: string,
        laneId: string,
        vaccineLot?: string,
        vaccineType?: string,
        clinicId?: string
    ) {
        const url = `/api/organizations/${organizationId}/clinic-vaccine-info/${laneId}`;
        let urlParams: string;
        switch (toggle) {
            case 'update':
                urlParams = `vaccineLot=${vaccineLot}&vaccineType=${vaccineType}&clinicId=${clinicId}`;
                break;
            case 'remove':
                urlParams = 'remove=true';
                break;
        }
        const requestUrl = `${url}?${urlParams}`;

        return this.networkService.putResource(requestUrl, {});
    }

    updateCommunityClinicTeam(organizationId: string, laneId: string, clinicId: string) {
        const url = `/api/organizations/${organizationId}/clinic-lane-update/${clinicId}`;
        return this.networkService.putResource(url, {});
    }

    updateClinicLane(organizationId: string, laneId: string, clinicId: string) {
        const url = `/api/organizations/${laneId}/clinic-lane-update?patient_group_id=${organizationId}&clinic_id=${clinicId}`;

        return this.networkService.putResource(url, {});
    }

    updateEventDate(organizationId: string, clinicAppointmentIds: any[], diffinDays: number, dateString: string) {
        const body = { clinicAppointmentIds, diff: diffinDays, dateString };
        const url = `/api/organizations/${organizationId}/reschedule-clinic-appointments`;
        return this.networkService.putResource(url, body);
    }

    // updateCommunityGroupVaccineType(organizationId: any, vaccineType: string) {
    //   const url = `/api/organizations/${organizationId}/update-vaccineLot?vaccineType=${vaccineType}`;
    //   return this.networkService.putResource(url, {}, {responseType: 'blob'});
    // }

    handleError(error: HttpErrorResponse) {
        console.error('server error:', error);
        if (error.error instanceof Error) {
            const errMessage = error.error.message;
            return throwError(() => errMessage);
        }
        return throwError(() => error || 'Server error');
    }

    uploadCommunityGroupImages(organizationId: any, images: any[]) {
        const url = `/api/attachments/organization/${organizationId}`;
        return this.networkService.postResource(url, images, { responseType: 'text' });
    }

    getCommunityGroupImages(organizationId: any) {
        const url = `/api/attachments/organization/${organizationId}`;
        return this.networkService.fetchResource(url);
    }

    public extendOrganizationOverview(
        selectedClinicIds: string[],
        parentId: string,
        params: { size?: number; page?: number; types?: string | string[], search: string },
        addOpenPod: boolean
    ): Observable<ExtendedClinic[]> {
        return this.getBranches(parentId, params).pipe(
            map((res) => {
                const openPodClinic = { name: 'Open POD', type: 'openPod' } as BranchModel;
                const clinics = res.filter((b) => b.type === 'CLINIC');
                const extendedClinics: ExtendedClinic[] = clinics.map((c) => {
                    const extendedClinic = new ExtendedClinic(c);

                    if (selectedClinicIds.includes(extendedClinic.id)) {
                        extendedClinic.selected = true;
                    }
                    return extendedClinic;
                });
                if (addOpenPod && params.page === 0) {
                    extendedClinics.unshift(new ExtendedClinic(openPodClinic));
                }

                return extendedClinics;
            })
        );
    }

    getInHomeVaccinationRequests(requestype: string, orgId, startDate, endDate, status: string): Observable<any> {
        const url = `/api/patient-requests/organization/${orgId}`;
        let httpParams = new HttpParams();
        httpParams = httpParams.append('requestType', requestype);
        httpParams = httpParams.append('dateFrom', startDate);
        if (endDate) {
            httpParams = httpParams.append('dateTo', endDate);
        }
        httpParams = httpParams.append('status', status);
        return this.networkService.fetchResource<any>(url, { params: httpParams });
    }

    exportPatientRideRequests(orgId, patientIds: string[]): Observable<any> {
        const url = `/api/patient-requests/organization/${orgId}/csv/download`;
        let httpParams = new HttpParams();
        httpParams = httpParams.append('patientRequestIds', patientIds.join(','));

        return this.networkService.fetchResource<any>(url, { params: httpParams, responseType: 'text/csv' });
    }

    exportPatientRequestEnrollments(orgId, patientIds: string[]): Observable<any> {
        const url = `/api/patient-requests/organization/${orgId}/csv/download/enrollments`;
        let httpParams = new HttpParams();
        httpParams = httpParams.append('patientRequestIds', patientIds.join(','));

        return this.networkService.fetchResource<any>(url, { params: httpParams, responseType: 'text/csv' });
    }

    getOrgGroupedStats(orgId: string): Observable<any> {
        const url = `/api/patient-requests/organization/${orgId}/grouped-stats`;
        return this.networkService.fetchResource<any>(url);
    }

    changePatientStatus(patientId: string, newStatus: string): Observable<any> {
        const url = `/api/patient-requests/${patientId}/status?status=${newStatus}`;
        return this.networkService.putResource<any>(url, {});
    }

    changeHomeBoungPatientStatus(patientRequestId: string, patient) {
        const url = `/api/patient-requests/${patientRequestId}`;
        return this.networkService.putResource<any>(url, patient);
    }

    removePatientRequest(patientRequestId: string) {
        const url = `/api/patient-requests/${patientRequestId}`;
        return this.networkService.deleteResource<any>(url);
    }

    getClinicDetails(date: string) {
        const url = '/api/organizations/clinicsLaneCheck';
        let params = new HttpParams();
        params = params.append('date', date);
        return this.networkService.fetchResource<any>(url, { params });
    }

    sendRejectEmailHomeBound(patientId: string, patientBody) {
        const url = `/api/patient-requests/${patientId}/homebound-request-status/send-email`;
        const defaultHeaders = {
            Authorization: `Bearer ${this.session.token.access_token}`,
        };
        const mergedHeaders = { ...defaultHeaders };
        return this.http
            .post<any>(environment.apiUrl + url, patientBody, { headers: new HttpHeaders(mergedHeaders) })
            .pipe(catchError(NetworkService.handleError));
    }

    getPatientGroups(orgId: string, params?: { size?: number; page?: number; types?: string | string[] }): Observable<any> {
        const url = `/api/organizations/${orgId}/patientgroups-analytics`;
        let httpParams = new HttpParams();

        if (params) {
            const { size = -1, page = -1, types = '' } = params;

            if (size) httpParams = httpParams.append('size', size.toString());
            if (page >= 0) httpParams = httpParams.append('page', page.toString());
            if (types) {
                if (Array.isArray(types)) {
                    (types as []).forEach((t) => (httpParams = httpParams.append('type', t)));
                } else {
                    const type = types as string;

                    httpParams = httpParams.append('type', type);
                }
            }
        }

        return this.networkService.fetchResource<any>(url, { params: httpParams });
    }

    getBranchesBrief(orgId: string, types?: string[]): Observable<any> {
        const url = `/api/organizations/${orgId}/branches-brief`;
        let httpParams = new HttpParams();

        if (types) {
            (types as []).forEach((t) => (httpParams = httpParams.append('type', t)));
        }

        return this.networkService.fetchResource<any>(url, { params: httpParams });
    }

    patientTestPDFdownload(patientName: any, taskid: any) {
        const url = `/api/elis/order-result/${taskid}/pdf`;
        const patientNameBase = patientName.replace('[^a-zA-Z ]', '_');
        let httpParams = new HttpParams();
        if (taskid) httpParams = httpParams.append('taskdId', taskid);

        this.networkService.fetchResource(url, { params: httpParams, responseType: 'blob' }).subscribe((file: any) => {
            const blob = new Blob([file], { type: 'application/pdf' });
            fileSaver.saveAs(blob, `${patientNameBase}_test_report.pdf`);
        });
    }

    updateContactedStatus(taskdId: any, status: any) {
        const url = `/api/elis/updateIsContacted/${taskdId}`;
        let httpParams = new HttpParams();
        httpParams = httpParams.append('isContacted', status);

        return this.networkService.putResource(url, {}, { params: httpParams });
    }
}

// eslint-disable-next-line no-shadow
export enum OrgOverviewStateActions {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    PerformerOrganization = 'add_performer_org_overview',
    // AddPerformerOrgPerformers = 'add_performer_org_performers',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ClearOrgOverview = 'clear_org_overview',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    SetReferralCode = 'set_referral_code',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    UpdateReferralCode = 'update_referral_code',
}

export class InHomeVaccinationModel {
    constructor(
        key: string,
        patientId: string,
        patientName: string,
        viechleNeeds: string,
        contactInfo: string,
        appointmentTime: string,
        pickLocation: string,
        dropLocation: string,
        communityGroupName: string,
        vaccineName: string,
        status: string,
        selected: boolean,
        lastExportDate: string,
        location?: any,
        patientRequestId?: string,
        dateTime?: string,
        vaccineTeam?: string,
        time?: string,
    ) {
        this.key = key;
        this.patientId = patientId;
        this.patientName = patientName;
        this.viechleNeeds = viechleNeeds;
        this.contactInfo = contactInfo;
        this.appointmentTime = appointmentTime;
        this.pickLocation = pickLocation;
        this.dropLocation = dropLocation;
        this.communityGroupName = communityGroupName;
        this.vaccineName = vaccineName;
        this.status = status;
        this.selected = selected;
        this.lastExportDate = lastExportDate;
        this.location = location;
        this.patientRequestId = patientRequestId;
        this.dateTime = dateTime;
        this.vaccineTeam = vaccineTeam;
        this.time = time;
    }

    patientId: string;
    key: string;
    communityGroupName: string;
    patientName: string;
    viechleNeeds: string;
    contactInfo: string;
    appointmentTime: string;
    dropLocation: string;
    pickLocation: string;
    vaccineName: string;
    status: string;
    selected: boolean;
    lastExportDate: string;
    vaccineTeam: string;
    lotNumber: string;
    location: any;
    dateTime: string;
    time: string;
    patientRequestId: string;
}
