import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';

import { NetworkService } from '../network/network.service';
import { ProgramPhase } from './program-phase.class';
import { IProgramPhase, IProgramPhaseRequest } from './program-phase.interface';

@Injectable({
    providedIn: 'root',
})
export class ProgramPhasesService {
    private baseURL = '/api/program-phase';

    constructor(private _networkService: NetworkService) {}

    /* Create a new phase */
    create(phase: IProgramPhaseRequest): Promise<IProgramPhase> {
        return firstValueFrom(this._networkService.postResource(`${this.baseURL}`, phase));
    }

    getProgramPhaseById(id): Observable<IProgramPhase> {
        return this._networkService.fetchResource<IProgramPhase>(`${this.baseURL}/${id}`);
    }

    /* Get a specific phase given you have its id */
    get(programPhaseId: string): Promise<IProgramPhase> {
        return firstValueFrom<IProgramPhase>(this.getProgramPhaseById(programPhaseId));
    }

    /* update a program phase given an object that fits IProgramPhase interface */
    update(phase: IProgramPhase): Promise<IProgramPhase> {
        return firstValueFrom<IProgramPhase>(
            this._networkService.putResource<IProgramPhase>(`${this.baseURL}/${phase.id}`, phase),
        );
    }

    /* delete a program phase given its id */
    delete(programPhaseId: string): Promise<void> {
        return firstValueFrom(this._networkService.deleteResource<void>(`${this.baseURL}/${programPhaseId}`));
    }

    updateEnrollmentSteps(programId: string, phases: ProgramPhase[]):Observable<any[]> {
        const _phases = [];
        let rank = 0;
        phases.forEach((phase) => {
            _phases.push({
                id: phase.id,
                rank
            });
            rank++;
        });
        return this._networkService.postResource<any>(`${this.baseURL}/${programId}/enrollment-steps`, _phases);
    }
}
