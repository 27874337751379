import { firstValueFrom } from 'rxjs';
import { InjectorInstance } from 'src/app/app.module';

import { ProgramPhase } from '../phases/program-phase.class';
import { ProgramPhaseItem } from './program-phase-item.class';
import { IProgramPhaseItemRequest } from './program-phase-item.interface';
import { ProgramPhaseItemService } from './program-phase-item.service';

export class ProgramPhaseItems {
    private _phases: ProgramPhaseItem[] = [];
    private _parentPhase: ProgramPhase;

    private programPhaseItemService = InjectorInstance.get(ProgramPhaseItemService);

    constructor(phase: ProgramPhase) {
        this._parentPhase = phase;
        phase?.data?.programPhaseItems?.forEach((phaseItem) => {
            this._phases.push(new ProgramPhaseItem(phaseItem));
        });
    }

    /** Get all the phases items inside the program phase. */
    get data() {
        return this._phases;
    }

    /** Create a new phase item inside the program phase. */
    create(programPhaseItem: IProgramPhaseItemRequest) {
        return firstValueFrom(this.programPhaseItemService.create(programPhaseItem));
    }

    /** get a program phase item by its id. */
    get(programPhaseItemId: string) {
        return this.programPhaseItemService.get(programPhaseItemId);
    }

    /** update a program phase item */
    update(programPhaseItem: IProgramPhaseItemRequest) {
        return firstValueFrom(this.programPhaseItemService.update(programPhaseItem.programPhaseId, programPhaseItem));
    }

    /** delete a program phase item given you have its id */
    delete(programPhaseItemId: string) {
        return firstValueFrom(this.programPhaseItemService.delete(programPhaseItemId));
    }
}
