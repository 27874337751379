import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';

import { ImageService } from '../services/image/image.service';
import { LinkUtil } from '../utils/link-util/link-util.service';

@Pipe({
    name: 'image',
    pure: true,
})
export class ImagePipe implements PipeTransform {
    constructor(private imageService: ImageService, private linkUtil: LinkUtil) {}

    transform(links: any, ...args: any[]): Observable<string> {
        let result = observableOf(null);

        if (typeof links === 'string') {
            const width: number = args[0] ? args[0] : 100;

            if (links.includes('/image/?width=')) {
                return result;
            }

            result = this.imageService.getImageNew(links, width);
        } else {
            const width: number = args[1] ? args[1] : 100;
            const name: string = args[0];
            const url = this.linkUtil.parseLink(links, name);

            if (url.includes('/image/?width=')) {
                return result;
            }

            if (url) {
                result = this.imageService.getImageNew(url, width);
            }
        }
        return result;
    }
}
