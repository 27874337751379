import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { IconListComponent } from './components/main/components/icon-list/icon-list.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SnackbarService } from './core/services/snackbar.service';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full',
    },
    {
        path: 'login',
        loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginModule),
    },
    {
        path: 'onboard',
        loadChildren: () =>
            import('./modules/onboard-organization/onboard-organization.module').then((m) => m.OnboardOrganizationModule),
    },
    { path: 'patient', loadChildren: () => import('./modules/patient/patient.module').then((m) => m.PatientModule) },
    {
        path: 'provider',
        loadChildren: () => import('./modules/provider-overview/provider.module').then((m) => m.ProviderModule),
    },
    {
        path: 'patient-screening',
        loadChildren: () =>
            import('./modules/patient-screening/patient-screening.module').then((m) => m.PatientScreeningModule),
    },
    {
        path: 'patient-registration',
        loadChildren: () =>
            import('./modules/patient-registration/patient-registration.module').then((m) => m.PatientRegistrationModule),
    },
    {
        path: 'curapatient',
        loadChildren: () => import('./modules/curapatient/curapatient.module').then((m) => m.CurapatientModule),
        data: { breadcrumb: '' },
    },
    {
        path: 'icons',
        component: IconListComponent,
    },
    {
        path: 'change-log',
        loadChildren: () => import('./modules/change-log/change-log.module').then((m) => m.ChangeLogModule),
    },
    {
        path: '**',
        component: PageNotFoundComponent,
    },
];

// TODO implement custom preload module
@NgModule({
    imports: [RouterModule.forRoot(routes, {
        onSameUrlNavigation: 'reload' /* preloadingStrategy: PreloadAllModules */,
        scrollOffset: [0, 0],
        scrollPositionRestoration: 'top',
    })],
    exports: [RouterModule],
    providers: [SnackbarService],
})
export class AppRoutingModule {}
