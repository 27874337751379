import {HttpParams} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, forkJoin, Observable} from 'rxjs';
import {defaultIfEmpty} from 'rxjs/operators';

import globals from '../../../../config';
import {
    MasterDataOption
} from '../../../modules/master-data/master-data-type-overview/master-data-overview/master-data-overview.component';
import {InsuranceProvider} from '../../models/insurance-provider';
import {InventoryModel} from '../../models/inventory.model';
import {DeviceModel, MasterDataModel, MetaDataModel} from '../../models/metadata.model';
import {ApiService} from '../api/api.service';
import {NetworkService} from '../network/network.service';
import {IProduct} from '../products/products.interface';
import {UserSessionService} from '../user-session/user-session.service';
import {IMasterDataResource} from "../../models/masterData.model";

export enum MasterDataTypes {
    masterData = 'Master Data',
    observationType = 'Goal Observation Type',
    appointmentTherapyType = 'appointment',
    manufacturerType = 'manufacture',
    assessmentTherapyType = 'assessment',
    educationTherapyType = 'education',
    medicationTherapyType = 'medication',
    exerciseTherapyType = 'exercise',
    foodTherapyType = 'food',
    teamTaskTherapyType = 'team task',
    serviceType = 'service-type',
    specialty = 'Specialty',
    inventory = 'INVENTORY',
    covid19 = 'COVID-19',
    covid19testing = 'COVID-19 TESTING',
    programType = 'program-type',
    programCategory = 'program-category',
    insuranceProvider = 'insurance-provider',
    icdCodes = 'icd-codes',
    interventionCodes = 'intervention-codes',
    providerNotesTemplates = 'provider-notes-templates',
    procedureCodes = 'procedure-codes',
    reasonCodes = 'reason-codes',
    feeSchedules = 'fee-schedules',
    payer = 'payer',
}

@Injectable({
    providedIn: 'root',
})
export class MasterDataService {
    private masterData: MetaDataModel;
    private masterDataType: string;
    private content: any;

    public globalsTaskType = globals.TASKTYPES;

    private readonly _masterDataSelection = new BehaviorSubject<any>(null);
    private readonly _finishSave = new EventEmitter<any>();
    readonly masterDataSelection$ = this._masterDataSelection.asObservable();
    private types = [
        {
            id: 0,
            name: 'Observation Type',
            type: MasterDataTypes.observationType,
            description: 'Observation Type is a type of observation.',
            restUrl: '/api/meta/observation',
            editModalType: 'master-data-observation-type',
            numberOfSubtype: '',
            order: 17,
        },
        {
            id: 1,
            name: 'Privilege',
            type: 'Privilege',
            description: 'Privilege is the privilege of a user.',
            restUrl: '/api/meta/privilege',
            numberOfSubtype: '',
            order: 1,
        },
        {
            id: 2,
            name: 'Role',
            type: 'Role',
            description: 'Role is the role of a user.',
            restUrl: '/api/meta/role',
            numberOfSubtype: '',
            order: 2,
        },
        {
            id: 3,
            name: 'Service Type',
            type: MasterDataTypes.serviceType,
            description: 'Service Type is a type of service.',
            restUrl: '/api/meta/service',
            editModalType: 'master-data-service-type',
            numberOfSubtype: '',
            order: 3,
        },
        {
            id: 4,
            name: 'Specialty',
            type: MasterDataTypes.specialty,
            description: 'Specialty is a specialty of provider.',
            restUrl: '/api/specialties',
            params: null,
            editModalType: 'master-data-specialty',
            numberOfSubtype: '',
            order: 4,
        },
        {
            id: 5,
            name: 'Task Type',
            type: 'task-type',
            description: 'Task Type is a type of task.',
            restUrl: '/api/meta/task',
            numberOfSubtype: '',
            hasChildren: true,
            order: 5,
        },
        {
            id: 6,
            name: 'Manufacture',
            type: MasterDataTypes.manufacturerType,
            description: 'Master Data is a Master Data',
            restUrl: '/api/masterData',
            editModalType: 'master-data-master-data',
            numberOfSubtype: '',
            order: 6,
        },
        {
            name: 'Appointment',
            type: MasterDataTypes.appointmentTherapyType,
            description: 'Appointment Therapy Type is a type of therapy.',
            restUrl: '/api/meta/therapy',
            taskTypeId: this.globalsTaskType.APPOINTMENT,
            editModalType: 'master-data-therapy',
            numberOfSubtype: '',
            order: 7,
        },
        {
            name: 'Assessment',
            type: MasterDataTypes.assessmentTherapyType,
            description: 'Assessment Therapy Type is a type of therapy.',
            restUrl: '/api/meta/therapy',
            taskTypeId: this.globalsTaskType.ASSESSMENT,
            editModalType: 'master-data-therapy',
            numberOfSubtype: '',
        },
        {
            name: 'Education',
            type: MasterDataTypes.educationTherapyType,
            description: 'Education Therapy Type is a type of therapy.',
            restUrl: '/api/meta/therapy',
            taskTypeId: this.globalsTaskType.EDUCATION,
            editModalType: 'master-data-therapy',
            numberOfSubtype: '',
        },
        {
            name: 'Medication',
            type: MasterDataTypes.medicationTherapyType,
            description: 'Medication Therapy Type is a type of therapy.',
            restUrl: '/api/meta/therapy',
            taskTypeId: this.globalsTaskType.MEDICATION,
            editModalType: 'master-data-therapy',
            numberOfSubtype: '',
        },
        {
            name: 'Exercise',
            type: MasterDataTypes.exerciseTherapyType,
            description: 'Exercise Therapy Type is a type of therapy.',
            restUrl: '/api/meta/therapy',
            taskTypeId: this.globalsTaskType.EXERCISE,
            editModalType: 'master-data-therapy',
            numberOfSubtype: '',
        },
        {
            name: 'Food',
            type: MasterDataTypes.foodTherapyType,
            description: 'Food Therapy Type is a type of therapy.',
            restUrl: '/api/meta/therapy',
            taskTypeId: this.globalsTaskType.FOOD,
            editModalType: 'master-data-therapy',
            numberOfSubtype: '',
        },
        {
            name: 'Team Task',
            type: MasterDataTypes.teamTaskTherapyType,
            restUrl: '/api/meta/therapy',
            taskTypeId: this.globalsTaskType.TEAM_TASK,
            // editModalType: 'master-data-therapy',
            numberOfSubtype: '',
        },
        {
            id: 12,
            name: 'Device Model',
            type: 'Device Model',
            description: 'Device Model.',
            restUrl: '/api/meta/device',
            editModalType: 'master-data-device-model',
            numberOfSubtype: '',
            order: 12,
        },
        {
            id: 13,
            name: 'Program Type',
            type: MasterDataTypes.programType,
            description: 'Program Type',
            restUrl: '/api/program-type',
            params: null,
            editModalType: 'master-data-program-type',
            numberOfSubtype: '',
            order: 13,
        },
        {
            id: 14,
            name: 'Program Category',
            type: MasterDataTypes.programCategory,
            description: 'Program Category',
            restUrl: '/api/program-category',
            params: null,
            editModalType: 'master-data-program-category',
            numberOfSubtype: '',
            order: 14,
        },
        {
            id: 15,
            name: 'Image Library',
            type: 'image-library',
            description: 'Image Library',
            numberOfSubtype: '-',
            order: 15,
        },
        {
            id: 16,
            name: 'Insurance Provider',
            type: MasterDataTypes.insuranceProvider,
            description: 'Insurance Provider',
            restUrl: '/api/insurance-providers',
            params: null,
            editModalType: 'master-data-insurance-provider',
            numberOfSubtype: '',
            order: 16,
        },
        {
            id: 19,
            name: 'Encounter Coding Preferences',
            description: 'Encounter Coding Preferences',
            type: 'encounter-coding-preferences',
            numberOfSubtype: '',
            hasChildren: true,
            order: 17,
            children: [
                {
                    id: 17,
                    name: 'ICD Codes',
                    type: MasterDataTypes.icdCodes,
                    description: 'ICD Codes',
                    restUrl: '/api/icdCodes',
                    editModalType: 'master-data-icd-codes',
                    numberOfSubtype: '',
                },
                {
                    id: 18,
                    name: 'Intervention Codes',
                    type: MasterDataTypes.interventionCodes,
                    description: 'Intervention Codes',
                    restUrl: '/api/interventionCodes',
                    editModalType: 'master-data-intervention-codes',
                    numberOfSubtype: '',
                },
            ]
        },
        {
            id: 20,
            name: 'Provider Notes Templates',
            type: MasterDataTypes.providerNotesTemplates,
            description: 'Provider Notes Templates',
            restUrl: '/api/masterData/provider-notes-templates',
            editModalType: 'master-data-provider-notes-templates',
            numberOfSubtype: '',
            order: 18
        },

        {
            id: 21,
            name: 'Payer',
            type: MasterDataTypes.payer,
            description: 'Payer',
            restUrl: '/api/masterData/payer',
            params: null,
            editModalType: 'master-data-payer',
            numberOfSubtype: '',
            order: 21,
        },
        /* {
             id: 22,
             name: 'Fee Schedules',
             type: MasterDataTypes.feeSchedules,
             description: 'Fee Schedules',
             restUrl: '/api/fee-schedules',
             params: null,
             editModalType: 'master-data-fee-schedules',
             numberOfSubtype: '',
             order: 22,
         },*/
        {
            id: 23,
            name: 'Procedure Codes',
            type: MasterDataTypes.procedureCodes,
            fieldName: 'procedureName',
            description: 'Master Data Procedure Codes',
            restUrl: '/api/procedure-codes/overview',
            editModalType: 'master-data-procedure-codes',
            numberOfSubtype: '',
            order: 23,
        },
        {
            id: 24,
            name: 'Reason Codes',
            type: MasterDataTypes.reasonCodes,
            fieldName: 'reasonName',
            description: 'Master Data Reason Codes',
            restUrl: ' /api/reason-codes/overview',
            editModalType: 'master-data-reason-codes',
            numberOfSubtype: '',
            order: 24,
        },
    ];

    static getAttachmentType(file): string {
        const imagePattern = /image-*/;
        const videoPattern = /video-*/;
        const documentPattern = /application-*/;

        if (file.type.match(imagePattern)) {
            return 'IMAGE';
        }
        if (file.type.match(videoPattern)) {
            return 'VIDEO';
        }
        if (file.type.match(documentPattern)) {
            return 'DOCUMENT';
        }
        return null;
    }

    constructor(
        private network: NetworkService,
        private router: Router,
        private session: UserSessionService
    ) {
    }

    public getAllMasterData(): Array<any> {
        const {types} = this;
        const organizationId = this.session.sessionAccount && this.session.sessionAccount.organizationId
            ? this.session.sessionAccount.organizationId
            : null;
        let httpParams = new HttpParams();

        if (organizationId) httpParams = httpParams.set('organizationId', organizationId);

        types.forEach((t) => {
            if (t.params === null) {
                t.params = httpParams;
            }
        });

        this.getNumberOfSubtype().subscribe((res) => {
            if (res && res.length > 0) {
                types.forEach((t) => {
                    t.numberOfSubtype = res.filter((r) => r.otherId == t.id && !r.taskTypeId)[0]?.numOfSub;
                });
            }
        }, (error) => types);
        return types;
    }

    public findMasterDataByType(type: MasterDataTypes): MasterDataOption {
        let item: MasterDataOption;

        this.getAllMasterData().forEach((mdl) => {
            if (mdl.type === type) {
                item = mdl;
            }

            if (mdl?.children) {
                mdl.children.forEach((mdlc) => {
                    if (mdlc.name.toLowerCase() === type.toLowerCase() || mdlc.type.toLowerCase() === type.toLowerCase()) {
                        item = mdlc;
                    }
                });
            }

        });

        return item;

    }


    setMasterData(data: MetaDataModel) {
        this.masterData = data;
    }

    setMasterDataType(type: string) {
        this.masterDataType = type;
    }

    public get masterDataSelection(): MasterDataOption {
        return this._masterDataSelection.getValue();
    }

    public set masterDataSelection(val: any) {
        this._masterDataSelection.next(val);
    }

    getMasterDataType(): string {
        return this.masterDataType;
    }

    clearData() {
        this.masterData = null;
        this.content = null;
    }

    attachFiles(files, id) {
        const fileList = [];

        files.forEach((file) => {
            if (file.id) return false;
            const attachment = new FormData();
            attachment.append('file', file);
            attachment.append('therapyTypeId', id);
            attachment.append('attachmentType', MasterDataService.getAttachmentType(file));

            fileList.push(this.network.postResource('/api/attachments/therapy-type', attachment));
        });

        return forkJoin(fileList).pipe(defaultIfEmpty([]));
    }

    public editTherapy(therapy, id): Observable<MetaDataModel> {
        return this.network.putResource<any>(`/api/meta/therapy/${id}`, therapy);
    }

    public createTherapy(therapy): Observable<MetaDataModel> {
        return this.network.postResource<any>(ApiService.addMetaData('therapy'), therapy);
    }

    public deleteTherapy(therapyId): Observable<MetaDataModel> {
        return this.network.deleteResource<any>(`/api/meta/therapy/${therapyId}`);
    }

    public deleteTherapyIcon(therapy) {
        return this.network.deleteResource(`/api/meta/therapy/${therapy?.id}/image`);
    }

    public createMasterData(masterData): Observable<MasterDataModel> {
        return this.network.postResource<any>('/api/masterData', masterData);
    }

    public editMasterData(masterData): Observable<MasterDataModel> {
        return this.network.putResource<any>(`/api/masterData/${masterData.id}`, masterData);
    }

    public getDeviceModel(): Observable<DeviceModel[]> {
        return this.network.fetchResource<DeviceModel[]>('/api/meta/device');
    }

    public getMasterDataById(id: string): Observable<IMasterDataResource> {
        return this.network.fetchResource<IMasterDataResource>(`/api/masterData/${id}`);
    }

    public getMasterDataByType(type: MasterDataTypes): Observable<any> {
        const url = `/api/masterData/searchByType/${type}`;

        return this.network.fetchResource(url);
    }

    public getInventories(): Observable<InventoryModel[]> {
        const url = '/api/masterData/inventories';

        return this.network.fetchResource(url);
    }

    public getProducts(): Observable<IProduct[]> {
        const url = '/api/products';
        return this.network.fetchResource(url);
    }

    public getInsuranceProviders(): Observable<InsuranceProvider[]> {
        const url = '/api/insurance-providers';
        return this.network.fetchResource(url);
    }

    public searchInsuranceProviders(name: string, limit: number, page: number): Observable<InsuranceProvider[]> {
        const params = new HttpParams()
            .set('name', name)
            .set('limit', limit)
            .set('page', page);
        const url = '/api/insurance-providers/search';
        return this.network.postResource(url, {}, {params});
    }

    public navigateToMasterData(type: MasterDataOption, route: ActivatedRoute) {
        this.setMasterDataType(type.type);
        this.masterDataSelection = type;
        localStorage.setItem('master-data-selection', JSON.stringify(type.type));
        this.router.navigate([(type.type || type.name).toLowerCase()], {relativeTo: route}).then();
    }

    public deleteMasterData(masterDataId, option?: any) {
        let url = `${option.restUrl || '/api/masterData'}/${masterDataId}`;

        url = url.replace('/provider-notes-templates', '');

        return this.network.deleteResource(url, {params: option.params ? option.params : null});
    }

    public getNumberOfSubtype(): Observable<any[]> {
        const url = '/api/meta/sub-type';

        return this.network.fetchResource(url);
    }

    public get finishSave() {
        return this._finishSave;
    }

    public set finishSave(val: any) {
        this._finishSave.next(val);
    }

    public getAllMasterDataAsync(): Array<any> {
        const {types} = this;
        const organizationId = this.session.sessionAccount && this.session.sessionAccount.organizationId
            ? this.session.sessionAccount.organizationId
            : null;
        let httpParams = new HttpParams();

        if (organizationId) httpParams = httpParams.set('organizationId', organizationId);

        types.forEach((t) => {
            if (t.params === null) {
                t.params = httpParams;
            }
        });
        return types;
    }

}
