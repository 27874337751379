import { Injectable } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { BehaviorSubject } from 'rxjs';

import config from '../../../../config';
import { AuthToken } from '../../models/auth-token.model';
import { MobileApiUserSession, UserSession } from '../../models/user-session.model';

@Injectable({
    providedIn: 'root',
})
export class UserSessionService {
    private _sessionAccount = new BehaviorSubject<UserSession>(null);
    private _patientCarePlan = new BehaviorSubject<any>(null);
    private _mobileSessionAccount = new BehaviorSubject<MobileApiUserSession>(null);

    public sessionAccount$ = this._sessionAccount.asObservable();
    public mobileSessionAccount$ = this._mobileSessionAccount.asObservable();

    chart: any;

    public token: AuthToken;
    public sessionAccount: UserSession;
    public loggedInStatus: boolean;
    public drawer: MatDrawer;
    public activities = [];
    public mobileApiSessionAccount: MobileApiUserSession;
    global = config;
    public isReload:boolean = false;

    constructor() {
        this.loggedInStatus = JSON.parse(localStorage.getItem('loggedIn') || 'false');
    }

    public invalidateSession() {
        localStorage.removeItem('keycloak');
        localStorage.removeItem('organization-id');
        localStorage.removeItem('patient-third-dose-status');
        this.setIsLoggedIn(false);
        this.isSSOLogin = false;
        delete this.token;
        localStorage.removeItem('keycloakToken');

        delete this.activities;
        delete this.sessionAccount;
        delete this.mobileApiSessionAccount;
    }

    public getSession(): UserSession {
        return this.sessionAccount;
    }

    public setAccountSession(session: UserSession) {
        if (session instanceof UserSession) {
            this.sessionAccount = session;
        } else {
            this.sessionAccount = Object.assign(new UserSession(), session);
        }
        this._sessionAccount.next(this.sessionAccount);
    }

    public getIsLoggedIn() {
        return JSON.parse(localStorage.getItem('loggedIn') || this.loggedInStatus.toString());
    }

    public setIsLoggedIn(status: boolean) {
        this.loggedInStatus = (status) ? status : false;
        localStorage.setItem('loggedIn', this.loggedInStatus.toString());
    }

    public isOrganizationAdmin(): boolean {
        return this.global.PRIVILEGEID.ORGANIZATION_ADMIN === +this.sessionAccount.privilege.id;
    }

    public isCordinator(): boolean {
        return this.global.PRIVILEGEID.COORDINATOR === +this.sessionAccount.privilege.id;
    }

    public isOrganizationManager(): boolean {
        return this.global.PRIVILEGEID.ORGANIZATION_MANAGER === +this.sessionAccount.privilege.id;
    }

    public isSuperAdmin(): boolean {
        return +this.sessionAccount.privilege.id === this.global.PRIVILEGEID.SUPER_ADMIN;
    }

    set patientCarePlan(value) {
        this._patientCarePlan.next(value);
    }
    get patientCarePlan() {
        return this._patientCarePlan.getValue();
    }

    public getMobileApiSession(): MobileApiUserSession {
        return this.mobileApiSessionAccount;
    }

    public setMobileApiAccountSession(session: MobileApiUserSession) {
        if (session instanceof MobileApiUserSession) {
            this.mobileApiSessionAccount = session;
        } else {
            this.mobileApiSessionAccount = Object.assign(new MobileApiUserSession(), session);
        }
        this._mobileSessionAccount.next(this.mobileApiSessionAccount);
    }

    public get isSSOLogin():boolean {
        return JSON.parse(localStorage.getItem('ssoLogin')) || false;
    }

    public set isSSOLogin(status:boolean) {
        localStorage.setItem('ssoLogin', status.toString());
    }
}
