export const environment = {

    // development, uat, production, local
    environmentType: 'production',
    production: false,
    versionCheckURL: './version.json',
    apiUrl: 'https://curapatient-be.prd-hcc.curapatient.com',
    webUrl: 'https://curapatient-be.prd-hcc.curapatient.com',
    apiSocketUrl: 'https://curapatient-be-ws.prd-hcc.curapatient.com',
    name: 'prod',
    carePlanId: 1314,
    defaultPODClinicId: 'bc11dc8d-3141-4dd9-a1fd-d4ef1c3bd9f4',
    patientRegistration: {
        assessmentId: 338,
        organizationId: 'e88ed8a5-e28b-4e01-99a7-dc44dc44920f',
    },
    sso: {
        client_id: '490ip27hthpu73q066bguhvm82',
        auth_url: 'https://prd-hcc-curapatient.auth.us-west-2.amazoncognito.com/oauth2/authorize',
    },
    dialogflow: {
        private_key: '-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCzvYN1ESyVjrlq\nx8gcxPNA/WVWfXp89FWsGkZ9S8vOHo5Geg5k/9MxuU5a49JKz5GXs1qDTZhV59mc\njbVIMVymlp5lxxd/sh99xdA18PCTkleD/UYPalY1ODT7bz1cbELXaOk+0F2OQsCN\nwyrkpgvohhkqdcLQy8+vq+H4CWvVfcfBKMOgssx9h3hN/YffVs9Zsw8hefOfMafD\nEQggwPuKOA6gFdu7G5WI9qwOAc4HsNLwmOuxIhnkilHifR2faKVzjO843CGl5bi1\nlBzr1/3K62gtYED9NdOug2DLoPV93Q0ZaeDe8h6rjaSctR7RoSzQDhEq9l+0JRVe\nGVnu303tAgMBAAECggEAIBXdiKFXNx9yQ/iD5XbXAav6UhU2L2YigQVzvdww9ouH\n1FbniOoOFltA/IKcwJQLJmxy5OuOHQ+1TfgR+2AsbOSIRBzlOftfGcGdKHPpTj6a\ndspYnlnaJ8fv1U6HQp9k0zU8JeVmPgX2f5fuaUCm8Yw2o9i/vSwU2AQz42YzJLnx\nhDvg7GJsXNVrZy7PvFxbp0JTLwWJ5in3IHmvDO7mG8kZFK8wgLpnKGgEe2boL/r8\nc68uYqq5V8MVG9PBGeX1L4HuNwx+VNLNf6GiiJvzY9plM+CQpEUFdZFsJrs/FluD\nEmB3h6lj+8uTe+sCNBQFJHAZkFLfotwi8kZuHdLPwQKBgQDn7UdH1jQ9IntWSJk2\nMhkU37dOgmG4N5boYJ4ToiuzLoqlSNpjwNrf7TUWcbiQpX4rxX+FcLTwFm92BfpM\nkIH/fGwxojVfw06nAvTc05j+In2FPKpqCKSwD46DtA4WHKRsWT/fPEzHWxAVwUhX\nZJdYJFCn+qHjIPG8weXbIL7E/QKBgQDGZYrCywMP86ihGYpq8mijnjLt/2HpQ/B/\nZC6Px24c0cVrUJ4ydaYMZb8+1p2VMwmzsTORq1cr5SjSkDuMPwphT4eMndXLuaVD\nff4P+GszZErwern8ZkCeWGksejXEifmCnwtlLLlvy+pwFHYIU6DVxt5X9U3bcLKC\nBjC6x133sQKBgQDUo9khxQQaNR4t6twGM4KJf9/57FKZ6uXCYqssRxiX7QkTJ6uL\noAtNGZf93wAgJot6qFYVy1MpePhylmJ5hliaHMK58wXKqi/Xsj88pAh2oLrCyO9Z\nzljoTysUF6Z+oX4jFOUZ0Jh4g0ZJG/AuqDyWhJ0qlgGNU+wvavtKoEgoJQKBgEmm\nXmnLxXpBgOM1QQ5MqQ28Z7m0aZuXzgrsnOBhL35hXUFmbyPaSuclSmbAc0V06fAc\nI0G7cqPn8UyhoImCyY6wYGiSG1ECvNgeSJeTJ/CvFabKWIrbRrfBqGGLiLRKaCbW\ndft8K+bVs1UiM2MJ8JYLunS6qsmAv7PHKoXcBwUxAoGBAN3XFitf39B3uxwAY2UB\nSrr7BslpbuQfIv3x2DDPDx2ZSkpDChPlGplJuIEQ0JCslB2Rnu6ouDsCRpEg3I32\ndaVcwo6vdRGL3vFP5cJClM/bkIQDu4R5bmu/lzjY8Kuk2WTMGDRGo16gDGFioR6H\nqx3fbiQG2s+QGaFPtMhbxnq1\n-----END PRIVATE KEY-----\n',
        project_id: 'curapatient-5db53',
        client_email: 'dialogflowserviceaccount@curapatient-5db53.iam.gserviceaccount.com',
        url: 'https://dialogflow.googleapis.com/v2beta1/projects',
    },
};
