import { ErrorHandler, Injectable } from '@angular/core';

import ErrorLog from '../error-log/error-log';

@Injectable({
    providedIn: 'root',
})
export class GlobalExceptionHandlerService implements ErrorHandler {
    handleError(error: any) {
    // let router = this.injector.get(Router);
        console.error(error);
        new ErrorLog(error);
        return true;
    // remove comments after development is complete
    // if(error && (error.status == "404" || error.status == "504") ){
    //   router.navigate(['/page-not-found']);
    // }
    }
}
