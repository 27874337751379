import { AsyncPipe, NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';

import config from '../../../../../config';
import { menuBarOptions } from '../../../../components/menu-bar/menu-bar.options';
import { IOrganization } from '../../../../core/models/organization.model';
import { PipesModule } from '../../../../core/pipes/pipes.module';
import { UntilDestroy } from '@ngneat/until-destroy';
import { RecentlyViewedOrganizationService } from './recently-viewed-organization.service';
import { Observable } from 'rxjs';

export const RECENTLY_VIEWED_KEY = 'recentlyViewedOrganizations' as const;

export interface IRecentlyViewed {
    [parentId: string]: {
        organizationId: string;
    }[];
}

@UntilDestroy()
@Component({
    selector: 'app-recently-viewed-organizations',
    templateUrl: './recently-viewed-organizations.component.html',
    styleUrls: ['./recently-viewed-organizations.component.scss'],
    standalone: true,
    imports: [
        CarouselModule,
        NgForOf,
        NgIf,
        RouterLink,
        RouterLinkActive,
        AsyncPipe,
        PipesModule,
        NgOptimizedImage,
        MatIconModule,
        MatTooltipModule,
    ],
})
export class RecentlyViewedOrganizationsComponent implements OnInit {
    @Input() parentId: string = 'self';

    @Input() availableOrganizations: IOrganization[] = [];

    @Output() organizationSelected = new EventEmitter<IOrganization>();

    recentlyViewedOrganizations: IRecentlyViewed = {};

    options: OwlOptions = { ...menuBarOptions, ...{ responsive: {}, stagePadding: 0 } };

    orgNoImage: string = config.images.organizationNoImage;

    recentlyViewedOrganizations$: Observable<IOrganization[]>;

    constructor(private recentlyViewedService: RecentlyViewedOrganizationService) {}

    ngOnInit(): void {
        if (!this.parentId) {
            this.parentId = 'self';
        }
        this.recentlyViewedService.setRecentlyViewed(this.availableOrganizations, this.parentId);
        this.recentlyViewedOrganizations$ = this.recentlyViewedService.recentlyViewed$;
    }

    orgSelected(org: IOrganization) {
        this.organizationSelected.emit(org);
    }

    removeOrgFromRecentlyVisited(event: MouseEvent, org: IOrganization) {
        event.stopPropagation();
        this.recentlyViewedService.removeFromRecentlyViewed(this.parentId, org);
    }
}
