import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, Observer, of, tap } from 'rxjs';
import { catchError, defaultIfEmpty, map, mergeMap, switchMap } from 'rxjs/operators';

import { ORGANIZATION_ID } from '../../../modules/organizations/services/org-overview.service';
import { Branch } from '../../classes/branch.class';
import { Appointment } from '../../models/appointment.model';
import { CareplanTaskModel } from '../../models/careplan-task-model';
import { LocationModel, Provider, ProviderResponse, ProviderUpdatedResponse } from '../../models/provider.model';
import { UserSession } from '../../models/user-session.model';
import { DateUtility } from '../../utils/date-utility';
import { LinkUtil } from '../../utils/link-util/link-util.service';
import { DataBrokerService } from '../data-broker/data-broker.service';
import { ImageService } from '../image/image.service';
import { NetworkService } from '../network/network.service';
import { UserSessionService } from '../user-session/user-session.service';

@Injectable({ providedIn: 'root' })
export class ProviderService {
    apiUrl = '/api/performers';
    private providerSubject = new BehaviorSubject<Provider>(undefined);
    private selectedOrganization: BehaviorSubject<Branch> = new BehaviorSubject<Branch>(null);
    public infoCardVisibility: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor(
        private network: NetworkService,
        private linkUtil: LinkUtil,
        private imageService: ImageService,
        private data: DataBrokerService,
        private userSession: UserSessionService,
    ) {}

    setInfoCardVisibility(value: boolean) {
        this.infoCardVisibility.next(value);
    }

    getInfoCardVisibility(): Observable<boolean> {
        return this.infoCardVisibility.asObservable();
    }

    public getAvailableLocations(organizationId: string): Observable<LocationModel[]> {
        const link = `/api/organizations/${ organizationId }/locations`;

        return this.network.fetchResource(link);
    }

    public getProviderNotifications(link) {
        return this.network
            .fetchResource(link)
            .pipe(
                mergeMap(({ content, links }) => {
                    const notifications: any[] = content.map((c) => {
                        const actorImgLink = this.linkUtil.parseLink(c.links, 'actorImg');

                        if (actorImgLink) {
                            return this.imageService.getImageNew(actorImgLink, 100).pipe(
                                map((actorImg) => ({ ...c, actorImg })),
                                catchError(() => of({ ...c, actorImg: '' })),
                            );
                        }

                        return of(c);
                    });

                    if (content.length) {
                        return forkJoin(notifications).pipe(map((iContent: any[]) => ({ content: iContent, links })));
                    }

                    return of({ content, links });
                }),
                defaultIfEmpty({ content: [], links: null }),
            )
            .pipe(
                mergeMap(({ content, links }) => {
                    const notifications = content.map((c) => {
                        const relatedAccountImgLink = this.linkUtil.parseLink(c.links, 'relatedAccountImg');

                        if (relatedAccountImgLink) {
                            return this.imageService.getImageNew(relatedAccountImgLink, 100).pipe(
                                map((relatedAccountImg) => ({ ...c, relatedAccountImg })),
                                catchError(() => of({ ...c, relatedAccountImg: '' })),
                            );
                        }

                        return of(c);
                    });

                    if (content.length) {
                        return forkJoin(notifications).pipe(map((iContent: any) => ({ content: iContent, links })));
                    }

                    return of({ content, links });
                }),
                defaultIfEmpty({ content: [], links: null }),
            );
    }

    public replyToRequest({ activity, ifAccept }) {
        const replyToRequestUrl = this.linkUtil.parseLink(activity.links, 'replyToRequest');

        this.network.putResource<any>(replyToRequestUrl, { ifAccept, activityId: activity.id }).subscribe(() => {
            this.data.publish(activity, 'reply-to-appointment-request');
        });
    }

    public getProviders(serviceTypeId) {
        /*
         * const providerLink = this.linkUtil.parseLink(links, 'serviceProviders');
         * this.getUrl(this.session.sessionAccount.links, 'serviceProviders'); // TODO: Fetch url from session
         */
        const serviceLink = '/api/performers/serviceProviders';
        const serviceUrl = `${ serviceLink }?serviceTypeId=${ serviceTypeId }`;

        return this.network.fetchResource(serviceUrl).pipe(
            mergeMap((response: any[]) => {
                const providers = response.map((provider) => {
                    const providerImageLink = this.linkUtil.parseLink(provider.links, 'providerImage');

                    return this.imageService.getImageNew(providerImageLink, 100).pipe(
                        map((providerImage) => ({ ...provider, providerImage })),
                        catchError(() => of({ ...provider, providerImage: '' })),
                    );
                });

                return forkJoin(providers);
            }),
            defaultIfEmpty([]),
        );
    }

    public getSelectedProvider(link?: string): Observable<Provider> {
        const currentProviderLink = link || localStorage.getItem('current-provider');

        if (this.providerSubject.value === undefined && currentProviderLink) {
            return this.getProviderByLink(currentProviderLink).pipe(
                tap((data) => this.providerSubject.next(data)),
                switchMap(() => this.providerSubject.asObservable()),
            );
        }

        return this.providerSubject.asObservable();
    }

    getProviderById(id: string): Observable<Provider> {
        return this.network.fetchResource<Provider>(`${ this.apiUrl }/${ id }`);
    }

    public createProvider(provider): Observable<ProviderUpdatedResponse> {
        const link = '/api/performers';

        return this.network.postResource<ProviderUpdatedResponse>(link, provider);
    }

    public updateProvider(provider): Observable<ProviderUpdatedResponse> {
        const link = `/api/performers/profile/${ provider.id }`;

        delete provider.id;
        delete provider.links;

        return this.network.putResource(link, provider);
    }

    public deleteProvider(provider): Observable<any> {
        const link = `/api/performers/${ provider.id }`;

        return this.network.deleteResource(link);
    }

    public deletePerformerById(performerId: string): Observable<any> {
        const link = `/api/performers/${ performerId }`;

        return this.network.deleteResource(link);
    }

    private getProviderByLink(link: string): Observable<Provider> {
        return this.network.fetchResource(link).pipe(
            map((iProvider: any) => {
                iProvider.specialtiesObj =
                    iProvider.specialties && iProvider.specialties.length ? iProvider.specialties : [];

                return iProvider;
            }),
            mergeMap((iProvider: any) => {
                let url = this.linkUtil.parseLink(iProvider.links, 'providerImage');

                if (url === '') {
                    url = this.linkUtil.parseLink(iProvider.links, 'image');
                }

                return this.imageService.getImageNew(url, 300).pipe(
                    map((profileImage) => ({ ...iProvider, profileImage })),
                    catchError(() => of({ ...iProvider, profileImage: '' })),
                );
            }),
        );
    }

    public clearProvider() {
        this.providerSubject.next(undefined);
    }

    isOwner(createdByAccountId?: string): Observable<boolean> {
        return new Observable<UserSession>((observer: Observer<any>) => {
            if (this.userSession.sessionAccount) {
                observer.next(this.userSession.sessionAccount);
            } else {
                observer.next(null);
            }
        }).pipe(map((sessionAccount: UserSession) =>
            sessionAccount.accountId && sessionAccount.accountId === createdByAccountId));
    }

    public getOrganizationTypes() {
        const link = '/api/organizations/all';

        return this.network.fetchResource(link);
    }

    public getAppointmentsForMonth(performerId, date, isSuperAdmin: boolean): Observable<Appointment[]> {
        const { startOfMonth, endOfMonth } = DateUtility.getMonthByDate(date);
        const organizationId = localStorage.getItem(ORGANIZATION_ID);
        const link =
            isSuperAdmin && organizationId
                ? `/api/performers/organization/${ organizationId }/appointments?dateFrom=${ startOfMonth }&dateTo=${ endOfMonth }`
                : `/api/performers/${ performerId }/appointments?dateFrom=${ startOfMonth }&dateTo=${ endOfMonth }`;

        return this.network.fetchResource(link).pipe(mergeMap((appointments: Appointment[]) => {
            if (appointments.length) {
                const tasks = appointments.map((c) => {
                    const patientImgLink = this.linkUtil.parseLink(c.links, 'patientImg');

                    if (patientImgLink) {
                        return this.imageService.getImageNew(patientImgLink).pipe(
                            map((patientImg) => ({ ...c, patientImg })),
                            catchError(() => of(c)),
                        );
                    }

                    return of(c);
                });

                return forkJoin(tasks);
            }

            return of([]);
        }));
    }

    public getProviderList(link): Observable<ProviderResponse> {
        return this.network.fetchResource(link);
    }

    public setSelectedOrganization(org: Branch): void {
        this.selectedOrganization.next(org);
    }

    public getSelectedOrganization(): Observable<Branch> {
        return this.selectedOrganization.asObservable();
    }

    public getAppointments(OrgId, startDate, endDate, status) {
        const link = `/api/tasks/${ OrgId }/location?dueDateFrom=${ startDate }&dueDateTo=${ endDate }&status=${ status }`;

        return this.network.fetchResource(link);
    }

    public getAppointmentAnalytics(clinicId, startDate, endDate): Observable<any> {
        const link = `/api/organizations/${ clinicId }/hourly-summary-chart-data`;
        const params = new HttpParams().set('startDate', startDate)
            .set('endDate', endDate);

        return this.network.fetchResource(link, { params });
    }

    public updateProviderOrganization(accountId, clinicId): Observable<any> {
        const link = `/api/accounts/${ accountId }`;

        return this.network.putResource(link, { organizationId: clinicId });
    }

    public getProviderDetails() {
        return this.providerSubject.asObservable();
    }

    public getProvidersByOrgId(orgId): Observable<Provider[]> {
        const link = `/api/performers/${ orgId }/providers`;

        return this.network.fetchResource(link);
    }

    public checkInPatient(patientId: string): Observable<CareplanTaskModel> {
        const link = `/api/tasks/${ patientId }/Check-In-Patient`;

        return this.network.fetchResource(link);
    }

    public getProvidersFromBranches(orgId: string): Observable<Provider[]> {
        const link = `/api/organizations/${ orgId }/providersFromBranches`;

        return this.network.fetchResource(link);
    }

    public getEncounterHistory(patientId: string): Observable<any[]> {
        const link = `/api/encounters/patientEncounters/${ patientId }`;

        return this.network.fetchResource(link);
    }

    public getEncounterClaim(encounterId: string): Observable<any> {
        const link = `/api/encounters/${ encounterId }/CMSByEncounter`;

        return this.network.fetchResource(link);
    }

    public getPatientCarePlans(patientId: string): Observable<any> {
        const link = `/api/carePlans/${ patientId }/getPatientActiveCareplans`;

        return this.network.fetchResource(link);
    }

    public getPatientCarePlanDetail(carePlanId: string): Observable<any> {
        const link = `/api/carePlans/${ carePlanId }`;

        return this.network.fetchResource(link);
    }
}
