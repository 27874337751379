import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ObservableStore } from '@codewithdan/observable-store';
import { Observable, of as observableOf } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import config from '../../../../config';
import { Assessment } from '../../../core/models/assessment.model';
import { TherapyModel } from '../../../core/models/therapy-type';
import { AssessmentService } from '../../../core/services/assessment/assessment.service';
import { NetworkService } from '../../../core/services/network/network.service';
import { SSAssessmentOverview, StoreState } from '../../../core/store/store-state';
import { AssessmentQuestionsStateActions } from '../components/assessment-questions/services/assessment-questions.service';

const initiateState: SSAssessmentOverview = {
    assessment: null,
    questions: null,
    therapy: null,
};

@Injectable({
    providedIn: 'root',
})
export class AssessmentOverviewService extends ObservableStore<StoreState> {
    constructor(private networkService: NetworkService, private assessmentService: AssessmentService) {
        super({});
        this.setState({ assessmentOverview: initiateState }, AssessmentOverviewStateActions.InitState);
    }

    fetchAssessment(assessmentId?: string): Observable<Assessment> {
        if (!assessmentId) {
            assessmentId = localStorage.getItem('current-assessment-id');
        }

        return this.assessmentService.getAssessment(assessmentId).pipe(
            mergeMap((assessment) => {
                this.setState((prevState) => ({ assessmentOverview: { ...prevState.assessmentOverview, assessment } }), AssessmentOverviewStateActions.GetAssessment);
                return this.getTherapy(assessment.id).pipe(map(() => assessment));
            })
        );
    }

    getAssessment(assessmentId?: string): Observable<Assessment> {
        const {
            assessmentOverview: { assessment },
        } = this.getState();

        if (assessment) {
            return observableOf(assessment);
        }

        return this.fetchAssessment(assessmentId);
    }

    fetchTherapy(assessmentId: string): Observable<TherapyModel> {
        const url = '/api/meta/therapy';
        const taskTypeId = config.TASKTYPES.ASSESSMENT;
        const httpParams = new HttpParams()
            .set('taskTypeId', taskTypeId.toString())
            .set('entityType', 'ASSESSMENT')
            .set('entityId', assessmentId);

        return this.networkService
            .fetchResource<TherapyModel[]>(url, { params: httpParams })
            .pipe(
                map(([therapy]) => {
                    this.setState(
                        (prevState) => ({
                            assessmentOverview: { ...prevState.assessmentOverview, therapy },
                        }),
                        AssessmentOverviewStateActions.GetTherapy
                    );

                    return therapy;
                })
            );
    }

    getTherapy(assessmentId?: string): Observable<TherapyModel> {
        const state = this.getState();

        if (!assessmentId) {
            assessmentId = localStorage.getItem('current-assessment-id');
        }

        if (state && state.assessmentOverview && state.assessmentOverview.therapy) {
            return observableOf(state.assessmentOverview.therapy);
        }

        return this.fetchTherapy(assessmentId);
    }

    updateTherapy(therapy) {
        const state = this.getState();

        if (state && state.assessmentOverview && state.assessmentOverview.assessment) {
            this.setState(
                (prevState) => ({
                    assessmentOverview: {
                        ...prevState.assessmentOverview,
                        therapy,
                    },
                }),
                AssessmentOverviewStateActions.UpdateAssessment
            );
        }
    }

    updateAssessment(assessment: Assessment): void {
        const state = this.getState();

        if (state && state.assessmentOverview && state.assessmentOverview.assessment) {
            this.setState(
                (prevState) => ({
                    assessmentOverview: {
                        ...prevState.assessmentOverview,
                        assessment,
                    },
                }),
                AssessmentOverviewStateActions.UpdateAssessment
            );
        }
    }

    assessmentChanged() {
        return this.stateChanged.pipe(
            map((state) => {
                if (state && state.assessmentOverview && state.assessmentOverview.assessment) {
                    return state.assessmentOverview.assessment;
                }
                return null;
            })
        );
    }

    saveQuestion(): void {
        let {
            assessmentOverview: {
                assessment: { numOfQuestions },
            },
        } = this.getState();

        numOfQuestions += 1;

        this.setState(
            (prevState) => ({
                assessmentOverview: {
                    ...prevState.assessmentOverview,
                    assessment: {
                        ...prevState.assessmentOverview.assessment,
                        numOfQuestions,
                    },
                },
            }),
            AssessmentQuestionsStateActions.SaveQuestionAction
        );
    }

    therapyChanged() {
        return this.stateChanged.pipe(
            map((state) => {
                if (state && state.assessmentOverview && state.assessmentOverview.therapy) {
                    return state.assessmentOverview.therapy;
                }
                return null;
            })
        );
    }

    clean() {
        this.setState({ assessmentOverview: initiateState }, AssessmentOverviewStateActions.ClearData);
    }
}

// eslint-disable-next-line no-shadow
export enum AssessmentOverviewStateActions {
    InitState = 'init_state',
    GetAssessment = 'get_assessment',
    UpdateAssessment = 'update_assessment',
    GetTherapy = 'get_therapy',
    ClearData = 'clear_data',
}
