import {EventEmitter, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {
    BehaviorSubject, combineLatest, Observable
} from 'rxjs';
import {distinctUntilChanged, map, tap} from 'rxjs/operators';
import {PatientModel} from 'src/app/core/models/patient.model';
import {OperationUserSessionService} from 'src/app/core/services/user-session/operation-user-session.service';
import {UserSessionService} from 'src/app/core/services/user-session/user-session.service';
import {PatientService} from 'src/app/modules/patients/services/patient.service';
import {
    ClinicAndTimeSlotService
} from 'src/app/shared/modules/service-booking/clinic-and-time-slot/services/clinic-and-time-slot.service';

import {constants} from '../constants';
import {distanceCalculate} from './calculateDistance';

@Injectable({
    providedIn: 'root'
})
export class PatientDataShareService {
    public onParentPatientUpdated = new EventEmitter<PatientModel>();
    public notifyParentOnChildDataLoaded = new EventEmitter<any>();
    public onViewAppointmentDetails = new EventEmitter<any>(null);
    public showHideOnScreenLoader = new EventEmitter<boolean>();
    public onFamilyAccountSwitched = new EventEmitter<PatientModel>();
    public onLanguageChanged = new EventEmitter<string>();
    public onConfirmedFamilyMember = new EventEmitter();
    public consentSubmittedForUnConfMember = new EventEmitter<string>();
    public onPatientTaskDataUpdated = new EventEmitter<any>();
    public isRegistrationUpdateProfile = false;
    public isMemberConsent = false;
    public isFamilyMemberViewing = false;
    public onAdditionalNoteSaveData = new EventEmitter<any>(null);
    public onAdditionalNoteDialogData = new EventEmitter<any>(null);
    private _patient = new BehaviorSubject<PatientModel>(null);

    constructor(
        private router: Router,
        private session: UserSessionService,
        private patientService: PatientService,
        private operationUserSessionService: OperationUserSessionService,
        private clinicAndTimeSlotService: ClinicAndTimeSlotService
    ) {
        this.onParentPatientUpdated.subscribe({
            next: (patient: PatientModel) => {
                if (patient) {
                    this.loggedInPatient = patient;
                }
            }
        });

        const familyLoggedInId = localStorage.getItem(constants.LocalStorageKeys.ImpersonatedFamilyPatientId)
        if (familyLoggedInId) {
            this.patientService.getPatient(familyLoggedInId)
                .subscribe(data => {
                    this.overrideCurrentPatient(data as PatientModel);
                })
        }
    }

    private _impersonateFamilyPatient = new BehaviorSubject<PatientModel>(null);

    get impersonateFamilyPatient() {
        return this._impersonateFamilyPatient.getValue();
    }

    set impersonateFamilyPatient(value) {
        if (value) {
            localStorage.setItem(constants.LocalStorageKeys.ImpersonatedFamilyPatientId, value.id);
        }
        this._impersonateFamilyPatient.next(value);
    }

    get loggedInPatient() {
        return this._patient.getValue();
    }

    set loggedInPatient(value) {
        this._patient.next(value);
    }

    public resetData() {
        this.removeImpersonateFamilyPatient();
        this.loggedInPatient = null;
        this.impersonateFamilyPatient = null;
    }

    public navigateToUrl(url: string) {
        this.router.navigateByUrl(url).then();
    }

    public navigateToDashboard() {
        this.router.navigateByUrl('/curapatient/patient/dashboard').then();
    }

    public navigateToPatientGroup() {
        this.router.navigateByUrl('/curapatient/organizations/organization/patient-group').then();
    }

    public navigateToAddNewFamilyMember() {
        this.router.navigateByUrl('/curapatient/patient/family/add-member').then();
    }

    public navigateToLogin() {
        this.router.navigateByUrl('/login').then();
    }

    public navigateToLoginAndRedirectToService() {
        this.router.navigateByUrl('/login?r=ps').then();
    }

    public reloadCurrentRoute() {
        const currentUrl = this.router.url;
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate([currentUrl]);
        });
    }

    public navigateToBookService(serviceIdToBook: any, serviceName: string) {
        this.router.navigateByUrl(
            '/curapatient/patient/service-centre',
            {state: {serviceId: serviceIdToBook, serviceName}}
        ).then();
    }

    public navigateToConfirmFamilyMemberAssessment(
        serviceIdToBook: any,
        serviceName: string,
        patientId: string,
        surveyId: string,
        orgId: string,
        isConfirmFamilyMember: boolean
    ) {
        this.router.navigateByUrl(
            '/curapatient/patient/service-centre',
            {
                state: {
                    serviceName,
                    patientId,
                    isConfirmFamilyMember,
                    familyOrganizationId: orgId,
                    surveys: {
                        surveyId,
                        organizationSurveyId: serviceIdToBook
                    }
                }
            }
        ).then();
    }

    public navigateToServiceBooking() {
        this.router.navigateByUrl(
            '/curapatient/patient/service-centre',
            {state: {bookService: true}}
        ).then();
    }

    public navigateToEditPatientProfile(insuranceShow: boolean = false) {
        this.router.navigateByUrl('/curapatient/patient/profile/edit', {state: {insuranceShow}}).then();
    }

    public gotoEducationCenter() {
        this.router.navigateByUrl('/curapatient/patient/education-center').then();
    }

    public navigateToRescheduleAppointment(appointmentTask: any, returnUrlIfNotDashboard?: string) {
        if (!returnUrlIfNotDashboard) returnUrlIfNotDashboard = '/curapatient/patient/dashboard';
        this.clinicAndTimeSlotService.setReschedule({
            appointmentTaskId: appointmentTask.id,
            locationID: appointmentTask.location.id,
            patientId: appointmentTask.patientId,
            returnURL: returnUrlIfNotDashboard,
        }, {
            testingType: appointmentTask.goalName,
            manufacture: appointmentTask.inventoryName,
            organizationId: appointmentTask.organizationId
        });
        this.router.navigateByUrl('/curapatient/patient/schedule-appointment');
    }

    /** @deprecated This function does not auto fill service data, only redirects. */
    public navigateToScheduleAppointment(appointmentTask: any, secondaryAppointmentTask?: any) {
        this.router.navigateByUrl('/patient-registration/services').then();
    }

    public navigateToCompleteAssessment(assessmentId: string, taskId: string, patientId: string) {
        const url = `/curapatient/patient/appointment/assessment/${assessmentId}?patientId=${patientId}&taskId=${taskId}`;
        this.router.navigateByUrl(url).then();
    }

    public navigateToViewFamilyMembers() {
        this.router.navigateByUrl('/curapatient/patient/family', {state: {isFamilyDetailView: true}}).then();
    }

    public navigateToFamilyRegistrationServices() {
        this.router.navigateByUrl('/curapatient/patient/patient-registration/services');
    }

    public navigateToAppointments() {
        this.router.navigateByUrl('/curapatient/patient/appointments');
    }

    public isPatientUserLoggedIn(): boolean {
        return <boolean>JSON.parse(localStorage.getItem('isUserPatient'));
    }

    public removeImpersonateFamilyPatient() {
        this.impersonateFamilyPatient = undefined;
        localStorage.removeItem(constants.LocalStorageKeys.ImpersonatedFamilyPatientId);
    }

    public getImpersonateFamilyPatientId(): string {
        return localStorage.getItem(constants.LocalStorageKeys.ImpersonatedFamilyPatientId);
    }

    public getCurrentPatient(): Observable<PatientModel> {
        return combineLatest([this._impersonateFamilyPatient, this._patient, this.patientService.getCurrentPatient()])
            .pipe(
                map((data) => data[0] || data[1] || data[2]),
                distinctUntilChanged((prev, curr) => prev?.id === curr?.id)
            );
    }

    public overrideCurrentPatient(patient: PatientModel) {
        if (!patient) return;

        if (this.getImpersonateFamilyPatientId() === patient.id) {
            this.impersonateFamilyPatient = patient;
        } else {
            this.loggedInPatient = patient;
        }
    }

    public setCurrentPatientIfNotAlready(patient: PatientModel) {
        if (!patient) return;

        if (this.getImpersonateFamilyPatientId() && !this.impersonateFamilyPatient
            && this.getImpersonateFamilyPatientId() == patient.id) {
            this.impersonateFamilyPatient = patient;
        } else if (!this.loggedInPatient) {
            this.loggedInPatient = patient;
        }
    }

    public checkAuthorization(doNotKickOutUnauthorized: boolean = false): boolean {
        if (!this.session.getIsLoggedIn() || !this.session.token
            || !this.session.token.access_token || !this.isPatientUserLoggedIn()) {
            if (!doNotKickOutUnauthorized) {
                this.navigateToLogin();
            }
            return false;
        }
        return true;
    }

    distanceCalculate(lat1, lon1, lat2, lon2, unit) {
        return distanceCalculate(lat1, lon1, lat2, lon2, unit);
    }

    getPosition(): Promise<any> {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (resp) => {
                    resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    checkPatientProfileCompleteness() {
        if (this.loggedInPatient?.profileCompleteness < constants.ProfileChildComponentNames.ProfileCompletenessScore) {
            this.isRegistrationUpdateProfile = true;
            this.navigateToEditPatientProfile();
        }
    }

    checkFamilyProfileCompleteness(currentPatient) {
        if (currentPatient?.profileCompleteness < constants.ProfileChildComponentNames.ProfileCompletenessScore) {
            this.isRegistrationUpdateProfile = true;
            this.navigateToEditPatientProfile();
        }
    }

    public navigateToServices() {
        this.router.navigateByUrl('/patient-registration/services').then(() => {
            window.location.reload();
        });
    }

    public getPatientTimeZone() {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    public isAdmin() {
        return this.operationUserSessionService.getUserSession();
    }
}
