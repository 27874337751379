<ng-container *ngIf="recentlyViewedOrganizations$ | async; let recentlyViewedOrganizations">
  <div class="recently-viewed-container" *ngIf="recentlyViewedOrganizations.length">
    <div class="recently-viewed bordered">
      <owl-carousel-o [options]="options">
        <ng-container *ngFor="let org of recentlyViewedOrganizations">
          <ng-template carouselSlide>
            <div class="recently-viewed-org" (click)="orgSelected(org)">
              <div class="closeIcon" (click)="removeOrgFromRecentlyVisited($event, org)"><mat-icon>close</mat-icon></div>
              <div class="img-org">
                <img
                  [ngSrc]="(org.links | image: 'organizationImage':40 | async) || orgNoImage"
                  width="40"
                  height="40"
                  alt="Profile icon"
                  class="w-40px border-radius-100"
                />
              </div>
              <span>
              <div class="main_gray_08 mr-0 fs-10 fw-500">Recently Viewed</div>
              <h4 class="mr-0 mt-0 fs-14 text-dark fw-500" matTooltip="{{org.name}}">{{org.name | truncateText: 20}}</h4>
            </span>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>
</ng-container>
