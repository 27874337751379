import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { Address } from "src/app/core/models/address.model";
import { IInventoryName } from "src/app/core/models/inventory.model";
import { ServiceBookingDetailsModel } from "src/app/core/models/patient.model";
import { distanceCalculate } from "src/app/modules/patient-overview/shared/services/calculateDistance";
import {
    AllAppointmentsService,
    IAllDatesRequest,
} from "../../../../../core/services/appointments/all-appointments/all-appointments.service";
import { SnackbarService } from "../../../../../core/services/snackbar.service";
import { ServiceBookingDataShareService } from "../../app-services/service-booking-data-share.service";
import { ISelectedSlot } from "../components/slots/slots.component";

export interface IClinicsAndDates {
    date: Date;
    userAddress: Address;
    availableAppointments: IAllDatesRequest;
    isLoading: boolean;
    isReschedule: {
        // not implemented
        appointmentTaskId: string;
        locationID: string;
        patientId: string;
        returnURL: string;
    };
    filters: {
        distance: number;
        zipCode: number;
    };
    serviceData: {
        testingType: string;
        manufacture: IInventoryName;
        organizationId: string;
    };
}

@Injectable({
    providedIn: "root",
})
export class ClinicAndTimeSlotService {
    private clinicsInformation = new BehaviorSubject<IClinicsAndDates>({
        date: new Date(),
        userAddress: undefined,
        availableAppointments: { clinics: [], dates: [] },
        isLoading: false,
        isReschedule: undefined,
        filters: {
            distance: undefined,
            zipCode: undefined,
        },
        serviceData: {
            testingType: undefined,
            manufacture: undefined,
            organizationId: undefined,
        },
    });

    /** untouched data by other components, used for filtering */
    private clinicsInformationData: IClinicsAndDates;

    private selectService = new Subject<ISelectedSlot>();
    private focusClinic = new Subject<any>();

    private serviceData: ServiceBookingDetailsModel;

    constructor(
        private allAppointmentsService: AllAppointmentsService,
        private serviceBookingDataShareService: ServiceBookingDataShareService,
        private snackbarService: SnackbarService,
    ) {
        this.init();
    }

    getSelection() {
        return this.clinicsInformation;
    }

    setLoading(isLoading: boolean) {
        const tempSelection = this.clinicsInformation.getValue();
        tempSelection.isLoading = isLoading;
        this.clinicsInformation.next(tempSelection);
    }

    setReschedule(reschedule: IClinicsAndDates["isReschedule"], serviceData?: IClinicsAndDates["serviceData"]) {
        const tempSelection = this.clinicsInformation.getValue();
        tempSelection.isReschedule = reschedule;
        this.setServiceData(serviceData);
        this.clinicsInformation.next(tempSelection);
    }

    setServiceData(serviceData: IClinicsAndDates["serviceData"]) {
        const tempSelection = this.clinicsInformation.getValue();
        tempSelection.serviceData = serviceData;
        this.clinicsInformation.next(tempSelection);
    }

    /** Update date to which user wants to book an appointment by default it is set to current date. */
    updateDate(date: Date) {
        const tempSelection = this.clinicsInformation.getValue();
        tempSelection.date = date;
        this.clinicsInformation.next(tempSelection);
        this.updateData();
    }

    /** Set user current location or user can enter their chosen address */
    updateAddress(address: Address, reloadData = false) {
        const tempSelection = this.clinicsInformation.getValue();
        tempSelection.userAddress = address;
        this.clinicsInformation.next(tempSelection);
        if (reloadData) this.updateData();
    }

    /** If the user wants to filter by zipCode or distance from their selected location. */
    updateFilters(filters: IClinicsAndDates["filters"]) {
        if (!filters) return;
        this.clinicsInformationData.filters = filters;
        this.filterClinic();
    }

    /** Used by google maps to highlight a clinic. Used in [clinic-map] */
    setFocusClinic(clinic) {
        this.focusClinic.next(clinic);
    }

    /** Get a clinic which google maps has clicked on. Used in [clinic-map] */
    getFocusClinic() {
        return this.focusClinic;
    }

    /** Emits void when a value is sent to serviceBookingDataShareService */
    /** This is done for backwards compatibility. */
    getEmittedSelectedSlot() {
        return this.selectService;
    }

    /** Emit void when a component has set a value in serviceBookingDataShareService */
    emitSelectedSlot(slot: ISelectedSlot) {
        this.selectService.next(slot);
        this.clearSlots();
    }

    /** Internal Service functions */
    private init() {
        this.monitorServiceData();
    }

    /** Get data for which service user wants to register. */
    private monitorServiceData() {
        return this.serviceBookingDataShareService.getServiceBookingDataSubject().subscribe((data) => {
            this.serviceData = data;
        });
    }

    private updateData(clinicInformation = this.clinicsInformation.getValue()) {
        this.getAppointmentsOpenDays(clinicInformation).then((r) => {});
    }

    /** Pull available clinics and which dates are open for registration */
    private async getAppointmentsOpenDays(clinicInformation: IClinicsAndDates) {
        if (clinicInformation.isLoading || !clinicInformation.userAddress) return;

        this.clearSlots();
        this.setLoading(true);

        const service = this?.serviceData?.services[0];
        const rescheduleData = clinicInformation.isReschedule;

        if (!service && !clinicInformation.serviceData) {
            return;
        }

        if (!clinicInformation?.serviceData?.organizationId) {
            this.snackbarService.error("Error: Organization ID not found, can not get appointments.");
        }

        try {
            const tempSelection = clinicInformation;
            tempSelection.availableAppointments.clinics = [];
            tempSelection.availableAppointments.dates = [];

            this.allAppointmentsService
                .getAppointments({
                    date: clinicInformation.date,
                    testingType: clinicInformation?.serviceData?.testingType || service?.metaData?.testingType,
                    manufacture: clinicInformation?.serviceData?.manufacture || (service?.name as any),
                    organizationId: clinicInformation?.serviceData?.organizationId,
                    state: clinicInformation.userAddress.state,
                })
                .then((appointmentData) => {
                    if (!appointmentData?.clinics) return;
                    tempSelection.isLoading = false;
                    tempSelection.availableAppointments.clinics = [
                        ...tempSelection.availableAppointments.clinics,
                        ...appointmentData.clinics,
                    ];
                    tempSelection.availableAppointments.dates = [
                        ...tempSelection.availableAppointments.dates,
                        ...appointmentData.dates,
                    ];
                    this.calculateDistanceFromLocation(tempSelection);
                    this.clinicsInformationData = tempSelection;
                    this.filterClinic();
                });
        } catch (e) {
            throw new Error(e);
        }
    }

    /** Adds a distance prototype to the clinic, The distance is from users inputted address to the clinic.  */
    private calculateDistanceFromLocation(data: IClinicsAndDates) {
        data.availableAppointments.clinics.forEach((clinic) => {
            const selectedLocation = data.userAddress;
            const clinicAddress = clinic.clinicOrganization.address;
            const distance = distanceCalculate(
                selectedLocation.gpsLatitude,
                selectedLocation.gpsLongitude,
                clinicAddress.gpsLatitude,
                clinicAddress.gpsLongitude,
                "mi",
            );
            clinic.distance = Math.ceil(distance);
        });
        data.availableAppointments.clinics.sort((a, b) => a.distance - b.distance);
    }

    /** Filter out unneeded zipCodes and distance which are too far from users location. */
    private filterClinic() {
        const tempSelection: IClinicsAndDates = JSON.parse(JSON.stringify(this.clinicsInformationData));

        /** filter out all non required zipCodes */
        if (tempSelection.filters.zipCode) {
            const zip = tempSelection.filters.zipCode.toString();
            tempSelection.availableAppointments.clinics = tempSelection.availableAppointments.clinics.filter(
                (clinic) => Number(clinic.clinicOrganization?.address?.zipcode) === Number(zip),
            );
        }

        /** filter out clinics that are not within distance radius. */
        if (tempSelection.filters.distance) {
            tempSelection.availableAppointments.clinics = tempSelection.availableAppointments.clinics.filter(
                (clinic) => Number(clinic.distance) <= Number(tempSelection.filters.distance),
            );
        }

        this.clinicsInformation.next(tempSelection);
    }

    /** Reset to default */
    private clearSlots() {
        this.clinicsInformationData = {
            date: new Date(),
            userAddress: undefined,
            availableAppointments: { clinics: [], dates: [] },
            isLoading: false,
            isReschedule: undefined,
            filters: {
                distance: undefined,
                zipCode: undefined,
            },
            serviceData: undefined,
        };
        this.clinicsInformation.next(this.clinicsInformationData);
    }
}
